import React, { useEffect, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Avatar } from "@mui/material";

function AvatarIcon(props) {
  const { shape, size, source, className, alt } = props;
  const { platfromRebrandPhase1 } = useFlags();

  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    if (source instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAvatarUrl(e.target.result);
      };
      reader.readAsDataURL(source);
    } else {
      setAvatarUrl(source);
    }
  }, [source]);

  return (
    <div className={className}>
      <Avatar
        variant={shape == "rounded" ? "rounded" : "circle"}
        src={avatarUrl}
        imgProps={{ crossOrigin: "anonymous" }}
        alt={platfromRebrandPhase1 ? alt : ""}
        sx={
          size
            ? {
                ...size,
              }
            : {}
        }
        fullwidth="true"
      />
    </div>
  );
}

export default AvatarIcon;

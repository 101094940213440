import React from "react";
import { Chip } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import { STATE_LABELS } from "components/Screens/CustomerOnboarding/constants";

import "./CustomerOnboardingInfoTile.scss";

function CustomerOnboardingInfoTile({ icon, heading, description, state }) {
  const { platfromRebrandPhase1 } = useFlags();

  return (
    <div className="onboarding-info-tile">
      <div className="onboarding-info-tile__content">
        <div
          className={
            platfromRebrandPhase1 ? "onboarding-info-tile__icon-wrapper-rebrand" : "onboarding-info-tile__icon-wrapper"
          }
        >
          {icon}
        </div>
        <div className="onboarding-info-tile__text">
          <div className="tile-heading">{heading}</div>
          <div className="tile-description">{description}</div>
        </div>
      </div>
      {state && (
        <Chip
          className={`para-body-s-medium onboarding-info-tile__state ${state}`}
          size="small"
          variant="filled"
          id="no-of-leaves"
          label={STATE_LABELS[state]}
        />
      )}
    </div>
  );
}

export default CustomerOnboardingInfoTile;

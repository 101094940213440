import React, { useState } from "react";

import MailOutlineIcon from "@mui/icons-material/MailOutline";

import GetHelpModal from "components/Screens/CustomerOnboarding/GetHelpModal";

import "./GetHelpSection.scss";

function GetHelpSection() {
  const [showGetHelpModal, setShowGetHelpModal] = useState(false);

  const handleGetHelpClick = () => {
    setShowGetHelpModal(true);
  };

  return (
    <>
      <div className="customer-onboarding-need-help-section">
        <div onClick={handleGetHelpClick}>
          <div className="w-100 flex justify-center items-center">
            <div className="need-help-text-container">
              <h3 className="para-body-m-semibold color-text-body-secondary">Need help?</h3>
              <p className="color-text-body-primary para-body-s-regular mr-2">
                We're here to assist with any questions or issues.
              </p>
            </div>
            <div className="need-help-email-icon-wrapper">
              <MailOutlineIcon />
            </div>
          </div>
        </div>
      </div>
      <GetHelpModal showGetHelpModal={showGetHelpModal} setShowGetHelpModal={setShowGetHelpModal} />
    </>
  );
}

export default GetHelpSection;

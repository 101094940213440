import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import PageLoader from "components/common/PageLoader";
import CustomAvatar from "components/common/Avatar";
import Menu, { MenuItem } from "components/common/Menu";
import Notifications from "components/Widgets/NotificationsV2";
import { usePermissions } from "components/Hook";

import ExpandSidebarMenuIcon from "assets/svgs/ExpandSidebarMenuIcon";

import { LogoutUser } from "store/actions/Logout";

import { baseUrl } from "util/APIBaseUrl";

import { getUserAdditionalInfo } from "selectors/user";

import { USER_TYPES } from "constants/index";
import { COMMUNITY_ROUTE, CUSTOMER_PROFILE_ROUTE, EMPLOYEE_PROFILE_ROUTE, SETTINGS_ROUTE } from "constants/routes";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import "./navbar.scss";

function AppHeaderNew(props) {
  const { open, setOpen, userDetails = {}, setCloseSideBarFromMenuIcon = () => {} } = props;
  const { logout: auth0Logout, isAuthenticated } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sideBarRevamp } = useFlags();

  const { permissions } = userDetails || {};

  const [userProfileUrl, setUserProfileUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const { loggedInUser, loggedInUserType } = usePermissions();
  const userAdditionalInfo = useSelector(getUserAdditionalInfo);

  const { userId, name, email, type } = loggedInUser || {};
  const { pictureVersion, customerId } = userAdditionalInfo || {};

  const menuRef = useRef(null);
  const anchorRef = useRef(null);

  const logoutUser = () => {
    setIsLoading(true);
    dispatch(LogoutUser());

    window.analytics?.track("Logged Out", {
      channel: "Button",
      name,
      userID: userId,
      email,
      accountType: type,
    });

    if (isAuthenticated) {
      auth0Logout({ returnTo: window.location.origin });
    }
  };

  useEffect(() => {
    let userProfilePath = {};

    if (loggedInUser) {
      userProfilePath = {
        customer: `${baseUrl}/customerUser/${userId}/logo?id=${pictureVersion}`,
        edge: `${baseUrl}/user/${userId}/logo?id=${pictureVersion}`,
        employee: `${baseUrl}/employee/${userId}/logo?id=${pictureVersion}`,
      };
    }
    setUserProfileUrl(userProfilePath[loggedInUserType]);
  }, [loggedInUserType, userId, customerId, pictureVersion, loggedInUser]);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleClose = () => {
    setMenuOpen(false);
  };

  const navigateToProfile = () => {
    const profileRoute = type === USER_TYPES.EMPLOYEE ? EMPLOYEE_PROFILE_ROUTE : CUSTOMER_PROFILE_ROUTE;
    navigate(profileRoute);
    setMenuOpen(false);
  };

  const getNotificationUserId = () => {
    if (loggedInUserType === USER_TYPES.CUSTOMER) {
      return customerId || "";
    }
    if (loggedInUserType === USER_TYPES.EMPLOYEE) {
      return loggedInUser?.userId || "";
    }
    return "";
  };

  const notificationUserId = getNotificationUserId();

  return (
    <>
      {isLoading && <PageLoader />}
      <header className="navbar-header" style={{ display: location?.pathname === COMMUNITY_ROUTE ? "none" : null }}>
        <div className="flex justify-between items-center h-full">
          <div className="flex items-center">
            {!sideBarRevamp && (
              <div
                className={`sidebar-toggle ${open ? "sidebar-open" : "sidebar-closed"}`}
                onClick={() => {
                  setCloseSideBarFromMenuIcon(true);
                  setOpen(!open);
                }}
              >
                <ExpandSidebarMenuIcon
                  className={location?.pathname === COMMUNITY_ROUTE ? "icon-white" : "icon-dark"}
                />
              </div>
            )}
          </div>

          <div className="navbar-actions flex items-center">
            <Notifications userId={notificationUserId} />

            <div className="user-menu-container" ref={menuRef}>
              <div
                className={`user-menu-trigger flex items-center cursor-pointer py-05 ${menuOpen ? "menu-open" : ""}`}
                onClick={toggleMenu}
                ref={anchorRef}
              >
                <div className="user-avatar">
                  <CustomAvatar src={userProfileUrl} size="md" />
                </div>
                <ArrowDropDownIcon className="dropdown-icon" />
              </div>

              {menuOpen && (
                <Menu
                  open={menuOpen}
                  onClose={handleClose}
                  anchorEl={anchorRef.current}
                  minWidth="280px"
                  autoWidth
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <div className="flex items-center p-075 gap-1">
                    <CustomAvatar src={userProfileUrl} size="lg" />
                    <div className="user-info">
                      <p className="heading-h5-regular">{name || ""}</p>
                      <p className="user-email heading-h5-regular">{email || ""}</p>
                    </div>
                  </div>

                  <div className="divider-bottom" />

                  <MenuItem value="Profile" onClick={navigateToProfile} />

                  {permissions?.includes("CustomerAdmin") && (
                    <MenuItem
                      value="Account Settings"
                      onClick={() => {
                        navigate(SETTINGS_ROUTE);
                        setMenuOpen(false);
                      }}
                    />
                  )}
                  <div className="divider-bottom" />
                  <MenuItem
                    value="Logout"
                    onClick={() => {
                      logoutUser();
                      setMenuOpen(false);
                    }}
                  />
                </Menu>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default AppHeaderNew;

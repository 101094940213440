import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Dialog, { DialogTitle, DialogContent, DialogActions } from "components/common/Dialog";
import Button from "components/common/Button";
import Text from "components/common/Text";
import Select from "components/common/Select";
import { ONBOARDING_GET_HELP_DROPDOWN_OPTIONS } from "components/Screens/CustomerOnboarding/constants";
import HelpSuccessModal from "components/Screens/CustomerOnboarding/HelpSuccessModal";

import GetHelpSchema from "validations/Onboarding/GetHelpSchema";
import { customerOnboardingGetHelp } from "services/customer";

import MailOutlineIcon from "@mui/icons-material/MailOutline";

import "./GetHelpModal.scss";

function GetHelpModal({ showGetHelpModal, setShowGetHelpModal }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const methods = useForm({
    resolver: yupResolver(GetHelpSchema),
    mode: "onChange",
  });

  const handleClose = () => {
    setShowGetHelpModal(false);
    methods.reset();
  };

  const handleSuccessModalClose = () => {
    setSuccess(false);
  };

  const { handleSubmit, formState } = methods;
  const { isValid } = formState;

  const onSubmit = async (payload = {}) => {
    try {
      setLoading(true);
      await customerOnboardingGetHelp(payload);
      handleClose();
      setSuccess(true);
    } catch (error) {
      console.error("Something went wrong:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={showGetHelpModal}
        onClose={handleClose}
        title={
          <DialogTitle
            title={
              <div className="flex flex-col items-start">
                <div className="mail-icon-container">
                  <MailOutlineIcon />
                </div>
                <h2 className="heading-h2-semibold color-text-heading-primary m-0 mt-1">We're here to help</h2>
              </div>
            }
          />
        }
        content={
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent>
                <div className="onboarding-get-help-dialogue-main-div">
                  <Select
                    label="What type of issue are you experiencing?"
                    options={ONBOARDING_GET_HELP_DROPDOWN_OPTIONS}
                    name="issueType"
                    placeholder="Select"
                  />
                  <Text
                    name="issueDetails"
                    multiline
                    rows={5}
                    size="medium"
                    label="How can we help you? Please describe your issue or question."
                  />
                  <p className="get-help-modal-max-words">Max 500 words</p>
                </div>
              </DialogContent>
            </form>
          </FormProvider>
        }
        actions={
          <DialogActions
            action1={<Button onClick={handleClose} variant="text" label="Cancel" size="large" />}
            action2={
              <Button
                onClick={handleSubmit(onSubmit)}
                isDisabled={!isValid}
                isLoading={loading}
                className="text-blue-600"
                label="Send Message"
                size="large"
              />
            }
          />
        }
        size="sm"
      />
      <HelpSuccessModal open={success} handleClose={handleSuccessModalClose} />
    </>
  );
}

export default GetHelpModal;

import React from "react";
import { useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";

import { usePermissions } from "components/Hook";

import "./PayrollStubs.css";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function PayrollStubs() {
  const payrollData = useSelector((state) => state?.EmployeeSalaryDetails?.employeeSalaryDetails);

  const { loggedInUser } = usePermissions();
  const { platfromRebrandPhase1 } = useFlags();

  const handleDownloadSlip = async (payrollId) => {
    const response = await getRequest(
      `${baseUrl}/employee/${loggedInUser?.userId}/payroll/${payrollId}?action=pdf`,
      true,
      "export"
    );

    if (!response?.ok) {
      return;
    }
    const url = window.URL.createObjectURL(response?.payload);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;

    link.download = "Salary Slip";
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="ep-payroll-stubs">
      <div className="ep-payroll-stubs-h1">Recent Paystubs</div>

      <div style={{ border: "0.1px solid #eff0fe" }} />

      <div>
        {(payrollData.ok ? payrollData.payload.rows.slice(-3) : [])?.reverse()?.map((stub) => {
          return (
            <div>
              <div className="ep-payroll-stubs-item" id="payroll-stubs">
                <div className="ep-payroll-stubs-details">
                  <img
                    src={
                      platfromRebrandPhase1
                        ? "../assets/Images/arrow-slashed-rebrand.svg"
                        : "../assets/Images/arrow-slashed.svg"
                    }
                    alt="Arrow icon"
                  />
                  <div>
                    <div className="ep-payroll-stubs-month">{`${
                      monthNames[new Date(stub.paymentDate).getMonth()]
                    }' ${new Date(stub.paymentDate).getFullYear()}`}</div>
                    <div className="ep-payroll-stubs-date">
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {stub?.status === "paid" ? "Paid" : stub?.status === "un-paid" ? "In Process" : null}
                    </div>
                  </div>
                </div>
                {stub?.status === "paid" && (
                  <img
                    src="../assets/Images/download.svg"
                    alt="Download payslip"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDownloadSlip(stub?.payrollId);
                    }}
                  />
                )}
              </div>
              <div style={{ border: "0.1px solid #eff0fe" }} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PayrollStubs;

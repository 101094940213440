import { format } from "date-fns";
import jwtDecode from "jwt-decode";

import { MONTH_NAMES, MONTH_NAMES_SHORT } from "../constants";

const getOrdinalSuffix = (day) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const getDateInFormat = (dateStr) => {
  const date = dateStr?.includes("T") ? new Date(dateStr) : new Date(`${dateStr}T00:00:00Z`);
  const day = date.getUTCDate();
  const month = MONTH_NAMES_SHORT[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  return `${day}${getOrdinalSuffix(day)} ${month}' ${year}`;
};

export const getDateInDashForCalendar = (dateStr) => {
  const year = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    timeZone: "UTC",
  }).format(new Date(dateStr));

  const month = new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    timeZone: "UTC",
  }).format(new Date(dateStr));
  const date = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    timeZone: "UTC",
  }).format(new Date(dateStr));

  return `${year}-${month}-${date}`;
};

export const getDateInDash = (dateStr) => {
  const dateObject = new Date(dateStr);
  const year = dateObject.getFullYear();
  let month = dateObject.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let date = dateObject.getDate();
  if (date < 10) {
    date = `0${date}`;
  }
  return `${year}-${month}-${date}`;
};

export const getMonthYearFromDate = (dateStr) => {
  if (dateStr) {
    const dateObject = new Date(dateStr);
    return `${MONTH_NAMES_SHORT[dateObject.getMonth()]}’ ${dateObject.getFullYear()}`;
  }
  return "-";
};

export const getFullMonthYearFromDate = (dateStr) => {
  if (dateStr) {
    const dateObject = new Date(dateStr);
    return `${MONTH_NAMES[dateObject.getUTCMonth()]} ${dateObject.getUTCFullYear()}`;
  }
  return "-";
};

export const getFullMontDateYearFromDate = (dateStr) => {
  if (dateStr) {
    return format(new Date(dateStr), "MMM d, yyyy");
  }
  return "_";
};

export const getGreetingMessageFromDate = () => {
  const msg = new Date().getHours() < 12 ? "Morning" : "Evening";
  return msg;
};

export const formatHeader = (header) => {
  return header.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
};

export const getPlaceholderText = (header, selectedOptions) => {
  if (selectedOptions[header]?.length) {
    return null;
  }

  switch (header) {
    case "customers":
      return "Type To Search Customer";
    case "paymentMethodType":
      return "Payment Method Type";
    default:
      return `Select ${formatHeader(header)}`;
  }
};

export const getDecodedToken = (token = null) => {
  if (token) {
    return jwtDecode(token);
  }
  return {};
};

export const getLoggedInUserDetailsFromLocalStorage = () => JSON.parse(localStorage.getItem("loggedInUser")) ?? {};

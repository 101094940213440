import React from "react";
import { useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Stepper, Step, StepLabel } from "@mui/material";

import useView from "components/Hooks/useViewHook";
import PageLoader from "components/common/PageLoader";
import GetHelpSection from "components/Screens/CustomerOnboarding/GetHelpSection";

import { ONBOARDING_SIDEBAR_STEPS } from "components/Screens/CustomerOnboarding/constants";

import "./CustomerOnboardingSidebar.scss";

export function getStrokeColor(isPaymentSuccess, platfromRebrandPhase1, isCreateMeetingStarted, isMeetingCreated) {
  if (isPaymentSuccess) {
    if (isMeetingCreated) return "var(--color-border-success)";
    if (isCreateMeetingStarted) return "var(--color-surface-secondary-inverse)";
    return "var(--color-border-success)";
  }

  if (platfromRebrandPhase1) {
    return "var(--color-surface-secondary-inverse)";
  }
  return "var(--color-border-primary-hover)";
}

function DesktopStepper({ isPaymentSuccess, isCreateMeetingStarted, isMeetingCreated }) {
  const { platfromRebrandPhase1 } = useFlags();

  const getStepClass = (isStepCompleted, isStepActive) => {
    if (isStepCompleted) return "completed";
    if (isStepActive) return "active";
    return "inactive";
  };

  return (
    <div className="customer-onboarding-desktop-view-main-div">
      <div className="desktop-stepper">
        <p className="pb-15 pt-2 color-text-heading-primary heading-h4-semibold">
          Complete these steps to set up your account
        </p>
        <Stepper activeStep="1" orientation="vertical">
          {ONBOARDING_SIDEBAR_STEPS.map(({ label, description }, index) => {
            let isStepCompleted = false;
            if (isPaymentSuccess && index === 0) {
              isStepCompleted = true;
            }
            if (isMeetingCreated && index === 1) {
              isStepCompleted = true;
            }

            let activeStep = 1;
            if (isMeetingCreated || isCreateMeetingStarted) {
              activeStep = 2;
            }

            const isStepActive = index + 1 === activeStep;

            return (
              <Step key={label} completed={isStepCompleted}>
                <StepLabel className={`custom-step-label ${getStepClass(isStepCompleted, isStepActive)}`}>
                  <div
                    className={`stepper-content ${isStepActive ? "active" : "inactive"}`}
                    style={{
                      borderColor: getStrokeColor(
                        isPaymentSuccess,
                        platfromRebrandPhase1,
                        isCreateMeetingStarted,
                        isMeetingCreated
                      ),
                    }}
                  >
                    <p className="para-body-m-semibold color-text-heading-primary">{label}</p>
                    <p className="para-body-s-regular color-text-body-secondary">{description}</p>
                  </div>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>

      <GetHelpSection />
    </div>
  );
}

function MobileStepperIndicator({
  isPaymentSuccess,
  isCreateMeetingStarted,
  isMeetingCreated,
  currentStep,
  totalSteps,
  nextStep,
}) {
  const { platfromRebrandPhase1 } = useFlags();
  const stepDetails = ONBOARDING_SIDEBAR_STEPS[currentStep - 1];

  return (
    <div className="onboarding-mobile-view-main-div">
      <p className="color-text-heading-primary heading-h4-semibold">Complete these steps to set up your account</p>
      <div className="mobile-step-indicator">
        <div className="step-progress">
          <div className="progress-ring">
            <svg width="48" height="48">
              <circle className="progress-ring-circle-bg" strokeWidth="4" fill="transparent" r="20" cx="24" cy="24" />
              <circle
                className="progress-ring-circle"
                strokeWidth="4"
                fill="transparent"
                r="20"
                cx="24"
                cy="24"
                stroke={getStrokeColor(
                  isPaymentSuccess,
                  platfromRebrandPhase1,
                  isCreateMeetingStarted,
                  isMeetingCreated
                )}
                strokeDasharray={`${(currentStep / totalSteps) * 125.6} 125.6`}
              />
            </svg>
            <div className="step-count">
              {currentStep} of {totalSteps}
            </div>
          </div>
        </div>
        <div>
          <h2 className="heading-h5-semibold color-text-heading-primary p-0 m-0">{stepDetails.label}</h2>
          <p className="para-body-s-regular color-text-heading-primary">{stepDetails.description}</p>
          {nextStep && (
            <div className="next-step">
              <span className="para-body-s-regular color-text-body-primary">Next: </span>
              <span className="para-body-s-regular color-text-body-primary">{nextStep}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function CustomerOnboardingSidebar({ isPaymentSuccess, isCreateMeetingStarted, isMeetingCreated }) {
  const { isMobile, isDesktop } = useView();
  const loadingAddBankAccountInfo = useSelector((state) => state.AddBankAccountInfo.loadingAddBankAccountInfo);
  return (
    <>
      {loadingAddBankAccountInfo && <PageLoader />}

      {isDesktop && (
        <DesktopStepper
          isPaymentSuccess={isPaymentSuccess}
          isCreateMeetingStarted={isCreateMeetingStarted}
          isMeetingCreated={isMeetingCreated}
        />
      )}
      {isMobile && (
        <MobileStepperIndicator
          isPaymentSuccess={isPaymentSuccess}
          isCreateMeetingStarted={isCreateMeetingStarted}
          isMeetingCreated={isMeetingCreated}
          currentStep={isPaymentSuccess && isCreateMeetingStarted ? 2 : 1}
          totalSteps={2}
          nextStep={!isPaymentSuccess ? "Schedule a kickoff call" : ""}
        />
      )}
    </>
  );
}

export default CustomerOnboardingSidebar;

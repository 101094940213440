import React from "react";

import "./NoResultsFound.scss";

function NoResultsFound() {
  return (
    <div className="no-result-container">
      <p>No results found</p>
    </div>
  );
}

export default NoResultsFound;

import React from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import PageLoader from "components/common/PageLoader";
import EmployeePayroll from "components/Screens/PayrollV2/EmployeePayrollById";
import { convertDate, getMonthlyYearlySalary, getValue } from "components/Screens/CommonUtils";

import EdgeLogo from "assets/svgs/EdgeLogo";

function PayrollHistoryTab() {
  const { platfromRebrandPhase1 } = useFlags();

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);
  const salaryInfo = useSelector((state) => state.SalaryInfo);
  const loadingEmployeeSalaryInfo = useSelector((state) => state.SalaryInfo.loadingEmployeeSalaryInfo);

  return (
    <>
      {(loadingGetEmployeeDetails || loadingEmployeeSalaryInfo) && <PageLoader />}
      <Grid>
        <Grid
          container
          sx={{
            border: "1px solid #B2B4E2",
            borderRadius: "24px",
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "18px",
              padding: "4px 15px",
            }}
          >
            {!platfromRebrandPhase1 && (
              <Grid
                sx={{
                  padding: "5px",
                  boxShadow: "0px 4px 22px rgba(6, 14, 100, 0.03)",
                  borderRadius: "15px",
                }}
              >
                <EdgeLogo />
              </Grid>
            )}
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins-Medium",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    color: "#000000",
                    whiteSpace: "nowrap",
                  }}
                >
                  {getMonthlyYearlySalary(salaryInfo?.employeeSalaryInfo?.payload?.grossSalary)}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    color: "#52537A",
                  }}
                >
                  Total current salary
                </Typography>
              </Grid>
              <Typography
                sx={{
                  fontFamily: "Poppins-Medium",
                  fontSize: "10px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  color: "#52537A",
                }}
              >
                {convertDate(salaryInfo?.employeeSalaryInfo?.payload?.paymentDate)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "18px",
              padding: "4px 15px",
              borderLeft: "1px solid #B2B4E2",
            }}
          >
            {!platfromRebrandPhase1 && (
              <Grid
                sx={{
                  padding: "5px",
                  boxShadow: "0px 4px 22px rgba(6, 14, 100, 0.03)",
                  borderRadius: "15px",
                }}
              >
                <EdgeLogo />
              </Grid>
            )}
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins-Medium",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    color: "#000000",
                    whiteSpace: "nowrap",
                  }}
                >
                  {getValue(getEmployeeDetails?.payload?.lastIncrement)}
                  {/* $ 300 (25%) */}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    color: "#52537A",
                  }}
                >
                  Last Raise recieved
                </Typography>
              </Grid>
              <Typography
                sx={{
                  fontFamily: "Poppins-Medium",
                  fontSize: "10px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  color: "#52537A",
                }}
              >
                {convertDate(getEmployeeDetails?.payload?.lastIncrementDate)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "18px",
              padding: "4px 15px",
              borderLeft: "1px solid #B2B4E2",
            }}
          >
            {!platfromRebrandPhase1 && (
              <Grid
                sx={{
                  padding: "5px",
                  boxShadow: "0px 4px 22px rgba(6, 14, 100, 0.03)",
                  borderRadius: "15px",
                }}
              >
                <EdgeLogo />
              </Grid>
            )}
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins-Medium",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    color: "#000000",
                    whiteSpace: "nowrap",
                  }}
                >
                  {getValue(getEmployeeDetails?.payload?.lastBonus)}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    color: "#52537A",
                  }}
                >
                  Last bonus recieved
                </Typography>
              </Grid>
              <Typography
                sx={{
                  fontFamily: "Poppins-Medium",
                  fontSize: "10px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  color: "#52537A",
                }}
              >
                {convertDate(getEmployeeDetails?.payload?.lastBonusDate)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            margin: "16px 0px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins-Medium",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              color: "#212231",
            }}
          >
            Payroll History
          </Typography>
          <EmployeePayroll />
        </Grid>
      </Grid>
    </>
  );
}

export default PayrollHistoryTab;

import React from "react";
import { Box } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link } from "react-router-dom";
import { getYear } from "date-fns";

import EdgeLogo from "components/Design/EdgeLogo/EdgeLogo";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import edgeLogo from "assets/images/edge-logo.svg";

import "./LinkExpired.scss";

function LinkExpired() {
  const { platfromRebrandPhase1 } = useFlags();

  return (
    <div className="flex flex-col items-center justify-between link-expired-main-container pl-1 pr-1">
      <div>
        {platfromRebrandPhase1 ? (
          <EdgeLogo size="md" state="dark" />
        ) : (
          <Box component="img" className="edge-logo" alt="edge" src={edgeLogo} />
        )}
      </div>
      <div className="flex flex-col items-center expiry-text-container">
        <ErrorOutlineOutlinedIcon className="error-icon" />
        <div className="heading-h1-semibold text-center">Uh oh. This onboarding link has expired.</div>
        <div className="text-center para-body-m-regular description-text">
          It seems that your onboarding link has expired. Please reach out to your sales representative or{" "}
          <Link
            className="mail-to-link para-body-m-regular"
            to="#"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "mailto:support@onedge.co";
            }}
          >
            support@onedge.co
          </Link>{" "}
          to receive a new onboarding email.
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="para-body-s-regular text-color">©️ Copyright 2010 - {getYear(new Date())} Edge, LLC </div>
        <div className="para-body-xs-regular text-color">
          {process.env.REACT_APP_NAME}
          {` ${process.env.REACT_APP_VERSION}`}
        </div>
      </div>
    </div>
  );
}

export default LinkExpired;

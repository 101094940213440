import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";

function LeaveMessage({ message, status }) {
  let maxWidth = "";
  let containerMaxWidth = "450px";
  const navigate = useNavigate();

  if (status == 1) maxWidth = "360px";
  else if (status == 2) maxWidth = "396px";
  else {
    maxWidth = "548px";
    containerMaxWidth = "550px";
  }

  const handleHome = () => {
    navigate("/");
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      sx={{ backgroundColor: "#FFFFFF", overflowY: "auto" }}
    >
      <Grid item container sx={{ maxWidth: containerMaxWidth }}>
        <Grid item container direction="column" rowSpacing={4}>
          <Grid className="flex items-center justify-center" item>
            <Box
              component="img"
              sx={{
                height: "auto",
                maxWidth: "100%",
              }}
              alt="edge"
              src="assets/Images/edge-logo.svg"
            />
          </Grid>
          <Grid className="flex item-center justify-center">
            <Typography
              sx={{
                fontFamily: "Poppins !important",
                color: "#000000",
                fontSize: "28px",
                fontWeight: "600",
                lineHeight: "42px",
                maxWidth,
                textAlign: "center",
                marginTop: "24px",
              }}
            >
              {message}
            </Typography>
          </Grid>

          <Grid className="flex item-center justify-center">
            <Typography
              sx={{
                fontFamily: "Poppins !important",
                color: "#000000",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                maxWidth: "360px",
                textAlign: "center",
                marginTop: "24px",
              }}
            >
              Track your employee&apos;s time off by logging into the platform
            </Typography>
          </Grid>

          <Grid item textAlign="center">
            <Button
              variant="contained"
              fullWidth={false}
              onClick={handleHome}
              sx={{
                backgroundColor: "var(--temp-color-primitives-primary-800)",
                height: "40px",
                fontFamily: "Poppins-Regular !important",
                fontWeight: "500",
                lineHeight: "20px",
                borderRadius: "99px",
                width: "140px",
                fontSize: "16px",
                color: "white",
                boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
              }}
            >
              Sign in
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LeaveMessage;

import { useEffect, useState } from "react";
import { Chip, Collapse, Divider, Tooltip } from "@mui/material";
import { differenceInDays } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

import Comment from "components/Screens/Timeoff/ManageTimeoff/Comment/Customer";
import Button from "components/Screens/Timeoff/ManageTimeoff/Customer/Button";
import { convertDate, requestDetails } from "components/Screens/CommonUtils";
import PageLoader from "components/common/PageLoader";
import LeaveType from "../LeaveType";
import EditByHrStatus from "../EditByHrStatus";

import PendingTimeIcon from "assets/images/pending-timer.svg";
import LeftArrow from "assets/images/left-arrow.svg";
import AvatarImage from "assets/images/fallback-image.svg";
import NoteExpandIcon from "assets/images/note-expand.svg";
import CrossIcon from "assets/svgs/CrossIcon";

import { baseUrl } from "util/APIBaseUrl";
import { makeRequest } from "util/APIHelper";

import { showSnackbar } from "store/actions/Utility";
import { GET_LEAVE_REQUESTS_SUCCESS } from "store/constants/Timeoff";
import { FetchLeaveRequests } from "store/actions/Timeoff/LeaveRequests";

import "./LeaveCards.css";

function LeaveCard({ data = {} }) {
  const {
    employeeId,
    customerId,
    employeeName,
    status,
    leaveStatus,
    leaveType,
    dateTo,
    dateFrom,
    noOfLeaves,
    createdAt,
    description,
    requestId,
    pictureVersion,
    approvedReason,
    rejectedReason,
    country,
    actionByName,
    approvedDate,
    rejectedDate,
    cancelledDate,
    documentsAttached,
    hrEdit,
    hrApproved,
    originalLeaveType,
  } = data;

  const dispatch = useDispatch();

  const scheduledLeaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.leaveRequests?.scheduled || []);
  const loadingLeaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.loadingLeaveRequests);
  const [collapsed, setCollapsed] = useState(true);
  const [empImageSrc, setEmpImageSrc] = useState(AvatarImage);
  const [customerImageSrc, setCustomerImageSrc] = useState(AvatarImage);
  const [isCancellingRequest, setIsCancellingRequest] = useState({ state: false, showSnackbar: false });

  const pending = status === "pending";

  const handleCancelLeave = async () => {
    setIsCancellingRequest({
      state: true,
      showSnackbar: false,
    });

    await makeRequest({ path: `${baseUrl}/employee/${requestId}/leave/Cancelled`, method: "PUT" }, false);

    setIsCancellingRequest({
      state: false,
      showSnackbar: true,
    });

    const newScheduledLeaves = scheduledLeaveRequests?.filter((req) => req.requestId !== requestId);
    const leaveReqs = {
      ...scheduledLeaveRequests,
      scheduled: newScheduledLeaves,
    };

    dispatch({
      type: GET_LEAVE_REQUESTS_SUCCESS,
      payload: { payload: { data: leaveReqs } },
    });

    dispatch(FetchLeaveRequests({}));
  };

  useEffect(() => {
    if (isCancellingRequest.showSnackbar) {
      dispatch(showSnackbar({ dispatch, type: "success", message: "Scheduled leave has been cancelled successfully" }));
    }
  }, [isCancellingRequest]);

  const leaveCardDetailsStyles =
    pending || !collapsed ? "pending-timeoff-details-text w-20p" : "scheduled-timeoff-details-text w-20p";

  useEffect(() => {
    if (employeeId && pictureVersion) {
      const empImageUrl = `${baseUrl}/employee/${employeeId}/logo?id=${pictureVersion}&variant=thumbnail`;
      setEmpImageSrc(empImageUrl);
    } else {
      setEmpImageSrc(AvatarImage);
    }
  }, [employeeId, pictureVersion]);

  useEffect(() => {
    if (customerId && pictureVersion) {
      const customerImageUrl = `${baseUrl}/customer/${customerId}/logo?id=${pictureVersion}&variant=thumbnail`;
      setCustomerImageSrc(customerImageUrl);
    } else {
      setCustomerImageSrc(AvatarImage);
    }
  }, [employeeId, pictureVersion]);
  return (
    <div
      className={
        pending || !collapsed ? "pending-leaves-card-container flex flex-col" : "pending-leaves-card-container "
      }
    >
      {(isCancellingRequest?.state || loadingLeaveRequests) && <PageLoader />}
      <div className="w-100p">
        <div className="pending-leaves-top-section">
          <div className={pending && "flex py-1"}>
            {pending && <img className="pending-leaves-time-icon" src={PendingTimeIcon} alt="pending time" />}

            <div className={!pending && "pl-1"}>
              <div className="pending-leave-reason" id="leave-type">
                {originalLeaveType && originalLeaveType !== leaveType ? (
                  <>
                    <s>{`${originalLeaveType}`}</s>

                    <span style={{ paddingLeft: "8px" }}>{`${leaveType}`}</span>
                  </>
                ) : (
                  `${leaveType}`
                )}
              </div>

              <div className="pending-leave-duration ">
                <div className="pending-leave-date" id="start-date">
                  {convertDate(dateFrom)}
                </div>
                {dateFrom !== dateTo && (
                  <>
                    <img className="pending-leave-arrow" src={LeftArrow} alt="left arrow" />
                    <div className="pending-leave-date" id="end-date">
                      {convertDate(dateTo)}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={`${leaveStatus === "Scheduled" ? "scheduled-leaves-top-section" : "leaves-top-section"}`}>
            {leaveStatus === "Scheduled" && (
              <Chip
                variant="outlined"
                className="pending-leave-chip"
                icon={<CrossIcon className="chip-leaves-pending-icon" fill="var(--color-primitives-primary-600)" />}
                clickable
                label={
                  <div className="chip-leaves-pending-label" id="cancel-request-btn" onClick={handleCancelLeave}>
                    Cancel Request
                  </div>
                }
              />
            )}
            <div
              onClick={() => setCollapsed(!collapsed)}
              className={`w-10p flex items-center justify-end cursor-pointer ${!collapsed}`}
            >
              <Tooltip title="View details">
                <img src={NoteExpandIcon} className="mr-2 mt-1 text-xs font-thin note-expand-icon" alt=" note expand" />
              </Tooltip>
            </div>
          </div>
        </div>

        <Divider light />

        <div className={`pending-timeoff-details-wrapper ${collapsed ? "collapsed-customer-portal " : ""}`}>
          <div className="pending-timeoff-details">
            <div className={leaveCardDetailsStyles}>Duration</div>
            <Chip
              className="secondary-20 custom-chip"
              size="small"
              variant="outlined"
              id="total-timeoff-used"
              label={`${noOfLeaves}  ${noOfLeaves == 1 ? "leave" : "leaves"}`}
            />
          </div>

          {!collapsed && (
            <div className="pending-timeoff-details custom-chip">
              <div className={leaveCardDetailsStyles}>Notice Period</div>
              <Chip
                className="secondary-20  custom-chip"
                size="small"
                variant="outlined"
                id="notice-period"
                label={`${differenceInDays(new Date(dateFrom), new Date(createdAt))} Days`}
              />
            </div>
          )}

          <div className="pending-timeoff-details">
            <div className={leaveCardDetailsStyles}>Status</div>
            <LeaveType type={leaveStatus} />
          </div>

          {country === "Peru" && (leaveType === "Sick" || hrEdit || hrApproved) && (
            <div className="pending-timeoff-details">
              <div className="pending-timeoff-details-text" style={{ width: "20%" }}>
                HR doc review
              </div>
              <EditByHrStatus
                style={{ width: "fit-content !important" }}
                hrEdit={hrEdit}
                hrApproved={hrApproved}
                leaveStatus={status}
              />
            </div>
          )}
        </div>
      </div>
      {pending && <Divider light />}

      <Collapse in={!collapsed}>
        {(pending || !collapsed) && (
          <>
            <div className="pending-timeoff-comments label-sm">Comments:</div>
            <Comment
              pending={pending}
              {...{
                description,
                employeeName,
                approvedReason,
                rejectedReason,
                empImageSrc,
                customerImageSrc,
                country,
                actionByName,
                leaveType,
                requestId,
                documentsAttached,
                dateFrom,
                dateTo,
                noOfLeaves,
              }}
            />
          </>
        )}
      </Collapse>

      {pending && <Button />}
      <div
        className={`body-sm neutral-20 request-date ${!collapsed ? "p-1" : "request-date-spacing mt-12"}`}
        id="request-date"
        style={collapsed ? { paddingTop: "12px" } : null}
      >
        {requestDetails(status, approvedDate, rejectedDate, cancelledDate, createdAt)}
      </div>
    </div>
  );
}

export default LeaveCard;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Badge, Box, Stack } from "@mui/material";

import ImageCropModal from "components/common/ImageCropperModal";
import AlertError from "components/Widgets/AlertError";

import AvatarIcon from "components/Icons/AvatarIcon";
import EditIcon from "assets/svgs/EditIcon";

import { baseUrl } from "util/APIBaseUrl";
import { usePermissions } from "components/Hook";

import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

function ProfilePicture(props) {
  // props
  const { page } = props;
  const photoInput = React.createRef();
  const dispatch = useDispatch();
  const { platfromRebrandPhase1 } = useFlags();
  // Custrom hook
  const { loggedInUser, loggedInUserType } = usePermissions();

  // component states
  const [userProfileUrl, setUserProfileUrl] = React.useState(null);
  const [showImageCropperModal, setShowImageCropperModal] = React.useState(false);

  const [openAlert, setOpenAlert] = React.useState(false);
  const [, setPictureUpdated] = React.useState(false);
  const [loadImageFormData] = React.useState({
    loadFromUrl: true,
    imagePreviewUrl: null,
    imageBlob: null,
  });
  const [imageSrc, setImageSrc] = React.useState({});
  const [isFileUploadError, setIsFileUploadError] = React.useState(false);

  const [, setIsLoading] = React.useState(false);

  // redux state
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);

  // functions
  const postLogo = async (actions) => {
    try {
      document.body.style.cursor = "wait";
      const response = await fetch(`${baseUrl}/${actions.request}`, {
        method: "POST",
        headers: {},
        body: actions.formData,
      });

      if (!response.ok) {
        setPictureUpdated(false);
        return;
      }
      setPictureUpdated(true);
      const req = {
        customer: `customer/${loggedInUser?.customerId}/info`,
        edge: `user/${loggedInUser.userId}/summary`,
        employee: `employee/${loggedInUser.userId}`,
      };

      dispatch(UserAdditionalInfo(req[loggedInUserType]));
      setShowImageCropperModal(false);
      document.body.style.cursor = "default";
    } catch (error) {
      setPictureUpdated(false);
      setOpenAlert(true);
      setShowImageCropperModal(false);
      document.body.style.cursor = "default";
    }
  };

  const uploadProfilePic = () => {
    photoInput.current.click();
  };

  const handleImageChange = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    e.target.value = "";

    if (file.size / 1024 / 1024 > 2) setIsFileUploadError(true);
    else setIsFileUploadError(false);

    const imageDataUrl = await readFile(file);

    setImageSrc(imageDataUrl);
    setShowImageCropperModal(true);
  };

  const getPictureVersion = () => {
    const pictureVersion = {
      companyProfile: userAdditionalInfo?.customerPictureVersion,
      personalProfile: userAdditionalInfo?.pictureVersion,
    };
    return pictureVersion[page];
  };
  React.useEffect(() => {
    const req = {
      personalProfile: {
        customer: `customerUser/${loggedInUser?.userId}/logo?id=${getPictureVersion()}`,
        edge: `user/${loggedInUser?.userId}/logo?id=${getPictureVersion()}`,
      },

      companyProfile: {
        customer: `customer/${loggedInUser?.customerId}/logo?id=${getPictureVersion()}`,
        edge: `user/${loggedInUser?.userId}/logo?id=${getPictureVersion()}`,
      },
    };

    setUserProfileUrl(`${baseUrl}/${req[page][loggedInUserType]}`);
  }, [page, userAdditionalInfo]);

  const handleSave = async (param) => {
    setIsLoading(true);
    const formData = new FormData();
    formData?.append("file", param);

    const req = {
      personalProfile: {
        customer: `customerUser/${loggedInUser?.userId}/logo`,
        edge: `user/${loggedInUser?.userId}/logo`,
      },

      companyProfile: {
        customer: `customer/${loggedInUser?.customerId}/logo`,
        edge: `user/${loggedInUser?.userId}/logo`,
      },
    };

    await postLogo({
      request: req[page][loggedInUserType],
      formData,
    });

    setIsLoading(false);
  };

  return (
    <>
      <ImageCropModal
        open={showImageCropperModal}
        onClose={() => setShowImageCropperModal(false)}
        imageSrc={imageSrc}
        onSave={handleSave}
        isFileUploadError={isFileUploadError}
        errorMessage="The file you've selected is too large. Please upload an image file file that is 2MB or smaller."
      />
      <Stack direction="column" spacing={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "var(--color-primitives-primary-100)",
            padding: "10px",
            borderRadius: "12px",
            height: "254px",
            position: "relative",
          }}
        >
          <input
            type="file"
            accept="image/png, image/jgp, image/jpeg"
            onChange={handleImageChange}
            ref={photoInput}
            style={{ display: "none" }}
          />
          <Badge
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="standard"
            badgeContent={
              <EditIcon
                color="var(--temp-color-primitives-primary-800)"
                style={{
                  width: "28px",
                  height: "28px",
                }}
              />
            }
            overlap="circular"
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: "#F7F7F7",
                minWidth: "50px",
                height: "50px",
                borderRadius: "25px",
                boxShadow: "0px 4px 10px 0px #080D461F",
                position: "absolute",
                top: "120px",
                right: "30px",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              uploadProfilePic();
            }}
          >
            <AvatarIcon
              className={platfromRebrandPhase1 ? "customer-profile-avatar" : ""}
              size={{
                width: "198px",
                height: "198px",
                borderRadius: "999px",
              }}
              source={
                loadImageFormData?.loadFromUrl ? userProfileUrl : `data:image/png;base64,${loadImageFormData.imageBlob}`
              }
              alt={userAdditionalInfo?.companyName}
            />
          </Badge>
        </Box>

        <AlertError
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          errors={[
            {
              ok: false,
              payload: {
                error: [
                  {
                    name: "Forbidden.",
                    statusCode: 413,
                    isOperational: false,
                    description: "An error occured while uploading photo.",
                  },
                ],
              },
            },
          ]}
        />
      </Stack>
    </>
  );
}

export default ProfilePicture;

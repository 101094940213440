import React from "react";
import { useParams } from "react-router-dom";

import { Grid, IconButton, Tooltip, Typography } from "@mui/material";

import BackgroundDocumentsIcon from "assets/svgs/BackgroundDocumentsIcon";
import PersonalIdIcon from "assets/svgs/PersonalIdIcon";

import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";

function DocumentCard({ data, setIsLoading, isEditable = false }) {
  const params = useParams();
  // function
  const handleExport = async (exportData) => {
    if (setIsLoading) setIsLoading(true);
    const response = await getRequest(
      `${baseUrl}/employees/${params?.id}/document/${exportData?.documentId}`,

      true,
      "export"
    );

    if (!response?.ok) {
      if (setIsLoading) setIsLoading(false);
      return;
    }

    const url = window.URL.createObjectURL(response?.payload);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;

    const documentName =
      exportData?.documentExtension && exportData?.documentExtension !== "null"
        ? `${exportData?.documentName}${exportData?.documentExtension}`
        : exportData?.documentName;

    link.download = documentName;
    if (setIsLoading) setIsLoading(false);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };
  return (
    <Grid
      item
      sx={{
        borderRadius: "20px",
        boxShadow: !isEditable ? "inset 0px 0.853633px 3.41453px rgba(2, 0, 110, 0.08)" : "none",
        backgroundColor: "#FFFFFF",
      }}
      style={{}}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "16px 0px",
        }}
      >
        <IconButton onClick={() => data?.documentAdded !== false && handleExport(data)}>
          {data?.type === "application/pdf" ? <BackgroundDocumentsIcon /> : <PersonalIdIcon />}
        </IconButton>
      </Grid>
      <Grid
        sx={{
          display: "felx",
          justifyContent: "center",
          padding: "6px 16px",
        }}
      >
        <Tooltip title={data?.documentName}>
          <Typography
            sx={{
              fontFamily: "Poppins-Medium",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "16px",
              color: "#212231",
              textAlign: "center",
              height: "38px",
              cursor: "pointer",
            }}
            className="customer-employee-docs"
            onClick={() => data?.documentAdded !== false && handleExport(data)}
          >
            {data?.documentAdded === false ? "No Document Found" : data?.documentName}
          </Typography>
        </Tooltip>
      </Grid>

      <Grid sx={{ display: "flex", justifyContent: "center" }}>
        <Grid sx={{ padding: "8px 12px", width: "100%" }} />
      </Grid>
    </Grid>
  );
}

export default DocumentCard;

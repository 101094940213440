import { useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";

import "./LeaveTypeCard.css";
import { Stack } from "@mui/material";

function LeaveTypeCard({ portal, style, leave, icon }) {
  const navigate = useNavigate();
  const { platfromRebrandPhase1 } = useFlags();

  const { leaveType, taken, noOfLeaves, pending, scheduled, leaveTypeId, isFinite, countryCode } = leave || {};

  return (
    <div
      className="leavetype-card-container"
      style={{
        ...style,
        background: platfromRebrandPhase1
          ? "linear-gradient(221deg, #FFE0D7 4.69%, var(--color-primitives-primary-100) 41.39%, #D9F5EA 120.42%)"
          : "linear-gradient(221deg, #ffefd3 4.69%, var(--color-primitives-primary-100) 41.39%, #b4fff2 120.42%)",
      }}
    >
      <div className="leave-count-wrapper">
        <div className="leave-count-request-wrapper">
          <Stack direction="row" spacing="13px" alignItems="center">
            <div className="leave-count">
              <div className="leave-count-flex-row">
                <div className="leave-count-remaining" id="taken-leaves">
                  {taken}
                </div>
                {noOfLeaves > "-1" && isFinite && !(leaveType == "Sick" && countryCode == "Peru") && (
                  <div className="leave-count-total" id="no-of-leaves">
                    /{noOfLeaves}
                  </div>
                )}
              </div>
              <div className="leave-card-type" id="leave-type">
                {leaveType}
              </div>
            </div>

            {leaveType === "Swapped" ? icon : null}
          </Stack>

          {portal !== "admin" && (
            <div
              className="leave-request-btn"
              id={`request-leave-${leaveType}`}
              onClick={() => navigate(`/main/timeoff/request?leaveTypeId=${leaveTypeId}`)}
            >
              Request
            </div>
          )}
        </div>
        {portal !== "admin" && pending + scheduled > 0 && (
          <div className="leave-count-scheduled">+{pending + scheduled} Scheduled/pending</div>
        )}
      </div>
    </div>
  );
}

export default LeaveTypeCard;

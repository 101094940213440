/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useSelector, useDispatch } from "react-redux";
import { GetRecentPayments } from "store/actions/DashBoard/RecentPayments";
import { ResetGetPaymentDetails } from "store/actions/Payroll/GetPaymentDetails";
import { RetryPayment } from "store/actions/Payment/RetryPayment";

import {
  convertDate,
  currencyFormat,
  getCurrencySymbol,
  getValue,
  handleRetryPayment,
  convertDateToUTCMonthAndYear,
} from "components/Screens/CommonUtils";
import Button from "components/Button/Button";
import AlertError from "components/Widgets/AlertError";
import PageLoader from "components/common/PageLoader";
import { usePermissions } from "components/Hook";
import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";

import ChevronIcon from "assets/images/chevron-bottom.svg";
import FailureIcon from "assets/svgs/PayrolFailure";
import SuccessIcon from "assets/svgs/PayrolSuccess";
import PendingIcon from "assets/svgs/PayrolPending";
import RetryIcon from "assets/svgs/PayrolFailureRetry";
import DownloadIcon from "assets/svgs/PayrolDownloadIcon";

import "./PayrollHistory.css";

function PayrollHistory(props) {
  const { selectPageHeading } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { integratedContractSigning } = useFlags();
  const { loggedInUser } = usePermissions();

  const [showRetryErr, setShowRetryErr] = useState(false);
  const [errorStyle, setErrorStyle] = useState({});
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const retryPayment = useSelector((state) => state.RetryPayment);
  const recentPayments = useSelector((state) => state.RecentPayments.recentPayments);
  const loadingGetRecentPayments = useSelector((state) => state.RecentPayments.loadingGetRecentPayments);
  const paymentDetails = useSelector((state) => state.GetPaymentDetails.paymentDetails);
  const loadingPaymentDetails = useSelector((state) => state.GetPaymentDetails.loadingPaymentDetails);

  const showRetryError = !retryPayment.loadingRetryPayment && retryPayment.retryPayment;

  const getPaymentDate = (failure, paymentDate) => {
    return failure ? `Paid on ${convertDate(paymentDate)}` : convertDate(paymentDate);
  };

  const handleDownloadReceipt = async (customerPayrollId) => {
    try {
      const response = await getRequest(`${baseUrl}/customerReceipt/${loggedInUser?.customerId}/${customerPayrollId}`);
      window.open(response?.payload?.receiptUrl, "_blank");
    } catch (error) {
      console.log("here is error");
    }
  };

  useEffect(() => {
    dispatch(ResetGetPaymentDetails({}));
    dispatch(
      GetRecentPayments({
        customerID: loggedInUser?.customerId,
        rowsPerPage: 10,
        page: 0,
      })
    );
  }, []);

  useEffect(() => {
    if (Object.keys(paymentDetails).length && paymentDetails?.ok) selectPageHeading("Payroll Details");
  }, [paymentDetails]);

  useEffect(() => {
    if (showRetryError) setShowRetryErr(true);
  }, [showRetryError]);

  return (
    <div>
      {(loadingGetRecentPayments || loadingPaymentDetails) && <PageLoader />}
      {integratedContractSigning && !!recentPayments?.payload?.rows?.length && (
        <p className="heading-h5-semibold">Your payments this year</p>
      )}
      <Grid container direction="row" rowSpacing={3} sx={{ paddingTop: integratedContractSigning ? "8px" : "16px" }}>
        <Grid item xs={12}>
          {!!recentPayments?.payload?.rows.length && (
            <TableContainer className="bg-white rounded-xl pb-1">
              {showRetryError && (
                <div style={{ float: "right" }}>
                  <AlertError
                    openAlert={showRetryErr}
                    setOpenAlert={() => setShowRetryErr(false)}
                    errors={[""]}
                    displayError={["An error occured while retrying"]}
                  />
                </div>
              )}
              <Table>
                <TableHead>
                  <TableRow>
                    {[
                      { label: "Payment Date", width: "20%" },
                      { label: "Paid On", width: "20%" },
                      { label: "Total Payment", width: "20%" },
                      { label: "Total Employees", width: "20%" },
                      { label: "", width: "20%", textAlign: "center" },
                    ].map((column, index) => (
                      <TableCell
                        key={column.label}
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "12px",
                          borderBottom: "none",
                          width: "20%",
                          ...(index === 0 && { paddingLeft: "24px" }),
                          ...(index === 4 && { textAlign: "center", paddingRight: "24px" }),
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentPayments?.payload?.rows.map((row, index) => {
                    const isHovered = hoveredRowIndex === index;
                    const isErrorRow = row?.failure || row?.status === "pending";
                    const isFailure = row?.failure;
                    const isSuccess = row?.status === "paid";
                    const isProcessing = row?.status === "pending";
                    const paymentDate = convertDate(row?.paymentDate);
                    const paidOn = convertDate(row?.paidOn);
                    const totalPayroll = getCurrencySymbol("en-US", "USD", currencyFormat(getValue(row?.totalPayroll)));
                    const totalEmployees = currencyFormat(getValue(row?.totalEmployees));
                    const handleTableRowClick = () => navigate(`detail/${row.customerPayrollId}`);

                    return (
                      <Box
                        style={{ width: "100%", display: "contents" }}
                        key={row?.customerPayrollId}
                        onMouseEnter={() => {
                          if (isErrorRow)
                            setErrorStyle({
                              index,
                              style: "un-hide",
                              block: "un-hide-block",
                            });
                          setHoveredRowIndex(index);
                        }}
                        onMouseLeave={() => {
                          setErrorStyle({});
                          setHoveredRowIndex(null);
                        }}
                      >
                        <TableRow
                          sx={{
                            cursor: "pointer",
                            "&:nth-of-type(odd)": {
                              backgroundColor: "#FBFAFF",
                            },
                            backgroundColor: `${
                              isHovered
                                ? isFailure
                                  ? "#FFEDEA !important"
                                  : isProcessing
                                    ? "#FFEFD3 !important"
                                    : "var(--color-primitives-primary-50) !important"
                                : isFailure
                                  ? "#FFFBFA !important"
                                  : isProcessing && "#FFFDFA !important"
                            }`,
                          }}
                        >
                          <TableCell
                            onClick={handleTableRowClick}
                            sx={{
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "20px",
                              borderBottom: "none",
                              paddingLeft: "24px",
                            }}
                          >
                            {isFailure ? (
                              <Grid
                                sx={{
                                  display: "flex",
                                  gap: "14px",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    padding: "12px",
                                    backgroundColor: "#FFDAD6",
                                    borderRadius: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FailureIcon />
                                </Box>
                                <Grid
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "6px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      lineHeight: "20px",
                                      color: "#BA1A1A",
                                    }}
                                    id="pay-date"
                                  >
                                    {paymentDate}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      lineHeight: "12px",
                                      color: "#212231",
                                    }}
                                    id="paid-on"
                                  >
                                    {paidOn}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid
                                sx={{
                                  display: "flex",
                                  gap: "14px",
                                  alignItems: "center",
                                }}
                              >
                                {isSuccess ? (
                                  <>
                                    <Box
                                      sx={{
                                        padding: "12px",
                                        backgroundColor: "#B4FFF2",
                                        borderRadius: "8px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <SuccessIcon />
                                    </Box>
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "6px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          lineHeight: "20px",
                                          color: "#000000",
                                        }}
                                        id="pay-date"
                                      >
                                        {convertDateToUTCMonthAndYear(row?.paymentDate)}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          lineHeight: "12px",
                                          color: "#212231",
                                        }}
                                        id="paid-on"
                                      >
                                        {getPaymentDate(isFailure, row?.paidOn)}
                                      </Typography>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Box
                                      sx={{
                                        padding: "12px",
                                        backgroundColor: "#FFEFD3",
                                        borderRadius: "8px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <PendingIcon />
                                    </Box>
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "6px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          lineHeight: "20px",
                                          color: "#3F2E00",
                                        }}
                                        id="pay-date"
                                      >
                                        {paymentDate}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          lineHeight: "12px",
                                          color: "#212231",
                                        }}
                                        id="paid-on"
                                      >
                                        {paidOn}
                                      </Typography>
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            )}
                          </TableCell>
                          <TableCell
                            onClick={handleTableRowClick}
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              lineHeight: "20px",
                              borderBottom: "none",
                              color: "#212231",
                            }}
                            id="paid-on"
                          >
                            {paidOn}
                          </TableCell>
                          <TableCell
                            onClick={handleTableRowClick}
                            sx={{
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "20px",
                              borderBottom: "none",
                              color: "black",
                            }}
                            id="total-payroll"
                          >
                            {totalPayroll}
                          </TableCell>
                          <TableCell
                            onClick={handleTableRowClick}
                            sx={{
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "20px",
                              borderBottom: "none",
                              color: "black",
                            }}
                            id="total-emp"
                          >
                            {totalEmployees}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "20px",
                              borderBottom: "none",
                              textAlign: "center",
                              paddingRight: "24px",
                            }}
                          >
                            <Grid
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {isFailure ? (
                                <Button
                                  type="button"
                                  variant="outlined"
                                  size="xs"
                                  style={{
                                    color: "#BA1A1A",
                                  }}
                                  leadIcon={<RetryIcon />}
                                  onClick={() =>
                                    handleRetryPayment(
                                      loggedInUser?.customerId,
                                      row?.customerPayrollId,
                                      dispatch,
                                      RetryPayment
                                    )
                                  }
                                >
                                  Retry
                                </Button>
                              ) : isProcessing ? (
                                <Button
                                  type="button"
                                  variant="outlined"
                                  size="xs"
                                  style={{
                                    color: "#5B4300",
                                  }}
                                >
                                  Payment Processing
                                </Button>
                              ) : row?.invoiceUrl ? (
                                <Button
                                  type="button"
                                  variant="outlined"
                                  leadIconColor="#131599"
                                  size="xs"
                                  onClick={() => handleDownloadReceipt(row?.customerPayrollId)}
                                  leadIcon={<DownloadIcon />}
                                >
                                  Download Receipt
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  variant="outlined"
                                  size="xs"
                                  style={{
                                    color: "#5B4300",
                                    borderColor: "#B4FFF2",
                                    backgroundColor: "#B4FFF2",
                                  }}
                                >
                                  Payment Successful
                                </Button>
                              )}
                            </Grid>
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }} onClick={handleTableRowClick}>
                            <img
                              className={`payroll-right-arrow ${errorStyle.index === index ? errorStyle.block : ""}`}
                              src={ChevronIcon}
                              alt="right arrow"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow className={`payroll-history-reasons ${errorStyle.index === index ? "open" : ""}`}>
                          <TableCell
                            sx={{
                              backgroundColor: `${
                                isFailure
                                  ? "#FFEDEA !important"
                                  : isProcessing
                                    ? "#FFEFD3 !important"
                                    : "var(--color-primitives-primary-50) !important"
                              }`,
                            }}
                            colspan="6"
                          >
                            <div className="payroll-failureReason-text">
                              {isFailure
                                ? row?.failureReason
                                : isProcessing
                                  ? "Your payment is currently in progress. Sit tight, and we'll notify you once it's completed."
                                  : ""}
                            </div>
                          </TableCell>
                        </TableRow>
                      </Box>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default PayrollHistory;

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Autocomplete, Button, Chip, Skeleton, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import CalendarWithLeaves from "./Calender";
import TimeoffChart from "./TimeoffChart";
import ReportedFlags from "./ReportedFlags";
import OnLeaveEmployees from "./OnLeaveEmployees";
import SectionHeader from "./SectionHeader";
import CustomDropdown from "components/common/Dropdown";

import {
  getEmployeesTimeOff,
  getEmployeesTimeOffDates,
  getTimeOffFlags,
  getTimeOffStats,
} from "./TimeOffAdminServices/Dashboard";
import { FetchLeaveTypes } from "store/actions/Timeoff/LeaveTypes";
import { GetCustomersList, ResetGetCustomersList } from "store/actions/Customers/GetCustomerList";
import { countryDropdown, leavesTypesDropdown, yearDropdown } from "./Constants";
import { getStartDateAndEndDate } from "constants";

import DropdownIcon from "assets/images/chevron-bottom.svg";
import "./TimeoffAdmin.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const dropdownStyles = {
  borderRadius: "33px",
  border: "1px solid var(--color-primitives-primary-100)",
  background: "var(--Primary-99, #FBFAFF)",
  minWidth: "auto",
  display: "flex",
  // flex:"1"
};
const customDropdownStyles = { display: "flex", flex: 1 };

const leaveYearSkeltonStyle = {
  borderRadius: "26px",
  backgroundColor: "rgb(189 192 255 / 30%)",
};

const graphSkeltonStyle = {
  borderRadius: "26px",
  backgroundColor: "rgb(189 192 255 / 30%)",
  width: "90%",
  height: "125px",
  margin: "auto",
};

function TimeoffAdmin() {
  // hooke
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // component states
  const [searchCustomerTimer, setSearchCustomerTimer] = useState(null);
  const [timeOffTypes, setTimeOffTypes] = useState([]);
  const [companyDropdownOptions, setCompanyDropdownOptions] = useState([]);
  const [employeesTimeOffList, setEmployeesTimeOffList] = useState({});
  const [employeesTimeOffListLoading, setEmployeesTimeOffListLoading] = useState({});
  const [employeesTimeOffDates, setEmployeesTimeOffDates] = useState({});
  const [showSpecificBarGraph, setShowSpecificBarGraph] = useState("total");
  const [timeOffFlags, setTimeOffFlags] = useState([]);
  const [timeOffFlagsLoading, setTimeOffFlagsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    selectedLeaveType: "",
    country: null,
    year: "thisYear",
    customerId: null,
  });

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    color: "var(--temp-color-primitives-primary-800)",
  });
  const [timeOffStats, setTimeOffStats] = useState(null);
  const [timeOffStatsLoading, setTimeOffStatsLoading] = useState(false);

  // redux state
  const getCustomerList = useSelector((state) => state.GetCustomerList.getCustomerList);
  const leaveTypes = useSelector((state) => state?.TimeoffLeaveTypes?.leaveTypes);

  const searchCustomer = (customerName) => {
    clearTimeout(searchCustomerTimer);
    const newTimer = setTimeout(() => {
      if (customerName.length >= 3) {
        dispatch(
          GetCustomersList({
            customerName,
            loaderFlag: false,
          })
        );
      } else {
        dispatch(ResetGetCustomersList({}));
      }
    }, 500);

    setSearchCustomerTimer(newTimer);
  };

  const fetchTimeOffStats = async () => {
    setTimeOffStatsLoading(true);
    try {
      const payload = { ...selectedFilter };
      const dateRange = getStartDateAndEndDate(payload?.year);
      payload.startDate = dateRange?.startDate;
      payload.endDate = dateRange?.endDate;
      payload.customerId = payload?.customerId?.value || "";
      const response = await getTimeOffStats(payload);
      setTimeOffStatsLoading(false);
      if (response?.success) {
        setTimeOffStats(response?.data);
      }
    } catch (error) {
      setTimeOffStatsLoading(false);
      console.error("Error fetching time off stats:", error);
    }
  };

  const fetchEmployeesTimeOffDetails = async () => {
    try {
      setEmployeesTimeOffListLoading(true);
      const payload = {
        startDate: moment(selectedDateRange?.startDate).format("YYYY-MM-DD"),
        endDate: moment(selectedDateRange?.endDate).format("YYYY-MM-DD"),
      };

      const response = await getEmployeesTimeOff(payload);
      setEmployeesTimeOffListLoading(false);
      if (response?.success) {
        setEmployeesTimeOffList(response?.data);
      }
    } catch (error) {
      setEmployeesTimeOffListLoading(false);
    }
  };

  const fetchEmployeesTimeOffDates = async () => {
    const payload = {
      startDate: moment().startOf("year").format("YYYY-MM-DD"),
      endDate: moment().endOf("year").format("YYYY-MM-DD"),
    };

    const response = await getEmployeesTimeOffDates(payload);
    if (response?.success) {
      setEmployeesTimeOffDates(response?.result);
    }
  };

  const FetchTimeOffFlags = async () => {
    try {
      setTimeOffFlagsLoading(true);
      const response = await getTimeOffFlags();
      setTimeOffFlagsLoading(false);
      if (response?.success) {
        setTimeOffFlags(response?.data);
      }
    } catch (error) {
      setTimeOffFlagsLoading(false);
    }
  };

  const getSelectedLeaveType = (leaveType) => {
    if (showSpecificBarGraph === leaveType) {
      setShowSpecificBarGraph("total");
    } else {
      setShowSpecificBarGraph(leaveType);
    }
  };

  const getSelectedLeaveTypeStyles = (leaveType) => {
    if (showSpecificBarGraph === leaveType) {
      return { background: "var(--temp-color-primitives-primary-800)", color: "#ffff" };
    }
    return {};
  };

  useEffect(() => {
    setTimeOffTypes(leavesTypesDropdown);
  }, [leaveTypes]);

  useEffect(() => {
    // get employee timeOff stats
    if (selectedDateRange?.startDate && selectedDateRange?.endDate) {
      fetchEmployeesTimeOffDetails();
    }
  }, [selectedDateRange]);

  useEffect(() => {
    const customers = [];
    if (getCustomerList?.ok) {
      getCustomerList?.payload?.forEach((data) => {
        customers.push({
          label: data.companyName,
          value: data.customerId,
        });
      });
    }
    setCompanyDropdownOptions(customers);
  }, [getCustomerList]);

  useEffect(() => {
    fetchTimeOffStats();
  }, [selectedFilter]);

  useEffect(() => {
    fetchEmployeesTimeOffDates();
    dispatch(FetchLeaveTypes({}));
    FetchTimeOffFlags();
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge | Timeoff</title>
      </Helmet>
      <div className="manage-timeoff-wrapper-admin">
        <div>
          <div className="flex w-full items-center justify-between">
            <SectionHeader
              heading="Time-off Requests and new activities"
              subHeading="Manage time-off requests and view upcoming holidays here"
            />
            <div onClick={() => navigate("/main/timeoff/admin/Utilisation-report")} className="report-button mr-1">
              View Report
            </div>
          </div>
          <div className="timeoff-container">
            <div className="timeoff-summary-container">
              <div className="timeoff-used-heading-activity">
                <h1 className="timeoff-used-heading">Time-off days used</h1>
                <Button
                  variant="primary"
                  size="lg"
                  id="new-activity-this-week"
                  onClick={() => navigate("/main/admin/timeoff/new-activity")}
                  sx={{
                    height: "28px",
                    fontSize: "14px",
                    fontFamily: "Poppins-Medium",
                    backgroundColor: "var(--temp-color-primitives-primary-800)",
                    borderRadius: "36px",
                    color: "white",
                    lineHeight: "28px",
                    px: "11px",
                  }}
                >
                  New Activity This Week ({timeOffFlags?.[0]?.value || 0})
                </Button>
              </div>

              {/* <div className="dropdowns-section">
              
  
         
            </div> */}

              <div style={{ height: "50px", padding: "0px", display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    border: "none",
                    flexDirection: "row",
                    gap: "7px",
                    display: "flex",
                    padding: "10px 0px 10px 11px",
                    width: "45%",
                  }}
                >
                  <CustomDropdown
                    options={yearDropdown}
                    onSelect={(key) => {
                      setSelectedFilter({
                        ...selectedFilter,
                        year: key,
                      });
                    }}
                    customDropdownStyles={{ ...customDropdownStyles }}
                    buttonLabel={yearDropdown?.find((leave) => leave?.key === selectedFilter?.year)?.label}
                    style={{ ...dropdownStyles, flex: 1 }}
                  />

                  <CustomDropdown
                    placeholder="Type"
                    options={timeOffTypes}
                    onSelect={(key) => {
                      setSelectedFilter({
                        ...selectedFilter,
                        selectedLeaveType: key,
                      });
                    }}
                    onClear={() =>
                      setSelectedFilter({
                        ...selectedFilter,
                        selectedLeaveType: "",
                      })
                    }
                    customDropdownStyles={{ ...customDropdownStyles }}
                    buttonLabel={timeOffTypes?.find((leave) => leave?.key === selectedFilter?.selectedLeaveType)?.label}
                    style={{ ...dropdownStyles, flex: 1 }}
                  />

                  <CustomDropdown
                    options={countryDropdown}
                    placeholder="Country"
                    onSelect={(key) => {
                      setSelectedFilter({
                        ...selectedFilter,
                        country: key,
                      });
                    }}
                    onClear={() =>
                      setSelectedFilter({
                        ...selectedFilter,
                        country: null,
                      })
                    }
                    customDropdownStyles={{ ...customDropdownStyles }}
                    buttonLabel={countryDropdown?.find((country) => country?.key === selectedFilter?.country)?.label}
                    style={{ ...dropdownStyles, flex: 1 }}
                  />
                </div>
                <div
                  style={{
                    paddingTop: "0px",
                    display: "flex",
                    width: "60%",
                    flex: 1,
                    alignItems: "center",
                    paddingLeft: "7px",
                    paddingRight: "12px",
                  }}
                >
                  <Autocomplete
                    clearOnBlur={false}
                    size="small"
                    limitTags={0}
                    id="standard"
                    popupIcon={<img className="dropdown-icon" src={DropdownIcon} alt="chevron-bottom" />}
                    sx={{
                      ...dropdownStyles,
                      flex: 1,

                      mb: 1,
                      "& .MuiOutlinedInput-root": {
                        padding: "2px",
                        borderRadius: "5px",
                        fontSize: "12px",
                        height: "fit-content !important",
                        "& fieldset": {
                          display: "none",
                        },
                      },
                      "& .MuiAutocomplete-inputRoot": {
                        height: "fit-content",
                        fontFamily: "Poppins-Medium",
                        fontSize: "14px",
                      },
                      "&.MuiAutocomplete-root": {
                        marginBottom: "0px",
                        height: "30px",
                      },
                      "& .MuiInputBase-sizeSmall": {
                        pt: "2px !important",
                        pb: "2px !important",
                      },
                      "& input#standard::placeholder": {
                        color: "black",
                        opacity: 1,
                        fontFamily: "Poppins-Medium",
                        fontSize: "14px",
                      },
                      "& .MuiAutocomplete-popupIndicator": {
                        padding: "5px 5px",
                      },
                    }}
                    options={companyDropdownOptions}
                    value={selectedFilter?.customerId}
                    onChange={(event, option) => {
                      setSelectedFilter({
                        ...selectedFilter,
                        customerId: option,
                      });
                    }}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        maxRows={1}
                        minRows={1}
                        variant="outlined"
                        onChange={(event) => searchCustomer(event.target.value)}
                        placeholder="Company"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="leaves-breakdown-section">
                <div className="all-total-leaves-section">
                  {timeOffStatsLoading ? (
                    <Skeleton
                      variant="circle"
                      sx={{ ...leaveYearSkeltonStyle, borderRadius: "50px", width: "50px", height: "51px" }}
                    />
                  ) : (
                    <h1 className="total-leaves-count" id="total-timeoff-scheduled">
                      {timeOffStats?.totalStats?.total}
                    </h1>
                  )}

                  {/* {timeOffStats?.totalStats?.scheduled ? ( */}
                  <h3 className="scheduled-leaves-count">+{timeOffStats?.totalStats?.scheduled} more scheduled</h3>
                  {/* )} */}
                  <div className="leaves-separate-count">
                    {timeOffStatsLoading ? (
                      <>
                        <Skeleton
                          variant="rounded"
                          sx={{ ...leaveYearSkeltonStyle, borderRadius: "16px", width: "82px", height: "32px" }}
                        />

                        <Skeleton
                          variant="rounded"
                          sx={{ ...leaveYearSkeltonStyle, borderRadius: "16px", width: "82px", height: "32px" }}
                        />

                        <Skeleton
                          variant="rounded"
                          sx={{ ...leaveYearSkeltonStyle, borderRadius: "16px", width: "82px", height: "32px" }}
                        />

                        <Skeleton
                          variant="rounded"
                          sx={{ ...leaveYearSkeltonStyle, borderRadius: "16px", width: "82px", height: "32px" }}
                        />
                      </>
                    ) : (
                      timeOffStats?.totalStats &&
                      Object.keys(timeOffStats?.totalStats)?.map((leave) =>
                        leave !== "total" && leave !== "scheduled" && timeOffStats?.totalStats?.[leave] > 0 ? (
                          <Chip
                            key={leave}
                            variant="outlined"
                            id="leave-type"
                            label={`${timeOffStats?.totalStats?.[leave] || 0} ${leave}`}
                            sx={{ ...getSelectedLeaveTypeStyles(leave), textTransform: "capitalize" }}
                            onClick={() => getSelectedLeaveType(leave)}
                          />
                        ) : null
                      )
                    )}
                  </div>
                </div>
                <div className="leaves-graph">
                  {timeOffStatsLoading ? (
                    <Skeleton variant="circle" sx={{ ...graphSkeltonStyle }} />
                  ) : (
                    <TimeoffChart leaveData={timeOffStats?.graph} showSpecificBarGraph={showSpecificBarGraph} />
                  )}
                  <div className="leave-graph-title">
                    {`Breakdown ${
                      showSpecificBarGraph !== "total" ? `| ${showSpecificBarGraph}` : ""
                    } | ${moment().format("YYYY")}`}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="timeoff-flags-container">
              <ReportedFlags timeOffFlags={timeOffFlags} timeOffFlagsLoading={timeOffFlagsLoading} />
            </div>
          </div>
        </div>
        <div>
          <SectionHeader
            heading="Scheduled leaves and holidays"
            subHeading="Click on the calendar dates to see who is off on that day"
          />
          <div className="scheduled-leaves-and-holidays-container">
            <div className="calender-section">
              <CalendarWithLeaves
                setSelectedDateRange={setSelectedDateRange}
                selectedDateRange={selectedDateRange}
                employeesTimeOffDates={employeesTimeOffDates}
              />
            </div>

            <div className="people-out-on-day">
              <OnLeaveEmployees
                employees={employeesTimeOffList}
                selectedDateRange={selectedDateRange}
                employeesTimeOffListLoading={employeesTimeOffListLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TimeoffAdmin;

import React from "react";
import { useSelector } from "react-redux";
import { Grid, Link, Typography } from "@mui/material";

import { USER_TYPES } from "constants";

function TermsAndPolicy({ userType, className = "" }) {
  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);

  return (
    <Grid className="mt-125">
      <Typography variant="body2" className={`para-body-s-regular-important ${className || ""}`}>
        By accessing the platform, you agree to abide by Edge's{" "}
        <Link
          href={platformConfig?.payload?.TERMS_URL}
          underline="hover"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            fontWeight: 500,
          }}
        >
          Terms of Use
        </Link>{" "}
        and{" "}
        <Link
          href={
            userType === USER_TYPES.EMPLOYEE
              ? platformConfig?.payload?.EMLOYEE_POLICY_URL
              : platformConfig?.payload?.CUSTOMER_POLICY_URL
          }
          underline="hover"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            fontWeight: 500,
          }}
        >
          Privacy Policy
        </Link>
        .
      </Typography>
    </Grid>
  );
}

export default TermsAndPolicy;

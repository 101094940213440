import { useEffect, useState } from "react";

import PayrollDetails from "./PayrollDetails";
import { usePermissions } from "components/Hook";
import { getUTCMonthName } from "components/Screens/CommonUtils";
import TopHeader from "components/common/TopHeader";
import PageLoader from "components/common/PageLoader";

import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";
import { CUSTOMER_PAYROLL_HISTORY } from "constants/routes";

import "./FirstPaymentPayroll.scss";

function FirstPaymentPayroll() {
  const { loggedInUser } = usePermissions();

  const [firstPayment, setFirstPayment] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { chargeDate } = firstPayment?.chargeDetails ?? {};
  const monthName = getUTCMonthName(chargeDate);

  useEffect(() => {
    const fetchOnboardingDetails = async () => {
      setIsLoading(true);
      const response = await getRequest(`${baseUrl}/customer/${loggedInUser.customerId}/charge/onboarding`, true);
      if (response?.ok) {
        setFirstPayment(response?.payload);
      }
      setIsLoading(false);
    };
    fetchOnboardingDetails();
  }, []);

  return (
    <div className="first-payment-payroll">
      {isLoading && <PageLoader />}
      <div className="pt-05">
        <TopHeader
          title={`Payroll for ${monthName || "-"}`}
          linkName={`Payroll / Payroll for ${monthName || "-"}`}
          link="Payroll for december"
          breadcrumbs
          backIcon={CUSTOMER_PAYROLL_HISTORY}
        />
      </div>
      <div className="pt-125">
        <PayrollDetails firstPayment={firstPayment} />
      </div>
    </div>
  );
}

export default FirstPaymentPayroll;

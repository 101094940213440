import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Button, Typography } from "@mui/material";

import EdgeLogo from "assets/svgs/EdgeLogo";
import EdgeLogoRebrand from "components/Design/EdgeLogo/EdgeLogo";

import "components/Screens/Login/SignUp/Auth0Login/Auth0Login.scss";

function Auth0Login() {
  const { platfromRebrandPhase1 } = useFlags();

  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      variant="contained"
      fullWidth
      onClick={() => {
        loginWithRedirect();
      }}
      className="auth0-button"
    >
      <div className="auth0-button-logo">{platfromRebrandPhase1 ? <EdgeLogoRebrand icon /> : <EdgeLogo />}</div>
      <Typography>Continue with Edge</Typography>
    </Button>
  );
}

export default Auth0Login;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import CustomTooltip from "components/common/Tooltip";

import { isMenuItemActive, getStartDateText } from "components/main/sidebar/utils/SidebarUtils";

import { usePermissions } from "components/Hook";

function CollapsedSidebar({ menuItems = [], smallLogo, location, onMenuItemClick, stickyItems = [], userInfo = {} }) {
  const { avatar, companyName, startDate, email, isBenchedEmployee } = userInfo;
  const { loggedInUser: { name: loggedInUserName = "" } = {}, loggedInUserType } = usePermissions();

  const groupedMenuItems = menuItems.reduce((acc, item) => {
    const category = item.category || "default";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  const categories = menuItems.reduce((acc, item) => {
    const category = item.category || "default";
    if (!acc.includes(category)) {
      acc.push(category);
    }
    return acc;
  }, []);

  const renderMenuItem = (item) => {
    const { id, title, path, icon, openInNewTab } = item;
    return (
      <CustomTooltip offset={5} key={id} content={title} direction="right" arrow>
        <div
          id={id}
          className={classNames("sidebar-collapsed-menu-item", {
            "sidebar-collapsed-menu-item-selected": isMenuItemActive(path, location),
          })}
          onClick={() => onMenuItemClick(path, openInNewTab)}
        >
          {icon}
        </div>
      </CustomTooltip>
    );
  };

  return (
    <div className="sidebar-collapsed-wrapper">
      <div className="sidebar-collapsed-content">
        <div className="sidebar-collapsed-logo">{smallLogo}</div>

        <div className="sidebar-collapsed-menu-container">
          {categories.map((category, index) => (
            <Fragment key={category}>
              <div className="sidebar-collapsed-category">{groupedMenuItems[category].map(renderMenuItem)}</div>
              {index < categories.length - 1 && <div className="sidebar-collapsed-divider" />}
            </Fragment>
          ))}
        </div>
      </div>

      <div className="sidebar-collapsed-footer">
        {stickyItems.length > 0 && (
          <div className="sidebar-collapsed-sticky-items">{stickyItems.map(renderMenuItem)}</div>
        )}

        {avatar && (
          <CustomTooltip
            offset={5}
            content={
              <>
                <div className="para-body-s-medium">{companyName || loggedInUserName}</div>
                {startDate && (
                  <div className="para-body-xs-regular text-disabled" id="started-date">
                    {getStartDateText(loggedInUserType, startDate, email, isBenchedEmployee)}
                  </div>
                )}
              </>
            }
            direction="right"
            arrow
          >
            <div className="sidebar-collapsed-user-avatar">{avatar}</div>
          </CustomTooltip>
        )}
      </div>
    </div>
  );
}

CollapsedSidebar.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      icon: PropTypes.node.isRequired,
      category: PropTypes.string,
      openInNewTab: PropTypes.bool,
    })
  ),

  smallLogo: PropTypes.node,

  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  }).isRequired,

  onMenuItemClick: PropTypes.func.isRequired,

  userInfo: PropTypes.shape({
    name: PropTypes.string,
    companyName: PropTypes.string,
    startDate: PropTypes.string,
    avatar: PropTypes.node,
  }),

  stickyItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      icon: PropTypes.node.isRequired,
      openInNewTab: PropTypes.bool,
    })
  ),
};

CollapsedSidebar.defaultProps = {
  menuItems: [],
  smallLogo: null,
  userInfo: {},
  stickyItems: [],
};

export default CollapsedSidebar;

import React from "react";

function BlogIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8327 17.5H4.16602C2.7853 17.5 1.66602 16.3807 1.66602 15V2.5H13.3327H14.9993V6.66667H18.3327V7.5V15C18.3327 16.3807 17.2134 17.5 15.8327 17.5ZM13.4749 15.8333C13.3828 15.5727 13.3327 15.2922 13.3327 15V4.16667H3.33268V15C3.33268 15.4602 3.70578 15.8333 4.16602 15.8333H13.4749ZM14.9993 8.33333V15C14.9993 15.4602 15.3724 15.8333 15.8327 15.8333C16.2929 15.8333 16.666 15.4602 16.666 15V8.33333H14.9993ZM8.33268 5.83333V9.16667H4.99935V5.83333H8.33268ZM11.666 6.66667V8.33333H9.16602V6.66667H11.666ZM11.666 10V11.6667H4.99935V10H11.666ZM11.666 12.5V14.1667H4.99935V12.5H11.666Z"
        fill="#FBFAFF"
      />
    </svg>
  );
}

export default BlogIcon;

/* eslint-disable no-nested-ternary */
import React from "react";
import { useParams } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import { Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import DocumentIcon from "./DocumentIcon";

import NdaIcon from "components/Screens/Employee/EmployeeSvgIcons/NdaIcon";
import ContractIcon from "components/Screens/Employee/EmployeeSvgIcons/ContractIcon";
import PersonalIdIcon from "components/Screens/Employee/EmployeeSvgIcons/PersonalIdIcon";

import { documentTpeEnum } from "./DocumentTpe.enum";
import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";
import { usePermissions } from "components/Hook";
import { getDocumentSorted } from "components/Screens/CommonUtils";

import "components/Screens/Employee/EmployeeDocuments.css";

function EmployeeDocuments(props) {
  const { empDocs, adminEmployeeId, setIsLoading, setEditDocument, setOpenEditDocument } = props;
  const { loggedInUser, loggedInUserType } = usePermissions();
  const params = useParams();

  const [employeeDocuments, setEmployeeDocuments] = React.useState({});

  const handleDownload = async (empDocument) => {
    if (setIsLoading) setIsLoading(true);
    if (empDocument) {
      let employeeId = "";

      if (loggedInUserType === "employee") {
        employeeId = loggedInUser?.userId;
      } else if (loggedInUserType === "edge") employeeId = adminEmployeeId;
      else if (loggedInUserType === "customer") employeeId = params?.id;

      const response = await getRequest(
        `${baseUrl}/employees/${employeeId}/document/${empDocument?.documentId}`,
        true,
        "export"
      );

      if (!response?.ok) {
        if (setIsLoading) setIsLoading(false);
        return;
      }
      const url = window.URL.createObjectURL(response?.payload);
      const link = document?.createElement("a");
      link.style.display = "none";
      link.href = url;
      const documentName =
        empDocument?.documentExtension && empDocument?.documentExtension !== "null"
          ? `${empDocument?.documentName}${empDocument?.documentExtension}`
          : empDocument?.documentName;

      link.download = documentName;
      if (setIsLoading) setIsLoading(false);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    }
  };

  React.useEffect(() => {
    if (empDocs?.docs) {
      const sortedGroupedDocuments = getDocumentSorted(empDocs);
      setEmployeeDocuments(sortedGroupedDocuments);
    }
  }, [empDocs?.docs]);

  const uploadedDocument = (doc) => (
    <div className="employee-documentA-list-item" style={{ margin: "none !important" }}>
      {loggedInUserType === "edge" && (
        <div className="employee-docs-edit-icon">
          <Tooltip title="Edit document">
            <IconButton>
              <EditIcon
                onClick={() => {
                  setEditDocument(doc);
                  setOpenEditDocument(true);
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      )}

      <div
        className="employee-docs-icon"
        style={{
          margin: loggedInUserType === "edge" ? "0px" : "10px 0px",
        }}
      >
        <IconButton onClick={() => handleDownload(doc)}>
          {doc?.documentType === documentTpeEnum?.Contract ? (
            <ContractIcon />
          ) : doc?.documentType === documentTpeEnum?.Nda ? (
            <NdaIcon />
          ) : doc?.documentType === documentTpeEnum?.Educational ? (
            <PersonalIdIcon />
          ) : (
            <DocumentIcon />
          )}
        </IconButton>
      </div>

      <Tooltip title={doc?.documentName}>
        <div
          className="employee-docs-title"
          onClick={() => handleDownload(doc)}
          style={{ cursor: "pointer" }}
          id="document-name"
        >
          {doc?.documentName}
        </div>
      </Tooltip>

      <br />
    </div>
  );

  return (
    <div className="employee-documentsA-list">
      {Object.keys(employeeDocuments)?.length
        ? Object.keys(employeeDocuments)?.map((documentType) => {
            return (
              <Accordion sx={{ width: "100%", background: "var(--color-primitives-primary-100)" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>{documentType}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ background: "var(--color-primitives-primary-100)" }}>
                  <Stack direction="row" sx={{ flexFlow: "wrap", gap: "40px" }}>
                    {empDocs?.docs && empDocs?.docs.length
                      ? employeeDocuments[documentType]?.map((item) => (
                          <Grid sx={{ width: "150px" }}>
                            {/* <DocumentCard data={item} /> */}
                            {uploadedDocument(item)}
                            {/* <EmployeeDocuments empDocs={empDocs} /> */}
                          </Grid>
                        ))
                      : null}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })
        : null}
    </div>
  );
}

export default EmployeeDocuments;

/* eslint-disable no-shadow */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Alert, Breadcrumbs, Grid, Link, List, ListItem, Paper, Snackbar, Typography } from "@mui/material";

import PayrollDetailsWidget from "./PayrollDetailsWidget";
import BonusTable from "./BonusTable";
import { currencyFormat, getMonthFromDate, getYearFromDate } from "components/Screens/CommonUtils";
import Button from "components/Button/Button";
import PageLoader from "components/common/PageLoader";

import { GiveRaise } from "store/actions/Raises/GiveRaise";
import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";

function EditPayroll() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [raiseAmount, setRaiseAmount] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [employeeUpdatedArray, setEmployeeUpdatedArray] = useState([]);
  const [disableSubmitButtonFlag, setDisableSubmitButtonFlag] = useState(false);

  const [BonusAmount, setBonusAmount] = useState(0);
  const [employeesIncreamentedSalary, setEmployeesIncrementedSalary] = useState([]);
  const [Raises, setRaises] = useState(0);
  const [Bonuses, setBonuses] = useState(0);
  const [totalSalaries, setTotalSalaries] = useState(0);
  const [userInfo, setUserInfo] = useState({});

  const giveRaiseInfo = useSelector((state) => state.GiveRaise.giveRaiseInfo);
  const loadingGiveRaiseInfo = useSelector((state) => state.GiveRaise.loadingGiveRaiseInfo);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const getTotalRaiseAmount = (
    totalRaiseAmount,
    raises,
    totalSalaries,
    totalBonusAmount,
    bonuses,
    arr,
    employeeUpdatedArray
  ) => {
    const negativeRaiseAmount = arr.filter((employee) => employee.negativeRaiseAmountFlag == true);

    const checkSalaryAfterIncrease = arr.filter((employee) => employee.salaryAfterIncrease == 0);

    const checkPercentageIncrease = arr.filter((employee) => employee?.percentageIncrease?.toString() == "");

    if (negativeRaiseAmount.length || checkSalaryAfterIncrease.length || checkPercentageIncrease.length) {
      setDisableSubmitButtonFlag(true);
    } else {
      setDisableSubmitButtonFlag(false);
    }
    const updatedArr = [];
    employeeUpdatedArray.forEach((updatedEmployee) => {
      const updated = arr.filter((employee) => employee.employeeId == updatedEmployee.employeeId);
      updatedArr.push(updated[0]);
    });

    if (totalSalaries >= 0 && totalRaiseAmount >= 0) {
      setRaiseAmount(totalRaiseAmount);
      setRaises(raises);
      setTotalSalaries(totalSalaries);

      setBonusAmount(totalBonusAmount);
      setBonuses(bonuses);
      setEmployeesIncrementedSalary(arr);
      setEmployeeUpdatedArray(updatedArr);
    }
  };

  const handleSubmit = () => {
    const newDist = [];
    employeeUpdatedArray.forEach((item) => {
      newDist.push({
        employeeId: item?.employeeId,
        amount:
          item?.amount && item?.raiseAmount
            ? parseInt(item?.raiseAmount) + parseInt(item.amount) + parseInt(item.currentSalary)
            : item?.raiseAmount
              ? parseInt(item?.raiseAmount) + parseInt(item.currentSalary)
              : item?.amount
                ? parseInt(item.amount) + parseInt(item.currentSalary)
                : parseInt(item.currentSalary),
        description: item?.description,
        bonusMonth: new Date(),
        incrementAmount: item?.raiseAmount,
        bonus: parseInt(item?.amount),
        incrementPercentage: item?.percentageIncrease,
      });
    });

    dispatch(
      GiveRaise({
        request: "addbonus",
        employeesIncreamentedSalary: newDist,
      })
    );
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              <Typography variant="h6" color="secondary" fontSize="12px">
                {error?.description}
              </Typography>
            </ListItem>
          </List>
        );
      });
    }
    return null;
  };

  // methods
  useEffect(() => {
    let loggedInUser = {};
    let request = null;
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (giveRaiseInfo?.ok == false) {
      setOpenAlert(false);
    } else if (giveRaiseInfo?.ok) {
      if (loggedInUser?.userId != undefined) {
        request = `customer/${loggedInUser.customerId}/info`;
        dispatch(UserAdditionalInfo(request));
        navigate("/main/payroll");
      }
    }
  }, [giveRaiseInfo]);

  useEffect(() => {
    setUserInfo(JSON.parse(sessionStorage.getItem("userAdditionalInfo")));
  }, [userAdditionalInfo]);

  return (
    <>
      {(loadingAdditionalInfo || loadingGiveRaiseInfo) && <PageLoader />}
      <Grid container sx={{ backgroundColor: "#FBFAFF", height: "calc(100% - 46px)" }}>
        <Grid sx={{ margin: "auto", marginTop: "0" }}>
          <Grid container sx={{ marginTop: "5px" }}>
            <Grid
              sx={{
                maxWidth: "1076px",
                width: "100%",
                margin: "0px auto",
                padding: "12px 0px",
              }}
            >
              <Grid sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                <Breadcrumbs sx={{ fontSize: "12px" }}>
                  <Link
                    href="/main/dashboard"
                    sx={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#212231",
                      textDecoration: "none",
                    }}
                  >
                    Home
                  </Link>
                  <Link
                    href="/main/payment"
                    sx={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#212231",
                      textDecoration: "none",
                    }}
                    aria-current="page"
                  >
                    Payroll
                  </Link>
                </Breadcrumbs>
                <Typography
                  sx={{
                    color: "#14151F",
                    fontFamily: "Poppins-Regular",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "32px",
                  }}
                >
                  Edit Payroll for {getMonthFromDate(userInfo?.paymentDate)}
                </Typography>
              </Grid>
              <ul style={{ margin: "0px", padding: "12px 18px 0px" }}>
                <li
                  style={{
                    marginBottom: "-5px",
                  }}
                >
                  <Typography
                    variant="h3Lite"
                    sx={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#212231",
                    }}
                  >
                    Raises will be applied in{" "}
                    {userInfo?.paymentDate
                      ? `${getMonthFromDate(userInfo?.paymentDate)} , ${getYearFromDate(userInfo?.paymentDate)}`
                      : null}{" "}
                    and salaries will be updated for all future updates
                  </Typography>
                </li>
                <li
                  style={{
                    marginBottom: "-5px",
                  }}
                >
                  <Typography
                    variant="h3Lite"
                    sx={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#212231",
                    }}
                  >
                    Add Final Salaries in the boxes next to the employee and then click submit
                  </Typography>
                </li>
                <li
                  style={{
                    marginBottom: "-5px",
                  }}
                >
                  <Typography
                    variant="h3Lite"
                    sx={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#212231",
                    }}
                  >
                    Added Bonus will only go out for this month’s payroll and will not be repeated
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>

          <Grid
            sx={{
              maxWidth: "1076px",
              width: "100%",
              margin: "0px auto",
              padding: "12px 0px",
            }}
          >
            <BonusTable getTotalRaiseAmount={getTotalRaiseAmount} />
            <Snackbar
              open={openAlert}
              autoHideDuration={2500}
              onClose={handleAlertClose}
              sx={{
                "&.MuiSnackbar-root": {
                  position: "relative",
                  bottom: 9,
                  left: 0,
                },
              }}
            >
              <Alert onClose={handleAlertClose} severity="error">
                {giveRaiseInfo?.ok == false ? getError(giveRaiseInfo?.payload?.error) : "null"}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
        {/* <Grid item> */}

        {/* </Grid> */}

        <Grid
          container
          sx={{
            backgroundColor: "white",
          }}
        >
          <Grid
            container
            sx={{
              maxWidth: "1076px",
              width: "100%",
              margin: "0px auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Paper elevation={0}>
              <Grid container sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <Grid item>
                  <PayrollDetailsWidget
                    amount={`$ ${currencyFormat(totalSalaries + raiseAmount + BonusAmount)}`}
                    urls={employeeUpdatedArray.length ? employeeUpdatedArray : employeesIncreamentedSalary}
                    avatarGroup
                    title={`Total Payroll for ${getMonthFromDate(userInfo?.paymentDate)}`}
                    subtitle={`$ ${currencyFormat(raiseAmount + BonusAmount)} Added`}
                  />
                </Grid>
                <Grid item alignItems="center">
                  <PayrollDetailsWidget
                    urls={employeeUpdatedArray.length ? employeeUpdatedArray : employeesIncreamentedSalary}
                    avatarGroup
                    amount={`$ ${currencyFormat(raiseAmount)}`}
                    title="Raise Added"
                    subtitle={`${Raises} employees`}
                  />
                </Grid>
                <Grid item alignItems="center">
                  <PayrollDetailsWidget
                    urls={employeeUpdatedArray.length ? employeeUpdatedArray : employeesIncreamentedSalary}
                    avatarGroup
                    amount={`$ ${currencyFormat(BonusAmount)}`}
                    title="Bonus Added"
                    subtitle={`${Bonuses} employees`}
                  />
                </Grid>
              </Grid>
            </Paper>

            <Grid sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
              <Button
                variant="ghost"
                size="lg"
                style={{
                  width: "120px",
                  height: "44px",
                  color: "",
                  fontFamily: "Poppins-Regular",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
                onClick={() => navigate("/main/payroll")}
              >
                Back
              </Button>
              <Button
                disableElevation
                disabled={disableSubmitButtonFlag}
                variant="primary"
                size="lg"
                sx={{
                  width: "120px",
                  height: "44px",
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default EditPayroll;

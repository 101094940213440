import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  Alert,
  List,
  ListItem,
} from "@mui/material";
import { Box } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import PageLoader from "components/common/PageLoader";
import GoogleSignup from "components/Screens/Login/SignUp/GoogleSignup";

import { AuthUser, ResetAuthUser } from "store/actions/AuthUser";
import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";
import { ResetGoogleSigunp } from "store/actions/Google/Google_signup";
import { GetPlatformConfig } from "store/actions/PlatformConfig";

import { baseUrl } from "util/APIBaseUrl";

import { SignInScreenPermissions } from "../SignIn.enum";

const userCredentials = {
  email: "",
  password: "",
  loginPortal: null,
};

function SignIn(props) {
  const { changeScreen } = props;
  const { handleSubmit } = useForm();

  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [userCredential, setUserCredential] = useState(userCredentials);
  const [loginButtonClicked, setLoginButtonClicked] = useState(false);

  const authUserData = useSelector((state) => state.AuthUser.authUserData);
  const loadingAuthUserData = useSelector((state) => state.AuthUser.loadingAuthUserData);
  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);
  const loadingplatformConfig = useSelector((state) => state.PlatformConfig.loadingplatformConfig);
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);
  const googleSignupInfo = useSelector((state) => state.GoogleSignup.googleSignupInfo);
  const loadingGoogleSignupInfo = useSelector((state) => state.GoogleSignup.loadingGoogleSignupInfo);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setUserCredential({
      ...userCredential,
      [event.target.name]: value,
    });
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },

              "&.MuiList-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              <ListItem
                sx={{
                  display: "list-item",
                }}
              >
                {(() => {
                  let message = "Undefined Error";
                  if (error?.msg) {
                    message = error.msg;
                  } else if (error?.description) {
                    message = error.description;
                  }

                  const param = error?.param || " ";
                  return `${message} ${param}`;
                })()}
              </ListItem>
            </ListItem>
          </List>
        );
      });
    }
    return null;
  };

  const checkError = (field, view) => {
    if (loginButtonClicked) {
      if (view != "textField") {
        if (userCredential[field]?.key != null) {
          return false;
        }
        return true;
      }
      if (userCredential[field] != "") {
        return false;
      }
      return true;
    }
    return false;
  };

  const routesMap = {
    "/admin": "admin",
    "/employee": "employee",
  };

  const loginHandler = () => {
    setLoginButtonClicked(true);
    dispatch(ResetGoogleSigunp({}));
    dispatch(ResetAuthUser({}));
    if (userCredential.email != "" && userCredential.password != "") {
      setOpenAlert(false);
      const request = `${baseUrl}/auth`;

      dispatch(
        AuthUser({
          userCredential,
          request,
          loginPortal: routesMap[location.pathname] ? routesMap[location.pathname] : "customer",
        })
      );
    } else {
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const accesstoken = localStorage.getItem("accessToken");
    let request = "";
    if (accesstoken && loggedInUser) {
      if (loggedInUser?.type == "customer") {
        request = `customer/${loggedInUser.customerId}/info`;
      } else if (loggedInUser?.type == "edge") {
        request = `user/${loggedInUser.userId}/summary`;
      } else if (loggedInUser?.type == "employee") {
        request = `employee/${loggedInUser.userId}`;
      }
      dispatch(UserAdditionalInfo(request));
    }

    if (Object.keys(authUserData).length > 0 && authUserData.ok != true) {
      setOpenAlert(true);
    }
  }, [authUserData]);

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const accesstoken = localStorage.getItem("accessToken");

    if (
      userAdditionalInfo != null &&
      Object.keys(userAdditionalInfo).length &&
      accesstoken != null &&
      accesstoken != "undefined" &&
      loggedInUser != null &&
      loggedInUser != "undefined"
    ) {
      window.analytics?.track("Logged In", {
        channel: "google",
        name: loggedInUser?.name,
        userID: loggedInUser?.userId,
        email: loggedInUser?.email,
        accountType: loggedInUser?.type,
      });

      if (loggedInUser?.type == "customer") {
        if (!loggedInUser?.bankAttached && userAdditionalInfo.status === "enabled") {
          navigateTo("/addPaymentMethod");
        } else {
          navigateTo("/main/dashboard");
        }
      } else if (loggedInUser?.type == "edge") {
        navigateTo("/main/dashboard");
      }
    }
    if (userAdditionalInfo != null && Object.keys(userAdditionalInfo).length > 0 && userAdditionalInfo.ok != true) {
      setOpenAlert(true);
    }
  }, [userAdditionalInfo]);

  useEffect(() => {
    dispatch(GetPlatformConfig({}));
  }, []);

  return (
    <>
      {(loadingAuthUserData == true ||
        loadingAdditionalInfo == true ||
        loadingplatformConfig == true ||
        loadingGoogleSignupInfo == true) && <PageLoader />}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "#DBEFED", overflowY: "scroll" }}
      >
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <form onSubmit={handleSubmit(loginHandler)}>
            <Grid item container direction="column" rowSpacing={2} sx={{ maxWidth: "330px" }}>
              <Grid item>
                <Typography
                  sx={{
                    fontFamily: "Poppins-Bold !important",
                    color: "#010533",
                    fontSize: "35px",
                    lineHeight: "50px",
                    marginTop: "20px",
                    "@media screen and (min-width: 912px)": {
                      marginTop: "0px",
                    },
                  }}
                >
                  Admin Portal !
                </Typography>
              </Grid>

              {platformConfig?.payload?.ADMIN_BASIC_AUTH == "1" && (
                <>
                  <Grid item>
                    <FormControl variant="outlined" fullWidth error>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label>
                        <Typography
                          fontWeight={500}
                          // color={"#000000"}
                          sx={{
                            fontFamily: "Poppins-Medium !important",
                            color: checkError("email", "textField") ? "#F64A14" : "000000",
                            fontSize: "13px",
                            lineHeight: "8px",
                            paddingTop: 1,
                          }}
                        >
                          Email
                        </Typography>
                      </label>
                      <TextField
                        sx={{
                          "& .MuiInputBase-root": {
                            backgroundColor: "white",
                            borderRadius: "6px",
                            fontFamily: "Poppins-Medium !important",
                          },
                          "& .MuiInputBase-input": {
                            paddingTop: 1,
                            paddingLeft: 2,
                            paddingBottom: 1,

                            fontSize: "13px",
                          },
                        }}
                        id="userName"
                        error={checkError("email", "textField")}
                        placeholder="ABC@Hotmail.com"
                        size="small"
                        name="email"
                        fullWidth
                        onChange={handleInputChange}
                        margin="dense"
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            notchedOutline: {
                              borderWidth: "1px",
                              borderColor: "yellow !important",
                            },
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <FormControl variant="outlined" fullWidth>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="password">
                        <Typography
                          fontWeight={500}
                          color="#000000"
                          sx={{
                            fontFamily: "Poppins-Medium !important",
                            color: checkError("password", "textField") ? "#F64A14" : "000000",
                            fontSize: "13px",
                            lineHeight: "8px",
                          }}
                        >
                          Password
                        </Typography>
                      </label>
                      <TextField
                        sx={{
                          "& .MuiInputBase-root": {
                            backgroundColor: "white",
                            borderRadius: "6px",
                            fontFamily: "Poppins-Medium !important",
                          },
                          "& .MuiInputBase-input": {
                            paddingTop: 1,
                            paddingLeft: 2,
                            paddingBottom: 1,
                            fontSize: "13px",
                          },
                        }}
                        id="password"
                        placeholder="Enter Password"
                        size="small"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        onChange={handleInputChange}
                        // variant={
                        //     checkError("password", "textField")
                        //         ? "outlined"
                        //         : "standard"
                        // }
                        error={checkError("password", "textField")}
                        margin="dense"
                        InputProps={{
                          disableUnderline: true,

                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <Link
                        sx={{
                          paddingTop: "5px",
                          color: "#000000",
                          fontSize: "12px",
                          fontWeight: "500",
                          fontFamily: "Poppins-Medium !important",
                          cursor: "pointer",
                          width: 130,
                        }}
                      >
                        <Typography
                          fontWeight={500}
                          color="#000000"
                          onClick={() => {
                            changeScreen(SignInScreenPermissions.FORGOT_PASSWORD);
                          }}
                          sx={{
                            fontFamily: "Poppins-Medium !important",

                            fontSize: "13px",
                            lineHeight: "8px",
                          }}
                        >
                          Forgot Password?
                        </Typography>
                      </Link>
                    </FormControl>
                  </Grid>
                </>
              )}

              <Grid item>
                {authUserData?.ok == false || googleSignupInfo?.ok == false ? (
                  <Alert
                    open={openAlert}
                    autoHideDuration={3500}
                    variant="filled"
                    icon={false}
                    sx={{
                      backgroundColor: "#F64A14",
                      padding: "initial",
                      px: "5px",
                      fontSize: "12px",
                      textAlign: "center",

                      borderRadius: "5px !important",
                      color: "#ffffff !important",
                      "&.MuiPaper-root": {
                        color: "#ffffff !important",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Regular !important",
                        fontWeight: "400",
                        fontSize: "13px",
                      }}
                    >
                      {/* TODO:  */}
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {authUserData?.ok == false
                        ? getError(authUserData?.payload?.error)
                        : googleSignupInfo?.ok == false
                          ? getError(googleSignupInfo?.payload?.error)
                          : "Enter all information before proceeding"}
                    </Typography>
                  </Alert>
                ) : null}
              </Grid>

              {platformConfig?.payload?.ADMIN_BASIC_AUTH == "1" && (
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "var(--color-primitives-primary-900)",
                      height: "50px",
                      fontFamily: "Poppins-Regular !important",
                      borderRadius: "6px",
                      fontSize: "16px",
                      color: "white",
                    }}
                  >
                    Lets Go!
                  </Button>
                </Grid>
              )}
              {platformConfig?.ok && (
                <>
                  {platformConfig?.payload?.ADMIN_BASIC_AUTH == "1" && (
                    <Grid item sx={{ mt: 5 }}>
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Bold !important",
                          color: "080D46",
                          fontSize: "18px",
                          fontWeight: "800",
                          lineHeght: "2px",
                          marginBottom: "0",
                        }}
                      >
                        Or Sign In with,
                      </Typography>
                    </Grid>
                  )}
                  <Grid item sx={{ paddingTop: "8px !important" }}>
                    <GoogleSignup changeScreen={changeScreen} />
                  </Grid>
                </>
              )}

              <Grid item textAlign="center" />
              <Grid item textAlign="center">
                <Typography variant="h3Lite" fontSize="9px">
                  {process.env.REACT_APP_NAME}
                  {` ${process.env.REACT_APP_VERSION}`}
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={12} xs={12} alignItems="flex-end" textAlign="end">
          <Box
            component="img"
            // mt={-10}
            sx={{
              height: "auto",
              maxWidth: "100%",
              objectFit: "contain",
              // override on screens greater than 912px
              "@media screen and (min-width: 912px)": {
                objectFit: "cover",
                height: "100vh",
              },
            }}
            alt="The house from the offer."
            src="assets/fonts/Images/Group 48.png"
          />
          {/* </Box> */}
        </Grid>
      </Grid>
    </>
  );
}

export default SignIn;

import React from "react";
import { Grid, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import SunIcon from "../../assets/svgs/SunIcon";
import MoonIcon from "../../assets/svgs/MoonIcon";

import { getValue } from "../Screens/CommonUtils";
import { usePermissions } from "../Hook";

import "./GreetingHeader.scss";

function GreetingHeader({ isEmployee }) {
  const { inAppNotifications } = useFlags();
  const getDayStatus = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return "Morning";
    }
    if (currentHour >= 12 && currentHour < 17) {
      return "Afternoon";
    }

    return "Evening";
  };

  const { loggedInUser } = usePermissions();

  return (
    <Grid
      container
      sx={{
        height: "80px",
        backgroundColor: "#FFFFFF",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          width: "752px",
          padding: "16px 0px",
          display: "flex",
          alignItems: "center",
          gap: "14px",
          marginX: inAppNotifications ? "auto" : "0",
        }}
      >
        {getDayStatus() === "Morning" || getDayStatus() === "Afternoon" ? <SunIcon /> : <MoonIcon />}

        <Grid>
          <Typography
            variant="h1"
            id="greetings"
            sx={{
              fontFamily: "Poppins-Semibold",
              fontStyle: "normal",
              fontSize: "20px",
              lineHeight: "29px",
              color: "#14151F",
            }}
          >
            Good {getDayStatus()}, {getValue(loggedInUser?.name)}!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Poppins-Regular",
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2C2D2E",
            }}
          >
            {isEmployee ? "Keep up the good work!" : "We hope you have a great day!"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GreetingHeader;

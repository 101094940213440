import React from "react";
import UserProfileWithInfo from "./UserProfileWithInfo";
import { Grid } from "@mui/material";

import Button from "components/Button/Button";

import EditIcon from "assets/svgs/EditIcon";
import DeleteIcon from "assets/svgs/DeleteIcon";

function DisplayUserList(props) {
  const { user, setAddorEditUser, setEditAbleUser, handleRemoveUser } = props;

  return (
    <Grid item container direction="row" justifyContent="space-between" alignItems="center" sx={{ pb: "16px" }}>
      <Grid item>
        <UserProfileWithInfo user={user} />
      </Grid>

      <Grid item sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Button
          type="button"
          variant="primary"
          style={{
            color: "var(--temp-color-primitives-primary-800)",
            backgroundColor: "var(--color-primitives-primary-50)",
            border: "none",
          }}
          onClick={() => {
            setAddorEditUser({
              open: true,
              mode: "editUser",
            });
            setEditAbleUser(user);
          }}
          size="sm"
          leadIcon={
            <EditIcon color="var(--temp-color-primitives-primary-800)" style={{ width: "16px", height: "16px" }} />
          }
        >
          Edit Profile
        </Button>

        {/* {user?.mainUser !== true && ( */}
        <Button
          type="button"
          variant="outlined"
          disabled={user?.mainUser}
          size="sm"
          style={{
            color: "#BA1A1A",
            fontSize: "14px",
            fontFamily: "Poppins-Medium",
            lineHeight: "20px",
          }}
          onClick={() => handleRemoveUser(user)}
          leadIcon={<DeleteIcon />}
        >
          Remove User
        </Button>
        {/* )} */}
      </Grid>
    </Grid>
  );
}

export default DisplayUserList;

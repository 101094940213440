/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFlags } from "launchdarkly-react-client-sdk";

import TopHeader from "components/common/TopHeader";
import Text from "components/common/Text";
import Button from "components/common/Button";
import Select from "components/common/Select";
import CustomDatePicker from "components/common/DatePicker";
import PhoneInput from "components/common/PhoneInput";
import DragAndDrop from "components/common/DragAndDrop";
import { AlertType } from "components/common/Snackbar";
import PageLoader from "components/common/PageLoader";
import EmployeeAddSuccessModal from "components/Screens/Workforce/AdminWorkforce/AddTalent/EmployeeAddSuccessModal";
import SearchableAutoComplete from "components/common/AutoComplete";

import AddTalentSchema from "validations/Admin/AddTalentSchema";

import { addNewEmployee, getEmployeeSuccessManagersList } from "services/Admin/Dashboard";
import { getUploadDocumentSignedUrl } from "services/BambooHrTabs/documents";

import { showSnackbar } from "store/actions/Utility";

import { getNationalIDHelperText } from "util/utils";

import { GENDERS, MARITAL_STATUS, MAX_FILE_SIZE_LIMIT, MAX_FILE_SIZE_LIMIT_TEXT } from "constants";

import "./AddTalent.scss";

function AddTalent() {
  const dispatch = useDispatch();
  const flags = useFlags();
  const { bambooHrBulkUpload } = flags;

  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [updatedFileName, setUpdatedFileName] = useState();
  const [fetchingEmployeeSuccessManagers, setFetchingEmployeeSuccessManagers] = useState(true);
  const [openEmployeeSuccessModal, setOpenEmployeeSuccessModal] = useState(false);
  const [resetUploadedFiles, setResetUploadedFiles] = useState(false);
  const [creatingEmployee, setCreatingEmployee] = useState(false);
  const [files, setFiles] = useState([]);

  // TODO: legacy code should be removed when removing after LD flag bambooHrBulkUpload is removed
  const [uploadProgress, setUploadProgress] = useState(0);

  const countriesList = useMemo(() => JSON.parse(sessionStorage.getItem("dropdownData"))?.payload?.country || [], []);
  const countries = countriesList.map(({ countryCode }) => ({
    value: countryCode,
    label: countryCode,
  }));

  const methods = useForm({
    resolver: yupResolver(AddTalentSchema),
    mode: "onChange",
  });

  const {
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting },
  } = methods;

  const country = watch("country");
  const stateValues = watch("stateValues");
  const successManagersList = watch("successManagersList");
  const values = watch();

  const fetchEmployeeSuccessManagersList = async () => {
    setFetchingEmployeeSuccessManagers(true);
    const res = await getEmployeeSuccessManagersList();
    const refinedManagersList = res?.rows.map(({ firstName, lastName, userId }) => ({
      label: firstName + lastName,
      value: firstName + lastName,
      userId,
    }));
    setValue("successManagersList", refinedManagersList || [], { shouldValidate: true });
    setFetchingEmployeeSuccessManagers(false);
  };

  const handleFileUpload = async (selectedFiles) => {
    if (selectedFiles.length === 0) {
      setFileUploadStatus(false);
    } else {
      setCreatingEmployee(true);
      setFileUploadStatus(false);
      const { file } = selectedFiles[0];
      setSelectedFile(file);

      const unixTimeStamp = Math.floor(Date.now() / 1000);
      const newFileName = `${unixTimeStamp}_${file.name}`;

      setUpdatedFileName(newFileName);
      let response = null;
      try {
        response = await getUploadDocumentSignedUrl(newFileName, file.type, file.size);
      } catch (error) {
        dispatch(
          showSnackbar({
            type: AlertType.error,
            message: error?.message,
            dispatch,
          })
        );
      }

      try {
        const options = {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setFiles((prev) =>
              prev.map((item) =>
                item.id === selectedFiles[0].id
                  ? { ...item, progress: percentage, retry: false, status: "uploading", error: false }
                  : item
              )
            );

            // TODO: legacy code should be removed when removing after LD flag bambooHrBulkUpload is removed
            setUploadProgress(percentage);
          },
        };
        await axios.put(response?.url, file, options);

        setFiles((prev) =>
          prev.map((item) =>
            item.id === selectedFiles[0].id ? { ...item, uniqueFileName: newFileName, status: "complete" } : item
          )
        );
        setFileUploadStatus(true);
        setCreatingEmployee(false);
      } catch (error) {
        setFiles((prev) =>
          prev.map((item) =>
            item.id === selectedFiles[0].id ? { ...item, status: "failed", error: "Network error", retry: true } : item
          )
        );
        setCreatingEmployee(false);
        dispatch(
          showSnackbar({
            type: AlertType.error,
            message: error?.message,
            dispatch,
          })
        );
      }
    }
  };

  const resetToDefaultValues = () => {
    reset({
      address: "",
      city: "",
      country: "",
      datePicker: "",
      edgeEmail: "",
      employeeSuccessManager: "",
      firstName: "",
      gender: "",
      hireDate: "",
      jobTitle: "",
      lastName: "",
      middleName: "",
      preferredName: "",
      nationalID: "",
      offerLetter: "",
      personalEmail: "",
      phoneNumber: "",
      maritalStatus: "",
      state: "",
      zip: "",
      successManagersList: values.successManagersList,
    });

    setOpenEmployeeSuccessModal(true);
    setOpenEmployeeSuccessModal(false);
    setFileUploadStatus(false);
    setFetchingEmployeeSuccessManagers();
    setFiles([]);
    setUpdatedFileName("");
    setSelectedFile();
    setResetUploadedFiles(true);
    setTimeout(() => {
      setResetUploadedFiles(false);
    }, 0);
  };

  const onSubmit = (data) => {
    const { personalEmail } = data;
    const tempSuccessManagersList = data.successManagersList;
    const tempStateValues = data.successManagersList;
    delete data.successManagersList;
    delete data?.stateValues;
    delete data?.personalEmail;
    if (!data?.maritalStatus) {
      delete data.maritalStatus;
    }

    setCreatingEmployee(true);
    addNewEmployee({
      ...data,
      edgeHREmployeeID: successManagersList.find((manager) => data.employeeSuccessManager == manager?.label)?.userId,
      cnic_dni_cex: data.nationalID,
      dateOfBirth: moment(data.datePicker).format("YYYY-MM-DD"),
      edgeJoiningDate: moment(data.hireDate).format("YYYY-MM-DD"),
      addressLine1: data.address,
      email: data.edgeEmail,
      ...(personalEmail && { personalEmail }),
      offerLetter: selectedFile
        ? {
            fileName: updatedFileName,
            size: selectedFile.size,
            fileType: selectedFile.type,
          }
        : undefined,
    }).then((res) => {
      setValue("successManagersList", tempSuccessManagersList);
      setValue("successManagersList", tempStateValues);
      setCreatingEmployee(false);

      if (res?.payload?.error?.length > 0) {
        dispatch(
          showSnackbar({
            type: AlertType.error,
            message: res?.payload?.error?.[0]?.description || res?.payload?.error?.[0]?.msg || "An error occurred.",
            dispatch,
          })
        );
        return;
      }

      setOpenEmployeeSuccessModal(true);
    });
  };

  useEffect(() => {
    if (!country) return;
    const states = countriesList
      .find((s) => s.countryCode === country)
      ?.states?.map(({ name }) => ({
        value: name,
        label: name,
      }));
    setValue("stateValues", states, { shouldValidate: true });
  }, [countriesList, country, setValue]);

  useEffect(() => {
    fetchEmployeeSuccessManagersList();
  }, []);

  return (
    <div className="add-talent-wrapper">
      {isSubmitting && fetchingEmployeeSuccessManagers && <PageLoader />}

      <TopHeader
        title="Add Employee"
        linkName="Workforce"
        link="/main/workforce"
        breadcrumbs
        backIcon="/main/workforce"
      />

      <EmployeeAddSuccessModal
        name={`${values.firstName} ${values.middleName || ""} ${values.lastName}`}
        open={openEmployeeSuccessModal}
        addAnotherEmployee={resetToDefaultValues}
      />

      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit((data) => onSubmit(data, e))(e);
          }}
        >
          <div className="add-talent-container">
            <div className="heading-h3-semibold">Personal</div>

            <div className="flex gap-1 mt-05">
              <Text label="First name" name="firstName" placeholder="e.g. John" />
              <Text label="Middle name" isOptional name="middleName" placeholder="e.g. Doe" />
              <Text label="Last name " name="lastName" placeholder="e.g. Smith" />
              {bambooHrBulkUpload && (
                <Text label="Preferred name" isOptional name="preferredName" placeholder="e.g. John" />
              )}
            </div>

            <div className="flex gap-1 mt-1">
              <SearchableAutoComplete label="Country" name="country" placeholder="Select" options={countries} />
              <Text
                label="National ID"
                helperTextWithoutError={getNationalIDHelperText(country)}
                name="nationalID"
                placeholder="CNIC, DNI, etc."
              />
            </div>

            <div className="flex gap-1 mt-1">
              <Select label="Gender" name="gender" placeholder="Select" options={GENDERS} />
              <CustomDatePicker name="datePicker" label="Date of birth" />
              {bambooHrBulkUpload && (
                <Select
                  isOptional
                  label="Marital status"
                  name="maritalStatus"
                  placeholder="Select"
                  options={MARITAL_STATUS}
                />
              )}
            </div>
          </div>

          <div className="add-talent-container">
            <div className="heading-h3-semibold">Contact</div>

            <div className="flex gap-1 mt-05">
              <PhoneInput
                label="Phone number"
                isOptional
                name="phoneNumber"
                placeholder="e.g. John"
                defaultCountry={country}
              />
              <Text label="Personal email address" isOptional name="personalEmail" placeholder="name@example.com" />
              <Text label="Work email address " name="edgeEmail" placeholder="name@example.com" />
            </div>

            <div className="flex gap-1 mt-1">
              <Select
                label="State"
                name="state"
                placeholder={!country ? "Select country first" : "Select"}
                disabled={!country}
                options={stateValues}
              />
              <Text label="City" name="city" placeholder="e.g. Middletown" />
              <Text label="ZIP code" name="zip" placeholder="e.g. 07701" />
            </div>

            <div className="flex gap-1 mt-1">
              <Text label="Residential address" name="address" placeholder="e.g. House 11, 350 East Main Road" />
            </div>
          </div>

          <div className="add-talent-container">
            <div className="heading-h3-semibold">Employment at Edge</div>

            <div className="flex gap-1 mt-05">
              <CustomDatePicker label="Hire date" name="hireDate" />
              <SearchableAutoComplete
                label="Employee success manager"
                name="employeeSuccessManager"
                isOptional
                placeholder="Search by name or email address"
                options={successManagersList}
              />
            </div>

            <div className="flex gap-1 mt-1">
              <Text label="Job title" name="jobTitle" placeholder="e.g. Client Associate" />
            </div>
          </div>

          <div className="add-talent-container">
            <div className="heading-h3-semibold">Edge Offer Letter (optional)</div>
            <div className="heading-h5-regular mb-05 mt-025 color-text-body-primary">
              This document will be saved in the Signed Documents folder and shared only with the Employee.
            </div>

            <DragAndDrop
              loading={!fileUploadStatus}
              helperText={`PDF, PNG, JPEG ${MAX_FILE_SIZE_LIMIT_TEXT}`}
              name="offerLetter"
              supportedFormats={["application/pdf", "image/png", "image/jpeg"]}
              maxFileSize={MAX_FILE_SIZE_LIMIT}
              onFileSelect={handleFileUpload}
              removeLoadingWhenCancelledUpload={() => setCreatingEmployee(false)}
              uploadProgress={uploadProgress}
              isFileUploaded={fileUploadStatus}
              files={files}
              setFiles={setFiles}
              resetUploadedFiles={resetUploadedFiles}
            />
          </div>

          <div className="add-talent-btn">
            <Button
              isDisabled={!isValid}
              isLoading={creatingEmployee}
              type="submit"
              name="submitButton"
              label="Save"
              isFullWidth
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default AddTalent;

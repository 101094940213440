import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Typist from "react-typist";
import { useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Button, IconButton, InputAdornment, OutlinedInput, Avatar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import NewChatExperience from "./NewChatExperience";
import NewUserExperience from "./NewUserExperience";
import ErrorPage from "./ErrorPage";
import { VivoSideBar } from "./VivoSideBar";

import {
  sendMessage,
  getThreadMessages,
  getUserThreads,
  deleteUserThread,
  getRandomPrompts,
} from "services/edgeGptService";
import { baseUrl } from "util/APIBaseUrl";

import vivoMainLogo from "assets/images/vivoMainLogo.svg";
import vivoBlackLogo from "assets/images/vivoBlackLogo.svg";
import sendButtonIcon from "assets/images/sendButtonIcon.svg";
import sendButtonIconRebrand from "assets/images/sendButtonIcon-rebrand.svg";

import "./EdgeVivo.css";

function EdgeVivo() {
  const latestMessageRef = useRef(null);
  const { platfromRebrandPhase1 } = useFlags();

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const username = loggedInUser?.name;
  const userEmail = loggedInUser?.email ? loggedInUser?.email : loggedInUser?.contactEmail;

  const [showNewUserExperience, setShowNewUserExperience] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isTypingComplete, setIsTypingComplete] = useState(true);
  const [examplePrompts, setExamplePrompts] = useState([]);
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [conversations, setConversations] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [threadToDelete, setThreadToDelete] = useState(null);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);

  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const response = await getRandomPrompts();
        setExamplePrompts(response.payload.data.prompts);
      } catch (error) {
        console.error("Error fetching prompts:", error);
      }
    };

    fetchPrompts();
  }, []);

  useEffect(() => {
    setProfilePictureUrl(`${baseUrl}/employee/${loggedInUser?.userId}/logo?id=${userAdditionalInfo?.pictureVersion}`);
  }, []);

  const handleOpenConfirmationModal = (threadId) => {
    setThreadToDelete(threadId);
    setOpenConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  useEffect(() => {
    const newUserExperienceShown = localStorage.getItem("newUserExperienceShown");
    if (!newUserExperienceShown) {
      setShowNewUserExperience(true);
    }
  }, []);

  const fetchUserThreadsAsync = async () => {
    try {
      const data = await getUserThreads({ email: userEmail });
      const { threads } = data.payload.data;
      const updatedConversations = threads.map((thread) => ({
        name: `Conversation ${thread.openai_thread_id}`,
        messages: thread.messages,
        isActive: thread.isActive,
        thread_id: thread.openai_thread_id,
        title: thread.thread_title,
      }));

      setConversations(updatedConversations.reverse());
    } catch (error) {
      console.error("Error fetching user threads:", error);
      setHasError(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserThreadsAsync();
    };
    fetchData();
  }, []);

  const deactivateThread = async (threadId) => {
    try {
      await deleteUserThread({ email: userEmail, thread_id: threadId });
      if (window.heap) {
        window.heap.track("Deleted a conversation", { "conversation-id": threadId });
      }
      handleCloseConfirmationModal();
      await fetchUserThreadsAsync();
    } catch (error) {
      console.error(error);
      setHasError(true);
    }
  };

  const handleStartNow = () => {
    setShowNewUserExperience(false);
    localStorage.setItem("newUserExperienceShown", "true");
  };

  const [loadingMessage, setLoadingMessage] = useState(false);

  const [showNewChatExperience, setShowNewChatExperience] = useState(true);
  const [activeThreadId, setActiveThreadId] = useState("");

  useEffect(() => {
    if (conversations.length < 1) {
      setShowNewChatExperience(true);
      setActiveThreadId(null);
    }
  }, [conversations]);

  const handleNewChatClick = () => {
    const tempId = `temp_${Math.random() * 2}`;
    setShowNewChatExperience(true);
    setActiveThreadId(tempId);
  };

  const [newMessage, setNewMessage] = useState("");

  const updateConversationsWithMessage = (threadId, message, isNew) => {
    setConversations((prevConversations) => {
      const updatedConversations = [...prevConversations];
      const conversationIndex = prevConversations.findIndex((c) => c.thread_id === threadId);
      if (conversationIndex !== -1) {
        updatedConversations[conversationIndex].messages.push(message);
      } else if (isNew) {
        updatedConversations.unshift({
          thread_id: threadId,
          name: `Conversation ${threadId}`,
          messages: [message],
          isActive: true,
        });
      }
      return updatedConversations;
    });
  };

  const processServerResponse = (tempThreadId, actualThreadId, messages, titleOfThread) => {
    setConversations((prevConversations) => {
      return prevConversations.map((conv) => {
        if (conv.thread_id === tempThreadId) {
          const messageIndex = conv.messages.findIndex((msg) => msg.id === "loadingMessage");
          if (messageIndex === -1 || !messages || messages.length === 0) {
            console.error("Loading message not found or no messages in the server response.");
            return conv;
          }

          const updatedMessages = [...conv.messages];
          updatedMessages[messageIndex] = {
            role: "assistant",
            time: new Date().toISOString(),
            text: messages,
            isNewMessage: true,
          };
          return {
            ...conv,
            thread_id: actualThreadId,
            messages: updatedMessages,
            ...(titleOfThread != null && titleOfThread !== undefined ? { title: titleOfThread } : {}),
          };
        }
        return conv;
      });
    });
    setActiveThreadId(actualThreadId);
    setLoadingMessage(false);
  };

  const removeLoadingMessage = (threadId, loadingMessageId) => {
    setConversations((prevConversations) =>
      prevConversations.map((conv) => {
        if (conv.thread_id === threadId) {
          const newMessages = conv.messages.filter((msg) => msg.id !== loadingMessageId);
          return { ...conv, messages: newMessages };
        }
        return conv;
      })
    );
  };

  const handleSendMessage = async (messageText = newMessage, specifiedThreadId = null) => {
    if (showNewChatExperience) {
      setShowNewChatExperience(false);
    }
    if (!messageText.trim()) return;
    const activeConvTitle = conversations.find((conv) => conv.thread_id === activeThreadId)?.title || "New chat";
    if (window.heap) {
      window.heap.track("Send message to Vivo", {
        "message-body": messageText,
        "conversation-title": activeConvTitle,
      });
    }
    setIsTypingComplete(false);
    setLoadingMessage(true);

    const isNewConversation = !activeThreadId || activeThreadId.startsWith("temp_");
    const tempThreadId = specifiedThreadId || activeThreadId;

    const userMessage = {
      text: messageText,
      role: "user",
      time: new Date().toISOString(),
    };

    updateConversationsWithMessage(tempThreadId, userMessage, isNewConversation);
    const loadingMessageId = "loadingMessage";
    const loadingMessageText = {
      id: loadingMessageId,
      text: "",
      role: "assistant",
      isLoading: true,
    };
    updateConversationsWithMessage(tempThreadId, loadingMessageText, isNewConversation);

    setNewMessage("");

    const payload = {
      text: messageText,
      email: userEmail,
      ...(isNewConversation ? {} : { thread_id: activeThreadId }),
    };

    try {
      const response = await sendMessage(payload);
      const messageContent = response?.payload?.data.response;
      const threadId = response.payload.data?.thread_id;
      const threadTitle = response.payload.data?.thread_title;

      await processServerResponse(tempThreadId, threadId, messageContent, threadTitle);
      await removeLoadingMessage(threadId, loadingMessageText.id);
    } catch (error) {
      console.error("Error sending message:", error);
      setLoadingMessage(false);
      setHasError(true);
    }
  };

  const handlePromptClick = async (promptText) => {
    const tempId = `temp_${Math.random() * 2}`;
    setActiveThreadId(tempId);
    setShowNewChatExperience(false);
    await handleSendMessage(promptText, tempId);
  };

  const activeConversation = conversations.find((conversation) => conversation?.thread_id === activeThreadId);
  useEffect(() => {
    if (latestMessageRef.current) {
      latestMessageRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [conversations]);

  useEffect(() => {}, [conversations]);

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const handleConversationClick = async (id) => {
    setActiveThreadId(id);
    setShowNewChatExperience(false);
    try {
      const {
        payload: {
          data: { messages },
        },
      } = await getThreadMessages(id);
      setConversations((currentConversations) =>
        currentConversations.map((conv) => (conv.thread_id === id ? { ...conv, messages } : conv))
      );
    } catch (error) {
      console.error(`Error fetching messages for conversation ${id}:`, error);
      setHasError(true);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 600) {
      setIsSidebarExpanded(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    const activeConv = conversations.find((conv) => conv.thread_id === activeThreadId);
    const shouldUpdateActiveThreadId = !activeConv && !showNewChatExperience;
    if (shouldUpdateActiveThreadId) {
      const nextActiveConv = conversations.find((conv) => conv.isActive);
      if (nextActiveConv) {
        setActiveThreadId(nextActiveConv.thread_id);
      } else {
        setActiveThreadId("");
      }
    }
  }, [conversations]);

  function parseBoldTextAndBullets(text, role) {
    const sections = text.split("\n\n");

    return sections.map((section, sectionIndex) => {
      const lines = section.split("\n");

      const formattedLines = lines.map((line) => {
        if (line.trim().startsWith("- ") && !line.startsWith("  -")) {
          const content = line.replace(/^- /, "• ");

          const formattedContent = content.split(/(\*\*[^*]+\*\*)/g).map((part) => {
            if (part.startsWith("**") && part.endsWith("**")) {
              return <span className="strong_text">{part.slice(2, -2)}</span>;
            }
            return part;
          });

          return <div className="bullet-line">{formattedContent}</div>;
        }
        if (line.startsWith("  -")) {
          console.log(line);
          const content = line.replace(/^ {2}-/, "  ◦ ");

          const formattedContent = content.split(/(\*\*[^*]+\*\*)/g).map((part) => {
            if (part.startsWith("**") && part.endsWith("**")) {
              return <span className="strong_text">{part.slice(2, -2)}</span>;
            }
            return part;
          });

          return <div className="sub-bullet-line">{formattedContent}</div>;
        }
        const formattedContent = line.split(/(\*\*[^*]+\*\*)/g).map((part) => {
          if (part.startsWith("**") && part.endsWith("**")) {
            return <span className="strong_text">{part.slice(2, -2)}</span>;
          }
          return part;
        });

        return <div className="normal-line">{formattedContent}</div>;
      });

      const isLastSection = sectionIndex === sections.length - 1;
      return (
        <div className={`text-section ${!isLastSection && role !== "user" ? "spaced-section" : ""}`}>
          {formattedLines}
        </div>
      );
    });
  }

  if (hasError) {
    return <ErrorPage />;
  }

  return (
    <>
      <Helmet>
        <title>Edge | Vivo</title>
      </Helmet>
      {showNewUserExperience == true ? (
        <NewUserExperience onStartNow={handleStartNow} />
      ) : (
        <div className="edgeVivo">
          <VivoSideBar
            activeThreadId={activeThreadId}
            conversations={conversations}
            onNewChat={handleNewChatClick}
            onConversationClick={handleConversationClick}
            isSidebarExpanded={isSidebarExpanded}
            toggleSidebar={toggleSidebar}
            threadToDelete={threadToDelete}
            handleOpenConfirmationModal={handleOpenConfirmationModal}
            handleCloseConfirmationModal={handleCloseConfirmationModal}
            openConfirmationModal={openConfirmationModal}
            deactivateThread={deactivateThread}
          />
          <div className="chatInterface flex justify-center">
            {!isSidebarExpanded && (
              <div className="flex justify-between items-center " style={{ marginInline: "15px" }}>
                <div className="flex justify-start items-center">
                  <IconButton
                    id="toggleSidebarBtn"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={toggleSidebar}
                  >
                    <MenuIcon />
                  </IconButton>
                  <div>
                    <div className="flex items-center">
                      <img src={vivoBlackLogo} className="vivoLogo" alt="vivoLogo" />
                      <h1 className="edgeVivoTitle">Edge Vivo</h1>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    startIcon={<CreateOutlinedIcon sx={{ color: "#000000" }} />}
                    sx={{
                      width: "100%",
                      padding: "10px 15px",
                      margin: "10px",
                      borderRadius: "24px",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid var(--color-primitives-primary-100)",
                      borderColor: "var(--color-primitives-primary-100)",
                      fontWeight: "500",
                    }}
                    onClick={handleNewChatClick}
                    id="new_chat_click"
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Medium",
                        fontSize: "13px",
                        fontWeight: "500",
                        lineHeight: "20px",
                        color: "var(--temp-color-primitives-primary-800)",
                      }}
                    >
                      Start a new chat
                    </Typography>
                  </Button>
                </div>
              </div>
            )}

            <div className={`messagesArea ${!showNewChatExperience ? "narrowWidth" : ""}`}>
              {showNewChatExperience ? (
                <NewChatExperience examplePrompts={examplePrompts} onPromptClick={handlePromptClick} />
              ) : (
                <div>
                  {activeConversation?.messages?.map((message, index) => (
                    <div
                      className={`message ${message.role === "user" ? "userMessage" : "VivoMessage"}`}
                      ref={index === activeConversation?.messages?.length - 1 ? latestMessageRef : null}
                    >
                      <div className="messageMeta">
                        <Avatar
                          sx={{
                            width: "26px",
                            height: "26px",
                            marginRight: "10px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          src={message.role === "user" ? profilePictureUrl : vivoMainLogo}
                        />
                        <div className="messageDetails">
                          <Typography
                            sx={{
                              color: "#292A3D",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "20px",
                              letterSpacing: "0.1px",
                            }}
                          >
                            {message.role === "user" ? username : "Vivo"}
                          </Typography>
                          <div className="messageContent fadeIn">
                            {message?.isLoading ? (
                              <div className="loading-message">
                                <div className="loadingDot" />
                                <div className="loadingDot" />
                                <div className="loadingDot" />
                              </div>
                            ) : (
                              <Typography
                                sx={{
                                  fontFamily: "Inter !important",
                                  color: "#292A3D",
                                  fontSize: "15px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "20px",
                                  letterSpacing: "0.1px",
                                }}
                              >
                                {index === activeConversation?.messages?.length - 1 && message?.isNewMessage ? (
                                  <Typist
                                    cursor={{ show: false }}
                                    avgTypingDelay={5}
                                    stdTypingDelay={5}
                                    className="typist-text-vivo"
                                    onTypingDone={() => setIsTypingComplete(true)}
                                  >
                                    {parseBoldTextAndBullets(message.text, message.role)}
                                  </Typist>
                                ) : (
                                  <span className="typist-text-vivo">
                                    {parseBoldTextAndBullets(message.text, message.role)}
                                  </span>
                                )}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="inputArea">
              <OutlinedInput
                type="text"
                value={newMessage}
                multiline
                disabled={loadingMessage || !isTypingComplete}
                onChange={(e) => setNewMessage(e.target.value)}
                inputProps={{ maxLength: 3000 }}
                onKeyPress={(e) => e.key === "Enter" && !e.shiftKey && newMessage.trim() && handleSendMessage()}
                placeholder="Message Vivo here"
                fullWidth
                sx={{
                  borderRadius: "12px",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  color: "#14151F",
                  minHeight: "50px",
                  height: "auto",
                  maxHeight: "500px",
                  overflowY: "hidden",
                  marginBottom: "20px",
                  "& .MuiOutlinedInput-input": {
                    wordWrap: "break-word",
                    height: "auto",
                    boxSizing: "border-box",
                    maxHeight: "500px",
                    overflowY: "scroll !important",
                  },
                }}
                endAdornment={
                  <InputAdornment position="end" sx={{ alignSelf: "flex-end", marginBottom: "10px" }}>
                    <IconButton
                      onClick={() => handleSendMessage()}
                      disabled={!newMessage.trim() || loadingMessage || !isTypingComplete}
                      edge="end"
                      sx={{
                        color: newMessage.trim() ? "primary.main" : "action.disabled",
                        cursor: "pointer",
                      }}
                      id="query_submit"
                    >
                      <Avatar
                        sx={{
                          width: "26px",
                          height: "26px",
                          borderRadius: "50%",
                          objectFit: "cover",
                          cursor: "pointer",
                          opacity: !newMessage.trim() || loadingMessage || !isTypingComplete ? 0.5 : 1,
                        }}
                        src={platfromRebrandPhase1 ? sendButtonIconRebrand : sendButtonIcon}
                      />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EdgeVivo;

import React, { useCallback, useEffect, useState } from "react";
import Search from "@mui/icons-material/Search";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import { usePermissions } from "components/Hook";
import Text from "components/common/Text";
import CustomButton from "components/common/Button";
import { CardHeader } from "components/common/Card";
import PageLoader from "components/common/PageLoader";
import TableComponent from "components/common/TableComponent";
import AssignEmployeeModal from "components/Screens/SettingV2/AddUser/AssignEmployeeModal";
import AvatarIcon from "components/Icons/AvatarIcon";
import NoEmployeeAdded from "components/Screens/SettingV2/AddUser/NoEmployeeAdded";
import NoResultsFound from "components/common/NoResultsFound";

import { baseUrl } from "util/APIBaseUrl";
import { getCustomerUserEmployees } from "services/customer";

import RemoveManager from "./RemoveManager";
import ReAssignManager from "./ReAssignManager";
import { useLocation } from "react-router-dom";

function ManagedEmployeeList({ customerUserId, managersList }) {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const { loggedInUser } = usePermissions();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showAssignEmployeeModal, setShowAssignEmployeeModal] = useState(false);
  const [openReAssignManagerModal, setOpenReAssignManagerModal] = useState(false);
  const [openRemoveManagerModal, setOpenRemoveManagerModal] = useState(false);

  const isEdgeAdmin = loggedInUser?.permissions.some((permission) =>
    ["EdgeAdmin", "EdgeEmployee"].includes(permission)
  );
  const isCustomerAdmin = loggedInUser?.permissions.includes("CustomerAdmin");
  const isAdmin = isEdgeAdmin || isCustomerAdmin;

  const getCustomerAdminEmployeeList = useCallback(async () => {
    setIsLoading(true);
    const employees = await getCustomerUserEmployees(customerUserId, limit, page + 1, searchKeyword);
    setEmployeeList(employees);
    setIsLoading(false);
  }, [customerUserId, limit, page, searchKeyword]);

  useEffect(() => {
    getCustomerAdminEmployeeList();
  }, [limit, page, searchKeyword, getCustomerAdminEmployeeList]);

  const columns = [
    {
      render: (value, row) => (
        <CardHeader
          title={`${row.firstName} ${row.lastName}`}
          subheader={row.email}
          avatar={<AvatarIcon source={`${baseUrl}/employee/${row.employeeId}/logo?id=${row?.pictureVersion}`} />}
        />
      ),
    },
    {
      id: "action",
      type: "action",
      cellStyle: { width: "20px" },
      moreActions: (() => {
        return isAdmin
          ? [
              {
                label: "Reassign Employee",
                icon: <ArrowCircleRightOutlinedIcon />,
              },
              {
                label: "Remove",
                icon: <RemoveCircleOutlineOutlinedIcon htmlColor="#E3382D" />,
                variant: "danger",
              },
            ]
          : null;
      })(),
      align: "right",
    },
  ];

  const handleAction = (action, row) => {
    setCurrentEmployee(row);
    if (action.label === "Reassign Employee") {
      setOpenReAssignManagerModal(true);
    } else if (action.label === "Remove") {
      setOpenRemoveManagerModal(true);
    }
  };

  const clearSearchField = () => {
    setSearchKeyword("");
  };

  return (
    <>
      {isLoading && <PageLoader />}
      {openRemoveManagerModal && (
        <RemoveManager
          setOpenRemoveManagerModal={setOpenRemoveManagerModal}
          openRemoveManagerModal={openRemoveManagerModal}
          getCustomerAdminEmployeeList={getCustomerAdminEmployeeList}
          employeeData={currentEmployee}
        />
      )}
      {openReAssignManagerModal && (
        <ReAssignManager
          setOpenReAssignManagerModal={setOpenReAssignManagerModal}
          openReAssignManagerModal={openReAssignManagerModal}
          getCustomerAdminEmployeeList={getCustomerAdminEmployeeList}
          employeeData={currentEmployee}
          managersList={managersList}
        />
      )}
      <div>
        <p className="heading-h4-semibold">
          {employeeList?.count ? `Managed Employees (${employeeList.count})` : "Managed Employees"}
        </p>

        {!isLoading && !searchKeyword && employeeList?.count === 0 ? (
          <NoEmployeeAdded
            showBorder={isEdgeAdmin || (isCustomerAdmin && location.pathname === "/main/settings")}
            setShowAssignEmployeeModal={setShowAssignEmployeeModal}
            showAddButton={isAdmin}
          />
        ) : (
          <div className="flex justify-between mt-05">
            <Text
              placeholder="Search employees"
              value={searchKeyword}
              startIcon={<Search htmlColor="#AFAFAF" />}
              onChange={(e) => {
                setPage(0);
                setSearchKeyword(e.target.value);
              }}
              class="mr-05"
              endIcon={
                searchKeyword && (
                  <CloseIcon style={{ color: "#AFAFAF" }} className="cursor-pointer" onClick={clearSearchField} />
                )
              }
            />

            {isAdmin && (
              <CustomButton
                startIcon={<AddIcon />}
                label="Add Employee(s)"
                color="secondary"
                size="large"
                onClick={() => setShowAssignEmployeeModal(!showAssignEmployeeModal)}
              />
            )}
          </div>
        )}
        {employeeList?.count > 0 ? (
          <div className="mt-05">
            <TableComponent
              columns={columns}
              data={employeeList?.list}
              onPageChange={(newPage) => setPage(newPage)}
              onRowsPerPageChange={(newRowsPerPage) => setLimit(newRowsPerPage)}
              totalCount={employeeList?.count}
              onAction={handleAction}
              showHeader={false}
              noCellPadding
              noBorderForSingleRow
            />
          </div>
        ) : (
          searchKeyword && <NoResultsFound />
        )}

        {showAssignEmployeeModal && (
          <AssignEmployeeModal
            open={showAssignEmployeeModal}
            handleClose={(isUpdated = false) => {
              setShowAssignEmployeeModal(!showAssignEmployeeModal);
              if (isUpdated === true) {
                getCustomerAdminEmployeeList();
                setSearchKeyword("");
              }
            }}
            customerUserId={customerUserId}
          />
        )}
      </div>
    </>
  );
}

export default ManagedEmployeeList;

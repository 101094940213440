export default {
  // Common routes
  dashboard: "/main/dashboard",
  settings: "/main/settings",
  feedback: "/main/feedback",
  holidays: "/main/holidays",
  workforce: "/main/workforce",

  // Customer routes
  payroll: "/main/payroll",
  payrollHistory: "/main/payroll?history=true",
  timeoffLeave: "/main/timeoff/leave#Pending",
  payment: "/main/payment",

  // Employee routes
  paystubs: "/main/paystubs",
  edgeWallet: "/main/edge-wallet",
  timeoff: "/main/timeoff",
  peers: "/main/peers",
  community: "https://community.onedge.co/",
  eprofile: "/main/eprofile",
  vivo: "/main/vivo",

  // Edge routes
  customers: "/main/customers",
  workforcePayrolls: "/main/workforcepayrolls",
  payrollsUpload: "/main/payrollsupload",
  timeoffAdmin: "/main/timeoff/admin",
  profile: "/main/profile",

  // External routes
  helpSupport: "https://get.help.onedge.co/servicedesk/customer/portals",
};

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
import React from "react";

import { Box, Grid, Typography } from "@mui/material";

import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis } from "recharts";

function BarChartV2({ payrollData }) {
  function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
      return (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "4px",
            padding: "5px 8px 5px 12px",
            borderRadius: "14px",
            width: "9.5rem",
            backgroundColor: "#FAFAFF",
            boxShadow: "0px 1px 1px rgba(2, 0, 110, 0.22), 0px 1px 6px rgba(2, 0, 110, 0.25)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "4px",
            }}
          >
            <Typography
              sx={{
                color: "#14151F",
                fontFamily: "Poppins-Medium",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "16px",
              }}
            >
              Gross salary :{" "}
            </Typography>

            <Typography
              sx={{
                color: "#14151F",
                fontFamily: "Poppins-Medium",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "16px",
              }}
            >{`$ ${payload[0].value}`}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "4px",
            }}
          >
            <Typography
              sx={{
                color: "#14151F",
                fontFamily: "Poppins-Medium",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "16px",
              }}
            >
              Bonus :{" "}
            </Typography>

            <Typography
              sx={{
                color: "#14151F",
                fontFamily: "Poppins-Medium",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "16px",
              }}
            >{`$ ${payload[0]?.payload?.bonus ?? "-"}`}</Typography>
          </Box>
        </Grid>
      );
    }

    return null;
  }
  const payrollCount = payrollData.length;

  return (
    <ResponsiveContainer
      width={
        payrollCount === 4
          ? "80%"
          : payrollCount === 3
            ? "62%"
            : payrollCount === 2
              ? "42%"
              : payrollCount === 1
                ? "20%"
                : "100%"
      }
      height="100%"
    >
      {payrollCount ? (
        <BarChart data={payrollData} barSize={payrollCount === 6 ? 53 : payrollCount <= 5 && 63}>
          <XAxis
            dataKey="month"
            axisLine={false}
            tickMargin={15}
            tickLine={false}
            tick={{
              fontFamily: "Poppins-Medium !important",
              fontWeight: 500,
              fontSize: 11,
              lineHeight: "16px",
              color: "#212231",
            }}
          />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Bar dataKey="amount" stackId="amount" fill="var(--temp-color-primitives-primary-800)" radius={4}>
            {payrollData.map((entry, index) => (
              <Cell
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                radius={4}
                fill={
                  index === payrollCount - 1
                    ? "var(--temp-color-primitives-primary-800)"
                    : "var(--color-primitives-primary-50)"
                }
              />
            ))}
          </Bar>
        </BarChart>
      ) : (
        <Typography
          sx={{
            fontFamily: "Poppins-Medium !important",
            fontWeight: 500,
            fontSize: 14,
            lineHeight: "16px",
            color: "#212231",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No Payroll Summary Found
        </Typography>
      )}
    </ResponsiveContainer>
  );
}

export default BarChartV2;

import React from "react";

function InfoIcon({ size = 24, fill = "#02006E" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 22.2282C6.35124 22.2282 1.77197 17.6489 1.77197 12.0001C1.77197 6.35136 6.35124 1.77209 12.0001 1.77209C17.6489 1.77209 22.2281 6.35136 22.2281 12.0001C22.2281 17.6489 17.6489 22.2282 12.0001 22.2282ZM12.0001 20.3686C16.6218 20.3686 20.3685 16.6219 20.3685 12.0001C20.3685 7.37841 16.6218 3.63175 12.0001 3.63175C7.37829 3.63175 3.63162 7.37841 3.63162 12.0001C3.63162 16.6219 7.37829 20.3686 12.0001 20.3686ZM12.9332 13.8583H13.8625V15.7179H10.1432V13.8583H11.0729V11.9986H10.1432V10.139H12.9332V13.8583ZM12.0003 9.20912C11.4866 9.20912 11.0702 8.79286 11.0702 8.27933C11.0702 7.7658 11.4866 7.34951 12.0003 7.34951C12.514 7.34951 12.9305 7.7658 12.9305 8.27933C12.9305 8.79286 12.514 9.20912 12.0003 9.20912Z"
        fill={fill}
      />
    </svg>
  );
}

export default InfoIcon;

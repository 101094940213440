import { Link } from "react-router-dom";

import { STATE_VARIANTS } from "components/Screens/CustomerOnboarding/constants";

// eslint-disable-next-line import/prefer-default-export
export const getPaymentStatus = (paymentStatus = "") => {
  if (paymentStatus === "paid") {
    return {
      state: STATE_VARIANTS.PAID,
      description: (
        <>
          Your payment has been successfully processed.{" "}
          <Link className="tile-link" to="/main/payroll?history=true">
            View details
          </Link>{" "}
        </>
      ),
      heading: "Payment recieved",
    };
  }

  if (paymentStatus === "failed") {
    return {
      state: STATE_VARIANTS.FAILED,
      description: (
        <>
          We are not able to verify your payment details. please contact{" "}
          <Link
            className="tile-link"
            to="#"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "mailto:support@onedge.co";
            }}
          >
            support@onedge.co
          </Link>
        </>
      ),
      heading: "Your payment failed",
    };
  }

  return {
    state: STATE_VARIANTS.PENDING,
    description: (
      <>
        We've received your payment details, and it is currently being processed.{" "}
        <Link className="tile-link" to="/main/payroll?history=true">
          View details
        </Link>{" "}
      </>
    ),
    heading: "Your payment is being processed",
  };
};

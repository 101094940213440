import * as Yup from "yup";

export default Yup.object().shape({
  accountHolderType: Yup.string()
    .required("Account holder type is required")
    .oneOf(["company", "individual"], "Invalid account holder type"),
  accountType: Yup.string().required("Account type is required").oneOf(["checking", "savings"], "Invalid account type"),
  accountHolderName: Yup.string().required("Account holder name is required"),
  accountNumber: Yup.string().required("Account number is required").matches(/^\d+$/, "Account number must be numeric"),
  routingNumber: Yup.string()
    .required("Routing number is required")
    .matches(/^\d{9}$/, "Routing number must be 9 digits"),
});

import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  border: "none",
  visibility: "visible !important",
  transform: "none !important",
  backgroundColor: "var(--color-primitives-primary-900)",
  color: "#FAFAFF",
  borderRadius: "0px !important",
  boxShadow: "none",
  [theme.breakpoints.down("sm")]: {
    position: "absolute",
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  border: "none",
  display: "none",
  zIndex: "10",
  backgroundColor: "var(--color-primitives-primary-900)",
  color: "#FAFAFF",
  borderRadius: "0px !important",
  boxShadow: "none",
  width: "56px",
  [theme.breakpoints.up("sm")]: {
    display: "inherit",
  },
});

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export { StyledDrawer, drawerWidth };

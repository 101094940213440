export const LOGIN_ROUTE = "/";
export const LINK_EXPIRED_ROUTE = "/linkExpired";
export const CUSTOMER_ONBOARDING_PAYMENT_ROUTE = "/paymentDetails";
export const CUSTOMER_ONBOARDING_PAYMENT_SUCCESS_ROUTE = "/paymentSuccess";
export const CUSTOMER_ONBOARDING_CALENDLY_MEETING_ROUTE = "/calendlyMeeting";
export const CUSTOMER_PAYROLL_FIRST_PAYMENT = "payroll/firstPayment";
export const CUSTOMER_PAYROLL_HISTORY = "/main/payroll?history=true";
export const CUSTOMER_PROFILE_ROUTE = "/main/profile";
export const EMPLOYEE_PROFILE_ROUTE = "/main/eprofile";
export const COMMUNITY_ROUTE = "/main/community";
export const SETTINGS_ROUTE = "/main/settings";

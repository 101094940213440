import React from "react";
import { useNavigate } from "react-router-dom";

import Layout from "components/Screens/CustomerOnboarding/Layout";
import Button from "components/common/Button";

import {
  CheckCircle as CheckCircleIcon,
  AttachMoney as AttachMoneyIcon,
  AccountBalanceOutlined as AccountBalanceOutlinedIcon,
} from "@mui/icons-material";

import { CUSTOMER_ONBOARDING_CALENDLY_MEETING_ROUTE } from "constants/routes";

import "./PaymentSuccess.scss";

function PaymentSuccess() {
  const navigate = useNavigate();

  const handleScheduleKickOffClick = () => {
    navigate(CUSTOMER_ONBOARDING_CALENDLY_MEETING_ROUTE);
  };
  return (
    <Layout title="Confirm your payment">
      <div className="onboarding-payment-success-container">
        <div>
          <CheckCircleIcon className="payment-check-circle" />
          <p className="heading-h1-semibold pb-05 pt-1">Payment details received</p>
          <p className="para-body-m-regular color-text-body-primary">
            We've received your payment details, and your payment is on its way to being processed. It may take 3-5
            business days to complete.
          </p>
        </div>
        <div className="onboarding-payment-container">
          <p className="para-body-l-semibold">Here's how you stay in the loop:</p>
          <div className="onboarding-payment-list">
            <AttachMoneyIcon className="payment-dollar-circle" />
            <div>
              <p className="para-body-m-medium">Want to know the payment status?</p>
              <p className="para-body-s-regular color-text-body-primary">
                Check the payment status anytime on our platform.
              </p>
            </div>
          </div>
          <div className="onboarding-payment-list">
            <AccountBalanceOutlinedIcon className="payment-dollar-circle" />
            <div>
              <p className="para-body-m-medium">Need to make updates later?</p>
              <p className="para-body-s-regular color-text-body-primary">
                No problem—you can change your ACH details on our platform.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="onboarding-payment-success-button">
        <div className="onboarding-payment-success-button-container">
          <Button onClick={handleScheduleKickOffClick} label="Schedule Kick-off Call" size="large" />
        </div>
      </div>
    </Layout>
  );
}

export default PaymentSuccess;

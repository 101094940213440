import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Avatar, Box, Card, CardContent, CardMedia, Grid, Stack, Typography } from "@mui/material";

import {
  getCountryFlag,
  getFieldValue,
  getProfileImage,
  getSalaryWithoutBonusAndIncrement,
  getSalaryWithoutBouns,
  isEmptyObject,
  currencyFormat,
} from "../Screens/CommonUtils";

import avatarImage from "assets/images/fallback-image.svg";
import Button from "components/Button/Button";
import CreditCardIcon from "assets/svgs/CreditCardIcon";
import GiftIcon from "assets/svgs/GiftIcon";

import { s3ImagesBaseURL } from "util/APIBaseUrl";

function WorkforceCard({ employee }) {
  const [imageSrc, setImageSrc] = useState(avatarImage);
  const navigate = useNavigate();
  const { platfromRebrandPhase1 } = useFlags();

  const handleNaviagte = () => {
    navigate(`/main/details/${employee?.id}`);
  };

  // method
  useEffect(() => {
    if (isEmptyObject(employee) && employee?.picturePath) {
      getProfileImage(employee?.id, employee?.pictureVersion).then((img) => {
        setImageSrc(img);
      });
    } else {
      setImageSrc(avatarImage);
    }
  }, [employee?.picturePath, employee?.id]);

  return (
    <Grid item sx={{ width: "100%" }}>
      <Card
        sx={{
          borderRadius: "12px !important",
          background: "#FFFFFF",
          display: "flex",
        }}
      >
        <div style={{ position: "relative" }}>
          <Box
            sx={{
              backgroundColor: "var(--color-primitives-primary-100)",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {platfromRebrandPhase1 ? (
              <CardMedia
                component="img"
                image={imageSrc[employee?.id] ? imageSrc[employee?.id] : `${s3ImagesBaseURL}/placeholder-avatar.svg`}
                alt={employee?.name}
                sx={{
                  width: "110px",
                  height: "126px",
                  objectFit: "cover",
                  objectPosition: imageSrc[employee?.id] ? "top" : "center",
                }}
              />
            ) : (
              <CardMedia
                component="img"
                image={imageSrc[employee?.id] ? imageSrc[employee?.id] : avatarImage}
                // image={employee?.profilePicture}
                alt={employee?.name}
                sx={{
                  width: "110px",
                  height: "126px",
                  objectFit: imageSrc[employee?.id] ? "cover" : "none",
                  objectPosition: imageSrc[employee?.id] ? "top" : "center",
                }}
              />
            )}
          </Box>
          <Avatar
            sx={{
              width: "26px",
              height: "26px",
              background: "#FFFFFF",
              borderRadius: "99px",
              position: "absolute",
              bottom: "4px",
              left: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="emp-country"
          >
            {getCountryFlag(employee?.country)}
          </Avatar>
        </div>
        <CardContent container spacing={2} sx={{ display: "flex", padding: "12px", width: "100%" }}>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              paddingX: "12px",
              paddingTop: "0px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins-Medium",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                color: "#000000",
                height: "42px",
              }}
              id="emp-name"
            >
              {employee?.name}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins-Regular",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "19px",
                color: "var(--color-primitives-primary-600)",
              }}
              id="emp-role"
            >
              {employee?.role}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins-Regular",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "12px",
                color: "#14151F",
              }}
              id="emp-joining-date"
            >
              {employee?.edgeJoiningDate}
            </Typography>
          </Grid>

          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              paddingX: "12px",
              paddingTop: "0px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins-Medium",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                color: "#000000",
                height: "42px",
              }}
            >
              Salary
            </Typography>
            <Stack direction="row" spacing={2}>
              <CreditCardIcon color="#484649" />
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "18px",
                  letterSpacing: "0.5px",
                }}
                id="salary-with-bonus"
              >
                {currencyFormat(getSalaryWithoutBouns(employee?.costToCompany, employee?.incrementDetails?.bonus))}
              </Typography>
            </Stack>

            <Typography
              sx={{
                fontFamily: "Poppins-Regular",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px",
                color: "#666899",
              }}
              id="salary-without-bonus-increment"
            >
              ${" "}
              {currencyFormat(
                getSalaryWithoutBonusAndIncrement(
                  employee?.costToCompany,
                  employee?.incrementDetails?.incrementAmount,
                  employee?.incrementDetails?.bonus
                )
              )}{" "}
              + $ {currencyFormat(getFieldValue(employee?.incrementDetails?.incrementAmount))}
            </Typography>
          </Grid>

          <Grid
            xs={3}
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              paddingX: "12px",
              paddingTop: "0px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins-Regular",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                height: "34px",
                color: "#212231",
              }}
            >
              Bonus
            </Typography>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
              }}
            >
              <Grid
                sx={{
                  maxWidth: "180px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <GiftIcon />
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#14151F",
                  }}
                >
                  $ {currencyFormat(getFieldValue(employee?.incrementDetails?.bonus))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            <Button
              variant="outlined"
              size="sm"
              style={{
                padding: "4px 56px",
                color: "var(--color-primitives-primary-500)",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
              }}
              onClick={handleNaviagte}
            >
              View Profile
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default WorkforceCard;

import React, { useEffect, useState } from "react";
import { Button, FormControl, Grid, Box } from "@mui/material";
import { useParams } from "react-router-dom";

import NotesList from "./NotesList";
import { usePermissions } from "components/Hook";
import TextFieldV2 from "components/common/TextFieldV2/TextFieldV2";

import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";
import postRequest from "util/APIHelperPost";

import SubmitButtonIcon from "assets/images/left-arrow.svg";

import "./Notes.css";

function Notes() {
  const [noteValue, setNoteValue] = useState("");
  const [notes, setNotes] = useState([]);
  const [list, setList] = useState([]);
  const [selectedList, setSelectedList] = useState({});
  const [categories, setCategories] = useState([]);
  const [textFieldKey, setTextFieldKey] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const { loggedInUser } = usePermissions();
  const params = useParams();

  const handleSubmit = async () => {
    try {
      if (!noteValue || !selectedList?.priority) return;
      setSubmitting(true);

      const trimmedNote = noteValue.length > 1500 ? noteValue.slice(0, 1500) : noteValue;

      await postRequest(
        `${baseUrl}/notes`,
        JSON.stringify({
          Description: trimmedNote,
          Priority: true,
          Title: selectedList?.priority,
          Edge_Employee_ID: loggedInUser?.userId,
          Employee_ID: params?.id,
        }),
        true,
        "POST"
      );
      setNoteValue("");
      setSelectedList({});
      setTextFieldKey((prevKey) => prevKey + 1);

      const resp = await getRequest(`${baseUrl}/notes/${params?.id}`);

      if (resp?.ok) {
        setNotes(resp?.payload);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  const fetchNotesData = async () => {
    const resp = await getRequest(`${baseUrl}/notes/${params?.id}`);
    if (!resp?.ok) {
      return;
    }
    const notesWithImages = resp?.payload.map((item) => ({
      ...item,
      image: `${baseUrl}/user/${item?.edgeEmployee?.edgeEmployeeId}/logo?id=${item?.edgeEmployee?.pictureVersion}`,
    }));

    setNotes(notesWithImages);
  };

  const fetchCategoriesData = async () => {
    const resp = await getRequest(`${baseUrl}/categories`);
    if (!resp?.ok) {
      return;
    }

    setCategories(resp?.payload);
    if (resp?.payload.length) {
      const transformedList = resp?.payload?.map((item) => ({ value: item.title, label: item.title }));

      setList(transformedList);
    }
  };

  const handleInputChange = (event) => {
    setSelectedList({
      ...selectedList,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    fetchNotesData();
  }, [textFieldKey]);

  useEffect(() => {
    fetchCategoriesData();
  }, []);

  const getCategoryDescription = (category, categoriesArray) => {
    const foundCategory = categoriesArray.find((item) => item.title === category);
    return foundCategory ? foundCategory.description : " ";
  };

  const description = selectedList?.priority && getCategoryDescription(selectedList.priority, categories);

  return (
    <div className="admin-notes-container">
      <div className="admin-notes-wrapper">
        <p>Add Note</p>

        <Grid container sx={{ marginBottom: "20px", gap: "20px", width: "555px" }}>
          <textarea
            className="notes-textarea"
            placeholder="Enter note*"
            value={noteValue}
            onChange={(e) => setNoteValue(e.target.value)}
          />
        </Grid>
        <FormControl variant="outlined" fullWidth error>
          <Grid
            sx={{
              display: "flex",
              alignItems: "start",
              gap: "16px",
              width: "555px",
            }}
          >
            <Grid sx={{ width: "66.66%" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #B2B4E2",
                  borderRadius: "12px",
                }}
              >
                <TextFieldV2
                  key={textFieldKey}
                  label="Select Priority Level*"
                  variant="filled"
                  name="priority"
                  id="notes-priority"
                  type="select"
                  select
                  dropdownValues={list}
                  onChange={handleInputChange}
                  value={selectedList.priority || ""}
                />
              </Box>
            </Grid>

            <Grid sx={{ width: "33.33%" }}>
              <Button
                type="submit"
                id="add-note-emp-btn"
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                disabled={!noteValue || !selectedList?.priority || submitting}
                sx={{
                  backgroundColor: "var(--color-primitives-primary-600)",
                  height: "55px",
                  fontFamily: "Poppins-Regular !important",
                  fontWeight: "500",
                  lineHeight: "20px",
                  borderRadius: "12px",
                  fontSize: "12px",
                  color: "white",
                  boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
                  gap: "4px",
                }}
              >
                Submit
                <img src={SubmitButtonIcon} alt="right arrow" className="right-arrow" />
              </Button>
            </Grid>
          </Grid>
        </FormControl>
        <p className="priority-list">
          {selectedList?.priority && (
            <>
              Examples of <b className="priority-example">{selectedList.priority}</b> priority issues include:{" "}
              {description || ""}
            </>
          )}
        </p>
      </div>
      <NotesList notes={notes} />
    </div>
  );
}

export default Notes;

import { List, ListItem, ListItemIcon, Typography, Chip } from "@mui/material";
import moment from "moment";

import ErrorIcon from "components/Screens/Login/ChangePassword/ResendLink/ErrorIcon";

import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";
import { getOrdinalSuffix } from "util/utils";

import avatarImage from "assets/images/fallback-image.svg";
import PakistanFlag from "assets/svgs/PakistanFlag";
import PeruFlag from "assets/svgs/PeruFlag";
import CostaRicaFlag from "assets/svgs/CostaRicaFlag";

// return 0, 10
export const getFieldValue = (value) => {
  if (value && value !== "") {
    return value;
  }
  return 0;
};

// Wed Oct 13 2021
export const getUtcFormatDate = (date) => {
  return new Date(date).toDateString();
};

// Oct 13, 2021, 3:30 PM
export const getFormattedDateAndTime = (date) => {
  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = new Date(date).toLocaleString("en-US", options);
  return formattedDate;
};

export const getSalaryWithoutBouns = (costToCompany, bonus) => {
  return (costToCompany || 0) - (bonus || 0);
};

export const getSalaryWithoutBonusAndIncrement = (costToCompany, incrementAmount, bonus) => {
  return (costToCompany || 0) - (incrementAmount || 0) - (bonus || 0);
};

export const getBonusDescription = (value) => {
  if (value) {
    return `For ${value}`;
  }
  return "-";
};

// (1, 2021) => 28
export const getDaysInMonth = (m, y) => {
  return 32 - new Date(y, m, 32).getDate();
};

export const getHolidaysDate = () => {
  const startDate = new Date();

  const endDate = new Date(
    new Date(new Date().setMonth(new Date().getMonth() + 5)).setDate(
      getDaysInMonth(new Date().getMonth() + 5, new Date().getFullYear())
    )
  );

  return { startDate, endDate };
};

export const getProfileImage = async (id, imgVersion, userType = "employee") => {
  let imageSrc = avatarImage;
  const response = await getRequest(
    `${baseUrl}/${userType}/${id}/logo?id=${imgVersion}&variant=thumbnail`,

    false,
    "export"
  );
  if (!response?.ok) {
    return {
      ...imageSrc,
      [id]: avatarImage,
    };
  }
  const url = window.URL.createObjectURL(response?.payload);

  imageSrc = {
    ...imageSrc,
    [id]: url,
  };
  return imageSrc;
};

export const getCountryFlag = (country, width, height) => {
  const countryFlag = {
    Pakistan: <PakistanFlag width={width} height={height} />,
    Peru: <PeruFlag width={width} height={height} />,
    "Costa Rica": <CostaRicaFlag width={width} height={height} />,
  };
  if (country) {
    return countryFlag[country];
  }
  return null;
};

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "-";
};

export const getValue = (value, placeholder = "-") => {
  if (value != undefined && value != null) {
    if (typeof value === "string" && !value.includes("@")) {
      return capitalizeFirstLetter(value);
    }
    return value;
  }
  return placeholder;
};

export const checkUserAdditionalInfo = (info) => {
  if (info && Object.keys(info).length) {
    return true;
  }
  return false;
};

// October '21
export const convertDateToMonthAndYear = (dateString, slice = -2) => {
  if (!dateString) return "-";

  const date = new Date(dateString);
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear().toString().slice(slice);
  return `${month}'  ${year}`;
};

// October '21
export const convertDateToUTCMonthAndYear = (dateString, slice = -2) => {
  if (!dateString) return "-";

  const date = new Date(`${dateString}T00:00:00.000Z`);
  if (Number.isNaN(date.getTime())) return "-";

  const month = date.toLocaleString("en-US", { month: "long", timeZone: "UTC" });
  const year = date.getUTCFullYear().toString().slice(slice);

  return `${month}' ${year}`;
};

// Oct 2021 - Oct 2022
export const convertDateToMonthAndYearShort = (startDate, endDate) => {
  const formatMonthAndYear = (date) => {
    if (!date) return "N/A";
    const d = new Date(date);
    return `${d.toLocaleString("default", { month: "short" })} ${d.getFullYear()}`;
  };

  const startMonthAndYear = formatMonthAndYear(startDate);
  const endMonthAndYear = endDate ? formatMonthAndYear(endDate) : "Present";

  return `${startMonthAndYear} - ${endMonthAndYear}`;
};

// Oct 13, 2021
export const convertDate = (date) => {
  if (date && date !== "-") {
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      timeZone: "UTC",
    };

    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  }
  return "-";
};

export const convertDateForDateRange = (date) => {
  if (date && date !== "-") {
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      timeZone: "UTC",
    };
    const parsedDate = new Date(date);
    const utcDate = new Date(
      Date.UTC(
        parsedDate.getFullYear(),
        parsedDate.getMonth(),
        parsedDate.getDate(),
        parsedDate.getHours(),
        parsedDate.getMinutes(),
        parsedDate.getSeconds(),
        parsedDate.getMilliseconds()
      )
    );
    return new Intl.DateTimeFormat("en-US", options).format(utcDate);
  }
  return "-";
};

// 13
export const getDateFromDateObject = (date) => {
  if (date) {
    return new Date(date).getDate();
  }
  return "-";
};

export const convertUTCTimeIntoLocalUserTime = (date) => {
  if (!date) return "Unavailable";
  const utcDate = new Date(date);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return utcDate.toLocaleString("en-US", options);
};

// 4 Years, 9 Months
export const getWorkingDuration = (date) => {
  if (date) {
    const startDate = new Date(date);
    const currentDate = new Date();

    if (startDate.toDateString() === currentDate.toDateString()) {
      return "Less than a day";
    }

    let years = currentDate.getFullYear() - startDate.getFullYear();
    let months = currentDate.getMonth() - startDate.getMonth();
    let days = currentDate.getDate() - startDate.getDate();

    // Adjust months and days to ensure they are non-negative
    if (days < 0) {
      const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
      days += lastDayOfMonth;
      months -= 1;
    }
    if (months < 0) {
      months += 12;
      years -= 1;
    }

    let duration = "";

    if (years >= 1) {
      duration += years + (years === 1 ? " Year" : " Years");
    }

    if (months >= 1) {
      if (duration !== "") {
        duration += ", ";
      }
      duration += months + (months === 1 ? " Month" : " Months");
    }

    if (days >= 1) {
      if (duration !== "") {
        duration += ", ";
      }
      duration += days + (days === 1 ? " Day" : " Days");
    }

    return duration;
  }
  return "-";
};

// October
export const getMonthNameOnly = (date) => {
  if (!date) return null;

  const parsedDate = new Date(date);
  if (Number.isNaN(parsedDate.getTime())) return null;
  const monthIndex = parsedDate.getMonth();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return monthNames[monthIndex];
};

export const getUTCMonthName = (date) => {
  if (!date) return "_";

  const parsedDate = new Date(`${date}T00:00:00.000Z`);
  if (Number.isNaN(parsedDate.getTime())) return "_";

  const monthIndex = parsedDate.getUTCMonth();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return monthNames[monthIndex];
};

// Oct
export const getMonthFromDate = (date) => {
  if (date && date !== "-") {
    const options = { timeZone: "UTC", month: "short" };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  }
  return "-";
};

// October, 13th
export const getMonthAndDateFromDateObject = (date) => {
  if (date) {
    const month = new Date(date).toLocaleString("en-us", { month: "long" });
    const day = new Date(date).getDate();
    return `${month} , ${day}th`;
  }
  return "";
};

function isNull(date) {
  return date != undefined || date != null;
}

// 2021
export const getYearFromDate = (date) => {
  if (isNull(date)) {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    return year;
  }
  return "-";
};

// October 13
export const getDayMonthFromDate = (date) => {
  return isNull(date)
    ? new Date(date).toLocaleString("en-us", {
        month: "long",
        day: "numeric",
      })
    : "-";
};

export const currencyFormat = (num) => {
  if (num !== "-" && num !== undefined && num !== null) {
    let formattedNum;
    if (num - Math.floor(num) !== 0) {
      formattedNum = parseFloat(num).toFixed(2);
    } else {
      formattedNum = parseInt(num, 10).toFixed(0);
    }
    return formattedNum.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  return "-";
};

export const getMonthlyYearlySalary = (value) => {
  if (value) {
    return `$ ${currencyFormat(value)} ($ ${currencyFormat(value * 12)} Yearly)`;
  }
  return "-";
};

export const getCurrencySymbol = (locale, currency, value) => {
  let AmountWithCurrencySymbol = "";
  if (value != "-") {
    AmountWithCurrencySymbol = (0)
      .toLocaleString(locale, {
        style: "currency",
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
    return `${AmountWithCurrencySymbol}${value} `;
  }
  return value;
};

export const getPaymentStatus = (paymentstatus, paymentfailure) => {
  if (paymentfailure) {
    return "Failed";
  }
  if (paymentstatus === "pending") {
    return "In Progress";
  }

  return capitalizeFirstLetter(paymentstatus);
};

export const getDateFromUtcFormat = (date) => {
  if (date != undefined || date != null) {
    let newDate = new Date(date);
    newDate.toLocaleString("en-US", { timeZone: "America/New_York" });
    const dateOptions = {
      timeZone: "UTC",
      month: "short",
      day: "numeric",
      year: "numeric",
    };

    const dateFormatter = new Intl.DateTimeFormat("en-US", dateOptions);
    const dateAsFormattedString = dateFormatter.format(newDate);
    newDate = new Date(dateAsFormattedString);
    return newDate;
  }
  return new Date();
};

export const handleRetryPayment = (customerId, customerPayrollId, dispatch, RetryPayment) => {
  if (customerId && customerPayrollId) {
    dispatch(
      RetryPayment({
        request: `${baseUrl}/customer/${customerId}/retryPayment/${customerPayrollId}`,
      })
    );
  }
};

export const getDocumentSorted = (empDocs) => {
  const groupedDocuments = empDocs?.docs.reduce((acc, document) => {
    const documentType = document?.documentType || "Others";
    acc[documentType] = acc[documentType] || [];
    acc[documentType].push(document);
    return acc;
  }, {});

  const sortedDocumentTypes = Object.keys(groupedDocuments).sort();

  const sortedGroupedDocuments = sortedDocumentTypes.reduce((acc, category) => {
    acc[category] = groupedDocuments[category];
    acc[category].sort((a, b) => a.documentName.localeCompare(b.documentName));
    return acc;
  }, {});

  return sortedGroupedDocuments || {};
};

export const getError = (errors, color) => {
  if (errors != null && errors != undefined) {
    return errors.map((error) => {
      return (
        <List
          sx={{
            listStyleType: "disc",
            paddingRight: "0px !important",
            pl: 3,
            "& .MuiListItem-root": {
              paddingRight: "0px",
              paddingLeft: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
            },
          }}
        >
          <ListItem
            sx={{
              "& .MuiListItemIcon-root": {
                minWidth: "40px",
              },
            }}
          >
            <ListItemIcon>
              <ErrorIcon />
            </ListItemIcon>
            <Typography variant="h6" color={color ? "white" : "black"} fontSize="12px">
              {`${error?.msg || error?.description || ""} ${error?.param || ""}`}
            </Typography>
          </ListItem>
        </List>
      );
    });
  }
  return null;
};

export const requestDetails = (status, approvedDate, rejectedDate, cancelledDate, createdAt) => {
  if (status === "Cancelled" && cancelledDate) {
    return (
      <p>
        Cancelled on: {convertDate(cancelledDate)} ({getWorkingDuration(cancelledDate)} ago)
      </p>
    );
  }

  if (status === "Rejected" && rejectedDate) {
    return (
      <p>
        Rejected on: {convertDate(rejectedDate)} ({getWorkingDuration(rejectedDate)} ago)
      </p>
    );
  }

  if (status === "Approved" && approvedDate) {
    return (
      <p>
        Approved on: {convertDate(approvedDate)} ({getWorkingDuration(approvedDate)} ago)
      </p>
    );
  }

  return (
    <p>
      Request date: {convertDate(createdAt)} ({getWorkingDuration(createdAt)} ago)
    </p>
  );
};

export const isValidResponse = (response) => {
  if (response && Object.keys(response).length && response?.ok) {
    return true;
  }
  return false;
};

export const isEmptyObject = (object) => {
  if (object && Object.keys(object).length) {
    return true;
  }
  return false;
};

// 13
export const getHolidayDate = (date) => {
  if (date && date !== "-") {
    const options = { timeZone: "UTC", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  }
  return "-";
};

// Wednesday
export const getHolidayDay = (date) => {
  if (date && date !== "-") {
    const options = { timeZone: "UTC", weekday: "long" };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  }
  return "-";
};

// 2021
export const getHolidayYear = (date, lastTwoDigits) => {
  let fullYear = "";
  if (date) {
    fullYear = new Date(date).getFullYear();
    if (lastTwoDigits) return String(fullYear).slice(-2);
    return fullYear;
  }
  return "-";
};

// - 13th Oct (Wednesday) ‘ 2021
export const getHolidayEndDate = (EndDate) => {
  if (EndDate) {
    const day = getHolidayDate(EndDate);
    const suffix = getOrdinalSuffix(day);

    return `- ${day}${suffix} ${getMonthFromDate(EndDate)} (${getHolidayDay(EndDate)}) ‘ ${getHolidayYear(EndDate)}`;
  }
  return "";
};

export const getDurationFromTwoDates = (startDate, endDate) => {
  const startMoment = moment(startDate);
  let endMoment = moment(endDate);

  if (!startMoment.isValid()) {
    return null;
  }

  if (!endMoment.isValid()) {
    endMoment = moment();
  }

  const totalMonths = endMoment.diff(startMoment, "months", true);
  const roundedMonths = Math.floor(totalMonths);

  if ((roundedMonths === 0 && totalMonths > 0) || totalMonths < 1) {
    return "1 month";
  }

  if (roundedMonths >= 12) {
    const years = Math.floor(roundedMonths / 12);
    return `${years} year${years > 1 ? "s" : ""}`;
  }

  return `${roundedMonths} month${roundedMonths > 1 ? "s" : ""}`;
};

export const formatDateDDMMYYYY = (date) => {
  if (date && date !== "-") {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "UTC",
    };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const formattedDate = formatter.format(new Date(date));
    return formattedDate.replace(/\//g, ".");
  }
  return "-";
};

export const getStatusChip = (status) => {
  const statusConfig = {
    paid: {
      background: "var(--color-chip-success-bg)",
      color: "var(--color-text-success)",
      label: "Successful",
    },
    pending: {
      background: "var(--color-chip-warning-bg)",
      color: "var(--color-text-warning)",
      label: "Processing",
    },
    failed: {
      background: "var(--color-chip-error-bg)",
      color: "var(--color-text-error)",
      label: "Failed",
    },
  };

  const config = statusConfig[status.toLowerCase()] || statusConfig.failed;

  return (
    <Chip
      sx={{
        background: config.background,
        color: config.color,
        padding: "0px 10px",
        "&.MuiChip-root": {
          borderRadius: "99px",
          fontFamily: "Inter",
          paddingInline: "0px",
        },
      }}
      label={config.label}
    />
  );
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Grid, Typography, Alert } from "@mui/material";
import { Box } from "@mui/system";
import { MailOutline } from "@mui/icons-material";

import Button from "components/common/Button";
import Slider from "components/common/Slider/Slider";
import ErrorsIndicatorList from "components/common/ErrorsIndicatorList/ErrorsIndicatorList";
import PageLoader from "components/common/PageLoader";
import TermsAndPolicy from "components/common/TermAndPolicy/Terms-And-Policy";
import EdgeLogo from "components/Design/EdgeLogo/EdgeLogo";
import { usePermissions } from "components/Hook";
import Text from "components/common/Text";
import useDeviceInfo from "components/Hooks/useDeviceInfo";

import {
  getValidationErrorList,
  settingUserInLocalStorage,
  validateConfirmPassword,
} from "components/Screens/CustomerOnboarding/helpers";
import { getLoggedInUserDetailsFromLocalStorage } from "helpers";

import { AuthUser, ResetAuthUser } from "store/actions/AuthUser";
import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";
import { GetPlatformConfig } from "store/actions/PlatformConfig";

import { validateCustomerOnboardingTokenApi, customerCreatePasswordApi } from "services/customer";
import { getAuthUserData } from "selectors/auth";
import { getUserAdditionalInfo } from "selectors/user";
import { baseUrl } from "util/APIBaseUrl";

import {
  CONFIRM_PASSWORD_VALIDATION,
  PASSWORD_VALIDATIONS_LIST,
  SLIDER_DATA,
  SLIDER_DATA_REBRAND,
  USER_PASSWORD_INITIAL_VALUES,
  VALIDATE_TOKEN_CASES,
} from "components/Screens/CustomerOnboarding/constants";
import { USER_TYPES } from "constants/index";
import { CUSTOMER_ONBOARDING_PAYMENT_ROUTE, LINK_EXPIRED_ROUTE, LOGIN_ROUTE } from "constants/routes";

import edgeLogo from "assets/images/edge-logo.svg";

import "./CreatePassword.scss";

const closedAlertState = { open: false, msg: "" };

function CreatePassword() {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { accessToken } = usePermissions();
  const { isIOS } = useDeviceInfo();
  const { platfromRebrandPhase1, integratedContractSigning } = useFlags();
  const authUserData = useSelector(getAuthUserData);
  const userAdditionalInfo = useSelector(getUserAdditionalInfo);
  const loadingplatformConfig = useSelector((state) => state.PlatformConfig.loadingplatformConfig);

  const [openAlert, setOpenAlert] = useState(closedAlertState);
  const [passwordData, SetPasswordData] = useState(USER_PASSWORD_INITIAL_VALUES);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [customerInfo, setCustomerInfo] = useState({});
  const [errorsListConfirmPass, setErrorsListConfirmPass] = useState([CONFIRM_PASSWORD_VALIDATION]);
  const [passwordValidationList, setPasswordValidationList] = useState(PASSWORD_VALIDATIONS_LIST);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const { email = " " } = customerInfo;

  const isValid =
    passwordValidationList.every((err) => err.isChecked) &&
    errorsListConfirmPass.every((err) => err.isChecked) &&
    !openAlert.open;

  const handleAlertClose = () => {
    setOpenAlert(closedAlertState);
  };

  const handleErrors = (name, value) => {
    if (name === "newPassword") {
      const updatedValidationList = getValidationErrorList({ passwordInput: value });
      setPasswordValidationList(updatedValidationList);
    }

    setErrorsListConfirmPass([
      {
        ...CONFIRM_PASSWORD_VALIDATION,
        isChecked: validateConfirmPassword({ name, value, passwordData }),
      },
    ]);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    handleErrors(name, value);
    SetPasswordData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = async (e) => {
    e?.preventDefault();

    if (isValid) {
      setIsSubmitting(true);

      setOpenAlert(closedAlertState);
      const { newPassword = "", confirmPassword = "" } = passwordData;
      const payload = {
        password: newPassword,
        confirmPassword,
        token,
      };

      try {
        const response = await customerCreatePasswordApi(payload);
        const { accessToken: accessTokenFromResponse = "", refreshToken = "" } = response;

        if (!accessTokenFromResponse) {
          setIsSubmitting(false);
          setOpenAlert({ open: true, msg: "" });
          return;
        }
        settingUserInLocalStorage(accessTokenFromResponse, refreshToken);
        setIsSubmitClicked(true);
        dispatch(
          AuthUser({
            userCredential: {
              password: newPassword,
              email,
            },
            request: `${baseUrl}/auth/customer`,
            loginPortal: "customer",
          })
        );
      } catch (error) {
        setOpenAlert({ open: true, msg: "" });
      }
    }
  };

  const validateToken = async () => {
    const { code, success, message, ...rest } = await validateCustomerOnboardingTokenApi({ token });
    setIsLoading(false);
    if (success === false && code === VALIDATE_TOKEN_CASES.USED) {
      navigateTo(LOGIN_ROUTE, {
        state: {
          onboardingError: VALIDATE_TOKEN_CASES.USED,
        },
      });
      return;
    }
    if (success === false && code === VALIDATE_TOKEN_CASES.INVALID) {
      navigateTo(LOGIN_ROUTE, {
        state: {
          onboardingError: VALIDATE_TOKEN_CASES.INVALID,
        },
      });
      return;
    }
    if (success === false && code === VALIDATE_TOKEN_CASES.EXPIRED) {
      navigateTo(LINK_EXPIRED_ROUTE);
      return;
    }

    if (success === false && code === VALIDATE_TOKEN_CASES.INACTIVE_USER) {
      navigateTo(LOGIN_ROUTE, {
        state: {
          onboardingError: VALIDATE_TOKEN_CASES.INACTIVE_USER,
          errorMessage: message,
        },
      });
      return;
    }

    setCustomerInfo({ ...rest });
  };

  useEffect(() => {
    if (token) {
      const redirectUser = () => {
        dispatch(GetPlatformConfig());
        if (accessToken) {
          localStorage.clear();
          dispatch(ResetAuthUser({}));
        }
        validateToken();
      };

      redirectUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (isSubmitClicked) {
      const loggedInUser = getLoggedInUserDetailsFromLocalStorage();
      const customerId = loggedInUser?.customerId || authUserData?.customerId;
      const request = `customer/${customerId}/info`;
      dispatch(UserAdditionalInfo(request));
    }
  }, [authUserData]);

  useEffect(() => {
    if (isSubmitClicked) {
      localStorage.setItem("communityLoginCheck", false);
      const loggedInUser = getLoggedInUserDetailsFromLocalStorage();
      if (userAdditionalInfo && Object.keys(userAdditionalInfo).length && loggedInUser) {
        window.analytics?.track("Logged In", {
          channel: "password",
          name: loggedInUser?.name,
          email: loggedInUser?.email,
          userID: loggedInUser?.userId,
          accountType: loggedInUser?.type,
        });
        setIsSubmitting(false);
        navigateTo(CUSTOMER_ONBOARDING_PAYMENT_ROUTE);
      }
    }
  }, [userAdditionalInfo]);

  if (!integratedContractSigning) {
    return navigateTo(LOGIN_ROUTE);
  }

  return isLoading || loadingplatformConfig ? (
    <PageLoader />
  ) : (
    <Grid container justifyContent="space-evenly" className="create-password-main-container">
      <Grid
        container
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        className="email-password-main-container flex justify-center items-center"
      >
        <form onSubmit={onSubmit}>
          <div className="change-password-inner-container">
            <div className="logo-container">
              {platfromRebrandPhase1 ? (
                <EdgeLogo size="lg" state="dark" />
              ) : (
                <Box component="img" className="edge-logo" alt="edge" src={edgeLogo} />
              )}
            </div>
            <div>
              <div className="heading-h1-semibold">Lets get you started with Edge</div>
              <div className="para-body-m-regular subheader-text">
                Set up your password to begin your journey with us.
              </div>
            </div>
            <div className="mt-2">
              <div className="email-container">
                <div className="email-icon-bg">
                  <MailOutline className="icon-color" />
                </div>

                <div className="flex flex-col justify-start">
                  <div className="heading-h5-regular label-color">Your email address</div>
                  <div className="para-body-m-medium email-color">{email}</div>
                </div>
              </div>
            </div>
            <div className="mt-1">
              <Text
                id="newPassword"
                name="newPassword"
                type="password"
                label="Password"
                labelClass="label-color"
                fullWidth
                onChange={handleInputChange}
                showPasswordEye
                variant="outlined"
              />
            </div>

            <ErrorsIndicatorList errors={passwordValidationList} />

            <div className="mt-1">
              <Text
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                labelClass="label-color"
                type="password"
                fullWidth
                onChange={handleInputChange}
                showPasswordEye
                variant="outlined"
              />
            </div>

            <ErrorsIndicatorList errors={errorsListConfirmPass} />

            <Grid item className="mt-1">
              {openAlert.open && (
                <Alert
                  open={openAlert}
                  variant="filled"
                  onClose={handleAlertClose}
                  icon={false}
                  sx={{
                    backgroundColor: "var(--color-icon-danger)",
                    padding: "initial",
                    px: "10px",
                    textAlign: "center",
                    borderRadius: "5px !important",
                    color: "#ffffff !important",
                    "&.MuiPaper-root": {
                      color: "#ffffff !important",
                    },
                  }}
                >
                  <Typography className="heading-h5-regular">{openAlert.msg || "Something Went Wrong"}</Typography>
                </Alert>
              )}
            </Grid>
            <div className="mt-1">
              <Button
                type="submit"
                id="creat-password"
                variant="contained"
                label="Create Password and Login"
                isDisabled={!isValid}
                isLoading={isSubmitting}
                fullWidth
                size="large"
              />
            </div>
            <TermsAndPolicy userType={USER_TYPES.CUSTOMER} className="subheader-text" />
          </div>
        </form>
      </Grid>
      <Grid
        container
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        className={`${isIOS ? "slider-main-container-ios" : "slider-main-container"} ${platfromRebrandPhase1 ? "rebrand" : "default"}`}
      >
        <Slider sliderData={platfromRebrandPhase1 ? SLIDER_DATA_REBRAND : SLIDER_DATA} />
      </Grid>
    </Grid>
  );
}

export default CreatePassword;

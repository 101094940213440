/* eslint-disable no-case-declarations */
import { getDecodedToken } from "helpers";

import {
  AUTH_USER,
  AUTH_USER_SUCCESS,
  AUTH_USER_FAILURE,
  RESET_AUTH_USER,
  SET_PASSWORD_SUCCESS,
} from "../constants/AuthUser";
import jwt from "jwt-decode";

const INIT_STATE = {
  authUserData: {},
  loadingAuthUserData: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_AUTH_USER:
      return INIT_STATE;

    case AUTH_USER:
      return {
        ...state,
        loadingAuthUserData: true,
      };
    case AUTH_USER_SUCCESS:
      // eslint-disable-next-line no-case-declarations

      if (action.payload.ok) {
        const user = jwt(action?.payload?.payload.accessToken); // decode your token here

        localStorage.setItem("accessToken", action?.payload?.payload.accessToken);
        localStorage.setItem("loggedInUser", JSON.stringify(user));
        localStorage.setItem("refreshToken", action?.payload?.payload.refreshToken);
        return {
          ...state,
          loadingAuthUserData: false,
          authUserData: user,
        };
      }
      return {
        ...state,
        loadingAuthUserData: false,
        authUserData: action.payload,
      };

    case SET_PASSWORD_SUCCESS:
      const { accessToken = "", refreshToken = "" } = action?.payload || {};
      const user = getDecodedToken(accessToken);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("loggedInUser", JSON.stringify(user));
      localStorage.setItem("refreshToken", refreshToken);
      return {
        ...state,
        loadingAuthUserData: false,
        authUserData: user,
      };

    // eslint-disable-next-line no-fallthrough
    case AUTH_USER_FAILURE:
      localStorage.removeItem("accessToken");
      return {
        ...state,
        loadingAuthUserData: false,
        // authUserData: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};

/**
 * Downloads a file from the provided data with the specified filename
 * @param {Blob} data - The file data to download
 * @param {string} fileName - The name to save the file as
 */
const downloadFile = (data, fileName) => {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export default downloadFile;

/* eslint-disable */

import React from "react";
// google auth
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
// material ui
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";

// router
import { useParams, useLocation, useNavigate } from "react-router-dom";
// redux
import { useSelector, useDispatch } from "react-redux";
import { GoogleSigunp, ResetGoogleSigunp } from "../../../../store/actions/Google/Google_signup";

// icons
import GoogleIcon from "../../../Icons/googleIconSmall.png";
import { SignInScreenPermissions } from "../SignIn.enum";
import { DropdownData } from "../../../../store/actions/DropdownData";
import { ResetAuthUser } from "../../../../store/actions/AuthUser";
import { GetPlatformConfig } from "../../../../store/actions/PlatformConfig";
import { UserAdditionalInfo } from "../../../../store/actions/UserAdditionalInfo";

function GoogleAuth(props) {
  const { changeScreen, loginPortal } = props;
  const history = useNavigate();
  const location = useLocation();

  // redux
  const dispatch = useDispatch();

  const [googleResponse, setGoogleResponse] = React.useState({});

  // redux state
  const googleSignupInfo = useSelector((state) => state.GoogleSignup.googleSignupInfo);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);

  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);

  const dropdownData = useSelector((state) => state.DropdownData.dropdownData);

  // functions

  const onLoginSuccess = async (googleData) => {
    let googleRequest = "";
    dispatch(ResetGoogleSigunp({}));
    dispatch(ResetAuthUser({}));
    setGoogleResponse(googleData);
    // if (googleData && location.pathname == "/") {

    if (googleData) {
      if (loginPortal == "customer") {
        googleRequest = "customers/googleAuth";
      } else if (loginPortal == "employee") {
        googleRequest = "employee/googleAuth";
      } else if (location.pathname == "/admin") {
        googleRequest = "admin/googleAuth";
      }
    }

    dispatch(
      GoogleSigunp({
        // customerData: customerData,
        googleToken: googleData.code,
        method: "POST",
        request: googleRequest,
      })
    );
  };

  const onLoginFailure = (response) => {
    // localStorage.removeItem("accessToken");
    // history("/");
    dispatch(ResetAuthUser({}));
  };

  React.useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const accesstoken = localStorage.getItem("accessToken");

    if (accesstoken != null && accesstoken != "undefined" && loggedInUser != null && loggedInUser != "undefined") {
      dispatch(DropdownData());
    }
  }, [googleSignupInfo]);

  React.useEffect(() => {
    let loggedInUser = {};
    const accesstoken = localStorage.getItem("accessToken");

    if (dropdownData?.ok) {
      if (accesstoken != null && accesstoken != "undefined") {
        loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser != null && loggedInUser != undefined) {
          window.analytics?.track("Logged In", {
            channel: "google",
            name: loggedInUser?.name,
            email: loggedInUser?.email,
            userID: loggedInUser?.userId,
            accountType: loggedInUser?.type,
          });

          if (loggedInUser?.type == "customer") {
            if (loggedInUser?.setupCompany) {
              changeScreen(SignInScreenPermissions.ADD_CUSTOMER_INFO);
            } else if (!loggedInUser?.bankAttached && userAdditionalInfo.status === "enabled") {
              changeScreen(SignInScreenPermissions.ADD_PAYMENT_METHOD);
            } else {
              history("/main/dashboard");
            }
          } else if (loggedInUser?.type == "employee") {
            dispatch(UserAdditionalInfo(`employee/${loggedInUser.userId}`));
            history("/main/dashboard");
          } else {
            history("/main/dashboard");
          }
        }
      }
    } else {
    }
  }, [dropdownData, userAdditionalInfo]);

  return (
    <GoogleLogin
      clientId={platformConfig.ok && platformConfig?.payload?.GOOGLE_CLIENT_ID}
      accessType="offline"
      approvalPrompt="force"
      prompt="consent"
      responseType="code"
      render={(renderProps) => (
        <Box>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            onClick={renderProps.onClick}
            sx={{
              backgroundColor: "#FFFFFF",
              height: "54px",
              fontFamily: "Poppins-Regular !important",
              fontWeight: "500",
              lineHeight: "20",
              borderRadius: "99px",
              fontSize: "16px",
              color: "var(--color-primitives-primary-600)",
              boxShadow: "2px 5px 7px rgba(2, 0, 110, 0.12)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              ":hover": {
                bgcolor: "primary.main",
                color: "white",
              },
            }}
          >
            <div>
              <img src={GoogleIcon} style={{ marginRight: 8 }} alt="gmail icon" width="26px" />
            </div>
            <Typography>Continue with Google</Typography>
          </Button>
        </Box>
      )}
      onSuccess={onLoginSuccess}
      onFailure={onLoginFailure}
      cookiePolicy="single_host_origin"
    />
  );
}
export default GoogleAuth;

import Modal from "components/common/Modal";
import Button from "components/common/Button";
import CustomTooltip from "components/common/Tooltip";

import { InfoOutlined } from "@mui/icons-material";

import "./PaymentBreakdownModal.scss";

function PaymentBreakdownModal({ open, onClose, nextMonthFee, nextMonthTotalAmount, discount, employeeCount }) {
  return (
    <Modal
      padding="0px"
      open={open}
      onClose={onClose}
      titleClass="px-125 py-1"
      title="Payment breakdown from next month onwards"
      width="532px"
    >
      <div className="modal-amount-container-wrapper">
        <div className="modal-amount-container">
          <div className="flex flex-col items-start">
            <p className="heading-h1-semibold">$ {nextMonthTotalAmount.toLocaleString()}</p>
            <p className="para-body-m-regular color-text-body-primary">every month</p>
          </div>
          <span className="para-body-m-regular color-text-body-primary">{employeeCount} employees</span>
        </div>
        <div className="modal-amount-fee-container">
          <div className="amount-fee-item">
            <p className="flex items-center para-body-m-regular">
              Total monthly fee
              <CustomTooltip
                content={
                  <div className="para-body-xs-medium">Your regular monthly charge for your associate's services.</div>
                }
                arrow={false}
                placement="top"
              >
                <InfoOutlined className="modal-amount-fee-item-icon" />
              </CustomTooltip>
            </p>
            <span className="para-body-m-regular text-black">$ {nextMonthFee?.toLocaleString()}</span>
          </div>
          <div className="amount-fee-item">
            <p className="para-body-m-regular">Discount</p>
            <span className="para-body-m-regular text-black">$ ({discount.toLocaleString()})</span>
          </div>
        </div>
      </div>
      <div className="flex justify-end px-125 my-1">
        <Button label="Done" size="large" onClick={onClose} />
      </div>
    </Modal>
  );
}

export default PaymentBreakdownModal;

import React from "react";

function DownloadIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12.6667V11.3333C13.1046 11.3333 14 10.4379 14 9.33332C14 8.22875 13.1046 7.33332 12 7.33332C11.9791 7.33339 11.9791 7.33339 11.9583 7.33375L11.3825 7.34544L11.2873 6.77743C11.0202 5.18417 9.63407 3.99999 7.99999 3.99999C6.79492 3.99999 5.70286 4.64428 5.11093 5.66931L4.9271 5.98766L4.55976 6.00208C3.13288 6.05809 1.99999 7.23362 1.99999 8.66666C1.99999 9.90922 2.84984 10.9533 3.99999 11.2493V12.6114C2.10818 12.294 0.666656 10.6487 0.666656 8.66666C0.666656 6.63917 2.17801 4.95563 4.14658 4.70017C5.006 3.44337 6.43561 2.66666 7.99999 2.66666C10.1057 2.66666 11.9167 4.0704 12.4836 6.03482C14.0954 6.26907 15.3333 7.65663 15.3333 9.33332C15.3333 11.1743 13.8409 12.6667 12 12.6667ZM7.33332 12.1688V7.99999H8.66666V12.1688L10.1953 10.6728L11.1381 11.5955L7.99999 14.6667L4.86192 11.5955L5.80473 10.6728L7.33332 12.1688Z"
      />
    </svg>
  );
}

export default DownloadIcon;

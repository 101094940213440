import { useEffect, useState } from "react";
import AvatarImage from "assets/images/fallback-image.svg";
import SearchIcon from "@mui/icons-material/Search";

import "./onLeaveEmployees.css";
import moment from "moment";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import { baseUrl } from "util/APIBaseUrl";
import EmployeeAvatar from "../EmployeeTimeOffDetails/EmployeeAvatar";
import { useNavigate } from "react-router-dom";
import { ResetAddEmployeeSalary } from "store/actions/Workforce/AddEmployee/AddEmployeeSalary";
import { ResetCalculateEmployeeSalary } from "store/actions/Workforce/AddEmployee/CalculateEmployeeSalary";
import { ResetAddEmployeeSalaryData } from "store/actions/Workforce/AddEmployee/AddEmployeeSalaryData";
import { GetEmployeeDetails, ResetGetEmployeeDetails } from "store/actions/GetEmployeeDetails";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions } from "components/Hook";
import CardHoverArrowIcon from "../EmployeeTimeOffDetails/Icons/CardHoverArrowIcon";

const leaveYearSkeltonStyle = {
  borderRadius: "26px",
  backgroundColor: "rgb(189 192 255 / 30%)",
};

function applyFilter({ inputData, query }) {
  if (query) {
    // eslint-disable-next-line no-param-reassign
    inputData = inputData?.filter(
      (employee) => employee?.employeeName?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return inputData;
}

function OnLeaveEmployees(props) {
  const { employees, selectedDateRange, employeesTimeOffListLoading } = props;
  const { count, rows } = employees;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedInUser } = usePermissions();

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEmployeeID, setSelectedEmployeeID] = useState(null);
  const [, setEmployeeImages] = useState({});
  const [open, setOpen] = useState(false);

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);

  const dataFiltered = applyFilter({
    inputData: rows || [],
    query: searchQuery,
  });

  const navigateToEmployeeProfile = (employeeId) => {
    dispatch(ResetAddEmployeeSalaryData({}));
    dispatch(ResetGetEmployeeDetails({}));
    dispatch(ResetAddEmployeeSalary({}));
    dispatch(ResetCalculateEmployeeSalary({}));
    if (Object.keys(loggedInUser).length && loggedInUser?.userId && employeeId) {
      setSelectedEmployeeID(employeeId);
      dispatch(
        GetEmployeeDetails({
          customerID: loggedInUser?.userId,
          employeeID: employeeId,
          request: `employee/${employeeId}`,
        })
      );
    }
  };

  const handleOpen = (id) => {
    setOpen({ open: true, id });
  };

  const handleClose = (id) => {
    setOpen({ open: true, id });
  };

  useEffect(() => {
    const newEmployeeImages = {};
    if (Array.isArray(rows)) {
      rows.forEach((employee) => {
        const imageUrl =
          employee.employeeId && employee.pictureVersion
            ? `${baseUrl}/employee/${employee.employeeId}/logo?id=${employee.pictureVersion}&variant=thumbnail`
            : AvatarImage;
        newEmployeeImages[employee.employeeId] = imageUrl;
      });
      setEmployeeImages(newEmployeeImages);
    }
  }, [rows]);

  useEffect(() => {
    if (getEmployeeDetails.ok) {
      if (selectedEmployeeID) {
        navigate(`/main/details/${selectedEmployeeID}?tab=Time-off&navigateFrom=timeoff/admin`);
      }
    }
  }, [getEmployeeDetails]);

  useEffect(() => {
    dispatch(ResetGetEmployeeDetails({}));
    setSelectedEmployeeID(null);
  }, []);

  return (
    <div className="on-leave-employees">
      <div className="top-row">
        <div className="date-badge" />
        <div className="date-info">
          <h2>
            {moment(selectedDateRange?.startDate).format("MMM DD, YYYY")} -{" "}
            {moment(selectedDateRange?.endDate).format("MMM DD, YYYY")}
          </h2>
          <p id="total-out-this-day">People out this day: {count || rows?.length}</p>
        </div>
      </div>
      <div className="search-container">
        <SearchIcon className="search-icon" />
        <input
          type="text"
          className="search-input"
          placeholder="Search employee"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="employees-container custom-scroll">
        {employeesTimeOffListLoading ? (
          <>
            <Skeleton
              variant="rectangle"
              sx={{ ...leaveYearSkeltonStyle, borderRadius: "10px", width: "100%", height: "85px" }}
            />

            <Skeleton
              variant="rectangle"
              sx={{ ...leaveYearSkeltonStyle, borderRadius: "10px", width: "100%", height: "85px" }}
            />

            <Skeleton
              variant="rectangle"
              sx={{ ...leaveYearSkeltonStyle, borderRadius: "10px", width: "100%", height: "85px" }}
            />

            <Skeleton
              variant="rectangle"
              sx={{ ...leaveYearSkeltonStyle, borderRadius: "10px", width: "100%", height: "85px" }}
            />
          </>
        ) : (
          dataFiltered?.map((employee, index) => (
            <div
              key={employee?.employeeId}
              className={`employee-item ${index % 2 === 0 ? "left" : "right"}`}
              onMouseEnter={() => handleOpen(index)}
              onMouseLeave={() => handleClose(index)}
              style={{
                position: "relative",
                border:
                  open?.open && open?.id === index
                    ? "1px solid var(--color-primitives-primary-100)"
                    : "1px solid var(--color-primitives-primary-50)",
                boxShadow: open?.open && open?.id === index ? "0px 1px 6px 0px #02006E40" : "none",
              }}
            >
              <div className="employee-info">
                <div className="employee-avatar">
                  <EmployeeAvatar
                    img={`${baseUrl}/employee/${employee?.employeeId}/logo?id=${employee?.pictureVersion}&variant=thumbnail`}
                    size={{ width: "57px", height: "57px", borderRadius: "50%" }}
                  />
                  <span
                    className={
                      employee?.leaveType === "holiday"
                        ? "status-dot calendar-dot-indicator-green"
                        : "status-dot calendar-dot-indicator-orange"
                    }
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <Tooltip
                    title={
                      employee?.leaveType === "holiday"
                        ? `${employee?.employeesCount} Employees -  ${employee?.country}`
                        : employee?.employeeName
                    }
                  >
                    <div className="employee-name" style={{ cursor: "pointer" }} id="emp-name">
                      {employee?.leaveType === "holiday"
                        ? `${employee?.employeesCount} Employees -  ${employee?.country}`
                        : employee?.employeeName}
                    </div>
                  </Tooltip>

                  <div className="employee-dates" id="emp-dates">
                    {moment.utc(employee?.dateFrom).format("MMM Do")}{" "}
                    {employee.dateFrom !== employee.dateTo &&
                      employee?.dateTo?.trim() !== "" &&
                      employee?.dateTo &&
                      ` - ${moment.utc(employee?.dateTo).format("MMM Do")}`}
                  </div>

                  <div
                    className={`employee-status ${employee?.status?.toLowerCase()}`}
                    id="emp-status"
                    style={{ marginTop: "6px", textTransform: "capitalize" }}
                  >
                    {employee?.leaveType === "holiday"
                      ? `${employee?.holidayName || "Holiday"}`
                      : `${employee?.leaveType || ""} Leave`}
                  </div>
                </div>
              </div>
              {/* </div> */}

              {open?.open && open?.id === index && (
                <IconButton
                  sx={{ position: "absolute", right: 7, top: 22 }}
                  onClick={() => {
                    navigateToEmployeeProfile(employee?.employeeId);
                  }}
                >
                  <CardHoverArrowIcon />
                </IconButton>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default OnLeaveEmployees;

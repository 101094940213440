/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Grid, TableContainer } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";

function EnhancedTableHead(props) {
  const { order, orderBy, tableheadCells, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableheadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontSize: "13px",
              fontFamily: "Poppins-SemiBold",
              "& .MuiTableSortLabel-icon": {
                color: "#40CAC1 !important",
                borderRadius: "10px",
                backgroundColor: "#F4F4F4",
              },
            }}
          >
            {headCell.sort != false ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant="h5">{headCell.label}</Typography>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const {
    titleIcon: TitleIcon,
    title,
    svgIcon,
    subTitle,
    filterButton,
    AddNewRowButton,
    SearchEmployee,
    datePicker,
    exportButton,
  } = props;

  return (
    <Grid container direction="row" justifyContent="space-between" sx={{ px: 2, py: 2 }}>
      <Grid item>
        <Box display="flex">
          {TitleIcon ? (
            svgIcon ? (
              <div height={22} width="auto">
                <TitleIcon />
              </div>
            ) : (
              <img src={TitleIcon} height={22} width="auto" alt="title" />
            )
          ) : null}
          <Typography variant="h2" style={{ fontWeight: "bold", marginTop: "5px" }} pl={2}>
            {title || null}
          </Typography>
          <Typography variant="paragraph" color="gray">
            {subTitle || null}
          </Typography>
        </Box>
      </Grid>
      {filterButton || datePicker || AddNewRowButton || SearchEmployee || exportButton ? (
        <Grid item>
          <Grid item container direction="row" columnSpacing={3}>
            {SearchEmployee ? <Grid item>{SearchEmployee}</Grid> : null}
            {datePicker ? <Grid item>{datePicker}</Grid> : null}
            {filterButton ? <Grid item>{filterButton}</Grid> : null}
            {exportButton ? <Grid item>{exportButton}</Grid> : null}
            {AddNewRowButton ? <Grid item>{AddNewRowButton}</Grid> : null}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default function DataTable(props) {
  const {
    handleRequestSortBy,
    totalCount,
    rowsData,
    tableheadCells,
    titleIcon,
    title,
    subTitle,
    toolBar,
    handleRowsPerPage,
    handleSelectedRow,
    sortByName,
    openDetailsPage,
    filterButton,
    AddNewRowButton,
    SearchEmployee,
    minimumHeight,
    tableHeader,
    tableRowContent,
    tableRowsPerPage,
    resetPage,
    datePicker,
    exportButton,
    disablePagination,
    svgIcon,
  } = props;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [, setHeaders] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(tableRowsPerPage || 10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    const sortRequest = {
      orderBy: property,
      order: direction,
    };
    setOrder(direction);
    setOrderBy(property);
    handleRequestSortBy(sortRequest);
  };

  const handleRowSelected = (id) => {
    if (id) {
      handleSelectedRow(id, "details");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  useEffect(() => {
    setOrderBy(sortByName);
  }, [sortByName]);

  useEffect(() => {
    if (rowsData.length && rowsData != undefined && rowsData != null) setHeaders(Object.keys(rowsData[0]));
  }, [rowsData]);

  useEffect(() => {
    if (resetPage) {
      setPage(0);
    }
  }, [resetPage, totalCount]);

  useEffect(() => {
    if (handleRowsPerPage) handleRowsPerPage({ rowsPerPage, page });
  }, [rowsPerPage, page]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          overflowX: "hidden",
          width: "100%",
          mb: 2,
          pt: "0.2rem",
          borderRadius: "5px !important",
        }}
      >
        <TableContainer
          sx={{
            px: 3,
            minHeight: minimumHeight != false ? (rowsData.length < 3 ? 470 : 150) : 150,
          }}
        >
          {toolBar ? (
            <EnhancedTableToolbar
              titleIcon={titleIcon}
              svgIcon={svgIcon}
              title={title}
              subTitle={subTitle}
              filterButton={filterButton}
              datePicker={datePicker}
              AddNewRowButton={AddNewRowButton}
              exportButton={exportButton}
              SearchEmployee={SearchEmployee}
            />
          ) : null}
          <Table
            sx={{
              "& .MuiTableCell-root": {
                borderBottom: "0.02rem #f4f4f4 solid",
              },
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            {tableHeader != false ? (
              <EnhancedTableHead
                order={order}
                tableheadCells={tableheadCells}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rowsData.length}
              />
            ) : null}
            <TableBody>
              {rowsData.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover={!!handleSelectedRow}
                    textAlign="start"
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={labelId}
                    selected={isItemSelected}
                    sx={{
                      "&.MuiTableRow-root": {
                        verticalAlign: tableRowContent ? "top !important" : "center !important",
                      },
                    }}
                  >
                    {tableheadCells.map((header) => {
                      return (
                        <TableCell
                          key={row[header.id]}
                          sx={{
                            whiteSpace: header?.wrapCell && "normal",
                            wordBreak: header?.wrapCell && "break-word",
                            cursor: header.id == openDetailsPage && "pointer",
                          }}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="dense"
                          align={header.align ? header.align : "left"}
                          width={header.width ? header.width : "auto"}
                          onClick={() => {
                            if (header.id == openDetailsPage) handleRowSelected(row.id);
                          }}
                        >
                          {row[header.id]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {disablePagination ? null : (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
}

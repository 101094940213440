import React, { useState } from "react";
import moment from "moment";
import {
  Table,
  Tooltip,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Stack,
} from "@mui/material";

import ArrowIcons from "./Icons/ArrowIcons";

import { NEW_ACTIVITY } from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeoffNewActivity/Enum";

import LeaveType from "components/Screens/Timeoff/ManageTimeoff/Common/LeaveType";
import EditByHrStatus from "components/Screens/Timeoff/ManageTimeoff/Common/EditByHrStatus";

const header = [
  {
    id: "dateRange",
    label: "Date Range",
    align: "left",
  },
  {
    id: "requestDate",
    label: "Request date",
    align: "left",
  },
  {
    id: "leaveType",
    label: "Leave Type",
    align: "left",
  },
  {
    id: "noOfLeaves",
    label: "Duration",
    align: "left",
  },

  {
    id: "noticePeriodDays",
    label: "Notice Period",
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    align: "left",
  },

  {
    id: "initiatedBy",
    label: "Initiated by",
    align: "left",
  },
  {
    id: "action",
    label: "Actions",
    align: "left",
  },
];

function EmployeeTimeOffReqTable(props) {
  const { employeeTimeOffReq, setEmployeeSelectedActivity, setScreenView } = props;
  const [headLabel] = useState(header);

  return (
    <TableContainer
      sx={{
        position: "relative",
        overflow: "unset",
        borderRadius: "10px",
      }}
      className="custom-scroll"
    >
      <Table size="medium" sx={{ minWidth: 800 }}>
        <TableHead className="employee-time-off-table-header">
          <TableRow>
            {headLabel?.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.align || "left"}
                className="employee-time-off-table-header-label"
              >
                <Tooltip title={headCell?.label || ""}>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "160px",
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                    }}
                  >
                    {headCell?.label}
                  </Typography>
                </Tooltip>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ background: "white" }}>
          {employeeTimeOffReq?.map((employeeActivity) => {
            return (
              <TableRow hover>
                {headLabel?.map((headCell) => {
                  switch (headCell?.id) {
                    case "dateRange":
                      return (
                        <TableCell sx={{ p: "12px 16px" }}>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <div className="employee-time-off-date-range" id="start-date">
                              {moment(employeeActivity?.dateFrom).format("MMM DD, YYYY")}
                            </div>
                            {employeeActivity?.dateTo && (
                              <>
                                <ArrowIcons />
                                <div className="employee-time-off-date-range" id="end-date">
                                  {moment(employeeActivity?.dateTo).format("MMM DD, YYYY")}
                                </div>
                              </>
                            )}
                          </Stack>
                        </TableCell>
                      );
                    case "requestDate":
                      return (
                        <TableCell sx={{ p: "12px 16px" }}>
                          <Typography noWrap className="employee-time-off-request-date-or-leave-cell" id="request-date">
                            {employeeActivity?.createdAt
                              ? moment(employeeActivity?.createdAt).format("MMM DD, YYYY")
                              : "-"}
                          </Typography>
                        </TableCell>
                      );

                    case "leaveType":
                      return (
                        <TableCell sx={{ p: "12px 20px" }} id="leave-type">
                          <Typography
                            noWrap
                            className="employee-time-off-request-date-or-leave-cell"
                            sx={{ color: "var(--temp-color-primitives-primary-800)" }}
                          >
                            {employeeActivity?.originalLeaveType &&
                            employeeActivity?.originalLeaveType !== employeeActivity?.leaveType ? (
                              <>
                                <s>{`${employeeActivity?.originalLeaveType}`}</s>

                                <span style={{ paddingLeft: "8px" }}>{`${employeeActivity?.leaveType}`}</span>
                              </>
                            ) : (
                              `${employeeActivity?.leaveType}`
                            )}
                          </Typography>
                        </TableCell>
                      );

                    case "noOfLeaves":
                      return (
                        <TableCell sx={{ p: "12px 16px" }} id="no-of-leaves">
                          <Typography noWrap className="employee-time-off-used">
                            {employeeActivity?.[headCell?.id] || "-"}
                          </Typography>
                        </TableCell>
                      );

                    case "noticePeriodDays":
                      return (
                        <TableCell sx={{ p: "12px 16px" }} id="notice-period-days">
                          <Typography noWrap className="employee-time-off-used">
                            {Math.abs(
                              moment(employeeActivity?.dateFrom, "YYYY-MM-DD").diff(
                                moment(employeeActivity?.createdAt, "YYYY-MM-DD"),
                                "days"
                              ) || 0
                            )}{" "}
                            days
                          </Typography>
                        </TableCell>
                      );

                    case "status":
                      return (
                        <TableCell sx={{ p: "12px 16px" }} id="status">
                          <LeaveType type={employeeActivity?.leaveStatus} />
                        </TableCell>
                      );

                    case "hrDocReview":
                      return (
                        <TableCell sx={{ p: "12px 16px" }} id="hr-doc">
                          <EditByHrStatus
                            style={{ minWidth: "107px" }}
                            hrEdit={employeeActivity?.hrEdit}
                            hrApproved={employeeActivity?.hrApproved}
                            leaveStatus={employeeActivity?.status}
                          />
                        </TableCell>
                      );

                    case "initiatedBy":
                      return (
                        <TableCell sx={{ p: "12px 16px" }} id="notice-period-days">
                          <Typography
                            noWrap
                            className="employee-time-off-initiated"
                            style={{
                              color: "var(--color-primitives-primary-900)",
                              background: "var(--color-primitives-primary-50)",
                              border: "1px solid #BFC2FF",
                            }}
                          >
                            {employeeActivity?.editsByHR ? "HR" : "Self"}
                          </Typography>
                        </TableCell>
                      );

                    default:
                      return (
                        <TableCell
                          sx={{ p: "12px 16px" }}
                          id="all-details"
                          onClick={() => {
                            setEmployeeSelectedActivity(employeeActivity);
                            setScreenView(NEW_ACTIVITY.EMPLOYEE_TIME_OFF_DETAILS);
                          }}
                        >
                          <Typography noWrap className="employee-time-off-view-details">
                            View All Details
                          </Typography>
                        </TableCell>
                      );
                  }
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EmployeeTimeOffReqTable;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardContent, CardMedia, Grid, Typography, Chip, Divider, Link } from "@mui/material";
import { Check, ErrorOutline } from "@mui/icons-material";
import "../payoneer.css";

// components
import PayoneerStatusCardSkeleton from "./PayoneerStatusCardSkeleton";
import ConsentDialog from "../PayonnerConsentDialog";

// assets
import payoneerLogo from "../../../../../assets/images/payoneer/Payoneer_logo.svg";
import PendingTimeIcon from "../../../../../assets/images/payoneer/7001276_clock_hour_time_duration_timer_icon 1.svg";
import eye from "../../../../../assets/images/payoneer/eye.svg";
import noEye from "../../../../../assets/images/payoneer/eye-no.svg";
import revoke from "../../../../../assets/images/payoneer/revoke.svg";
import ArrowRight from "../../EmployeeSvgIcons/ArrowRight";

import {
  employeePayoneerConsent,
  getEmployeeBalance,
  getEmployeePayoneerStatus,
  revokePayoneerAccessToken,
  saveEmployeeAccessToken,
} from "services/Payoneer";

import { showSnackbar } from "store/actions/Utility";
import { AlertType } from "components/common/Snackbar";
import { concentTypes } from "../constants";

const isHideBalanceKey = "payoneerHideBalance";

function PayoneerStatusCard({ payoneerAccountDetail }) {
  const [showBalance, setShowBalance] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(null);
  const [payoneerDetail, setPayoneerDetail] = useState(payoneerAccountDetail);
  const [consentLink, setConsentLink] = useState(null);
  const dispatch = useDispatch();

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const code = localStorage.getItem("payoneerCode");
  const payoneerHideBalance = localStorage.getItem(isHideBalanceKey);

  const handleConsent = () => {
    window.location.href = consentLink;
  };
  const goToPayoneer = () => {
    window.open(payoneerDetail?.loginUrl, "_blank");
  };
  function handleDialog() {
    setDialogOpen(!dialogOpen);
  }

  async function fetchEmployeePayoneerStatus() {
    try {
      setLoading(true);
      const payoneerStatus = await getEmployeePayoneerStatus();
      setPayoneerDetail(payoneerStatus);
    } catch (error) {
      dispatch(
        showSnackbar({
          type: AlertType.error,
          message: error?.message,
          dispatch,
        })
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleRevokePayoneerToken() {
    try {
      setDialogOpen(!dialogOpen);
      setLoading(true);
      await revokePayoneerAccessToken();
      setPayoneerDetail({ ...payoneerDetail, consentStatus: concentTypes.REVOKED });
      fetchEmployeePayoneerStatus();

      dispatch(
        showSnackbar({
          type: AlertType.success,
          message: "Balance access revoked successfully",
          dispatch,
        })
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          type: AlertType.error,
          message: error?.message,
          dispatch,
        })
      );
    } finally {
      setDialogOpen(!dialogOpen);
      setLoading(false);
    }
  }

  async function getEmployeePayoneerBalance() {
    try {
      setLoading(true);
      const empBalance = await getEmployeeBalance();
      const result = empBalance?.find((item) => item?.currency === "USD");
      setBalance(result);
    } catch (error) {
      dispatch(
        showSnackbar({
          type: AlertType.error,
          message: error?.message,
          dispatch,
        })
      );
      const payoneerStatus = await getEmployeePayoneerStatus();
      setPayoneerDetail(payoneerStatus);
    } finally {
      setLoading(false);
    }
  }

  async function getConsentLink() {
    try {
      setLoading(true);
      const authorizationLink = await employeePayoneerConsent();
      setConsentLink(authorizationLink);
    } catch (error) {
      dispatch(
        showSnackbar({
          type: AlertType.error,
          message: error?.message,
          dispatch,
        })
      );
    } finally {
      setLoading(false);
    }
  }

  async function saveEmployeePayoneerAccessToken() {
    try {
      setLoading(true);
      await saveEmployeeAccessToken(code);
      dispatch(
        showSnackbar({
          type: AlertType.success,
          message: "Balance access granted successfully",
          dispatch,
        })
      );
      fetchEmployeePayoneerStatus();
    } catch (error) {
      dispatch(
        showSnackbar({
          type: AlertType.error,
          message: error?.message,
          dispatch,
        })
      );
    } finally {
      localStorage.removeItem("payoneerCode");
      setLoading(false);
    }
  }

  useEffect(() => {
    if (code) {
      saveEmployeePayoneerAccessToken();
    }
    if (payoneerHideBalance) {
      setShowBalance(false);
    } else {
      setShowBalance(true);
    }
  }, []);

  useEffect(() => {
    if (payoneerDetail?.consentStatus === concentTypes.ACTIVE) {
      getEmployeePayoneerBalance();
    }
  }, [payoneerDetail?.consentStatus]);

  useEffect(() => {
    if (!balance) {
      getConsentLink();
    }
  }, [balance]);

  useEffect(() => {
    if (showBalance) {
      localStorage.removeItem(isHideBalanceKey);
    } else {
      localStorage.setItem(isHideBalanceKey, "Yes");
    }
  }, [showBalance]);
  return (
    <div>
      {loading ? (
        <PayoneerStatusCardSkeleton />
      ) : (
        <>
          {dialogOpen && (
            <ConsentDialog open={dialogOpen} handleRevokeToken={handleRevokePayoneerToken} handleClose={handleDialog} />
          )}
          <Grid container className="justify-center items-center w-full" id="payoneer-status-card">
            <div className="peers-wrapper">
              <Card>
                <CardContent id="payoneer-payee-status">
                  <CardMedia
                    image={payoneerLogo}
                    alt="financial"
                    component="img"
                    sx={{
                      height: 42,
                      width: 102,
                      objectFit: "contain",
                    }}
                  />
                  <Grid className="flex flex-col items-center">
                    <Typography className="label-lg">{loggedInUser?.email}</Typography>
                    {(() => {
                      const status = payoneerDetail?.status?.description;
                      if (status === "Active") {
                        return (
                          <Chip
                            id="payoneer-status-active"
                            className="payoneer-pending-leave-chip-status-active"
                            label={
                              <div className="payoneer-pending-leave-chip-status-icon-active-label">
                                <Check sx={{ width: "15px", height: "15px", marginLeft: "-5px" }} />
                                <div className="payoneer-pending-leave-chip-status-review-image-text">Active</div>
                              </div>
                            }
                            sx={{ ml: 2, height: "14px" }}
                          />
                        );
                      }
                      if (status === "Pending") {
                        return (
                          <Chip
                            id="payoneer-status-reviewing"
                            className="payoneer-pending-leave-chip-status-review"
                            label={
                              <div className="payoneer-pending-leave-chip-status-review-label">
                                <img
                                  className="payoneer-pending-leave-chip-status-review-image"
                                  src={PendingTimeIcon}
                                  alt=""
                                />
                                <div className="payoneer-pending-leave-chip-status-review-image-text">Reviewing</div>
                              </div>
                            }
                            sx={{ ml: 2 }}
                          />
                        );
                      }
                      if (status === "Inactive") {
                        return (
                          <Chip
                            id="payoneer-status-rejected"
                            className="payoneer-pending-leave-chip-status-error"
                            label={
                              <div className="payoneer-pending-leave-chip-status-icon-error-label">
                                <ErrorOutline sx={{ width: "15px", height: "15px", marginLeft: "-5px" }} />
                                <div className="payoneer-pending-leave-chip-status-review-image-text">Rejected</div>
                              </div>
                            }
                            sx={{ ml: 2 }}
                          />
                        );
                      }
                      return null;
                    })()}
                  </Grid>
                  <Grid className="flex flex-row">
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "14px",
                      }}
                    >
                      Currency:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "var(--color-primitives-primary-600)",
                      }}
                    >
                      &nbsp;USD
                    </Typography>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent id="payoneer-balance-card">
                  <Grid>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "14px",
                        textAlign: "start",
                      }}
                    >
                      Your balance
                    </Typography>
                    <Grid className="flex justify-between text-center">
                      <Grid>
                        {payoneerDetail?.consentStatus === concentTypes.ACTIVE ? (
                          <Grid className="flex flex-row">
                            <Typography
                              id="payoneer-balance-value"
                              sx={{
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "24px",
                              }}
                            >
                              {showBalance && balance ? `$ ${balance?.available_balance}` : "$ ----"}
                            </Typography>
                            <Chip
                              className="align-self-center m-1 text-center items-center justify-center"
                              clickable
                              onClick={() => setShowBalance(!showBalance)}
                              sx={{ backgroundColor: "var(--color-primitives-primary-50)", ml: 2, width: "31px" }}
                              label={
                                <div className="payoneer-label-balance-icons">
                                  <CardMedia
                                    id="payoneer-balance-visibility-button"
                                    image={showBalance ? eye : noEye}
                                    alt={showBalance ? "eye" : "noEye"}
                                    component="img"
                                    sx={{
                                      height: 20,
                                      width: 20,
                                      objectFit: "contain",
                                    }}
                                  />
                                </div>
                              }
                            />
                            <Chip
                              className="align-self-center m-1 text-center items-center justify-center"
                              clickable
                              onClick={handleDialog}
                              sx={{ backgroundColor: "var(--color-primitives-primary-50)", ml: 1, width: "31px" }}
                              label={
                                <div className="payoneer-label-balance-icons">
                                  <CardMedia
                                    id="payoneer-balance-revoke-button"
                                    image={revoke}
                                    alt="revoke"
                                    component="img"
                                    sx={{
                                      height: 20,
                                      width: 20,
                                      objectFit: "contain",
                                    }}
                                  />
                                </div>
                              }
                            />
                          </Grid>
                        ) : (
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "var(--color-primitives-primary-600)",
                            }}
                          >
                            {payoneerDetail?.consentStatus === concentTypes.EXPIRED && "Your link was expired | "}
                            <Link
                              id="payonner-request-access-link"
                              rel="noopener noreferrer"
                              onClick={handleConsent}
                              sx={{ cursor: "pointer" }}
                            >
                              Allow Edge to show your payoneer balance
                            </Link>
                          </Typography>
                        )}
                      </Grid>
                      <Grid>
                        <Chip
                          id="payoneer-account-link"
                          variant="outlined"
                          className="align-self-center m-1 text-center items-center justify-center"
                          clickable
                          onClick={goToPayoneer}
                          sx={{ backgroundColor: "var(--color-primitives-primary-50)", border: "none" }}
                          label={
                            <div className="payoneer-label-go-to-payoneer">
                              <div className="payoneer-btn-text">Go to Payoneer</div>
                              <ArrowRight
                                className="payoneer-go-to-payoneer-arrow-left"
                                fill="var(--color-primitives-primary-900)"
                              />
                            </div>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </>
      )}
    </div>
  );
}

export default PayoneerStatusCard;

import React from "react";

function DocumentIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 2.84522V5.83337H15.4882L12.5 2.84522ZM15.8333 7.50004H12.5C11.5795 7.50004 10.8333 6.75385 10.8333 5.83337V2.50004H4.16667V17.5H15.8333V7.50004ZM4.16667 0.833374H12.8452L17.5 5.4882V17.5C17.5 18.4205 16.7538 19.1667 15.8333 19.1667H4.16667C3.24619 19.1667 2.5 18.4205 2.5 17.5V2.50004C2.5 1.57957 3.24619 0.833374 4.16667 0.833374ZM6.66667 14.1667V12.5H11.6667V14.1667H6.66667ZM6.66667 10.8334V9.16671H13.3333V10.8334H6.66667Z"
        fill="#FBFAFF"
      />
    </svg>
  );
}

export default DocumentIcon;

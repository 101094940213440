import React from "react";

function CalendarIcon({ fill = "black", style, className }) {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6V3.75H13.5V4.5H12V3.75H6V4.5H4.5V3.75H3V6H15ZM15 7.5H3V15H15V7.5ZM13.5 2.25H15C15.8284 2.25 16.5 2.92157 16.5 3.75V15C16.5 15.8284 15.8284 16.5 15 16.5H3C2.17157 16.5 1.5 15.8284 1.5 15V3.75C1.5 2.92157 2.17157 2.25 3 2.25H4.5V1.5H6V2.25H12V1.5H13.5V2.25ZM6.75 10.5H5.25V9H6.75V10.5ZM9.75 10.5H8.25V9H9.75V10.5ZM12.75 10.5H11.25V9H12.75V10.5ZM6.75 13.5H5.25V12H6.75V13.5ZM9.75 13.5H8.25V12H9.75V13.5Z"
        fill={fill}
      />
    </svg>
  );
}

export default CalendarIcon;

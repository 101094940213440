import React, { useState, useEffect } from "react";
import { IconButton, Skeleton, Stack } from "@mui/material";
import moment from "moment";
import { addDays } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

import LeaveTypeCard from "../../ManageTimeoff/LeaveTypeCard";
import EditLeavesTaken from "../EditLeavesTaken";
import {
  getEmployeeLeavesDataForAdmin,
  getEmployeesLeaveAndHolidays,
} from "../TimeOffAdminServices/EmployeeTimeOffDetailsService";
import AddSwappedIcon from "./Icons/AddSwappedIcon";
import AddTimeOffRequest from "./Icons/AddTimeOffRequest";
import AddSwappedLeaves from "../AddSwappedLeaves";
import LeavesValidityDates from "./LeavesValidityDates";

import { GetAllHolidays } from "store/actions/Holidays";
import CalendarIcon from "./Icons/CalendarIcon";

import { usePermissions } from "components/Hook";
import { baseUrl } from "util/APIBaseUrl";

import "components/Screens/Timeoff/ManageTimeoff/ManageTimeoff.css";

const cardStyles = {
  background:
    "var(--Gradient-light, linear-gradient(221deg, #FFEFD3 2.69%, var(--color-primitives-primary-100) 41.39%, #B4FFF2 121.42%))",
  borderRadius: "12px",
  gap: "62px",
  width: "11rem",
  padding: "12px 19px",
  display: "flex",
  height: "auto",
};

const leaveCardSkeltonStyle = {
  backgroundColor: "rgb(189 192 255 / 30%)",
  borderRadius: "12px",
  width: "176px",
  height: "81px",
};

const leaveYearSkeltonStyle = {
  backgroundColor: "rgb(189 192 255 / 30%)",
  borderRadius: "26px",
  width: "62px",
  height: "28px",
};

function EmployeeTakenLeaves(props) {
  const { setLeaveTakenSuccessfully, leaveTakenSuccessfully } = props;

  const { loggedInUserType } = usePermissions();
  const dispatch = useDispatch();

  // loading states
  const [employeeLeavesDataLoading, setEmployeeLeavesDataLoading] = useState(false);

  const [openRequestLeave, setOpenRequestLeave] = useState(false);
  const [openSwappedLeaves, setOpenSwappedLeaves] = useState(false);
  const [employeeLeavesData, setEmployeeLeavesData] = useState([]);
  const [allLeavesAndHolidaysData, setAllLeavesAndHolidaysData] = useState();
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);

  const renderLeaveTypeCards = () => {
    const cards = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < employeeLeavesData?.length; i++) {
      if (employeeLeavesData[i]?.visible || employeeLeavesData[i]?.leaveType === "Swapped") {
        cards.push(
          <LeaveTypeCard
            key={i}
            portal="admin"
            style={cardStyles}
            leave={employeeLeavesData[i]}
            icon={
              <IconButton
                onClick={() => setOpenSwappedLeaves(true)}
                sx={{ "&.MuiIconButton-root": { padding: "0px" } }}
              >
                <AddSwappedIcon />
              </IconButton>
            }
          />
        );
      }
    }

    return (
      <div className="leave-type-cards-container" style={{ alignItems: "center" }}>
        {cards}

        <div
          className="edit-leave-type-taken"
          style={{ alignItems: "center" }}
          onClick={() => setOpenRequestLeave(true)}
        >
          <div className="add-timeoff-req">Add time-off request</div>
          <IconButton sx={{ ml: "20px", padding: "0px" }}>
            <AddTimeOffRequest />
          </IconButton>
        </div>
      </div>
    );
  };

  const fetchEmployeeLeavesDataForAdmin = async () => {
    setEmployeeLeavesDataLoading(true);
    try {
      const res = await getEmployeeLeavesDataForAdmin(getEmployeeDetails?.payload?.employeeId);
      setEmployeeLeavesDataLoading(false);
      setEmployeeLeavesData(res?.data);
    } catch (error) {
      setEmployeeLeavesDataLoading(false);
    }
  };

  const fetchEmployeeLeavesAndHolidaysData = async () => {
    try {
      const startDate = moment().startOf("year").format("YYYY-MM-DD");
      const endDate = moment().endOf("year").format("YYYY-MM-DD");

      const res = await getEmployeesLeaveAndHolidays(getEmployeeDetails?.payload?.employeeId, startDate, endDate);
      setAllLeavesAndHolidaysData(res?.result);
    } catch (error) {
      console.log(error);
    }
  };

  const onSuccessLeaveTaken = () => {
    setLeaveTakenSuccessfully(!leaveTakenSuccessfully);
    fetchEmployeeLeavesDataForAdmin();
    if (loggedInUserType == "edge" && getEmployeeDetails?.payload?.employeeId) {
      dispatch(
        GetAllHolidays({
          request: `${baseUrl}/employee/${getEmployeeDetails?.payload?.employeeId}/leave_holidays`,
          region: getEmployeeDetails?.payload?.country,
          startDate: loggedInUserType == "edge" ? moment().startOf("year").format("YYYY-MM-DD") : new Date(),
          endDate: loggedInUserType == "edge" ? moment().endOf("year").format("YYYY-MM-DD") : addDays(new Date(), 365),
        })
      );
    }
  };

  useEffect(() => {
    if (getEmployeeDetails?.payload?.employeeId) {
      fetchEmployeeLeavesDataForAdmin();
      fetchEmployeeLeavesAndHolidaysData();
    }
  }, [getEmployeeDetails]);

  return (
    <Stack
      spacing="12px"
      sx={{
        mt: "-1px",
        border: "1px solid var(--color-primitives-primary-50)",
        background: "var(--primary-99, #FBFAFF)",
        padding: "28px 60px",
      }}
    >
      <div style={{ maxWidth: "max-content" }}>
        <Stack direction="row" spacing="12px" alignItems="center" justifyContent="space-between" sx={{ mb: "5px" }}>
          <Stack direction="row" spacing="12px" alignItems="start">
            <CalendarIcon />
            <div className="timeoff-subheading">Leaves taken</div>
            {employeeLeavesDataLoading ? (
              // {true ? (
              <Skeleton variant="rounded" sx={{ ...leaveYearSkeltonStyle }} />
            ) : (
              <div className="timeoff-year"> {employeeLeavesData?.[0]?.year || "-"}</div>
            )}
          </Stack>
          {employeeLeavesData?.length && employeeLeavesData?.[0]?.startDate && employeeLeavesData?.[0]?.endDate && (
            <LeavesValidityDates
              startDate={employeeLeavesData?.[0]?.startDate}
              endDate={employeeLeavesData?.[0]?.endDate}
            />
          )}
        </Stack>

        {employeeLeavesDataLoading ? (
          // {true ? (
          <Stack direction="row" gap="1rem">
            <Skeleton variant="rounded" sx={{ ...leaveCardSkeltonStyle }} />

            <Skeleton variant="rounded" sx={{ ...leaveCardSkeltonStyle }} />

            <Skeleton variant="rounded" sx={{ ...leaveCardSkeltonStyle }} />
          </Stack>
        ) : (
          renderLeaveTypeCards()
        )}
      </div>
      {openSwappedLeaves && (
        <AddSwappedLeaves
          open={openSwappedLeaves}
          closeModal={() => setOpenSwappedLeaves(false)}
          employeeLeavesData={employeeLeavesData}
          onSuccess={onSuccessLeaveTaken}
        />
      )}

      {openRequestLeave && (
        <EditLeavesTaken
          open={openRequestLeave}
          closeModal={() => setOpenRequestLeave(false)}
          employeeLeavesData={employeeLeavesData}
          onSuccess={onSuccessLeaveTaken}
          allLeavesAndHolidaysData={allLeavesAndHolidaysData}
        />
      )}
    </Stack>
  );
}

export default EmployeeTakenLeaves;

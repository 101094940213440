import React from "react";

import Dialog, { DialogTitle, DialogContent, DialogActions } from "components/common/Dialog";
import Button from "components/common/Button";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import "./HelpSuccessModal.scss";

function HelpSuccessModal({ open, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={
        <DialogTitle
          title={
            <div className="help-success-modal-title">
              <div className="help-success-modal-icon">
                <CheckCircleOutlineIcon />
              </div>
              <p className="help-success-modal-heading">Your message is on its way!</p>
            </div>
          }
        />
      }
      content={
        <DialogContent>
          <div className="success-modal-content">
            <p className="para-body-m-regular color-text-body-primary">
              We've received your message. Our team will get back to you soon through email. Thanks for your patience!
            </p>
          </div>
        </DialogContent>
      }
      actions={
        <DialogActions
          action2={<Button onClick={handleClose} className="text-blue-600" label="Continue" size="large" />}
        />
      }
      size="xs"
    />
  );
}

export default HelpSuccessModal;

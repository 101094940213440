import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";

import { getValue } from "components/Screens/CommonUtils";
import HistoryLeaves from "components/Screens/Timeoff/ManageTimeoff/HistoryLeaves";

import backButtonIcon from "assets/images/left-arrow.svg";

import { FetchLeaveRequests } from "store/actions/Timeoff/LeaveRequests";

import "./Details.css";

function LeaveDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const getEmployeeDetails = useSelector((s) => s.GetEmployeeDetails.getEmployeeDetails);

  useEffect(() => {
    dispatch(FetchLeaveRequests({}));
  }, []);

  return (
    <Grid container direction="column" rowSpacing={3} sx={{ backgroundColor: "#FFF" }}>
      <Grid
        container
        rowSpacing={3}
        sx={{
          marginTop: "29px",
          borderBottom: "1px solid var(--color-primitives-primary-100)",
        }}
      >
        <Grid
          sx={{
            maxWidth: "1076px",
            width: "100%",
            margin: "0px auto",
            padding: "12px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Breadcrumbs sx={{ fontSize: "12px" }}>
              <Link href="/main/dashboard" className="holidays-breadcrumbs-link">
                Home
              </Link>
              <Link href="/main/workforce" className="holidays-breadcrumbs-link" aria-current="page">
                Workforce
              </Link>
              <Link className="holidays-breadcrumbs-link" aria-current="page">
                Profile
              </Link>
            </Breadcrumbs>

            {/* switch title for edit salary page and for others tabs */}
            <div className="flex items-center">
              <img src={backButtonIcon} onClick={() => navigate(-1)} alt="back button" style={{ cursor: "pointer" }} />
              <Typography className="holidays-main-header-title">
                {`${getValue(getEmployeeDetails?.payload?.firstName)} ${getValue(
                  getEmployeeDetails?.payload?.lastName
                )}`}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>

      {/* show edit salary ui */}
      <Grid container sx={{ marginTop: "16px" }}>
        <Grid
          sx={{
            maxWidth: "1076px",
            width: "100%",
            margin: "0px auto",
            display: "flex",
            gap: "16px",
          }}
        >
          <HistoryLeaves data={state} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LeaveDetails;

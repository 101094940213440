import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  Box,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu, { MenuItem } from "components/common/Menu";

import "./TableComponent.scss";

function TableComponent({
  columns,
  data,
  onSort,
  onRowClick,
  onPageChange,
  onRowsPerPageChange,
  clientSidePagination = false,
  totalCount,
  rowsPerPageOptions = [10, 25, 50],
  initialRowsPerPage = 10,
  tableContainerProps,
  tableProps,
  onAction,
  tableHeadSmall,
  tableCellSmall,
  showHeader = true,
  noCellPadding = false,
  noBorderForSingleRow = false,
}) {
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (onSort) {
      onSort(property, isAsc ? "desc" : "asc");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (onRowsPerPageChange) {
      onRowsPerPageChange(newRowsPerPage);
    }
  };

  const handleMenuOpen = (event, row) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleAction = (action) => {
    if (onAction && selectedRow) {
      onAction(action, selectedRow);
    }
    handleMenuClose();
  };

  const getData = () => {
    if (!clientSidePagination) {
      return data;
    }
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  const isShowPaginationButtons = () => {
    if (clientSidePagination) {
      if (data.length < initialRowsPerPage) {
        return false;
      }
      return true;
    }
    return totalCount > initialRowsPerPage;
  };

  const renderCellContent = (column, row) => {
    const value = row[column.id];
    let content;
    if (column.render) {
      content = column.render(value, row);
    } else if (column.type === "action") {
      content = (
        <Box className="mr-075">
          {column.actions?.map((action) => (
            <IconButton
              size="small"
              className={`${
                typeof action.disabled === "function" && action.disabled(row)
                  ? "action-icon-color-disabled"
                  : "action-icon-color"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                onAction(action, row);
              }}
              disabled={typeof action.disabled === "function" ? action.disabled(row) : action.disabled}
            >
              {action.icon}
            </IconButton>
          ))}
          {column.moreActions && (
            <IconButton size="small" onClick={(e) => handleMenuOpen(e, row)}>
              <MoreVertIcon className="three-dots" />
            </IconButton>
          )}
        </Box>
      );
    } else {
      content = value;
    }

    return <div className="table-cell-content">{content}</div>;
  };

  return (
    <Paper className="table-component">
      <TableContainer {...tableContainerProps}>
        <Table {...tableProps}>
          {showHeader && (
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align || "left"}
                    style={{ minWidth: column.minWidth, ...column.headStyle }}
                    sortDirection={orderBy === column.id ? order : false}
                    className={` ${tableHeadSmall ? "table-head-small" : "table-head-regular"}`}
                  >
                    {column.sortable !== false ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        style={{
                          flexDirection: ({ header }) => {
                            return header?.alignContent && header?.alignContent === "right" ? "row-reverse" : "row";
                          },
                        }}
                        onClick={() => handleRequestSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {getData().map((row, index) => {
              const isLastRow = index === data.length - 1;
              const shouldRemoveBorder = noBorderForSingleRow && (data.length === 1 || (data.length < 10 && isLastRow));
              return (
                <TableRow
                  hover
                  onClick={() => onRowClick && onRowClick(row)}
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id || index}
                  className={`table-body-row ${shouldRemoveBorder ? "no-bottom-border" : ""}`}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={column.cellStyle}
                      className={`${tableCellSmall ? "table-cell-small" : "table-cell-regular"} ${noCellPadding ? "no-cell-padding" : ""}`}
                    >
                      {renderCellContent(column, row)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isShowPaginationButtons() && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={clientSidePagination ? data.length : totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="table-component-pagination"
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          className: "action-menu",
        }}
      >
        {columns
          .find((col) => col.type === "action")
          ?.moreActions?.map((action) =>
            action.label === "divider" ? (
              <hr key={`divider-${action.label}`} className="menu-divider" />
            ) : (
              <MenuItem
                key={action.label}
                value={action.label}
                onClick={() => handleAction(action)}
                className={` ${action.variant === "error" ? "menu-item-error" : ""}`}
              />
            )
          )}
      </Menu>
    </Paper>
  );
}

export default TableComponent;

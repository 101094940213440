import { useEffect, useState } from "react";
import { Button, RadioGroup, FormControl, Radio, FormControlLabel, Typography, Stack } from "@mui/material";

import TextFieldV2 from "components/common/TextFieldV2/TextFieldV2";
import { convertDate } from "components/Screens/CommonUtils";

import LeftArrow from "assets/images/left-arrow.svg";

import "./leaveReplacement.css";

const MAX_CHARACTER_LIMIT = 300;

function LeaveReplacement({
  closeModal,
  employeeDetails,
  onDataReceived,
  fromEmail,
  setShowReplacementSuccessModal = () => {},
}) {
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const token = parameters.get("token");
  let employeeDetailsFromToken;

  if (token) employeeDetailsFromToken = JSON.parse(atob(token, "base64"));

  const [noteValue, setNoteValue] = useState("");
  const [action, setAction] = useState("new");
  const [replacementName, setReplacementName] = useState("");
  const [isCharacterLimitExceeded, setIsCharacterLimitExceeded] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      tasks: noteValue,
      credentials: action,
      replacementRequired: true,
      reportsTo: replacementName,
    };
    onDataReceived(formData);
    setShowReplacementSuccessModal(true);
    closeModal();
  };

  const handleChange = (event) => {
    setAction(event.target.value);
  };

  useEffect(() => {
    setIsCharacterLimitExceeded(noteValue.length > MAX_CHARACTER_LIMIT);
  }, [noteValue]);

  return (
    <div>
      <form style={{ maxHeight: "100vh", overflowY: "scroll" }} onSubmit={handleSubmit}>
        <p className="main-title">Request for replacement</p>
        <ul className="list-item">
          <li>
            Request replacement coverage for your current Edge associates that are out of the office for 10 days or
            longer.
          </li>
          <li>
            Note: Your replacement associate will assist with covering the top three tasks that your original associate
            was hired to do.
          </li>
          <li>This request is subject to availability and may be approved or denied.</li>
        </ul>
        <div>
          <p className="sub-title">Requesting replacement for:</p>
          <p className="employee-name py-05">
            {fromEmail ? employeeDetailsFromToken?.employeeName : employeeDetails?.employeeName}
          </p>
        </div>
        <div className="pending-leave-duration ">
          <div className="pending-leave-date" id="start-date">
            {convertDate(fromEmail ? employeeDetailsFromToken?.dateFrom : employeeDetails?.dateFrom)}
          </div>
          <img className="pending-leave-arrow" src={LeftArrow} alt="left arrow" />
          <div className="pending-leave-date" id="end-date">
            {convertDate(fromEmail ? employeeDetailsFromToken?.dateTo : employeeDetails?.dateTo)}
          </div>
        </div>
        <div>
          <p className="report-title pt-1">Who should your replacement report to?</p>
          <TextFieldV2
            margin="normal"
            fullWidth
            height="48px"
            label=""
            name="name"
            variant="outlined"
            value={replacementName}
            onChange={(e) => setReplacementName(e.target.value)}
          />
        </div>
        <div>
          <p className="report-title pt-1">Will the replacement be using new or existing credentials?</p>
          <div className="flex items-center justify-start">
            <FormControl>
              <RadioGroup
                className={`rounded-lg px-1 mt-05 mr-025 ${action === "new" ? "border-blue" : "border"}`}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={action}
                onChange={handleChange}
              >
                <FormControlLabel value="New" control={<Radio />} label={<Typography variant="info">New</Typography>} />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <RadioGroup
                className={`rounded-lg px-1 mt-05 ml-025 ${action === "existing" ? "border-blue" : "border"}`}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={action}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Existing"
                  control={<Radio />}
                  label={<Typography variant="info">Existing</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div>
          <p className="report-title pt-1">What tasks will the replacement cover?</p>
          <textarea
            className="body-md replacement-textarea w-full"
            placeholder=""
            value={noteValue}
            onChange={(e) => setNoteValue(e.target.value)}
          />
          <p className="body-sm neutral-50">{MAX_CHARACTER_LIMIT - noteValue.length} characters left</p>
        </div>
        <p className="label-lg neutral-50 py-1">
          Note: By submitting request for replacement, you have automatically accepted the leave request
        </p>
        <Stack direction="row" spacing={1}>
          {!fromEmail && (
            <Button
              variant="text"
              sx={{
                borderRadius: "99px",
                height: "36px",
                padding: "9px, 24px, 9px, 24px",
                fontFamily: "Poppins !important",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                width: "90px",
                border: "1px solid gray",
              }}
              onClick={() => closeModal()}
            >
              <Typography variant="h5" className="save-buttontext" color="var(--color-primitives-primary-600)">
                Cancel
              </Typography>
            </Button>
          )}
          <Button
            type="submit"
            size="md"
            variant="contained"
            disabled={!noteValue || !action || !replacementName || isCharacterLimitExceeded}
            sx={{
              backgroundColor: "var(--temp-color-primitives-primary-800)",
              height: "36px",
              width: "99px",
              padding: "9px, 24px, 9px, 24px",
              fontFamily: "Poppins !important",
              fontWeight: "500",
              lineHeight: "20px",
              borderRadius: "99px",
              letterSpacing: "0.1px",
              fontSize: "14px",
              color: "white",
              boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
            }}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </div>
  );
}

export default LeaveReplacement;

import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Alert,
  Button,
  Grid,
  Paper,
  Snackbar,
  Typography,
  List,
  ListItem,
  Avatar,
  FormControl,
  Badge,
  IconButton,
  Stack,
  Autocomplete,
} from "@mui/material";
import { Box } from "@mui/system";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { getValue, isEmptyObject, isValidResponse } from "components/Screens/CommonUtils";
import TextFieldV2 from "components/common/TextFieldV2/TextFieldV2";
import { usePermissions } from "components/Hook";
import ImageCropperModal from "components/common/ImageCropperModal";
import PageLoader from "components/common/PageLoader";

import { baseUrl } from "util/APIBaseUrl";
import EditIcon from "assets/svgs/EditIcon";

import { DropdownData } from "store/actions/DropdownData";
import { AddUser, ResetAddUser } from "store/actions/Settings/AddUser";
import { GetUserDetails, ResetGetUserDetails } from "store/actions/Settings/GetUserDetails";
import { AddPermissions, ResetAddPermissions } from "store/actions/Permissions/AddPermissions";

const user = {
  userName: "",
  firstName: "",
  lastName: "",
  email: "",
  contactNumber: "",
  password: "",
  phoneNumber: "",
  imageBlob: "",
  permissions: { title: null, description: null, value: null },
  country: { key: null, value: "None" },
  loadFromUrl: false,
};

function CustomPopperComponent({ children, ...props }) {
  return (
    <Box
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 1px 1px rgba(2, 0, 110, 0.22), 0px 1px 6px rgba(2, 0, 110, 0.25)",
        borderRadius: "12px ",
      }}
    >
      <Paper
        {...props}
        square
        style={{
          marginTop: 4,
          zIndex: 999,
        }}
      >
        {children}
      </Paper>
    </Box>
  );
}

export default function AddUserForm(props) {
  const { navigatedFor, paramId, setAddorEditUser } = props;
  const dispatch = useDispatch();
  const photoInput = React.createRef();

  const [userData, setUserData] = React.useState(user);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [addUserFlag, setAddUserFlag] = React.useState(false);
  const [dropdownData_, setDropdownData_] = React.useState({});
  const [permDropdownOption, setPermDropdownOption] = React.useState([]);
  const [showImageCropperModal, setShowImageCropperModal] = React.useState(false);
  const [imageSrc, setImageSrc] = React.useState({});
  const [isFileUploadError, setIsFileUploadError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const addPermissions = useSelector((state) => state.AddPermissions.addPermissions);
  const getUserDetails = useSelector((state) => state.GetUserDetails.getUserDetails);
  const addUser = useSelector((state) => state.AddUser.addUser);

  const { loggedInUserType, dropdownData } = usePermissions();

  let actionMode = useMemo(
    () => ({
      editUser: {
        customer: {
          logoRequest: `customerUser/${paramId}/logo?id=${getUserDetails?.payload?.pictureVersion}`,
          permissionRequest: `customerUser/${paramId}/addUserPermission`,
        },
        edge: {
          logoRequest: `user/${paramId}/logo`,
          permissionRequest: `user/${paramId}/addUserPermission`,
        },
      },

      addUser: {
        customer: {
          logoRequest: `customerUser/${addUser?.payload?.userId}/logo`,
          permissionRequest: `customerUser/${addUser?.payload?.userId}/addUserPermission`,
        },
        edge: {
          logoRequest: `user/${addUser?.payload?.userId}/logo`,
          permissionRequest: `user/${addUser?.payload?.userId}/addUserPermission`,
        },
      },
    }),
    [getUserDetails, addUser, paramId]
  );

  // functions
  const postLogo = async (actions) => {
    const response = await fetch(`${baseUrl}/${actions.request}`, {
      method: "POST",
      headers: {},
      body: actions.formData,
    });
    if (!response.ok) {
      return;
    }
    await response.json();
  };

  const uploadProfilePic = () => {
    photoInput.current.click();
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleUserData = (event, stateName, fieldType) => {
    if (fieldType == "number") {
      const onlyNums = event.target.value.replace(/[^0-9+]/g, "");

      setUserData({
        ...userData,
        [event.target.name]: onlyNums,
      });
    } else {
      setUserData({
        ...userData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const callbackFromMultiSelect = (option, key) => {
    userData[key] = option || { key: null, value: null };
    setUserData({
      ...userData,
    });
  };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          setUserData({
            ...userData,
            picFile: file,
            imageBlob: reader.result.split(",")[1],
            formData: new FormData(),
            loadFromUrl: false,
          });
          resolve(reader.result);
        },
        false
      );
      reader.readAsDataURL(file);
    });
  }

  const handleImageChange = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    e.target.value = "";

    if (file.size / 1024 / 1024 > 2) setIsFileUploadError(true);
    else setIsFileUploadError(false);

    const imageDataUrl = await readFile(file);
    setImageSrc(imageDataUrl);

    setUserData({
      ...userData,
      picFile: file,
      formData: new FormData(),
      loadFromUrl: false,
    });

    setShowImageCropperModal(true);
  };

  const handleCreateUser = async () => {
    setIsLoading(true);

    let userAdditionalInfo = null;
    userAdditionalInfo = JSON.parse(sessionStorage.getItem("userAdditionalInfo"));

    if (userData.email != "" && userData.firstName != "" && userData.permissions.value) {
      actionMode = {
        editUser: {
          customer: `customerUser/${paramId}`,
          edge: `user/${paramId}`,
          method: "PATCH",
        },

        addUser: {
          customer: "customerUsers/",
          edge: "users",
          method: "POST",
        },
      };

      await dispatch(
        AddUser({
          request: actionMode[navigatedFor][loggedInUserType],
          userData,
          customerId: userAdditionalInfo?.customerId,
          method: actionMode[navigatedFor]?.method,
        })
      );
    } else {
      setOpenAlert(true);
      setIsLoading(false);
    }
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              <Typography variant="h6" color="white" fontSize="12px">
                {(() => {
                  if (error?.msg) return error.msg;
                  if (error?.description) return error.description;
                  return "";
                })()}{" "}
                ${error?.param ? error?.param : ""}`
              </Typography>
            </ListItem>
          </List>
        );
      });
    }
    return null;
  };

  const checkError = (field, view) => {
    if (addUserFlag) {
      if (view != "textField") {
        if (userData[field]?.value) {
          return false;
        }
        return true;
      }
      if (userData[field] != "") {
        return false;
      }
      return true;
    }
    return false;
  };

  const getUserPermission = () => {
    const permission = {
      customer: getUserDetails?.payload?.permissions.includes("CustomerAdmin") ? "CustomerAdmin" : "CustomerEmployee",
      edge: getUserDetails?.payload?.permissions.includes("EdgeAdmin") ? "EdgeAdmin" : "EdgeEmployee",
    };

    return permission[loggedInUserType];
  };

  const handleSave = (param) => {
    const formData = new FormData();
    formData?.append("file", param);
    setShowImageCropperModal(false);
    postLogo({
      request: actionMode[navigatedFor][loggedInUserType].logoRequest,
      formData,
    });
  };

  React.useEffect(() => {
    if (isValidResponse(addUser)) {
      dispatch(
        AddPermissions({
          request: actionMode[navigatedFor][loggedInUserType].permissionRequest,
          permissionName: [userData?.permissions?.value],
        })
      );
      if (userData?.formData && !userData?.loadFromUrl) {
        postLogo({
          request: actionMode[navigatedFor][loggedInUserType].logoRequest,
          formData: userData?.formData,
        });
      }
    } else if (Object.keys(addUser).length && addUser?.ok == false) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  }, [addUser]);

  React.useEffect(() => {
    if (isValidResponse(addPermissions)) {
      setAddorEditUser({
        open: false,
        mode: null,
      });
      dispatch(ResetGetUserDetails({}));
      dispatch(ResetAddUser({}));
      dispatch(ResetAddPermissions({}));
    } else if (Object.keys(addPermissions).length && addPermissions?.ok == false) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  }, [addPermissions]);

  React.useEffect(() => {
    let countrySelected = [];
    let selectedPermission = [];
    if (navigatedFor && navigatedFor == "editUser" && getUserDetails?.ok) {
      const req = {
        customer: `${baseUrl}/customerUser/${paramId}/logo?id=${getUserDetails?.payload?.pictureVersion}`,
        edge: `${baseUrl}/user/${paramId}/logo?id=${getUserDetails?.payload?.pictureVersion}`,
      };

      countrySelected = dropdownData?.payload?.country.filter(
        (country) => country?.countryCode == getUserDetails?.payload?.country
      );

      selectedPermission = permDropdownOption.find((permission) => permission?.value == getUserPermission());

      setUserData({
        ...userData,
        userName: `${getValue(getUserDetails?.payload?.firstName)} ${getValue(getUserDetails?.payload?.lastName)}`,
        firstName: getValue(getUserDetails?.payload?.firstName),
        lastName: getValue(getUserDetails?.payload?.lastName),
        email: getValue(getUserDetails?.payload?.email),
        password: "",
        imageBlob: req[loggedInUserType],
        loadFromUrl: true,
        permissions: selectedPermission,
        phoneNumber: getValue(getUserDetails?.payload?.phoneNumber),
        country: {
          key: countrySelected[0]?.countryCode,
          value: countrySelected[0]?.countryCode,
        },
      });
    }
  }, [getUserDetails]);

  React.useEffect(() => {
    let loggedInUser = {};
    let permissionsOption = [];

    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    setOpenAlert(false);
    dispatch(DropdownData());

    if (isEmptyObject(loggedInUser)) {
      permissionsOption = [
        {
          title: (() => {
            if (loggedInUser?.type == "customer") return "Customer Admin";
            if (loggedInUser?.type == "edge") return "Edge Admin";
            return null;
          })(),
          description: "User can add new Users, Edit Company & Bank Details",
          value: (() => {
            if (loggedInUser?.type == "customer") return "CustomerAdmin";
            if (loggedInUser?.type == "edge") return "EdgeAdmin";
            return null;
          })(),
        },
        {
          title: (() => {
            if (loggedInUser?.type == "customer") return "Customer Employee";
            if (loggedInUser?.type == "edge") return "Edge Employee";
            return null;
          })(),
          description: "Limited Access User",
          value: (() => {
            if (loggedInUser?.type == "customer") return "CustomerEmployee";
            if (loggedInUser?.type == "edge") return "EdgeEmployee";
            return null;
          })(),
        },
      ];

      setPermDropdownOption(permissionsOption);
    }

    if (isEmptyObject(loggedInUser) && navigatedFor == "editUser" && paramId) {
      const userDetailsReuqest = {
        customer: `customerUser/${paramId}`,
        edge: `user/${paramId}`,
      };

      dispatch(
        GetUserDetails({
          request: userDetailsReuqest[loggedInUserType],
        })
      );
    }

    if (!Object.keys(dropdownData_).length) {
      const countries = [];
      const edgePermissions = [];
      const customerPermissions = [];
      let Permissions = [];

      if (isValidResponse(dropdownData)) {
        dropdownData?.payload?.country?.map((data) => {
          return countries.push({
            key: data.countryCode2,
            value: data.countryCode,
          });
        });
        dropdownData?.payload?.edgePermission?.map((permission) => {
          return edgePermissions.push({
            key: permission.description,
            value: permission.permissionName,
            valueDescription: permission.permissionName == "EdgeAdmin" ? "Admin" : "Employee",
          });
        });

        dropdownData?.payload?.customerPermission?.map((permission) => {
          return {
            key: permission.description,
            value: permission.permissionName,
            valueDescription: permission.permissionName == "CustomerAdmin" ? "Admin" : "Employee",
          };
        });

        if (loggedInUser?.type == "customer") {
          Permissions = customerPermissions;
        } else if (loggedInUser?.type == "edge") {
          Permissions = edgePermissions;
        }

        setDropdownData_({
          ...dropdownData_,
          countries,
          Permissions,
        });
      }
    }
  }, []);

  return (
    <>
      {isLoading && <PageLoader />}
      <ImageCropperModal
        open={showImageCropperModal}
        onClose={() => setShowImageCropperModal(false)}
        imageSrc={imageSrc}
        onSave={handleSave}
        isFileUploadError={isFileUploadError}
        errorMessage="The file you've selected is too large. Please upload an image file file that is 2MB or smaller."
      />
      <Grid container direction="row" sx={{ padding: "16px" }} rowSpacing={4} mb={4}>
        <Grid item>
          <Stack direction="row">
            <IconButton
              onClick={() =>
                setAddorEditUser({
                  open: false,
                  mode: null,
                })
              }
            >
              <ArrowBackIosNewIcon sx={{ color: "var(--color-primitives-primary-900)", fontSize: "12px" }} />
            </IconButton>
            <Typography
              sx={{
                color: "#14151F",
                fontFamily: "Poppins-Medium !important",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "32px",
              }}
            >
              Add a User
            </Typography>
          </Stack>
        </Grid>

        <Grid item container direction="row" spacing={4}>
          <Grid item>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={photoInput}
              style={{ display: "none" }}
            />
            <Badge
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              variant="standard"
              badgeContent={
                <EditIcon
                  color="var(--temp-color-primitives-primary-800)"
                  style={{
                    width: "28px",
                    height: "28px",
                  }}
                />
              }
              overlap="rectangular"
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "#F7F7F7",
                  minWidth: "40px",
                  height: "40px",
                  borderRadius: "25px",
                  boxShadow: "0px 4px 10px 0px var(--color-primitives-primary-900)",
                  position: "absolute",
                  ml: 4,
                  // top: "120px",
                  // right: "30px",
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                uploadProfilePic();
              }}
            >
              <Avatar
                sx={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "12px",
                }}
                variant="square"
                // src={
                //     "https://www.qa2.edgeonline.co/api/customerUser/Bni7YPhqM4/logo?id=St0QHZ1a8"
                // }
                src={userData?.loadFromUrl ? userData.imageBlob : `data:image/png;base64,${userData.imageBlob}`}
              />
            </Badge>
          </Grid>
          <Grid item container direction="row" xs={7} spacing={2}>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth error>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #B2B4E2",
                    borderRadius: "12px",
                  }}
                >
                  <TextFieldV2
                    error={checkError("firstName", "textField")}
                    value={userData?.firstName}
                    onChange={(e) => handleUserData(e, "firstName", "text")}
                    label="First Name"
                    variant="filled"
                    type="text"
                    name="firstName"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth error>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #B2B4E2",
                    borderRadius: "12px",
                  }}
                >
                  <TextFieldV2
                    // error={checkError("lastName", "textField")}
                    value={userData?.lastName}
                    onChange={(e) => handleUserData(e, "lastName", "text")}
                    label="Last Name"
                    variant="filled"
                    type="text"
                    name="lastName"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth error>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #B2B4E2",
                    borderRadius: "12px",
                  }}
                >
                  <TextFieldV2
                    error={checkError("email", "textField")}
                    value={userData?.email}
                    label="Email Address"
                    onChange={(e) => handleUserData(e, "email", "text")}
                    type="text"
                    variant="filled"
                    name="email"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth error>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #B2B4E2",
                    borderRadius: "12px",
                  }}
                >
                  <TextFieldV2
                    value={userData?.phoneNumber}
                    onChange={(e) => handleUserData(e, "phoneNumber", "number")}
                    type="number"
                    label="Phone Number"
                    variant="filled"
                    name="phoneNumber"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth error>
                <Autocomplete
                  // open={open}
                  // onOpen={() => setOpen(true)}
                  // disablePortal
                  id="free-solo-demo"
                  value={userData?.permissions}
                  freeSolo
                  sx={{
                    "& label + .MuiInput-formControl": {
                      marginTop: "1vw",
                    },
                  }}
                  onChange={(event, option) => {
                    callbackFromMultiSelect(option, "permissions");
                  }}
                  PaperComponent={CustomPopperComponent}
                  options={permDropdownOption.map((option) => option)}
                  getOptionLabel={(option) => option.title || ""}
                  renderOption={(prop, option) => {
                    return (
                      <>
                        <li {...prop}>
                          <Box
                            sx={{
                              display: "flex",
                              flex: 1,
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              sx={{
                                mb: 0.5,
                                fontFamily: "Poppins-Regular",
                                fontWeight: 450,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                                color: "#292A3D",
                              }}
                            >
                              {option.title}
                            </Typography>
                            <Typography
                              sx={{
                                mb: 1,
                                fontFamily: "Poppins-Regular",
                                fontWeight: 350,
                                fontSize: "12px",
                                lineHeight: "16px",
                                letterSpacing: "0.4px",
                                color: "#7C7EB6",
                              }}
                            >
                              {option.description}
                            </Typography>
                          </Box>
                        </li>
                        {/* {option.title !== "Employee" && (
                        <Divider
                          sx={{
                            background: "#B2B4E2",
                          }}
                        />
                      )} */}
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #B2B4E2",
                        borderRadius: "12px",
                      }}
                    >
                      <TextFieldV2
                        error={checkError("permissions", "dropdown")}
                        {...params}
                        label="Select Permissions"
                        variant="filled"
                        type="text"
                        name="permissions"
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Box>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          columnSpacing={2}
          rowSpacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Snackbar
              open={openAlert}
              autoHideDuration={2500}
              onClose={handleAlertClose}
              sx={{
                "&.MuiSnackbar-root": {
                  position: "relative",
                  bottom: 0,
                  left: 0,
                },
              }}
            >
              <Alert
                // open={openAlert}
                // autoHideDuration={3500}
                variant="filled"
                icon={false}
                sx={{
                  backgroundColor: "#313033",
                  padding: "initial",
                  px: "15px",
                  py: "8px",
                  fontSize: "14px",
                  textAlign: "left",
                  minWidth: "347px",

                  borderRadius: "12px !important",
                  color: "#ffffff !important",
                  "&.MuiPaper-root": {
                    color: "#ffffff !important",
                    boxShadow: "0px 4px 8px rgba(2, 0, 110, 0.18)",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular !important",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {addUser?.ok == false
                    ? getError(addUser?.payload?.error)
                    : //   : googleSignupInfo?.ok == false
                      //   ? getError(googleSignupInfo?.payload?.error)
                      "Enter all information before proceeding"}
                </Typography>
              </Alert>
            </Snackbar>
          </Grid>
          <Grid item>
            <Button
              // variant="contained"
              // fullWidth={true}
              variant="text"
              sx={{
                borderRadius: "99px",
                width: "99px",
                height: "40px",
              }}
              onClick={() =>
                setAddorEditUser({
                  open: false,
                  mode: null,
                })
              }
            >
              <Typography variant="h5" color="var(--color-primitives-primary-600)">
                Cancel
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disableElevation
              sx={{
                borderRadius: "99px",
                width: "99px",
                height: "40px",
              }}
              onClick={() => {
                handleCreateUser();
                setAddUserFlag(true);
              }}
            >
              <Typography variant="h5" color="white">
                Save
              </Typography>
            </Button>
          </Grid>

          {/* {addUser?.ok == false ? ( */}

          {/* ) : null} */}
        </Grid>
      </Grid>
    </>
  );
}

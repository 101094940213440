/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import AlertError from "components/Widgets/AlertError";

import PayrollSuccessIcon from "assets/images/payroll-success.svg";
import PayrollFailureIcon from "assets/images/payroll-failure.svg";
import PayrollPendingIcon from "assets/images/customer-payroll-pending.svg";

import {
  GetEmployeePayrollById,
  ResetGetEmployeePayrollById,
} from "../../../store/actions/EmployeeSalaries/EmployeePayrollById";

import { currencyFormat, getValue, convertDateToMonthAndYear } from "../CommonUtils";
import { usePermissions } from "../../Hook";

import "./PayrollHistory.css";
import moment from "moment";

function EmployeePayroll({ selectPageHeading }) {
  const dispatch = useDispatch();

  const { loggedInUser } = usePermissions();

  const [showRetryErr, setShowRetryErr] = React.useState(false);

  const retryPayment = useSelector((state) => state.RetryPayment);
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const employeePayrollById = useSelector((state) => state.EmployeePayrollById.employeePayrollById);
  const paymentDetails = useSelector((state) => state.GetPaymentDetails.paymentDetails);

  const getPaymentCellData = (payment) => {
    const boxBackgroundColor = payment?.failure ? "#FFDAD6" : payment.status === "paid" ? "#B4FFF2" : "#FFEFD3";

    const statusIcon = payment?.failure
      ? PayrollFailureIcon
      : payment.status === "paid"
        ? PayrollSuccessIcon
        : PayrollPendingIcon;

    const paymentDateColor = payment?.failure ? "#BA1A1A" : payment.status === "paid" ? "#000000" : "#3F2E00";

    const paymentDate = convertDateToMonthAndYear(payment?.paymentDate);

    const paidOnDate =
      payment.status === "paid" || payment?.failure
        ? `Paid on ${moment(payment?.paidOn).format("Do MMM' YY")}`
        : "Pending";

    return {
      boxBackgroundColor,
      statusIcon,
      paymentDateColor,
      paymentDate,
      paidOnDate,
    };
  };

  const getPaymentDateCell = (payment) => {
    const { boxBackgroundColor, statusIcon, paymentDateColor, paymentDate, paidOnDate } = getPaymentCellData(payment);

    return (
      <Grid
        sx={{
          display: "flex",
          gap: "14px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            padding: "12px",
            backgroundColor: boxBackgroundColor,
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={statusIcon} alt="failure-icon" />
        </Box>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "6px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins-Medium",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.1px",
              color: paymentDateColor,
            }}
          >
            {paymentDate}
          </Typography>

          <Typography
            sx={{
              fontFamily: "Poppins-Regular",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "12px",
              color: "#212231",
            }}
          >
            {paidOnDate}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    dispatch(ResetGetEmployeePayrollById({}));

    dispatch(
      GetEmployeePayrollById({
        customerId: loggedInUser?.customerId,
        employeeId: getEmployeeDetails?.payload?.employeeId,
      })
    );

    // }
  }, []);

  useEffect(() => {
    if (Object.keys(paymentDetails).length && paymentDetails?.ok) {
      selectPageHeading("Payroll Details");
    }
  }, [paymentDetails]);

  useEffect(() => {
    if (retryPayment.loadingRetryPayment === false && !!retryPayment.retryPayment) {
      setShowRetryErr(true);
    }
  }, [retryPayment.loadingRetryPayment === false && !!retryPayment.retryPayment]);

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <TableContainer
          sx={{
            background: "#FFFFFF",
            borderRadius: "24px",
            paddingBottom: "16px",
            border: "1px solid var(--color-primitives-primary-50)",
          }}
        >
          {retryPayment.loadingRetryPayment === false && !!retryPayment.retryPayment && (
            <div style={{ float: "right" }}>
              <AlertError
                openAlert={showRetryErr}
                setOpenAlert={() => setShowRetryErr(false)}
                errors={[""]}
                displayError={["An error occured while retrying"]}
              />
            </div>
          )}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="payroll-history-table-header">Paid On</TableCell>
                <TableCell className="payroll-history-table-header">Raise Added</TableCell>
                <TableCell className="payroll-history-table-header">Bonus Added</TableCell>
                <TableCell className="payroll-history-table-header">Salary</TableCell>
                <TableCell className="payroll-history-table-header">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeePayrollById?.rows?.map((row) => {
                return (
                  <TableRow
                    key={row?.paymentDate}
                    sx={{
                      "&:nth-of-type(odd)": {
                        backgroundColor: "#FBFAFF",
                      },
                      backgroundColor: `${
                        row?.failure ? "#FFFBFA !important" : row?.status === "un-paid" && "#FFFDFA !important"
                      }`,
                    }}
                  >
                    <TableCell className="payroll-history-table-cell">{getPaymentDateCell(row)}</TableCell>

                    <TableCell
                      className="payroll-history-table-cell"
                      sx={{
                        fontSize: "13px !important",
                        lineHeight: "32px !important",
                        color: "#00201c",
                        "&.MuiTableCell-root": {
                          verticalAlign: "baseline",
                        },
                      }}
                    >
                      $ {currencyFormat(getValue(row?.raise))}
                    </TableCell>

                    <TableCell
                      className="payroll-history-table-cell"
                      sx={{
                        fontSize: "14px !important",
                        lineHeight: "32px !important",
                        color: "#00201c",
                        "&.MuiTableCell-root": {
                          verticalAlign: "baseline",
                        },
                      }}
                    >
                      $ {currencyFormat(getValue(row?.bonus))}
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Regular",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "20px",
                          borderBottom: "none",
                          color: "var(--temp-color-primitives-primary-800)",
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        {row?.description ? `For ${row?.description}` : ""}
                      </Typography>
                    </TableCell>

                    <TableCell
                      className="payroll-history-table-cell"
                      sx={{
                        fontSize: "14px !important",
                        lineHeight: "32px !important",
                        color: "#00201c",
                      }}
                    >
                      ${currencyFormat(getValue(row?.payrollAmount || 0))}
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Regular",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "20px",
                          borderBottom: "none",
                          color: " var(--temp-color-primitives-primary-800)",
                        }}
                      >
                        {" "}
                        ${currencyFormat(getValue((row?.payrollAmount || 0) - (row?.bonus || 0) - (row?.raise || 0)))} +
                        ${currencyFormat(getValue(row?.raise))}
                      </Typography>
                    </TableCell>

                    <TableCell
                      className="payroll-history-table-cell"
                      sx={{
                        fontSize: "14px !important",
                        lineHeight: "32px !important",
                        color: "#00201c",
                      }}
                    >
                      $ {currencyFormat(getValue(row?.payrollAmount || 0))}
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Regular",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "20px",
                          borderBottom: "none",
                          color: " var(--temp-color-primitives-primary-800)",
                        }}
                      >
                        {" "}
                        ${currencyFormat(getValue((row?.payrollAmount || 0) - (row?.bonus || 0)))} + $
                        {currencyFormat(getValue(row?.bonus))}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default EmployeePayroll;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  Alert,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { SignInScreenPermissions } from "../SignIn.enum";

import GoogleSignup from "./GoogleSignup";
import { ResetSigunp, Sigunp } from "store/actions/SignUp/SignUp";

import { DropdownData } from "store/actions/DropdownData";
import { checkUserAdditionalInfo } from "../../CommonUtils";

const userCredentials = {
  email: "",
  password: "",
  loginPortal: null,
  confirmPassword: "",
};

function SignUp(props) {
  const { changeScreen } = props;
  const { handleSubmit } = useForm();

  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [userCredential, setUserCredential] = React.useState(userCredentials);
  const [loginButtonClicked, setLoginButtonClicked] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [passwordMatch, setPasswordMatch] = React.useState(true);

  const signUpIfno = useSelector((state) => state.SignUp.signUpIfno);

  const googleSignupInfo = useSelector((state) => state.GoogleSignup.googleSignupInfo);

  const dropdownData = useSelector((state) => state.DropdownData.dropdownData);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setUserCredential({
      ...userCredential,
      [event.target.name]: value,
    });
  };

  const signUpHandler = () => {
    setLoginButtonClicked(true);

    if (userCredential.email != "" && userCredential.password != "") {
      if (userCredential.password == userCredential.confirmPassword) {
        setOpenAlert(false);
        setPasswordMatch(true);
        dispatch(
          Sigunp({
            request: "customerUser/signUp",
            userCredential,
            // loginPortal: userCredential.loginPortal.key,
          })
        );
      } else if (userCredential.password != userCredential.confirmPassword) {
        dispatch(ResetSigunp({}));
        setPasswordMatch(false);
        setOpenAlert(true);
      }
    } else {
      setOpenAlert(true);
    }
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },

              "&.MuiList-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              {`${error?.msg ? error.msg : error?.description ? error.description : "Undefined Error"} ${
                error?.param ? error?.param : ""
              }`}
            </ListItem>
          </List>
        );
      });
    }
    return null;
  };

  // eslint-disable-next-line consistent-return
  const checkError = (field, view) => {
    if (loginButtonClicked) {
      if (view != "textField") {
        if (userCredential[field]?.key != null) {
          return false;
        }
        return true;
      }
      if (userCredential[field] != "") {
        return false;
      }
      return true;
    }
  };

  // methods
  React.useEffect(() => {
    if (googleSignupInfo?.ok == false) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  }, [googleSignupInfo]);

  React.useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const accesstoken = localStorage.getItem("accessToken");

    if (accesstoken != null && accesstoken != "undefined" && loggedInUser != null && loggedInUser != "undefined") {
      dispatch(DropdownData());
    }
    if (Object.keys(signUpIfno).length && signUpIfno.ok != true) {
      setOpenAlert(true);
    }
  }, [signUpIfno]);

  React.useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const accesstoken = localStorage.getItem("accessToken");

    if (
      accesstoken != null &&
      accesstoken != "undefined" &&
      loggedInUser != null &&
      loggedInUser != "undefined" &&
      Object.keys(dropdownData).length
    ) {
      if (loggedInUser) {
        if (loggedInUser?.setupCompany) {
          changeScreen(SignInScreenPermissions.ADD_CUSTOMER_INFO);
        } else if (!loggedInUser?.bankAttached && userAdditionalInfo.status === "enabled") {
          changeScreen(SignInScreenPermissions.ADD_PAYMENT_METHOD);
        } else {
          navigateTo("/main/dashboard");
        }
      }
    }
  }, [dropdownData]);

  React.useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const accesstoken = localStorage.getItem("accessToken");

    if (checkUserAdditionalInfo(userAdditionalInfo) && accesstoken && loggedInUser) {
      if (loggedInUser?.type == "customer") {
        if (!loggedInUser?.bankAttached && userAdditionalInfo.status === "enabled") {
          changeScreen(SignInScreenPermissions.ADD_PAYMENT_METHOD);
        } else {
          navigateTo("/main/dashboard");
        }
      } else if (loggedInUser?.type == "edge") {
        navigateTo("/main/dashboard");
      }
    }

    if (checkUserAdditionalInfo(userAdditionalInfo) && userAdditionalInfo.ok != true) {
      setOpenAlert(true);
    }
  }, [userAdditionalInfo]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      sx={{ backgroundColor: "#DBEFED", overflowY: "scroll" }}
    >
      <form onSubmit={handleSubmit(signUpHandler)}>
        <Grid item container sx={{ maxWidth: "330px" }}>
          <Grid item container direction="column" rowSpacing={2}>
            <Grid item>
              <Typography
                sx={{
                  fontFamily: "Poppins-Bold !important",
                  color: "#010533",
                  fontSize: "50px",
                  lineHeight: "50px",
                }}
              >
                Start Hiring Globally !
              </Typography>
            </Grid>

            <Grid item>
              <FormControl variant="outlined" fullWidth error>
                <label>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    sx={{
                      fontFamily: "Poppins-Medium !important",
                      color: checkError("email", "textField") ? "#F64A14" : "000000",
                      fontSize: "13px",
                      lineHeight: "8px",
                      paddingTop: 1,
                    }}
                  >
                    Email
                  </Typography>
                </label>
                <TextField
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "white",
                      borderRadius: "6px",
                      fontFamily: "Poppins-Medium !important",
                    },
                    "& .MuiInputBase-input": {
                      paddingTop: 1,
                      paddingLeft: 2,
                      paddingBottom: 1,
                      fontSize: "13px",
                    },
                  }}
                  id="userName"
                  error={checkError("email", "textField")}
                  placeholder="Enter User Name"
                  size="small"
                  name="email"
                  fullWidth
                  onChange={handleInputChange}
                  margin="dense"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl variant="outlined" fullWidth>
                <label>
                  <Box display="flex" flexDirection="row" alignItems="center" height="10px">
                    <Typography
                      fontWeight={500}
                      color="#000000"
                      sx={{
                        fontFamily: "Poppins-Medium !important",
                        color: checkError("password", "textField") ? "#F64A14" : "000000",
                        fontSize: "13px",
                      }}
                    >
                      Password
                    </Typography>
                    <Tooltip
                      title="Password Length should be at least 8 Characters, containing at least 1 Uppercase, Lowercase, Number and a Special Character"
                      placement="right-start"
                    >
                      <IconButton>
                        <InfoOutlinedIcon sx={{ fontSize: "13px" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </label>
                <TextField
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "white",
                      borderRadius: "6px",
                      fontFamily: "Poppins-Medium !important",
                    },
                    "& .MuiInputBase-input": {
                      paddingTop: 1,
                      paddingLeft: 2,
                      paddingBottom: 1,
                      fontSize: "13px",
                    },
                  }}
                  id="password"
                  placeholder="Enter Password"
                  size="small"
                  name="password"
                  error={checkError("password", "textField")}
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  onChange={handleInputChange}
                  margin="dense"
                  InputProps={{
                    disableUnderline: true,

                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl variant="outlined" fullWidth>
                <label>
                  <Typography
                    fontWeight={500}
                    color="#000000"
                    sx={{
                      fontFamily: "Poppins-Medium !important",
                      color: checkError("confirmPassword", "textField") ? "#F64A14" : "000000",
                      fontSize: "13px",
                      lineHeight: "8px",
                    }}
                  >
                    Confirm Password
                  </Typography>
                </label>
                <TextField
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "white",
                      borderRadius: "6px",
                      fontFamily: "Poppins-Medium !important",
                    },
                    "& .MuiInputBase-input": {
                      paddingTop: 1,
                      paddingLeft: 2,
                      paddingBottom: 1,
                      fontSize: "13px",
                    },
                  }}
                  id="password"
                  placeholder="Enter Password"
                  size="small"
                  name="confirmPassword"
                  variant={checkError("confirmPassword", "textField") ? "outlined" : "standard"}
                  error={checkError("confirmPassword", "textField")}
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={handleInputChange}
                  fullWidth
                  margin="dense"
                  InputProps={{
                    disableUnderline: true,

                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item>
              {signUpIfno?.ok == false || passwordMatch == false || googleSignupInfo?.ok == false ? (
                <Alert
                  open={openAlert}
                  autoHideDuration={3500}
                  variant="filled"
                  severity="error"
                  sx={{
                    backgroundColor: "#F64A14",
                    padding: "initial",
                    px: "5px",
                    fontSize: "12px",
                    textAlign: "center",

                    borderRadius: "5px !important",
                    color: "#ffffff !important",
                    "&.MuiPaper-root": {
                      color: "#ffffff !important",
                    },
                  }}
                >
                  {signUpIfno?.ok == false && passwordMatch
                    ? getError(signUpIfno?.payload?.error)
                    : googleSignupInfo?.ok == false
                      ? getError(signUpIfno?.payload?.description)
                      : passwordMatch == false
                        ? "Passwords do not match"
                        : " Enter all information before proceeding"}
                </Alert>
              ) : null}
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                fullWidth
                type="submit"
                sx={{
                  backgroundColor: "var(--color-primitives-primary-900)",
                  height: "50px",
                  fontFamily: "Poppins-Regular !important",
                  borderRadius: "6px",
                  fontSize: "16px",
                  color: "white",
                }}
              >
                Lets Go!
              </Button>
            </Grid>

            <Grid item>
              <Typography
                sx={{
                  fontFamily: "Poppins-Bold !important",
                  color: "080D46",
                  fontSize: "18px",
                  fontWeight: "800",
                  lineHeght: "2px",
                  marginBottom: "0",
                }}
              >
                Or Sign Up with,
              </Typography>
            </Grid>

            <Grid item>
              <GoogleSignup changeScreen={changeScreen} />
            </Grid>

            <Grid item textAlign="center">
              <Link
                sx={{
                  color: "#000000",
                  fontSize: "13px",
                  fontFamily: "Poppins-Medium !important",
                }}
                onClick={() => navigateTo("/")}
              >
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <Grid item>
        <Grid
          sx={{
            background: "radial-gradient(circle, rgba(245,245,245,1) 12%, rgba(204,240,240,1) 59%)",
            width: "650px",
            height: "620px",
            borderRadius: "50%",
            marginTop: "10%",
            textAlign: "center",
            boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 4px;",
          }}
        >
          <Box
            component="img"
            mt={-10}
            sx={{
              height: "750px",
              width: "520x",
            }}
            alt="The house from the offer."
            src="assets/fonts/Images/Untitled_Artwork 3 3.png"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SignUp;

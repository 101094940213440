import { CheckCircleOutline, CancelOutlined } from "@mui/icons-material";

export const showIcon = (isChecked = null) => {
  if (isChecked === null) {
    return <CheckCircleOutline className="error-list-icon icon-color-initial" />;
  }
  if (isChecked) {
    return <CheckCircleOutline className="error-list-icon icon-color-success" />;
  }

  return <CancelOutlined className="error-list-icon icon-color-danger" />;
};

export const getTextColorClass = (isChecked = null) => {
  if (isChecked === null) {
    return "initial";
  }
  if (isChecked) {
    return "success";
  }

  return "error";
};

export function getStartDateText(loggedInUserType, startDate, email, isBenchedEmployee) {
  if (isBenchedEmployee) {
    return "On the bench";
  }
  if (loggedInUserType === "employee") {
    return `Joined on ${startDate}`;
  }
  if (loggedInUserType === "edge") {
    return `${email}`;
  }
  return `Partner since ${startDate}`;
}

export function isMenuItemActive(path, currentLocation) {
  if (path?.includes("?")) {
    const [pathname, search] = path.split("?");
    return currentLocation.pathname === pathname && currentLocation.search === `?${search}`;
  }

  return currentLocation.pathname === path && !currentLocation.search;
}

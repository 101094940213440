/* eslint-disable no-nested-ternary */
import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis } from "recharts";

function TimeoffBarChart(props) {
  const { chartData, showSpecificBarGraph } = props;

  // eslint-disable-next-line react/no-unstable-nested-components
  function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
      const tooltipData = payload[0]?.payload;
      return (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "4px",
            padding: "5px 8px 5px 12px",
            borderRadius: "14px",
            width: "9.5rem",
            backgroundColor: "#FAFAFF",
            boxShadow: "0px 1px 1px rgba(2, 0, 110, 0.22), 0px 1px 6px rgba(2, 0, 110, 0.25)",
          }}
        >
          {Object.entries(tooltipData).map(
            ([key, value]) =>
              // Skip rendering the entry for 'month'
              key.toLowerCase() !== "month" && (
                <Box
                  key={key}
                  sx={{
                    display: "flex",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#14151F",
                      fontFamily: "Poppins-Medium",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "16px",
                      textTransform: "capitalize",
                    }}
                  >
                    {`${key}:`}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#14151F",
                      fontFamily: "Poppins-Medium",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "16px",
                    }}
                  >
                    {value}
                  </Typography>
                </Box>
              )
          )}
        </Grid>
      );
    }

    return null;
  }

  const leavesCount = chartData?.length;

  return (
    <ResponsiveContainer
      width={
        leavesCount === 4
          ? "80%"
          : leavesCount === 3
            ? "62%"
            : leavesCount === 2
              ? "42%"
              : leavesCount === 1
                ? "20%"
                : "100%"
      }
      height="100%"
    >
      {leavesCount ? (
        <BarChart data={chartData} barSize={15}>
          <XAxis
            dataKey="month"
            axisLine={false}
            tickMargin={15}
            tickLine={false}
            tick={{
              fontFamily: "Poppins-Medium !important",
              fontWeight: 500,
              fontSize: 11,
              lineHeight: "16px",
              color: "#212231",
            }}
          />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Bar
            dataKey={showSpecificBarGraph || "total"}
            stackId="leaves"
            fill="var(--temp-color-primitives-primary-800)"
            radius={5}
          >
            {chartData.map((entry, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Cell key={index} radius={4} />
            ))}
          </Bar>
        </BarChart>
      ) : (
        <Typography
          sx={{
            fontFamily: "Poppins-Medium !important",
            fontWeight: 500,
            fontSize: 14,
            lineHeight: "16px",
            color: "#212231",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No Leave Data Found
        </Typography>
      )}
    </ResponsiveContainer>
  );
}

export default TimeoffBarChart;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography, Tooltip } from "@mui/material";

import {
  currencyFormat,
  getValue,
  getCurrencySymbol,
  getMonthFromDate,
  convertDate,
  capitalizeFirstLetter,
} from "components/Screens/CommonUtils";

import { GetCustomerPaymentById } from "store/actions/Customers/GetCustomerPaymentById";
import { GetCustomerPaymentSummary } from "store/actions/Customers/GetCustomerPaymentSummary";

import EmailWithIcon from "components/Widgets/EmailWithIcon";
import DataTable from "components/Widgets/DataTable";
import calendarIcon from "components/Icons/CalendarIcon.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const tableheadCells = [
  {
    id: "month",
    numeric: true,
    disablePadding: false,
    label: (
      <Typography variant="h5" fontSize="15px">
        Month
      </Typography>
    ),
  },
  {
    id: "noOfEmployees",
    numeric: true,
    disablePadding: false,
    label: (
      <Typography variant="h5" fontSize="15px" pl="1.6rem">
        Employees
      </Typography>
    ),
  },

  {
    id: "payrollAmount",
    numeric: true,
    disablePadding: false,
    label: (
      <Typography variant="h5" fontSize="15px" pl="1.6rem">
        Payroll Amount
      </Typography>
    ),
  },

  {
    id: "paymentDate",
    numeric: true,
    disablePadding: false,
    label: (
      <Typography variant="h5" fontSize="15px" pl="1.6rem">
        Payment Date
      </Typography>
    ),
  },

  {
    id: "paidon",
    numeric: true,
    disablePadding: false,
    label: (
      <Typography variant="h5" pl={2}>
        Paid On
      </Typography>
    ),
  },

  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: (
      <Typography variant="h5" pl={2}>
        Status
      </Typography>
    ),
  },
];

const componentsProps = {
  tooltip: {
    sx: {
      bgcolor: "var(--color-primitives-primary-900)",
      fontSize: "12px",
      height: "32px",
      paddingTop: "6px",
      borderRadius: "8px",
      color: "white",
      position: "relative",
      maxWidth: "none",
      whiteSpace: "normal",
      "&::after": {
        content: "''",
        position: "absolute",
        top: "50%",
        right: "-11px",
        transform: "translateY(-50%)",
        borderLeft: "12px solid var(--color-primitives-primary-900)",
        borderTop: "12px solid transparent",
        borderBottom: "12px solid transparent",
      },
    },
  },
};

function PaymentHistory(props) {
  const { selectPageHeading, getSelectedPaymentId } = props;

  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const customerDetails = useSelector((state) => state.GetCustomerDetails.customerDetails);
  const customerPaymentSummary = useSelector((state) => state.GetCustomerPaymentSummary.customerPaymentSummary);
  const customerPaymentById = useSelector((state) => state.GetCustomerPaymentById.customerPaymentById);

  const handleSelectedRow = (selectedCustomerPaymentID) => {
    getSelectedPaymentId(selectedCustomerPaymentID);
    dispatch(
      GetCustomerPaymentById({
        request: `customerPayment/${selectedCustomerPaymentID}`,
        rowsPerPage: 10,
        page: 0,
      })
    );
  };

  const handleRowsPerPage = (pagination) => {
    setRowsPerPage(pagination.rowsPerPage);
    setPage(pagination.page);
  };

  const getPaymentStatus = (paymentstatus, paymentfailure) => {
    let status = "";
    let color = "";
    let backgroundColor = "";
    if (paymentfailure) {
      status = "Failed";
      color = "#DE0E22";
      backgroundColor = "#FAE6E8";
    } else if (paymentstatus === "pending") {
      status = "Processing";
      color = "#B2840B";
      backgroundColor = "#FAF4E6";
    } else if (paymentstatus === "paid") {
      status = "Successful";
      color = "#477E35";
      backgroundColor = "#EFFAEB";
    } else {
      status = capitalizeFirstLetter(paymentstatus);
    }
    const styles = {
      width: "81px",
      height: "22px",
      backgroundColor,
      color,
      fontFamily: "inter",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "4px",
      borderRadius: "6px",
      padding: "1px 8px",
      fontSize: "12px",
      fontWeight: 600,
    };
    return <div style={styles}>{status}</div>;
  };

  useEffect(() => {
    if (customerPaymentById?.ok) {
      selectPageHeading("Payment Details");
    }
  }, [customerPaymentById]);

  useEffect(() => {
    const Data = [];
    if (customerPaymentSummary?.ok) {
      customerPaymentSummary?.payload?.rows?.map((payment) => {
        return Data.push({
          id: payment?.customerPayrollId,
          month: (
            <EmailWithIcon
              iconButton
              data={getMonthFromDate(payment?.paymentDate)}
              alignItem="center"
              variant="h6Sidebar"
              fontsize="13px"
              icon={<img alt="email icon" src={calendarIcon} height={20} width="auto" />}
            />
          ),
          noOfEmployees: (
            <Typography variant="h6" textAlign="center">
              {currencyFormat(getValue(payment?.totalEmployees))}
            </Typography>
          ),
          payrollAmount: (
            <Typography variant="h6" textAlign="center">
              {getCurrencySymbol("en-US", "USD", currencyFormat(getValue(payment?.totalPayroll)))}
            </Typography>
          ),
          paymentDate: (
            <Typography variant="h6" textAlign="center">
              {convertDate(payment?.paymentDate)}
            </Typography>
          ),
          paidon: (
            <Typography variant="h3" textAlign="center">
              {convertDate(payment?.paidOn)}
            </Typography>
          ),
          status: payment?.failure ? (
            <div className="flex items-center justify-center mr-1 gap-025">
              <Tooltip title={payment?.failureReason} componentsProps={{ ...componentsProps }} placement="left">
                <InfoOutlinedIcon sx={{ width: 20, height: 20, color: "#9D9FB6" }} className="cursor-pointer" />
              </Tooltip>
              <Typography>{getValue(getPaymentStatus(payment?.status, payment?.failure))}</Typography>
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <Typography>{getValue(getPaymentStatus(payment?.status, payment?.failure))}</Typography>
            </div>
          ),
        });
      });
      setRowsData(Data);
    }
  }, [customerPaymentSummary]);

  useEffect(() => {
    if (customerDetails?.payload?.customerId) {
      dispatch(
        GetCustomerPaymentSummary({
          customerId: customerDetails?.payload?.customerId,
          rowsPerPage,
          page,
        })
      );
    }
  }, [rowsPerPage, page]);

  return (
    <Grid item container direction="column" rowSpacing={3}>
      <Grid item xs={12}>
        <DataTable
          rowsData={rowsData}
          totalCount={customerPaymentSummary?.payload?.count ? customerPaymentSummary.payload.count : 0}
          tableheadCells={tableheadCells}
          title={null}
          subTitle={null}
          toolBar={false}
          handleRowsPerPage={handleRowsPerPage}
          handleSelectedRow={handleSelectedRow}
          sortByName="month"
          openDetailsPage="month"
          rowTextAlign="center"
        />
      </Grid>
    </Grid>
  );
}

export default PaymentHistory;

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import { usePermissions } from "components/Hook";
import { convertDate } from "components/Screens/CommonUtils";

import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";

import "./EarningsBanner.css";

function EarningsBanner({ userDetails }) {
  const { loggedInUser } = usePermissions();
  const { platfromRebrandPhase1 } = useFlags();

  const payrollData = useSelector((state) => state?.EmployeeSalaryDetails?.employeeSalaryDetails);

  const latestPayrollExecuted = useMemo(() => {
    if (payrollData?.payload?.rows?.length)
      return payrollData?.payload?.rows.filter((payroll) => payroll.status === "paid");
    return [];
  }, [payrollData]);

  const downloadSlip = async () => {
    const response = await getRequest(
      `${baseUrl}/employee/${loggedInUser?.userId}/payroll/${
        latestPayrollExecuted?.[latestPayrollExecuted?.length - 1]?.payrollId
      }?action=pdf`,
      true,
      "export"
    );

    if (!response?.ok) {
      return;
    }
    const url = window.URL.createObjectURL(response?.payload);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;

    link.download = "Salary Slip";
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    // alert("your file has downloaded!");
  };

  return (
    <div
      className="banner-conatiner"
      style={{
        background: platfromRebrandPhase1
          ? "var(--gradient-dark, linear-gradient(56deg, #F1CEDC 0%, var(--color-primitives-primary-200) 100%))"
          : "var(--gradient-dark, linear-gradient(56deg, var(--color-primitives-primary-500) 0%, #0bdeca 100%))",
      }}
    >
      {!platfromRebrandPhase1 && (
        <img src="../assets/Images/finance.svg" className="banner-finance-img" alt="finance" />
      )}
      <div className="banner-text-container">
        <div className={`banner-text-typography ${platfromRebrandPhase1 ? "p-15 text-black-important" : ""}`}>
          {`Have a great month, ${userDetails.name}! 
            ${latestPayrollExecuted[latestPayrollExecuted?.length - 1]?.status === "paid" ? "You got paid" : ""}`}
          {latestPayrollExecuted[latestPayrollExecuted?.length - 1]?.paidOn
            ? ` on
              ${convertDate(latestPayrollExecuted[latestPayrollExecuted?.length - 1]?.paidOn)}
              `
            : null}
        </div>
        <div>
          {latestPayrollExecuted[latestPayrollExecuted?.length - 1]?.status !== "paid" ? (
            <button
              className={`banner-text-btn ${platfromRebrandPhase1 ? "banner-text-btn-rebrand" : ""}`}
              onClick={downloadSlip}
              type="button"
            >
              Download payslip
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default EarningsBanner;

import React, { useState, useEffect } from "react";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

import "components/common/Slider/Slider.scss";

const AUTO_SLIDE_CHANGE_DURATION = 3 * 1000;

function Slider({ autoSlideChangeDuration = AUTO_SLIDE_CHANGE_DURATION, sliderData = [] }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % sliderData.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + sliderData.length) % sliderData.length);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, autoSlideChangeDuration);

    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <div className="slider-container  w-100p">
      <div className="slides-container w-100p flex flex-col items-center justify-center">
        <div className="flex flex-col items-center w-100p">
          <div className="flex justify-evenly items-center w-100p">
            <ArrowBackIos fontSize="18px" onClick={handlePrev} aria-label="previous" />
            <img src={sliderData[currentSlide]?.image} alt="slide" className="slider-images" />
            <ArrowForwardIos fontSize="18px" onClick={handleNext} aria-label="next" />
          </div>

          <div className="heading-h1-semibold mt-2 heading-text ">{sliderData[currentSlide]?.heading}</div>
          <div className="para-body-m-regular description-text ">{sliderData[currentSlide]?.description}</div>
        </div>
      </div>
      <div className="flex justify-center three-dots-container">
        {sliderData.map(({ id = Math.random() }, index) => (
          <div
            key={id}
            onClick={() => goToSlide(index)}
            className={`dot dot-color-${currentSlide === index ? "active" : "inactive"}`}
            data-testid={`dot-${index}`}
          />
        ))}
      </div>
    </div>
  );
}

export default Slider;

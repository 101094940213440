import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { isMenuItemActive, getStartDateText } from "components/main/sidebar/utils/SidebarUtils";

import { usePermissions } from "components/Hook";

function SidebarContent({ menuItems = [], logo, userInfo = {}, location, onMenuItemClick, stickyItems = [] }) {
  const { loggedInUser, loggedInUserType } = usePermissions();
  const { name, companyName, startDate, avatar, email, isBenchedEmployee } = userInfo;

  const { groupedItems, categoryList } = menuItems.reduce(
    (acc, item) => {
      const category = item.category || "default";

      if (!acc.groupedItems[category]) {
        acc.groupedItems[category] = [];
        acc.categoryList.push(category);
      }
      acc.groupedItems[category].push(item);

      return acc;
    },
    { groupedItems: {}, categoryList: [] }
  );

  const renderMenuItem = (item) => (
    <ListItem
      key={item.id}
      id={item.id}
      disablePadding
      onClick={() => onMenuItemClick(item.path, item.openInNewTab)}
      className={classNames("sidebar-menu-item", {
        "sidebar-menu-item-selected": isMenuItemActive(item.path, location),
      })}
    >
      <ListItemButton className="sidebar-menu-item-button">
        <ListItemIcon className="sidebar-menu-item-icon">{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} className="sidebar-menu-item-text" />
      </ListItemButton>
    </ListItem>
  );

  return (
    <div className="sidebar-container">
      <div className="sidebar-content">
        <div className="sidebar-header">
          <div className="sidebar-logo">{logo}</div>
        </div>

        <div className="sidebar-menu-container">
          {categoryList.map((category) => (
            <div key={category} className="sidebar-category">
              {category !== "default" && (
                <div className="sidebar-menu-category-title para-body-s-regular">{category}</div>
              )}

              <List className="sidebar-menu-list">{groupedItems[category].map(renderMenuItem)}</List>
            </div>
          ))}
        </div>
      </div>

      <div className="sidebar-footer">
        {stickyItems.length > 0 && <List className="sidebar-sticky-menu-list">{stickyItems.map(renderMenuItem)}</List>}

        {(name || companyName) && (
          <div className="sidebar-user-info-footer">
            <div className="sidebar-user-info-content">
              {avatar && <div className="sidebar-user-avatar">{avatar}</div>}
              <div className="sidebar-user-details">
                {(companyName || loggedInUserType === "edge") && (
                  <div className="sidebar-company-name" id="company-name">
                    {companyName || loggedInUser?.name}
                  </div>
                )}

                {startDate && (
                  <div className="sidebar-start-date" id="started-date">
                    {getStartDateText(loggedInUserType, startDate, email, isBenchedEmployee)}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

SidebarContent.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      icon: PropTypes.node.isRequired,
      category: PropTypes.string,
      openInNewTab: PropTypes.bool,
    })
  ),

  logo: PropTypes.node,

  userInfo: PropTypes.shape({
    name: PropTypes.string,
    companyName: PropTypes.string,
    startDate: PropTypes.string,
    avatar: PropTypes.node,
    email: PropTypes.string,
  }),

  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  }).isRequired,

  onMenuItemClick: PropTypes.func.isRequired,

  stickyItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      icon: PropTypes.node.isRequired,
      openInNewTab: PropTypes.bool,
    })
  ),
};

SidebarContent.defaultProps = {
  menuItems: [],
  logo: null,
  userInfo: {},
  stickyItems: [],
};

export default SidebarContent;

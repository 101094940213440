import React from "react";

function VivoIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M379.438 71.5287C375.546 72.8259 64.4148 240.159 57.9291 244.513C38.4718 257.3 25.4076 277.591 20.3116 302.329C17.9953 313.726 18.8291 332.257 22.1647 343.56C28.4651 364.778 41.0661 382.382 57.9291 393.501C69.7888 401.376 377.863 566.763 382.218 567.597C389.908 569.172 397.876 565.559 403.065 558.147L405.844 554.162L406.122 483.097L406.308 412.124L403.806 407.028C402.323 404.249 400.192 401.284 398.895 400.357C397.598 399.523 345.619 384.884 283.263 367.836C158.644 333.832 166.519 336.426 162.535 327.809C158.922 320.119 161.701 307.889 168.094 303.349C169.577 302.237 217.201 288.617 283.912 270.086C408.439 235.619 400.656 238.213 404.547 229.596C406.122 226.075 406.308 221.721 406.308 156.4C406.308 90.9859 406.122 86.724 404.547 83.2031C402.601 78.941 398.803 74.8642 394.818 72.8258C391.668 71.158 382.773 70.4169 379.438 71.5287Z"
        fill="white"
      />
      <path
        d="M407.231 234.043C401.764 237.008 399.633 238.954 397.224 242.938L394.723 247.2V318.544C394.723 389.794 394.723 389.887 396.761 393.593C397.78 395.632 399.726 398.226 400.93 399.338C402.691 401.005 427.985 408.232 517.118 432.6C641.552 466.604 634.233 464.195 638.032 472.349C641.552 479.946 639.607 490.602 633.677 496.161C631.361 498.292 615.609 502.925 517.026 530.165C428.727 554.718 402.691 562.223 400.93 563.891C399.726 565.003 397.78 567.597 396.761 569.635C394.723 573.342 394.723 573.434 394.723 644.222V715.102L397.224 719.364C398.614 721.68 401.486 724.831 403.71 726.313C407.324 728.722 408.435 729 415.107 729H422.519L575.861 646.631C660.176 601.323 733.094 562.038 737.912 559.258C760.149 546.287 775.159 524.884 780.719 498.292C782.386 490.231 782.108 471.144 780.255 462.157C775.437 440.013 761.076 418.702 742.916 406.843C739.488 404.526 665.92 364.592 579.567 317.988L422.519 233.302L416.033 233.024C411.771 232.839 408.713 233.209 407.231 234.043Z"
        fill="white"
      />
    </svg>
  );
}

export default VivoIcon;

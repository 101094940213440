import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";

const useView = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return {
    isMobile,
    isDesktop,
  };
};

export default useView;

import React from "react";

import { Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import GiftIcon from "../../../../assets/svgs/GiftIcon";
import "../PayrollHistory.css";

function BonusDropdown(props) {
  const { employeeUpdatedArray, employeeId, getSalaryAfterIncrease, getUpdatedEmployeeSalary, employeeSalaryDetails } =
    props;

  const [bonusAmount, setBonusAmount] = React.useState({
    key: 1,
    value: "lunch bonus",
    amount: 0,
    imageName: null,
  });

  // function
  const handleTextFieldChanage = (event) => {
    const input = event.target.value.replace(/[^0-9]/g, "");

    if (!input || (input[input.length ? input.length - 1 : 0].match("[0-9]") && input[0].match("[0-9]"))) {
      const option = {
        key: 1,
        value: null,
        amount: input != "" ? input : 0,
      };

      option.employeeId = employeeId;

      getSalaryAfterIncrease(option, "bonus");
      getUpdatedEmployeeSalary(option, "bonus");
      setBonusAmount(option);
    }
  };

  React.useEffect(() => {
    let option = {};
    if (employeeSalaryDetails?.incrementDetails && Object.keys(employeeSalaryDetails?.incrementDetails).length) {
      if (employeeSalaryDetails?.amount || employeeSalaryDetails?.amount == 0) {
        option = {
          key: employeeSalaryDetails.key,
          value: employeeSalaryDetails.value,
          amount: employeeSalaryDetails.amount,
        };
      } else {
        option = {
          key: 1,
          value: employeeSalaryDetails.incrementDetails.description,
          amount: employeeSalaryDetails.incrementDetails.bonus,
        };
      }

      option.employeeId = employeeId;
      getSalaryAfterIncrease(option, "bonus");

      setBonusAmount(option);
    } else {
      setBonusAmount({
        key: 1,
        value: null,
        amount: 0,
        imageName: null,
        description: null,
      });
    }
  }, [employeeSalaryDetails, employeeUpdatedArray]);

  return (
    <Grid container direction="row" xs={12} alignItems="center" columnSpacing={1} sx={{ height: "62px" }}>
      <Grid item container direction="column" lg={10} md={10} sm={10} xs={12}>
        <Grid item sx={{ position: "relative" }}>
          <Box
            sx={{
              width: "216px",
              display: "flex",
              alignItems: "center",
              gap: "4px",
              backgroundColor: "white",
              color: "grey",
              paddingLeft: "12px",
              borderRadius: "5px",
              border: " 1px solid #BFC2FF",
              "&:hover": {
                borderColor: "var(--color-primitives-primary-600)",
              },
              "& fieldset": {
                border: "none",
              },
            }}
          >
            <GiftIcon color="var(--color-primitives-primary-600)" />
            <TextField
              variant="outlined"
              fullWidth
              value={
                bonusAmount?.amount?.toString()?.length === 1 && bonusAmount?.amount == 0 ? "" : bonusAmount?.amount
              }
              onChange={handleTextFieldChanage}
              placeholder="type bonus"
              className="add-bonus-input"
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BonusDropdown;

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { isEmptyObject } from "components/Screens/CustomerOnboarding/helpers";
import { STATE_VARIANTS } from "components/Screens/CustomerOnboarding/constants";
import CustomerOnboardingInfoTile from "components/Screens/DashBoard/CustomerDashBoardV2/CustomerOnboardingInfoTile";
import { getPaymentStatus } from "components/Screens/DashBoard/CustomerDashBoardV2/utils";

import { getUserAdditionalInfo } from "selectors/user";

import { AttachMoney, Today as TodayIcon, SupervisedUserCircle as SupervisedUserCircleIcon } from "@mui/icons-material";

import "./OnboardedCustomerInitialView.scss";

function OnboardedCustomerInitialview({ customerOnboardingDetails = {} }) {
  const userAdditionalInfo = useSelector(getUserAdditionalInfo);

  const { accountManager } = userAdditionalInfo;
  const { firstName = "", lastName = "" } = accountManager ?? {};

  const { associateCount = 0, firstChargeDetails, calendlyData } = customerOnboardingDetails;
  const { paymentStatus = STATE_VARIANTS.PENDING } = firstChargeDetails ?? {};
  const areAssociatesOnboarded = Number(associateCount) > 0;
  const meetingStatusSchdueled = calendlyData && !isEmptyObject(calendlyData || {});

  const paymentTileInfo = getPaymentStatus(paymentStatus);

  // this comment is must : this check ensures that this component renders nothing when conditions met
  if (
    (Number(associateCount) > 0 && paymentStatus === "paid" && meetingStatusSchdueled) ||
    (!firstChargeDetails && !calendlyData)
  ) {
    return null;
  }

  return (
    <div className="onboarded-customer-view-container">
      <div className="welcome-heading">Welcome to Edge</div>
      <div className="onboarded-view-description-text">Let's get you started. Here's what's happening right now:</div>
      <div className="info-tile-container">
        {firstChargeDetails && (
          <CustomerOnboardingInfoTile
            icon={<AttachMoney />}
            heading={paymentTileInfo.heading}
            description={paymentTileInfo.description}
            state={paymentTileInfo.state}
          />
        )}

        {calendlyData && (
          <CustomerOnboardingInfoTile
            icon={<TodayIcon />}
            heading={`Your kickoff call is scheduled with ${firstName} ${lastName}`}
            description={
              <>
                For any questions, drop an email to{" "}
                <Link
                  className="tile-link"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "mailto:support@onedge.co";
                  }}
                >
                  support@onedge.co
                </Link>
              </>
            }
            state={meetingStatusSchdueled ? STATE_VARIANTS.SCHEDULED : STATE_VARIANTS.IN_PROGRESS}
          />
        )}

        <CustomerOnboardingInfoTile
          icon={<SupervisedUserCircleIcon />}
          heading={
            areAssociatesOnboarded ? "Your associate(s) are now onboard" : "Your associate(s) are being onboarded"
          }
          description={
            areAssociatesOnboarded ? (
              <>
                You can now view and manage your associates from the{" "}
                <Link className="tile-link" to="/main/workforce">
                  workforce
                </Link>{" "}
                section.
              </>
            ) : (
              "We're onboarding your associate(s) to the platform. Once this process is complete, you'll be able to view and manage them seamlessly."
            )
          }
          state={areAssociatesOnboarded ? STATE_VARIANTS.COMPLETED : STATE_VARIANTS.IN_PROGRESS}
        />
      </div>
    </div>
  );
}

export default OnboardedCustomerInitialview;

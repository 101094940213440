import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import jwt from "jwt-decode";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { List, ListItem } from "@material-ui/core";
import { Box, Button, FormControl, Grid, Typography, Alert, Divider } from "@mui/material";

import { SignInScreenPermissions } from "../SignIn.enum";

import TextFieldV2 from "components/common/TextFieldV2/TextFieldV2";
import PageLoader from "components/common/PageLoader";

import { ResetAddCustomer, AddCustomer, AddGoogleCustomer } from "store/actions/Customers/AddCustomer/AddCustomer";

import paymentProcessImage from "assets/images/payment-process.png";
import EdgeIcon from "assets/svgs/edgeIcon";

const customer = {
  companyName: "",
  email: "",
  password: "",
  industry: "",
  contactPerson: "",
  contactNumber: "",
  country: { key: null, value: "None" },
  companyAddress: "",
  companyAddressLine2: "",
  numberOfEmployees: "",
  state: "",
  city: "",
  code: "",
  google: "true",
};

function AddCustomerInfo(props) {
  const { changeScreen, navigatedFrom } = props;
  const navigateTo = useNavigate();
  const { handleSubmit } = useForm();

  const dispatch = useDispatch();

  const addCustomerInfo = useSelector((state) => state.AddCustomer.addCustomerInfo);
  const loadingAddCustomerInfo = useSelector((state) => state.AddCustomer.loadingAddCustomerInfo);
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const [customerData, setCustomerData] = useState(customer);
  const [dropdownData_, setDropdownData_] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [confirmButtonClicked, setConfirmButtonClicked] = useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleCreateCustomer = () => {
    let request = "";
    setConfirmButtonClicked(true);
    if (
      customerData.companyName != "" &&
      customerData.companyAddress != "" &&
      customerData.companyAddressLine2 != "" &&
      customerData.city != "" &&
      customerData?.state != ""
    ) {
      if (navigatedFrom == "signUpScreen" && JSON.parse(localStorage.getItem("loggedInUser"))?.google != true) {
        request = "customer/signUp";
        dispatch(
          AddCustomer({
            customerData,
            method: "POST",
            request,
          })
        );
      } else if (navigatedFrom == "signInScreen" || JSON.parse(localStorage.getItem("loggedInUser"))?.google) {
        request = "customers/setupCompany";
        dispatch(
          AddGoogleCustomer({
            customerData,
            method: "POST",
            request,
          })
        );
      }
    } else {
      setOpenAlert(true);
    }
  };

  const handleCustomerData = (event) => {
    setCustomerData({
      ...customerData,
      [event.target.name]: event.target.value,
    });
  };

  // eslint-disable-next-line consistent-return
  const checkError = (field, view) => {
    if (confirmButtonClicked) {
      if (view != "textField") {
        if (customerData[field]?.key != null) {
          return false;
        }
        return true;
      }
      if (customerData[field] != "") {
        return false;
      }
      return true;
    }
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },

              "&.MuiList-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              {`${error?.msg || error?.description || "Undefined Error"} ${error?.param || ""}`}
            </ListItem>
          </List>
        );
      });
    }
    return null;
  };

  useEffect(() => {
    let user = "";
    if (addCustomerInfo?.ok) {
      user = jwt(addCustomerInfo.payload.accessToken); // decode your token here

      localStorage.setItem("accessToken", addCustomerInfo.payload.accessToken);
      localStorage.setItem("refreshToken", addCustomerInfo.payload.refreshToken);
      localStorage.setItem("loggedInUser", JSON.stringify(user));
      if (userAdditionalInfo.status === "enabled") {
        changeScreen(SignInScreenPermissions.ADD_PAYMENT_METHOD);
      } else {
        navigateTo("/main/dashboard");
      }
      dispatch(ResetAddCustomer({}));
    } else {
      setOpenAlert(true);
    }
  }, [addCustomerInfo]);

  useEffect(() => {
    setOpenAlert(false);
    setConfirmButtonClicked(false);
    if (!Object.keys(dropdownData_).length) {
      const countries = [];
      let dropdownData = {};
      dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));

      if (dropdownData != undefined && Object.keys(dropdownData).length && dropdownData.ok) {
        dropdownData?.payload?.country?.forEach((data) => {
          countries.push({
            key: data.countryCode2,
            value: data.countryCode,
          });
        });

        setDropdownData_({
          ...dropdownData_,
          countries,
        });
      }
    }
    const accesstoken = localStorage.getItem("accessToken");
    if (accesstoken != null && accesstoken != "undefined") {
      let customerInfo = {};
      customerInfo = JSON.parse(localStorage.getItem("loggedInUser"));
      if (navigatedFrom == "signUpScreen" && JSON.parse(localStorage.getItem("loggedInUser"))?.google != true) {
        setCustomerData({
          ...customerData,
          email: customerInfo?.email,
        });
      } else if (navigatedFrom == "signInScreen" || JSON.parse(localStorage.getItem("loggedInUser"))?.google) {
        setCustomerData({
          ...customerData,
          email: customerInfo.email,
        });
      }
    }
  }, []);

  return (
    <>
      {(loadingAdditionalInfo || loadingAddCustomerInfo) && <PageLoader />}
      <Grid container direction="row" justifyContent="center" alignItems="start" sx={{ overflowY: "auto" }}>
        <Grid
          item
          container
          direction="row"
          xl={3}
          lg={3}
          md={3}
          sm={12}
          xs={12}
          sx={{ backgroundColor: "#FBFAFF", minHeight: "100vh" }}
          justifyContent="start"
          paddingTop="100px"
          paddingLeft="45px"
          paddingRight="20px"
        >
          <Grid item>
            <Grid item>
              <EdgeIcon />
            </Grid>
            <Grid item sx={{ paddingTop: "20px !important" }}>
              <Typography
                sx={{
                  fontFamily: "Poppins !important",
                  color: "#000000",
                  fontSize: "28px",
                  fontWeight: "500",
                  lineHeight: "36px",
                }}
              >
                We just need a little more information to get you started
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular !important",
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  paddingTop: "16px",
                }}
              >
                Enter the following details to begin!
              </Typography>
            </Grid>
            <Grid item sx={{ paddingTop: "70px !important" }}>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular !important",
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  paddingLeft: "44px",
                }}
              >
                STEP 1
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <span
                  style={{
                    width: "14px",
                    height: "14px",
                    borderRadius: "99px",
                    backgroundColor: "var(--color-primitives-primary-600)",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular !important",
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                >
                  Company Details
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <span
                style={{
                  display: "block",
                  width: "4px",
                  height: "92px",
                  backgroundColor: "#B2B4E2",
                  margin: "10px 0px 10px 60px",
                }}
              />
            </Grid>
            <Grid item>
              <Divider orientation="vertical" flexItem />
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular !important",
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  paddingLeft: "44px",
                }}
              >
                STEP 2
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <span
                  style={{
                    width: "14px",
                    height: "14px",
                    borderRadius: "99px",
                    backgroundColor: "var(--color-primitives-primary-600)",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular !important",
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                  }}
                >
                  Payment Details
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xl={9}
          lg={9}
          md={9}
          sm={12}
          xs={12}
          direction="column"
          alignItems="start"
          rowSpacing={1}
          sx={{
            backgroundColor: "#FFFFFF",
            height: "100%",
            overflowY: "auto",
          }}
          padding="100px 86px 0px"
        >
          <form onSubmit={handleSubmit(handleCreateCustomer)}>
            <Grid>
              <img src={paymentProcessImage} alt="payment process" />
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular !important",
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  marginTop: "14px",
                }}
              >
                STEP 1
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins-Bold !important",
                  color: "var(--color-primitives-primary-900)",
                  fontSize: "32px",
                  fontWeight: "600",
                  lineHeight: "44px",
                  maxWidth: "472px",
                }}
              >
                Enter your company details
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular !important",
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  paddingTop: "4px",
                }}
              >
                Enter the following details to begin!
              </Typography>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "18px",
                paddingTop: "54px",
              }}
            >
              <Grid item sx={{ maxWidth: "318px" }}>
                <FormControl variant="outlined">
                  <Typography
                    sx={{
                      marginBottom: "8px",
                      fontFamily: "Poppins-Regular",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: checkError("companyName", "textField") ? "#BA1A1A" : "#212231",
                    }}
                  >
                    Company Name
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #B2B4E2",
                      borderRadius: "12px",
                    }}
                  >
                    <TextFieldV2
                      placeholder="Company Name"
                      hiddenLabel
                      variant="filled"
                      name="companyName"
                      id="companyName"
                      type="text"
                      value={customerData.companyName}
                      onChange={handleCustomerData}
                      // error={checkError(
                      //     "companyName",
                      //     "textField"
                      // )}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Box>
                </FormControl>
              </Grid>

              <Grid item sx={{ maxWidth: "318px" }}>
                <FormControl variant="outlined">
                  <Typography
                    sx={{
                      marginBottom: "8px",
                      fontFamily: "Poppins-Regular",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: checkError("companyAddress", "textField") ? "#BA1A1A" : "#212231",
                    }}
                  >
                    Company Address Line 1
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #B2B4E2",
                      borderRadius: "12px",
                    }}
                  >
                    <TextFieldV2
                      placeholder="Company Address Line 1"
                      hiddenLabel
                      variant="filled"
                      name="companyAddress"
                      id="companyAddress"
                      type="text"
                      value={customerData.companyAddress}
                      onChange={handleCustomerData}
                      // error={checkError(
                      //     "companyAddress",
                      //     "textField"
                      // )}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Box>
                </FormControl>
              </Grid>

              <Grid item sx={{ maxWidth: "318px" }}>
                <FormControl variant="outlined">
                  <Typography
                    sx={{
                      marginBottom: "8px",
                      fontFamily: "Poppins-Regular",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: checkError("companyAddressLine2", "textField") ? "#BA1A1A" : "#212231",
                    }}
                  >
                    Company Address Line 2
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #B2B4E2",
                      borderRadius: "12px",
                    }}
                  >
                    <TextFieldV2
                      placeholder="Company Address Line 2"
                      hiddenLabel
                      variant="filled"
                      name="companyAddressLine2"
                      id="companyAddressLine2"
                      type="text"
                      value={customerData.companyAddressLine2}
                      onChange={handleCustomerData}
                      // error={checkError(
                      //     "companyAddressLine2",
                      //     "textField"
                      // )}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Box>
                </FormControl>
              </Grid>

              <Grid item sx={{ maxWidth: "318px" }}>
                <FormControl variant="outlined">
                  <Typography
                    sx={{
                      marginBottom: "8px",
                      fontFamily: "Poppins-Regular",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: checkError("city", "textField") ? "#BA1A1A" : "#212231",
                    }}
                  >
                    City
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #B2B4E2",
                      borderRadius: "12px",
                    }}
                  >
                    <TextFieldV2
                      placeholder="City"
                      hiddenLabel
                      variant="filled"
                      name="city"
                      id="city"
                      type="text"
                      value={customerData.city}
                      onChange={handleCustomerData}
                      // error={checkError("city", "textField")}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Box>
                </FormControl>
              </Grid>

              <Grid item sx={{ maxWidth: "318px" }}>
                <FormControl variant="outlined">
                  <Typography
                    sx={{
                      marginBottom: "8px",
                      fontFamily: "Poppins-Regular",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: checkError("state", "textField") ? "#BA1A1A" : "#212231",
                    }}
                  >
                    State
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #B2B4E2",
                      borderRadius: "12px",
                    }}
                  >
                    <TextFieldV2
                      placeholder="State"
                      hiddenLabel
                      variant="filled"
                      name="state"
                      id="state"
                      type="text"
                      value={customerData.state}
                      onChange={handleCustomerData}
                      // error={checkError("state", "textField")}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Box>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item>
              {addCustomerInfo?.ok == false ? (
                <Alert
                  open={openAlert}
                  variant="filled"
                  onClose={handleAlertClose}
                  icon={false}
                  autoHideDuration={3500}
                  sx={{
                    backgroundColor: "#F64A14",
                    padding: "initial",
                    px: "5px",
                    width: "350px",
                    mt: "20px",
                    fontSize: "12px",
                    textAlign: "center",
                    fontFamily: "Poppins-Regular !important",

                    borderRadius: "6px !important",
                    color: "#ffffff !important",
                    "&.MuiPaper-root": {
                      color: "#ffffff !important",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins-Regular !important",
                      fontWeight: "400",
                      fontSize: "13px",
                    }}
                  >
                    {addCustomerInfo?.ok == false
                      ? getError(addCustomerInfo?.payload?.error)
                      : "Enter all the information before proceeding"}
                  </Typography>
                </Alert>
              ) : null}
            </Grid>
            <Grid item marginTop="54px" sx={{ display: "flex", gap: "12px" }}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "var(--color-primitives-primary-600)",
                  maxWidth: "318px",
                  height: "54px",
                  fontFamily: "Poppins-Regular !important",
                  fontWeight: "500",
                  lineHeight: "20px",
                  borderRadius: "99px",
                  fontSize: "16px",
                  color: "white",
                  boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
                }}
              >
                Continue
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default AddCustomerInfo;

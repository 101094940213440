import { Modal, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiModal-backdrop": {
      backgroundColor: "background.paper",
    },
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
}));

function CustomModal({
  open,
  children,
  title,
  width = "24rem",
  borderRadius = "4px",
  padding = "16px 24px",
  titleClass,
  sx,
  onClose,
  containerClass = "",
}) {
  const classes = useStyles();
  return (
    <Modal open={open} className={classes.modalContainer} onClose={onClose}>
      <Box
        className={`${classes.modalContent} ${containerClass}`}
        sx={{ width, borderRadius, padding, mx: { xs: "20px", sm: "auto" }, ...sx }}
      >
        <p className={`heading-h2-semibold ${titleClass}`}>{title}</p>
        {children}
      </Box>
    </Modal>
  );
}

export default CustomModal;

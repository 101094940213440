import React from "react";

function PencilIcon({ fill = "#131599" }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="pencil-create">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.20147 5.29696L3.13065 10.3666C2.86055 10.6054 2.69346 10.9397 2.66659 11.2454V13.3355L4.70924 13.3367C5.06539 13.3115 5.39691 13.1457 5.66311 12.8412L10.7044 7.79997L8.20147 5.29696ZM9.14439 4.35426L11.6472 6.85716L13.2431 5.26124C13.3009 5.20351 13.3333 5.1252 13.3333 5.04355C13.3333 4.9619 13.3009 4.88359 13.2431 4.82585L11.174 2.75673C11.1169 2.69919 11.0392 2.66683 10.9582 2.66683C10.8771 2.66683 10.7994 2.69919 10.7423 2.75673L9.14439 4.35426ZM10.9582 1.3335C11.3946 1.3335 11.8131 1.50778 12.1187 1.81578L14.1859 3.88304C14.4937 4.19083 14.6666 4.60828 14.6666 5.04355C14.6666 5.47882 14.4937 5.89627 14.1859 6.20405L6.63826 13.7493C6.1726 14.2865 5.51252 14.6165 4.75632 14.6684H1.33325V14.0017L1.33542 11.1917C1.39221 10.4887 1.71903 9.83505 2.21758 9.39578L9.79684 1.81658C10.1042 1.50738 10.5222 1.3335 10.9582 1.3335Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default PencilIcon;

import React, { useCallback, useEffect, useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePlaidLink } from "react-plaid-link";

import Text from "components/common/Text";
import Button from "components/common/Button";
import RadioButton from "components/common/RadioButtons";
import PaymentBreakdown from "components/Screens/CustomerOnboarding/PaymentBreakdown";
import CustomTooltip from "components/common/Tooltip";
import Layout from "components/Screens/CustomerOnboarding//Layout";
import AddPaymentLoaderModal from "components/Screens/CustomerOnboarding/AddPaymentLoaderModal";
import CustomSnackbar from "components/common/Snackbar";

import { getLoggedInUserDetailsFromLocalStorage } from "helpers";
import { getCustomerOnboardingIncompleteStepRoute } from "components/Screens/CustomerOnboarding/helpers";

import { getUserAdditionalInfo } from "selectors/user";

import AddAchPaymentSchema from "validations/Onboarding/AddAchPaymentSchema";
import { customerOnboardingAddPayment, getLinkToken, skipCustomerOnboardingStepApi } from "services/customer";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  CUSTOMER_ONBOARDING_CALENDLY_MEETING_ROUTE,
  CUSTOMER_ONBOARDING_PAYMENT_SUCCESS_ROUTE,
} from "constants/routes";

import "./AddAchPayment.scss";

function AddAchPayment() {
  const [loading, setLoading] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(null);

  const navigate = useNavigate();

  const loggedInUser = getLoggedInUserDetailsFromLocalStorage();

  const { onboardingProgress = {} } = useSelector(getUserAdditionalInfo);

  const { customerId = "" } = loggedInUser ?? {};
  const { steps = [], onboardingProgressId = null } = onboardingProgress ?? {};
  const paymentStep = steps.find((step) => step.slug === "add-payment");
  const { skippable = false, slug = "" } = paymentStep ?? {};
  const isStepSkippable = skippable ?? false;

  const dispatch = useDispatch();

  const handleCustomerOnboardingAddPayment = async (data, customerID) => {
    try {
      setLoading(true);
      const response = await customerOnboardingAddPayment(data, customerID);
      if (response?.success) {
        navigate(CUSTOMER_ONBOARDING_PAYMENT_SUCCESS_ROUTE);
      }
    } catch (error) {
      const errorMessage = error[0]?.description || "Something went wrong, please try again";
      setSnackbarMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const onSuccess = useCallback((publicToken) => {
    const setToken = async () => {
      const body = {
        publicToken,
      };
      await handleCustomerOnboardingAddPayment(body, customerId);
    };
    setToken();
  }, []);

  const config = {
    token: linkToken?.link_token,
    onSuccess,
  };

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getLinkToken();
      setLinkToken(token);
    };
    fetchToken();
  }, []);

  const { open } = usePlaidLink(config);

  const methods = useForm({
    resolver: yupResolver(AddAchPaymentSchema),
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = methods;

  const onSubmit = async (data = {}) => {
    await handleCustomerOnboardingAddPayment(data, customerId);
  };

  const onSkip = async () => {
    const { payload = {} } = await skipCustomerOnboardingStepApi({ onboardingProgressId, stepSlug: slug }, dispatch);
    const { success = false } = payload ?? {};
    if (success) {
      navigate(CUSTOMER_ONBOARDING_CALENDLY_MEETING_ROUTE);
    }
  };

  useEffect(() => {
    if (paymentStep?.completed_at !== null) {
      const customerOnboardingIncompleteStepRoute = getCustomerOnboardingIncompleteStepRoute(onboardingProgress);
      if (customerOnboardingIncompleteStepRoute) {
        navigate(customerOnboardingIncompleteStepRoute);
      }
    }
  }, []);

  const renderContent = () => (
    <div className="onboarding-add-ach-payment-container">
      <div className="onboarding-add-ach-payment-main-div">
        <div>
          <p className="heading-h3-semibold color-text-heading-primary">Add your ACH payment details</p>
          <p className="para-body-m-regular color-text-body-primary mb-2">
            Provide your ACH information to set up your payment method
          </p>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="onboarding-input-group">
              <p className="para-body-m-medium text-headings-secondary">Account holder type</p>
              <div className="flex gap-1 flex-nowrap">
                <Controller
                  name="accountHolderType"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RadioButton
                      label="Company"
                      value="company"
                      withContainer
                      checked={field.value === "company"}
                      onChange={() => field.onChange("company")}
                    />
                  )}
                />
                <Controller
                  name="accountHolderType"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RadioButton
                      withContainer
                      label="Individual"
                      value="individual"
                      checked={field.value === "individual"}
                      onChange={() => field.onChange("individual")}
                    />
                  )}
                />
              </div>
            </div>
            <div className="onboarding-input-group">
              <p className="para-body-m-medium text-headings-secondary mt-15">Account type</p>
              <div className="flex gap-1 flex-nowrap">
                <Controller
                  name="accountType"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RadioButton
                      withContainer
                      label="Checking"
                      value="Checking"
                      checked={field.value === "checking"}
                      onChange={() => field.onChange("checking")}
                    />
                  )}
                />
                <Controller
                  name="accountType"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RadioButton
                      withContainer
                      label="Savings"
                      value="Savings"
                      checked={field.value === "savings"}
                      onChange={() => field.onChange("savings")}
                    />
                  )}
                />
              </div>
            </div>
            <div className="mt-15">
              <Text label="Account holder name" name="accountHolderName" />
            </div>
            <div className="input-group-add-payment">
              <Text
                label="Account number"
                labelIcon={
                  <CustomTooltip content="Your account number is the unique identifier assigned to your bank account.">
                    <InfoOutlinedIcon className="onboarding-add-payment-label-icon" />
                  </CustomTooltip>
                }
                name="accountNumber"
              />
              <Text
                label="ACH routing number"
                labelIcon={
                  <CustomTooltip content="Your ACH routing number is a 9-digit code that identifies your bank and branch.">
                    <InfoOutlinedIcon className="onboarding-add-payment-label-icon" />
                  </CustomTooltip>
                }
                name="routingNumber"
              />
            </div>

            <div className="flex flex-nowrap">
              <LockOutlinedIcon className="onboarding-lock-icon" />
              <p className="information-secure-text">Your information will be encrypted and securely stored.</p>
            </div>
            <div>
              <Button
                type="button"
                onClick={() => open()}
                name="connectViaPlaid"
                variant="text"
                size="medium"
                startIcon={<InsertLinkIcon />}
                label="Or Connect Your Bank via Plaid"
                isFullWidth={false}
              />
            </div>
            <div className="add-ach-note-wrapper">
              <p className="para-body-s-regular mt-2 pt-025 pb-125 color-text-body-primary">
                Note: <br />
                Only the current month's payroll will be charged. <br />
                If payment is not made, the start date for your employee(s) may be delayed.
              </p>
            </div>
          </form>
        </FormProvider>
      </div>
      <div className="onboarding-bottom-button">
        <div className="onboarding-bottom-button-container">
          {isStepSkippable ? (
            <Button onClick={onSkip} name="Skip" size="large" label="Skip" isFullWidth={false} />
          ) : (
            <Button
              onClick={handleSubmit(onSubmit)}
              name="Pay Now"
              size="large"
              label="Pay Now"
              isFullWidth={false}
              isDisabled={!isValid}
              isLoading={loading}
            />
          )}
        </div>
      </div>
      {loading && <AddPaymentLoaderModal open={loading} />}
    </div>
  );

  return (
    <Layout title="Confirm Your Payment">
      <div className="onboarding-add-payment-main-div">
        {renderContent()}
        <PaymentBreakdown
          totalAmount={4000}
          employeeCount={4}
          monthlyFee={4000}
          implementationFee={2000}
          proratedAdjustment={-2000}
          discount={-2000}
        />
      </div>
      <CustomSnackbar
        open={!!snackbarMessage}
        message={snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
        type="error"
      />
    </Layout>
  );
}

export default AddAchPayment;

import { useFlags } from "launchdarkly-react-client-sdk";

import { Typography, Button } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import EastIcon from "@mui/icons-material/East";

import vivoNewUserExperience from "assets/images/vivoNewUserExperience.png";

import { s3ImagesBaseURL } from "util/APIBaseUrl";

function NewUserExperience({ onStartNow }) {
  const { platfromRebrandPhase1 } = useFlags();

  return (
    <div className="newUserParentDiv">
      <div className="newUserExperience">
        <div>
          <img
            src={platfromRebrandPhase1 ? `${s3ImagesBaseURL}/welcome-to-vivo.svg` : vivoNewUserExperience}
            className="vivoNewUserExperience"
            alt="vivo user experience"
          />
        </div>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: "#292A3D",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "22px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "31px",
            letterSpacing: "0.1px",
          }}
        >
          Welcome to Vivo!
        </Typography>
        <Typography
          paragraph
          sx={{
            color: "#52537A",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            letterSpacing: "0.1px",
          }}
        >
          Your AI-powered companion designed to revolutionize the way you interact with technology.
        </Typography>

        <ul style={{ listStyle: "none", padding: 0, textAlign: "center" }}>
          <li className="newUserListItem">
            <DoneIcon sx={{ color: "#17A225" }} />
            <Typography variant="body1">Integration into Daily Workflow</Typography>
          </li>
          <li className="newUserListItem">
            <DoneIcon sx={{ color: "#17A225" }} />
            <Typography variant="body1">Continuous Learning</Typography>
          </li>
          <li className="newUserListItem">
            <DoneIcon sx={{ color: "#17A225" }} />
            <Typography variant="body1">Team Collaboration</Typography>
          </li>
        </ul>

        <Button
          variant="contained"
          endIcon={<EastIcon />}
          onClick={onStartNow}
          id="vivo_start_now"
          sx={{
            borderRadius: "42px",
            backgroundColor: "var(--temp-color-primitives-primary-800)",
            color: "#FFFFFF",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "0.1px",
            padding: "12px 24px",
            gap: "8px",
            marginTop: "20px",
          }}
        >
          Lets Start
        </Button>
      </div>
    </div>
  );
}

export default NewUserExperience;

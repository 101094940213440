import { useState, useEffect } from "react";

const browsers = [
  { name: "Opera", regex: /opera|opr/i },
  { name: "Samsung Browser", regex: /samsungbrowser/i },
  { name: "Edge", regex: /edg/i },
  { name: "Chrome", regex: /chrome|chromium|crios/i },
  { name: "Firefox", regex: /firefox|fxios/i },
  { name: "Safari", regex: /safari/i },
  { name: "Internet Explorer", regex: /msie|trident/i },
];

const osList = [
  { name: "Android", regex: /android/i },
  { name: "iOS", regex: /iphone|ipad|ipod/i },
  { name: "Windows", regex: /win/i },
  { name: "MacOS", regex: /mac/i },
  { name: "Linux", regex: /linux/i },
];

const getBrowserName = (userAgent) => {
  const browser = browsers.find((b) => b.regex.test(userAgent));
  return browser ? browser.name : "Unknown";
};

const getOSName = (userAgent) => {
  const os = osList.find((o) => o.regex.test(userAgent));
  return os ? os.name : "Unknown";
};

const useDeviceInfo = () => {
  const [deviceInfo, setDeviceInfo] = useState({
    browser: "",
    os: "",
    isAndroid: false,
    isIOS: false,
  });

  useEffect(() => {
    const { userAgent } = window.navigator;

    const browserName = getBrowserName(userAgent);
    const osName = getOSName(userAgent);
    const isAndroid = osName === "Android";
    const isIOS = osName === "iOS";

    setDeviceInfo({
      browser: browserName,
      os: osName,
      isAndroid,
      isIOS,
    });
  }, []);

  return deviceInfo;
};

export default useDeviceInfo;

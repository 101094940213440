import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Grid } from "@mui/material";

import WorkforceCard from "components/WorkforceCard/WorkforceCard";

import { EmployeePeers } from "store/actions/EmployeePeers";

import "./EmployeePeers.css";

function EmployeePeersCom() {
  const dispatch = useDispatch();
  const { platfromRebrandPhase1 } = useFlags();

  const peers = useSelector((state) => state.EmployeePeers);

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    dispatch(EmployeePeers(`employee/${loggedInUser?.userId}/peers`));
  }, []);

  return (
    <div
      className="ep-peers-list"
      style={{
        justifyContent: peers?.peers?.count >= 4 ? "space-between" : "normal",
      }}
    >
      {peers?.peers?.count ? (
        peers?.peers?.rows?.slice(0, 4).map((peer) => {
          return (
            <Grid sx={{ width: "176px" }} id={peer?.firstName || peer.employeeId}>
              <WorkforceCard data={peer} isEmployee />
            </Grid>
          );
        })
      ) : (
        <div
          style={{
            background: platfromRebrandPhase1 ? "var(--color-primitives-primary-50)" : "#F1EEFF",
            padding: "80px",
            width: "100%",
            borderRadius: "24px",
            border: "1px dashed #C0C2FE",
          }}
          id="no-peers-found"
        >
          <p
            style={{
              margin: "auto",
              textAlignLast: "center",
              width: "100%",
            }}
          >
            {peers?.peers?.message || "No Peers found"}
          </p>
        </div>
      )}
    </div>
  );
}

export default EmployeePeersCom;

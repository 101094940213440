import React from "react";
import Lottie from "lottie-react";

import Dialog from "components/common/Dialog";
import addPaymentLoader from "assets/loaders/addPaymentLoader.json";

import "./AddPaymentLoaderModal.scss";

function AddPaymentLoaderModal({ open = false }) {
  return (
    <Dialog
      open={open}
      content={
        <div className="onboarding-payment-loader-modal">
          <div className="lottie-animation-container">
            <Lottie animationData={addPaymentLoader} loop autoplay className="payment-lottie-animation" />
          </div>
          <div className="onboarding-payment-loader-content">
            <p className="heading-h2-semibold text-center color-text-headings-primary">
              Hang tight, We’re processing your payment...
            </p>
            <p className="onboaring-payment-loader-text">
              Please give us a moment to finalize your payment details. This may take a few seconds.
            </p>
          </div>
        </div>
      }
      size="sm"
    />
  );
}

export default AddPaymentLoaderModal;

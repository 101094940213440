import { LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILURE } from "../constants/Logout";

const INIT_STATE = {
  logoutUserdata: {},
  loadingLogoutUserdata: true,
};

export default (state = INIT_STATE, action) => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const anonymousKey = localStorage.getItem("anonymousKeyForLD");

  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        loadingLogoutUserdata: true,
      };
    case LOGOUT_SUCCESS:
      localStorage.setItem("communityLoginCheck", false);

      if (loggedInUser?.type == "edge") {
        window.location.replace("/admin");
      } else if (loggedInUser?.type == "customer" || loggedInUser?.type == "employee") {
        window.location.replace("/");
      }

      localStorage.clear();
      sessionStorage.clear();

      if (anonymousKey && anonymousKey !== "null") localStorage.setItem("anonymousKeyForLD", anonymousKey);

      return {
        ...state,
        loadingLogoutUserdata: false,
        logoutUserdata: action.payload,
      };

    case LOGOUT_FAILURE:
      return {
        ...state,
        loadingLogoutUserdata: false,
        logoutUserdata: "Error in  getting Club Data",
      };
    default:
      return state;
  }
};

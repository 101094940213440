import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import {
  HomeOutlined as HomeIcon,
  LocalAtmOutlined as LocalAtmOutlinedIcon,
  HistoryOutlined as HistoryOutlinedIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon,
  DateRangeOutlined as DateRangeOutlinedIcon,
  FlightTakeoffOutlined as FlightTakeoffOutlinedIcon,
  CreditCard as CreditCardIcon,
  SettingsOutlined as SettingsIcon,
  AccountBalanceWalletOutlined as AccountBalanceWalletOutlinedIcon,
  ForumOutlined as ForumOutlinedIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
  GroupAddOutlined as GroupAddOutlinedIcon,
  CorporateFareOutlined as CorporateFareOutlinedIcon,
  AttachMoneyOutlined as AttachMoneyOutlinedIcon,
  ManageAccountsOutlined as ManageAccountsOutlinedIcon,
  HelpOutline as HelpOutlineIcon,
  ReviewsOutlined as ReviewsOutlinedIcon,
} from "@mui/icons-material";

import { usePermissions } from "components/Hook";

import NavigationRoutes from "constants/NavigationRoutes";

import { REFERRAL_URLS } from "constants";

import VivoIcon from "assets/svgs/VivoIconRebrand";

export const useMenuItems = () => {
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const { loggedInUser, loggedInUserType } = usePermissions();

  return useCallback(() => {
    const {
      dashboard,
      payroll,
      payrollHistory,
      workforce,
      timeoffLeave,
      holidays,
      payment,
      settings,
      paystubs,
      edgeWallet,
      timeoff,
      peers,
      community,
      eprofile,
      vivo,
      customers,
      workforcePayrolls,
      payrollsUpload,
      timeoffAdmin,
      profile,
    } = NavigationRoutes;

    const items = [
      {
        id: "home",
        title: "Home",
        path: dashboard,
        icon: <HomeIcon />,
        category: "default",
      },
    ];

    if (loggedInUserType === "customer") {
      items.push(
        {
          id: "payroll",
          title: "Payroll information",
          path: payroll,
          icon: <LocalAtmOutlinedIcon />,
          category: "Payroll",
        },
        {
          id: "payroll-history",
          title: "Payroll history",
          path: payrollHistory,
          icon: <HistoryOutlinedIcon />,
          category: "Payroll",
        },
        {
          id: "workforce",
          title: "Workforce",
          path: workforce,
          icon: <PeopleAltOutlinedIcon />,
          category: "Workforce",
        },
        {
          id: "timeoff",
          title: "Time-off",
          path: timeoffLeave,
          icon: <FlightTakeoffOutlinedIcon />,
          category: "Workforce",
          divider: true,
        },
        {
          id: "holidays",
          title: "Holiday calendar",
          path: holidays,
          icon: <DateRangeOutlinedIcon />,
          category: "Workforce",
        }
      );

      if (userAdditionalInfo?.status === "enabled") {
        items.push({
          id: "payment-methods",
          title: "Payment method",
          path: payment,
          icon: <CreditCardIcon />,
          category: "Account",
        });
      }

      if (loggedInUser?.permissions?.includes("CustomerAdmin")) {
        items.push({
          id: "account-settings",
          title: "Account settings",
          path: settings,
          icon: <SettingsIcon />,
          category: "Account",
          divider: true,
        });
      }
    } else if (loggedInUserType === "employee") {
      items.push({
        id: "paystubs",
        title: "Pay slips",
        path: paystubs,
        icon: <LocalAtmOutlinedIcon />,
        category: "Payroll",
      });

      if (userAdditionalInfo?.allowedForPayoneer) {
        items.push({
          id: "edge-wallet",
          title: "Edge wallet",
          path: edgeWallet,
          icon: <AccountBalanceWalletOutlinedIcon />,
          category: "Payroll",
        });
      }

      if (userAdditionalInfo?.customerId && userAdditionalInfo?.timeoffFlag) {
        items.push({
          id: "timeoff",
          title: "Time-off",
          path: timeoff,
          icon: <FlightTakeoffOutlinedIcon />,
          category: "Time-off & holidays",
          divider: true,
        });
      }

      items.push({
        id: "holidays",
        title: "Holiday calendar",
        path: holidays,
        icon: <DateRangeOutlinedIcon />,
        category: "Time-off & holidays",
      });

      items.push(
        {
          id: "peers",
          title: "Team",
          path: peers,
          icon: <PeopleAltOutlinedIcon />,
          category: "Team",
        },
        {
          id: "community",
          title: "Community",
          path: community,
          icon: <ForumOutlinedIcon />,
          category: "Team",
          divider: true,
          openInNewTab: true,
        }
      );

      items.push(
        {
          id: "profile",
          title: "Profile",
          path: eprofile,
          icon: <PersonOutlineOutlinedIcon />,
          category: "Account",
        },

        {
          id: "vivo",
          title: "Vivo",
          path: vivo,
          icon: <VivoIcon width={24} />,
          category: "Edge resources",
          divider: true,
        },
        {
          id: "referrals",
          title: "Referrals",
          path: REFERRAL_URLS[userAdditionalInfo?.country],
          icon: <GroupAddOutlinedIcon />,
          category: "Edge resources",
          openInNewTab: true,
        }
      );
    } else if (loggedInUserType === "edge") {
      items.push(
        {
          id: "workforce",
          title: "Workforce",
          path: workforce,
          icon: <PeopleAltOutlinedIcon />,
          category: "Workforce & Customers",
        },
        {
          id: "customers",
          title: "Customers",
          path: customers,
          icon: <CorporateFareOutlinedIcon />,
          category: "Workforce & Customers",
          divider: true,
        }
      );

      if (loggedInUser?.permissions?.includes("EdgeAdmin")) {
        items.push(
          {
            id: "finances",
            title: "Finances",
            path: workforcePayrolls,
            icon: <AttachMoneyOutlinedIcon />,
            category: "Payroll",
          },
          {
            id: "payrolls-upload",
            title: "Upload payroll",
            path: payrollsUpload,
            icon: <LocalAtmOutlinedIcon />,
            category: "Payroll",
            divider: true,
          }
        );
      }

      items.push({
        id: "timeoff",
        title: "Time-off",
        path: timeoffAdmin,
        icon: <FlightTakeoffOutlinedIcon />,
        category: "Time-off",
      });

      if (loggedInUser?.permissions?.includes("EdgeAdmin")) {
        items.push({
          id: "account-settings",
          title: "Manage users",
          path: settings,
          icon: <ManageAccountsOutlinedIcon />,
          category: "Account",
        });
      }
      items.push({
        id: "profile",
        title: "Profile",
        path: profile,
        icon: <PersonOutlineOutlinedIcon />,
        category: "Account",
      });
    }

    return items;
  }, [loggedInUserType, loggedInUser, userAdditionalInfo]);
};

export const useStickyItems = () => {
  const { loggedInUserType } = usePermissions();
  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);

  return useCallback(() => {
    const { helpSupport, feedback } = NavigationRoutes;
    const items = [];

    if (loggedInUserType === "customer" || loggedInUserType === "employee") {
      items.push({
        id: "help",
        title: "Help & support",
        path: helpSupport,
        icon: <HelpOutlineIcon />,
        openInNewTab: true,
      });

      items.push({
        id: "feedback",
        title: "Feedback",
        path: feedback,
        icon: <ReviewsOutlinedIcon />,
      });
    }

    return items;
  }, [loggedInUserType, platformConfig]);
};

export default useMenuItems;

import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Alert, Divider, FormControl, Grid, List, ListItem, Snackbar, Typography } from "@mui/material";
import { Box } from "@mui/system";

import TextFieldV2 from "components/common/TextFieldV2/TextFieldV2";
import AlertIcon from "assets/svgs/AlertIcon";
import Button from "components/Button/Button";
import UserAvatarIcon from "assets/svgs/UserAvatarIcon";
import PencilIcon from "assets/svgs/PencilIcon";

function DisplayCompanyInfo(props) {
  const {
    data,
    title,
    hanldeEdit,
    editAbleModal,
    handleInputChange,
    fieldsData,
    handleCancel,
    handleSave,
    icon,
    bgColor,
    open,
    setOpenAlert,
    editCompanyInfo,
    saveInfo,
  } = props;

  const { platfromRebrandPhase1 } = useFlags();

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              <Typography variant="h6" color="secondary" fontSize="12px">
                {`${error?.msg || error?.description || ""} ${error?.param || ""}`}
              </Typography>
            </ListItem>
          </List>
        );
      });
    }
    return null;
  };

  return (
    <Grid
      container
      xs={12}
      sx={{
        background: "white",
        borderRadius: "12px",
        padding: "12px",
        paddingBottom: "32px",
      }}
    >
      <Grid container direction="row" alignItems="center" justifyContent="space-between">
        <Grid sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {icon && (
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: bgColor,
                  borderRadius: "99px",
                  width: "42px",
                  height: "42px",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "9px",
                }}
              >
                {title === "Company Information" && (
                  <AlertIcon color={title === "Company Information" ? "#00C0AF" : "#F9BD00"} />
                )}
                {title === "General Information" && (
                  <AlertIcon color={title === "Company Information" ? "#00C0AF" : "#F9BD00"} />
                )}
                {title === "Personal Information" && <UserAvatarIcon color="#F9BD00" size="24" />}
              </Box>
            </Grid>
          )}
          <Grid item>
            <Typography
              sx={{
                color: "#14151F",
                fontFamily: "Poppins-Medium !important",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "32px",
              }}
              id="title"
            >
              {title}
            </Typography>
          </Grid>
        </Grid>

        {!editAbleModal?.edit && (
          <Button
            variant="primary"
            size="sm"
            disableElevation
            leadIcon={<PencilIcon fill={platfromRebrandPhase1 ? "#8139d0" : "#131599"} />}
            style={{
              backgroundColor: "var(--color-primitives-primary-50)",
              borderRadius: "99px",
              marginRight: "4px",
              borderColor: "transparent",
            }}
            onClick={() => hanldeEdit(title)}
          >
            <Typography variant="h5" color="primary">
              Edit
            </Typography>
          </Button>
        )}
      </Grid>

      {/* info */}
      <Grid
        item
        container
        direction="column"
        sx={{
          mt: 2,
          ml: 1,
          mr: 2,
        }}
      >
        {editAbleModal?.modalName === title && editAbleModal?.edit ? (
          <>
            <Grid item container direction="row" spacing={2} alignItems="center">
              {data.map((info) => {
                return (
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth error>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #B2B4E2",
                          borderRadius: "12px",
                        }}
                      >
                        <TextFieldV2
                          label={info.title}
                          variant="filled"
                          name={info.name}
                          disabled={info.disabled}
                          id={info.name}
                          type={info.type}
                          select={info.type === "select"}
                          dropdownValues={info.type === "select" && info.dropdownValues}
                          value={fieldsData[info.name]}
                          onChange={(event) => handleInputChange(event, info.name)}
                          error={saveInfo && info.isRequired ? fieldsData[info.name] == "" : null}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                );
              })}
            </Grid>

            <Grid sx={{ mt: 2, display: "flex", gap: "6px" }}>
              <Button
                variant="primary"
                size="lg"
                disableElevation
                onClick={() => handleSave(editAbleModal?.modalName, editAbleModal?.edit)}
                style={{
                  borderRadius: "99px",
                  width: "99px",
                  height: "40px",
                  fontFamily: "Poppins-Medium",
                  fontSize: "13px",
                  lineHeight: "20px",
                }}
              >
                Save
              </Button>

              <Button
                onClick={() => handleCancel(editAbleModal?.modalName, editAbleModal?.edit)}
                variant="ghost"
                size="lg"
                style={{
                  borderRadius: "99px",
                  width: "99px",
                  height: "40px",
                  fontFamily: "Poppins-Medium",
                  fontSize: "13px",
                  lineHeight: "20px",
                  color: "var(--color-primitives-primary-600)",
                }}
              >
                Cancel
              </Button>

              <Grid item textAlign="center" sx={{ willChange: "transform" }}>
                <Snackbar
                  open={open}
                  autoHideDuration={2500}
                  onClose={handleAlertClose}
                  sx={{
                    "&.MuiSnackbar-root": {
                      position: "relative",
                      bottom: 0,
                      left: 0,
                    },
                  }}
                >
                  <Alert
                    onClose={handleAlertClose}
                    severity="error"
                    variant="filled"
                    icon={false}
                    sx={{
                      backgroundColor: "#313033",
                      padding: "initial",
                      px: "15px",
                      py: "8px",
                      fontSize: "14px",
                      textAlign: "left",
                      minWidth: "347px",

                      borderRadius: "12px !important",
                      color: "#ffffff !important",
                      "&.MuiPaper-root": {
                        color: "#ffffff !important",
                        boxShadow: "0px 4px 8px rgba(2, 0, 110, 0.18)",
                      },
                    }}
                  >
                    {editCompanyInfo?.ok == false
                      ? getError(editCompanyInfo?.payload?.error)
                      : "REQUIRED FIELD MISSING!"}
                  </Alert>
                </Snackbar>
              </Grid>
            </Grid>
          </>
        ) : (
          data.map((info, index) => {
            return (
              <>
                <Grid item container justifyContent="space-between" alignItems="center" pl={4} pr={8}>
                  <Grid item>
                    <Typography
                      variant="h3"
                      sx={{
                        fontFamily: "Poppins-Regular",
                        color: "#292A3D",
                      }}
                      fontSize="14px"
                      id="title"
                    >
                      {info.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      // variant="h5"
                      sx={{
                        fontFamily: "Poppins-Medium",
                        color: "#292A3D",
                      }}
                      fontSize="14px"
                      id="value-info"
                    >
                      {info.value}
                    </Typography>
                  </Grid>
                </Grid>
                {data.length - 1 !== index && (
                  <Grid item pl={4} pr={8}>
                    <Divider
                      light
                      sx={{
                        my: 0.75,
                        "&.MuiDivider-root": {
                          borderColor: "#d9daf266",
                          borderWidth: "0.1px",
                        },
                      }}
                    />
                  </Grid>
                )}
              </>
            );
          })
        )}
      </Grid>
    </Grid>
  );
}

export default DisplayCompanyInfo;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import { useFlags } from "launchdarkly-react-client-sdk";

import EdgeLogo from "components/Design/EdgeLogo/EdgeLogo";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import useView from "components/Hooks/useViewHook";

import { s3ImagesBaseURL } from "util/APIBaseUrl";

import LaptopMacIcon from "@mui/icons-material/LaptopMac";

import animationData from "./success-modal-animation.json";

import "./CalendlySuccessModal.scss";

function CalendlySuccessModal({ isOpen, onClose }) {
  const navigate = useNavigate();
  const { platfromRebrandPhase1 } = useFlags();
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  const { isMobile } = useView();

  const handleGoToHomepage = () => {
    if (isMobile) {
      setShowMobileWarning(true);
      onClose();
    } else {
      navigate("/main/dashboard");
      onClose();
    }
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose} containerClass="calendly-success-modal-container" width="1200px">
        <div className="calendly-success-modal">
          <div className="background-gif" />
          <div className="lottie-container">
            {animationData && <Lottie animationData={animationData} loop autoplay />}
          </div>
          <div className="modal-content">
            <img
              src={platfromRebrandPhase1 ? `${s3ImagesBaseURL}/all-set-rebrand.svg` : `${s3ImagesBaseURL}/all-set.svg`}
              alt="All Set"
              className="all-set-image"
            />
            <div className="heading-h1-semibold heading-color">You're all set!</div>
            <div className="para-body-m-regular description-color">Everything's in place. Let's get started!</div>
            <Button label="Go to Homepage" onClick={handleGoToHomepage} size="large" className="homepage-button" />
          </div>
        </div>
      </Modal>

      {showMobileWarning && (
        <div className="mobile-warning-modal-overlay">
          <div className="mobile-warning-modal">
            <EdgeLogo size="lg" state="dark" />

            <LaptopMacIcon className="laptop-icon" sx={{ width: "60px", height: "60px" }} />
            <h1 className="heading-h1-semibold mb-05 mt-0">Our platform is best viewed on desktop</h1>

            <p>
              For the best experience, please visit{" "}
              <span className="para-body-m-medium color-class">app.onedge.co</span> from a desktop or laptop to log in
              and start using the platform.
            </p>
            <br />
            <p className="para-body-m-regular ">
              Our platform is not yet optimized for mobile, but we're working on it and appreciate your patience!
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default CalendlySuccessModal;

import { useState } from "react";
import { useDispatch } from "react-redux";

import { IconButton, Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import {
  ArrowDropDown as ArrowDropDownCircleIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import CustomAvatar from "components/common/Avatar";
import Button from "components/common/Button";
import GetHelpSection from "components/Screens/CustomerOnboarding/GetHelpSection";

import { LogoutUser } from "store/actions/Logout";

import "./AvatarMenu.scss";

function AvatarMenu({ fullName, imageUrl, email }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);
  const [desktopMenuAnchor, setDesktopMenuAnchor] = useState(null);

  const handleOpenMobileMenu = (event) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleOpenDesktopMenu = (event) => {
    setDesktopMenuAnchor(event.currentTarget);
  };

  const handleCloseMobileMenu = () => {
    setMobileMenuAnchor(null);
  };

  const handleCloseDesktopMenu = () => {
    setDesktopMenuAnchor(null);
  };

  const handleLogoutClick = () => {
    dispatch(LogoutUser());
  };

  return (
    <div>
      {isMobile && (
        <div className="avatar-menu-mobile">
          <IconButton className="onboarding-avatar-menu-icon-button" onClick={handleOpenMobileMenu}>
            {mobileMenuAnchor ? <CloseIcon /> : <MenuIcon />}
          </IconButton>

          <div className="mobile-menu-main-div">
            <Menu
              className="onboarding-avatar-mobile-menu"
              anchorEl={mobileMenuAnchor}
              open={Boolean(mobileMenuAnchor)}
              onClose={handleCloseMobileMenu}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MenuItem>
                <div className="w-100p onboarding-mobile-menu-profile">
                  <CustomAvatar
                    className="mobile-menu-avatar"
                    alt={fullName}
                    variant="circular"
                    size="lg"
                    src={imageUrl}
                    sx={{
                      bgColor: "#C8C8C8",
                    }}
                  />
                  <p className="para-body-m-semibold color-text-body-secondary">{fullName}</p>
                  <p className="para-body-m-regular color-text-body-primary">{email}</p>
                </div>
              </MenuItem>

              <MenuItem onClick={handleLogoutClick}>
                <div className="mt-050">
                  <Button
                    startIcon={<LogoutIcon className="onboarding-avatar-menu-icon-logout mr-050" />}
                    onClick={handleLogoutClick}
                    color="error"
                    variant="outlined"
                    label="Logout"
                    size="large"
                  />
                </div>
              </MenuItem>

              <GetHelpSection />
            </Menu>
          </div>
        </div>
      )}

      {!isMobile && (
        <div className="avatar-menu-desktop flex items-center gap-025">
          <CustomAvatar
            className="avatar-desktop-menu-avatar"
            alt={fullName}
            variant="circular"
            size="sm"
            src={imageUrl}
          />
          <p className="pl-025 para-body-s-regular">{fullName || "N/A"}</p>
          <IconButton onClick={handleOpenDesktopMenu} className="onboarding-avatar-menu-icon-container">
            <ArrowDropDownCircleIcon className="onboarding-avatar-menu-icon" />
          </IconButton>

          <Menu
            className="onboarding-avatar-desktop-menu"
            anchorEl={desktopMenuAnchor}
            open={Boolean(desktopMenuAnchor)}
            onClose={handleCloseDesktopMenu}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MenuItem onClick={handleLogoutClick}>
              <LogoutIcon className="onboarding-avatar-menu-icon-logout mr-1" />
              <p className="para-body-l-regular">Logout</p>
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
}

export default AvatarMenu;

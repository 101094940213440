import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { getTextColorClass, showIcon } from "./utils";

import CircleCross from "assets/images/circle-x.svg";
import CircleCheck from "assets/images/circle-checked.svg";

import "components/common/ErrorsIndicatorList/ErrorsIndicatorList.scss";

function ErrorsIndicatorList({ errors }) {
  const { integratedContractSigning } = useFlags();

  if (integratedContractSigning)
    return (
      <div className="flex flex-col gap-025 error-indicator-list-container">
        {errors.map((err) => {
          const { isChecked = null, message = "" } = err;
          return (
            <span className="flex gap-05">
              {showIcon(isChecked)}
              <div className={`para-body-s-regular text-color-${getTextColorClass(isChecked)}`}>{message}</div>
            </span>
          );
        })}
      </div>
    );

  // TODO - old to be removed
  // used in /Users/usamanaseer/Desktop/Edge/frontend/src/components/Screens/Login/ChangePassword/index.js
  // used in /Users/usamanaseer/Desktop/Edge/frontend/src/components/Screens/Login/SignIn/ResetPasswordV2.js
  return (
    <div
      style={{
        borderRadius: "12px",
        border: "1px solid var(--color-primitives-primary-50)",
        background: "#fff8f8",
        margin: "18px 0",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {errors.map((err) => (
        <span
          style={{
            display: "flex",
            gap: "8px",
            // marginLeft: "1rem",
          }}
        >
          <img style={{ alignSelf: "flex-start" }} src={err?.isChecked ? CircleCheck : CircleCross} alt="icon" />
          <div
            style={{
              fontFamily: "Poppins-Regular",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 400,
              color: "#5E5D67",
            }}
          >
            {err.message}
          </div>
        </span>
      ))}
    </div>
  );
}

export default ErrorsIndicatorList;

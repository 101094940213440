import React from "react";
import PropTypes from "prop-types";
import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import "./tooltip.scss";

function CustomTooltip({ content, children, direction = "top", arrow = true, className = "", offset = 0, ...other }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const tooltipProps = {
    title: content,
    placement: direction,
    arrow,
    classes: {
      tooltip: "custom-tooltip",
      arrow: "custom-arrow",
    },
    className,
    ...other,
    open: isMobile ? open : undefined,
    onClick: isMobile ? handleToggle : undefined,
    onMouseEnter: !isMobile ? () => setOpen(true) : undefined,
    onMouseLeave: !isMobile ? () => setOpen(false) : undefined,
  };

  if (offset) {
    tooltipProps.PopperProps = {
      modifiers: [
        {
          name: "offset",
          options: {
            offset: Array.isArray(offset) ? offset : [0, offset],
          },
        },
      ],
    };
  }

  return <Tooltip {...tooltipProps}>{children}</Tooltip>;
}

CustomTooltip.propTypes = {
  content: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf([
    "top",
    "bottom",
    "left",
    "right",
    "top-start",
    "top-end",
    "bottom-start",
    "bottom-end",
    "left-start",
    "left-end",
    "right-start",
    "right-end",
  ]),
  arrow: PropTypes.bool,
  className: PropTypes.string,
  offset: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
};

CustomTooltip.defaultProps = {
  direction: "top",
  arrow: true,
  className: "",
  offset: undefined,
};

export default CustomTooltip;

import { useState, useEffect, useId } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import GreetingHeader from "components/GreetingHeader/GreetingHeader";
import Button from "components/Button/Button";
import WorkforceCard from "components/WorkforceCard/WorkforceCard";
import BarChartV2 from "components/Graphs/BarChartV2";
import PageLoader from "components/common/PageLoader";
import {
  getValue,
  getMonthFromDate,
  getDayMonthFromDate,
  currencyFormat,
  getMonthNameOnly,
  isEmptyObject,
} from "components/Screens/CommonUtils";
import Notifications from "components/Screens/DashBoard/CustomerDashBoardV2/Notifications";
import MissingPaymentMethodBanner from "components/Screens/DashBoard/CustomerDashBoardV2/MissingPaymentMethodBanner";
import OnboardedCustomerInitialview from "components/Screens/DashBoard/CustomerDashBoardV2/OnboardedCustomerInitialView";

import { s3ImagesBaseURL } from "util/APIBaseUrl";
import { getLoggedInUserDetailsFromLocalStorage } from "helpers";

import { PayrollSummary } from "store/actions/PayrollSummary";
import { EmployeeInfo } from "store/actions/EmployeeInfo";
import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";
import { getCustomerOnboardingDetails } from "services/customer";

import CreditCardIcon from "assets/svgs/CreditCardIcon";
import UserAvatarIcon from "assets/svgs/UserAvatarIcon";

function CustomerDashBoardV2() {
  const id = useId();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { platfromRebrandPhase1, integratedContractSigning, inAppNotifications } = useFlags();

  const [payrollSummaryGraphData, setPayrollSummaryGraphData] = useState([]);
  const [customerOnboardingDetails, setCustomerOnboardingDetails] = useState({});

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);
  const employeesInfo = useSelector((state) => state.EmployeeInfo.employeesInfo);
  const loadingEmployeesInfo = useSelector((state) => state.EmployeeInfo.loadingEmployeesInfo);
  const payrollSummary = useSelector((state) => state.PayrollSummary.payrollSummary);
  const loadingPayrollSummary = useSelector((state) => state.PayrollSummary.loadingPayrollSummary);

  const { associateCount = 0, firstChargeDetails, calendlyData } = customerOnboardingDetails ?? {};
  const { paymentStatus } = firstChargeDetails ?? {};
  const meetingStatusSchdueled = calendlyData && !isEmptyObject(calendlyData || {});

  const isReadyToExpandTeam = paymentStatus === "paid" && associateCount && meetingStatusSchdueled;

  useEffect(() => {
    const loggedInUser = getLoggedInUserDetailsFromLocalStorage();
    const { customerId = null, permissions } = loggedInUser;

    const hasCustomerAdminAccess = permissions?.includes("CustomerAdmin");

    const getOnboardingDetails = async () => {
      const response = await getCustomerOnboardingDetails(customerId, dispatch);
      setCustomerOnboardingDetails(response);
    };

    if (customerId && hasCustomerAdminAccess) {
      getOnboardingDetails();
    }
  }, []);

  useEffect(() => {
    if (userAdditionalInfo?.customerId) {
      dispatch(
        EmployeeInfo({
          customerID: userAdditionalInfo?.customerId,
          rowsPerPage: 10,
          page: 0,
          dist: null,
        })
      );

      dispatch(
        PayrollSummary({
          customerID: userAdditionalInfo?.customerId,
          rowsPerPage: 6,
          page: 0,
        })
      );
    }
  }, [userAdditionalInfo]);

  useEffect(() => {
    const payrollData = [];
    if (Object.keys(payrollSummary).length && payrollSummary?.ok && payrollSummary?.payload?.rows?.length) {
      payrollSummary.payload.rows.reverse().forEach((payroll) => {
        let amount = 0;
        if (payroll?.totalPayroll) {
          amount = getValue(payroll?.totalPayroll);
          if (payroll?.totalBonus) {
            amount -= getValue(payroll?.totalBonus);
          }
        }

        payrollData.push({
          month: getMonthFromDate(payroll?.paymentDate),
          amount,
          bonus: payroll?.totalBonus || 0,
        });
      });
    }
    setPayrollSummaryGraphData(payrollData);
  }, [payrollSummary]);

  useEffect(() => {
    let loggedInUser = {};
    let request = null;
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser?.customerId != undefined) {
      request = `customer/${loggedInUser?.customerId}/info`;

      dispatch(UserAdditionalInfo(request));
    }
  }, []);

  return (
    <>
      {loadingEmployeesInfo == true || loadingAdditionalInfo == true || loadingPayrollSummary == true ? (
        <PageLoader />
      ) : null}
      {!userAdditionalInfo?.paymentMethod && <MissingPaymentMethodBanner />}
      <Grid
        continer
        display="flex"
        sx={{
          background: "#FBFAFF",
          minHeight: "calc(100vh - 70px)",
          marginTop: "5px",
        }}
      >
        <Grid
          container
          rowSpacing={3}
          mt={0}
          sx={{
            background: "#FBFAFF",
            width: inAppNotifications ? "100%" : "calc(100% - 328px)",
            display: "flex",
            flexDirection: "column",
            gap: "0px",
          }}
        >
          <GreetingHeader />

          <Grid container>
            <Grid
              sx={{
                width: "752px",
                marginX: "auto",
                paddingTop: "26px",
              }}
            >
              <div>
                {integratedContractSigning && (
                  <OnboardedCustomerInitialview customerOnboardingDetails={customerOnboardingDetails} />
                )}
              </div>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                    maxWidth: "452px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins-Medium",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#14151F",
                    }}
                    id="payroll-month"
                  >
                    Payroll This Month ({getMonthNameOnly(getValue(userAdditionalInfo?.paymentDate))})
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#918F9A",
                    }}
                  >
                    Add bonuses, raises or increments for the cycle of{" "}
                    {getMonthNameOnly(getValue(userAdditionalInfo?.paymentDate))} before the 16th
                  </Typography>
                </Grid>
                <Grid>
                  <Button
                    id="edit-payroll-button"
                    variant="secondary"
                    size="sm"
                    onClick={() => navigate("/main/customers/editPayroll")}
                  >
                    Edit payroll for {getMonthFromDate(getValue(userAdditionalInfo?.paymentDate))}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              sx={{
                width: "752px",
                marginX: "auto",
                paddingTop: "20px",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  gap: "16px",
                  alignItems: "center",
                }}
              >
                <Grid sx={{ width: "176px", height: "160px" }} onClick={() => navigate("/main/payroll")}>
                  <Grid
                    sx={{
                      height: "100%",
                      borderRadius: "24px",
                      background: platfromRebrandPhase1
                        ? "linear-gradient(220.8deg, #FFE0D7 4.69%, var(--color-primitives-primary-100) 43.22%, #D9F5EA 85.44%)"
                        : "linear-gradient(220.8deg, #FFEFD3 4.69%, #B4FFF2 43.22%, var(--color-primitives-primary-100) 85.44%)",
                      padding: "40px 18px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      "&:hover": {
                        boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <CreditCardIcon
                      color="var(--color-primitives-primary-900)"
                      style={{ width: "24px", height: "24px" }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Semibold",
                        fontSize: "22px",
                        lineHeight: "32px",
                        color: "#212231",
                        whiteSpace: "nowrap",
                      }}
                      id="customer-total-payroll"
                    >
                      ${currencyFormat(getValue(userAdditionalInfo?.totalPayroll))}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#212231",
                      }}
                      id="customer-total-employees"
                    >
                      Total payroll for {getDayMonthFromDate(getValue(userAdditionalInfo?.paymentDate)).slice(0, 3)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ width: "176px", height: "160px" }} onClick={() => navigate("/main/workforce")}>
                  <Grid
                    sx={{
                      height: "100%",
                      borderRadius: "24px",
                      background: "#FFFFFF",
                      padding: "40px 18px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      "&:hover": {
                        boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <UserAvatarIcon color="var(--color-primitives-primary-900)" size="24" />
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Semibold",
                        fontSize: "22px",
                        lineHeight: "32px",
                        color: "#212231",
                      }}
                    >
                      {currencyFormat(getValue(userAdditionalInfo?.totalEmployees))}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#212231",
                      }}
                    >
                      Total employees
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ width: "368px", height: "160px" }}>
                  <Grid
                    sx={{
                      height: "100%",
                      borderRadius: "24px",
                      background: "#FFFFFF",
                      padding: "12px",
                      flexDirection: "column",
                      gap: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    id="graph-customer-payroll"
                  >
                    <BarChartV2 payrollData={payrollSummaryGraphData} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {!!employeesInfo?.count && (
            <Grid container>
              <Grid
                sx={{
                  width: "752px",
                  marginX: "auto",
                  paddingTop: "32px",
                  paddingBottom: !isReadyToExpandTeam && "32px",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                      maxWidth: "452px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Medium",
                        fontSize: "15px",
                        lineHeight: "22px",
                        color: "#14151F",
                      }}
                    >
                      Your Workforce ({employeesInfo?.count})
                    </Typography>
                  </Grid>
                  <Grid>
                    <Button
                      variant="secondary"
                      id="view-all-workforce"
                      size="sm"
                      onClick={() => navigate("/main/Workforce")}
                    >
                      View All
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    paddingTop: "15px",
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  {employeesInfo?.rows?.length ? (
                    <>
                      {" "}
                      {employeesInfo?.rows?.slice(0, 4)?.map((i) => {
                        return (
                          <Grid sx={{ width: "176px" }} id={i?.firstName || i?.employeeId}>
                            <WorkforceCard key={id} data={i} />
                          </Grid>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        background: platfromRebrandPhase1 ? "var(--color-primitives-primary-50)" : "#F1EEFF",
                        padding: "80px",
                        width: "100%",
                        borderRadius: "24px",
                        border: "1px dashed #C0C2FE",
                      }}
                    >
                      <p
                        id="no-workforce"
                        style={{
                          margin: "auto",
                          textAlignLast: "center",
                          width: "100%",
                        }}
                      >
                        No Workforce assigned
                      </p>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}

          {!!isReadyToExpandTeam && (
            <Grid container>
              <Grid
                sx={{
                  width: "752px",
                  marginX: "auto",
                  paddingBottom: "32px",
                  paddingTop: "32px",
                }}
              >
                <Grid
                  container
                  sx={{
                    background: "#FFFFFF",
                    border: "1px solid #BFC2FF",
                    borderRadius: "12px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "space-between",
                      gap: "30px",
                    }}
                  >
                    <Box
                      component="img"
                      src={
                        platfromRebrandPhase1
                          ? `${s3ImagesBaseURL}/expand-team.svg`
                          : "../assets/Images/employee-hiring.png"
                      }
                      sx={{ width: "80px", height: "86px" }}
                    />

                    <Grid
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignSelf: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--color-primitives-primary-600)",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                          fontFamily: "Poppins-Medium",
                          marginBottom: "5px",
                        }}
                      >
                        Need to expand your team?
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "12px",
                          lineHeight: "18px",
                          maxWidth: "450px",
                          color: "#52537A",
                        }}
                      >
                        We are constantly hiring the best candidates from around the globe! Reach out to us to learn
                        more about our marketplace
                      </Typography>
                    </Grid>
                    <Button
                      variant="primary"
                      size="lg"
                      onClick={() => navigate("/main/contact")}
                      style={{
                        alignSelf: "center",
                      }}
                      id="get-in-touch-to-expand-team"
                    >
                      Get in touch
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        {!inAppNotifications && (
          <Grid
            sx={{
              width: "328px",
              height: "calc(100vh - 46px)",
              position: "fixed",
              background: "#F5F5FF",
              right: 0,
            }}
            id="notifications"
          >
            <Notifications />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default CustomerDashBoardV2;

/* eslint-disable no-nested-ternary */
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import {
  Button,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
  Alert,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import TextFieldV2 from "../../../common/TextFieldV2/TextFieldV2";

import { EmailOutlined, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { SignInScreenPermissions } from "../SignIn.enum";

import GoogleSignup from "./GoogleSignup";
import PageLoader from "components/common/PageLoader";

import { ResetSigunp, Sigunp } from "store/actions/SignUp/SignUp";
import { DropdownData } from "store/actions/DropdownData";

import loginImage from "components/Icons/login-image.png";
import EdgeIcon from "assets/svgs/edgeIcon";

const userCredentials = {
  email: "",
  password: "",
  loginPortal: null,
  confirmPassword: "",
};

function SignUp(props) {
  const { changeScreen } = props;
  const { handleSubmit } = useForm();

  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const params = useParams();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [userCredential, setUserCredential] = React.useState(userCredentials);
  const [loginButtonClicked, setLoginButtonClicked] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [passwordMatch, setPasswordMatch] = React.useState(true);

  const signUpIfno = useSelector((state) => state.SignUp.signUpIfno);
  const loadingSignupInfo = useSelector((state) => state.SignUp.loadingSignupInfo);

  const googleSignupInfo = useSelector((state) => state.GoogleSignup.googleSignupInfo);

  const dropdownData = useSelector((state) => state.DropdownData.dropdownData);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setUserCredential({
      ...userCredential,
      [event.target.name]: value,
    });
  };

  const signUpHandler = () => {
    setLoginButtonClicked(true);

    if (userCredential.email != "" && userCredential.password != "") {
      if (userCredential.password == userCredential.confirmPassword) {
        setOpenAlert(false);
        setPasswordMatch(true);
        dispatch(
          Sigunp({
            request: "customerUser/signUp",
            userCredential,
          })
        );
      } else if (userCredential.password != userCredential.confirmPassword) {
        dispatch(ResetSigunp({}));
        setPasswordMatch(false);
        setOpenAlert(true);
      }
    } else {
      setOpenAlert(true);
    }
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },

              "&.MuiList-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              {`${error?.msg ? error.msg : error?.description ? error.description : "Undefined Error"} ${
                error?.param ? error?.param : " "
              }`}
            </ListItem>
          </List>
        );
      });
    }
    return null;
  };

  // eslint-disable-next-line consistent-return
  const checkError = (field, view) => {
    if (loginButtonClicked) {
      if (view != "textField") {
        if (userCredential[field]?.key != null) {
          return false;
        }
        return true;
      }
      if (userCredential[field] != "") {
        return false;
      }
      return true;
    }
  };

  // methods
  React.useEffect(() => {
    if (googleSignupInfo?.ok == false) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  }, [googleSignupInfo]);

  React.useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const accesstoken = localStorage.getItem("accessToken");

    if (accesstoken != null && accesstoken != "undefined" && loggedInUser != null && loggedInUser != "undefined") {
      dispatch(DropdownData());
    }
    if (Object.keys(signUpIfno).length && signUpIfno.ok != true) {
      setOpenAlert(true);
    }
  }, [signUpIfno]);

  React.useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const accesstoken = localStorage.getItem("accessToken");

    if (
      accesstoken != null &&
      accesstoken != "undefined" &&
      loggedInUser != null &&
      loggedInUser != "undefined" &&
      Object.keys(dropdownData).length
    ) {
      if (loggedInUser) {
        if (loggedInUser?.setupCompany) {
          changeScreen(SignInScreenPermissions.ADD_CUSTOMER_INFO);
        } else if (!loggedInUser?.bankAttached && userAdditionalInfo.status === "enabled") {
          changeScreen(SignInScreenPermissions.ADD_PAYMENT_METHOD);
        } else {
          navigateTo("/main/dashboard");
        }
      }
    }
  }, [dropdownData]);

  React.useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const accesstoken = localStorage.getItem("accessToken");

    if (
      userAdditionalInfo != null &&
      Object.keys(userAdditionalInfo).length &&
      accesstoken != null &&
      accesstoken != "undefined" &&
      loggedInUser != null &&
      loggedInUser != "undefined"
    ) {
      if (loggedInUser?.type == "customer") {
        if (!loggedInUser?.bankAttached && userAdditionalInfo.status === "enabled") {
          changeScreen(SignInScreenPermissions.ADD_PAYMENT_METHOD);
        } else {
          navigateTo("/main/dashboard");
        }
      } else if (loggedInUser?.type == "edge") {
        navigateTo("/main/dashboard");
      }
    }

    if (userAdditionalInfo != null && Object.keys(userAdditionalInfo).length > 0 && userAdditionalInfo.ok != true) {
      setOpenAlert(true);
    }
  }, [userAdditionalInfo]);

  return (
    <>
      {loadingSignupInfo && <PageLoader />}
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        sx={{ backgroundColor: "#FFFFFF", overflowY: "auto" }}
      >
        <Grid
          item
          xl={7}
          lg={7}
          md={7}
          sm={12}
          xs={12}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          padding="20px 0px"
        >
          <form onSubmit={handleSubmit(signUpHandler)}>
            <Grid item container direction="column">
              <Grid item>
                <EdgeIcon />

                {/*               
              <img
                style={{
                  height: "auto",
                  maxWidth: "100%",
                }}
                alt="edge"
                src={edgeLogo}
              /> */}
              </Grid>

              <Grid item sx={{ paddingTop: "20px !important" }}>
                <Typography
                  sx={{
                    fontFamily: "Poppins-Medium !important",
                    color: "#000000",
                    fontSize: "32px",
                    fontWeight: "600",
                    lineHeight: "44px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Get started with Edge
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular !important",
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                  }}
                >
                  Enter the following details to begin!
                </Typography>
              </Grid>

              <Grid continer sx={{ maxWidth: "318px", width: "100%" }}>
                <Grid item mt="20px">
                  <FormControl variant="outlined" fullWidth error>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #B2B4E2",
                        borderRadius: "12px",
                      }}
                    >
                      <EmailOutlined
                        sx={{
                          color: "#3D3E5C",
                          marginLeft: "18px",
                        }}
                      />
                      <TextFieldV2
                        label="Email Address"
                        variant="filled"
                        name="email"
                        id="email"
                        type="text"
                        onChange={handleInputChange}
                        error={checkError("email", "textField")}
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Box>
                  </FormControl>
                </Grid>

                <Grid item mt="18px">
                  <FormControl variant="outlined" fullWidth>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #B2B4E2",
                        borderRadius: "12px",
                        paddingLeft: "4px",
                      }}
                    >
                      <TextFieldV2
                        label="Password"
                        variant="filled"
                        name="password"
                        id="password"
                        onChange={handleInputChange}
                        error={checkError("password", "textField")}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOutlined
                                    sx={{
                                      color: "#3D3E5C",
                                    }}
                                  />
                                ) : (
                                  <VisibilityOffOutlined
                                    sx={{
                                      color: "#3D3E5C",
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </FormControl>
                </Grid>

                <Grid item mt="18px">
                  <FormControl variant="outlined" fullWidth>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #B2B4E2",
                        borderRadius: "12px",
                        paddingLeft: "4px",
                      }}
                    >
                      <TextFieldV2
                        label="Confirm Password"
                        variant="filled"
                        name="confirmPassword"
                        id="password"
                        onChange={handleInputChange}
                        error={checkError("confirmPassword", "textField")}
                        type={showConfirmPassword ? "text" : "password"}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownConfirmPassword}
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOutlined
                                    sx={{
                                      color: "#3D3E5C",
                                    }}
                                  />
                                ) : (
                                  <VisibilityOffOutlined
                                    sx={{
                                      color: "#3D3E5C",
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </FormControl>
                </Grid>

                {signUpIfno?.ok == false || passwordMatch == false || googleSignupInfo?.ok == false ? (
                  <Grid item mt="20px">
                    <Alert
                      open={openAlert}
                      autoHideDuration={3500}
                      variant="filled"
                      severity="error"
                      sx={{
                        backgroundColor: "#F64A14",
                        padding: "initial",
                        px: "5px",
                        fontSize: "12px",
                        textAlign: "center",
                        borderRadius: "5px !important",
                        color: "#ffffff !important",
                        "&.MuiPaper-root": {
                          color: "#ffffff !important",
                        },
                      }}
                    >
                      {signUpIfno?.ok == false && passwordMatch
                        ? getError(signUpIfno?.payload?.error)
                        : googleSignupInfo?.ok == false
                          ? getError(googleSignupInfo?.payload?.description)
                          : passwordMatch == false
                            ? "Passwords do not match"
                            : " Enter all information before proceeding"}
                    </Alert>
                  </Grid>
                ) : null}

                <Grid item mt="20px">
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "var(--color-primitives-primary-600)",
                      height: "54px",
                      fontFamily: "Poppins-Regular !important",
                      fontWeight: "500",
                      lineHeight: "20px",
                      borderRadius: "99px",
                      fontSize: "16px",
                      color: "white",
                      boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
                    }}
                  >
                    Continue
                  </Button>
                </Grid>

                <Divider sx={{ margin: "20px 0px" }} />

                <Grid item>
                  <GoogleSignup changeScreen={changeScreen} loginPortal={params?.loginPortal} />
                </Grid>

                <Grid item textAlign="center" mt="45px">
                  <Link
                    sx={{
                      color: "#000000",
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium !important",
                      fontWeight: 500,
                      textDecoration: "none",
                    }}
                  >
                    Already a member?{" "}
                    <Link
                      onClick={() => navigateTo("/")}
                      sx={{
                        color: "var(--color-primitives-primary-600) !important",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      Sign In
                    </Link>
                  </Link>
                </Grid>

                <Grid item mt="20px">
                  <Typography
                    sx={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      textAlign: "center",
                      color: "#000000",
                    }}
                  >
                    By clicking continue, you agree to Edge terms and services.
                  </Typography>
                </Grid>

                <Grid item mt="20px">
                  <Typography
                    sx={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      textAlign: "center",
                      color: "#B3B3B3",
                    }}
                  >
                    © Copyright 2010 - 2023 Edge, LLC
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
          backgroundColor="var(--color-primitives-primary-600)"
          minHeight="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img
            style={{
              height: "auto",
              backgroundColor: "var(--color-primitives-primary-600)",
              objectFit: "cover",
              maxWidth: "400px",
              width: "100%",
              "@media screen and (min-width: 912px)": {
                objectFit: "cover",
                maxWidth: "400px",
                width: "100%",
              },
            }}
            alt="The house from the offer."
            src={loginImage}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default SignUp;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useFlags } from "launchdarkly-react-client-sdk";

import Text from "components/common/Text";
import TextCheckBox from "components/common/TextCheckBox";
import Button from "components/common/Button";
import DragAndDrop from "components/common/DragAndDrop";
import { AlertType } from "components/common/Snackbar";
import SelectedFile from "components/common/DragAndDrop/SelectedFile";
import Modal from "components/common/Modal";

import {
  getUploadDocumentSignedUrlForExistingEmployee,
  saveDocumentsOfEmployee,
} from "services/BambooHrTabs/documents";
import { showSnackbar } from "store/actions/Utility";

import { MAX_FILE_SIZE_LIMIT, MAX_FILE_SIZE_LIMIT_TEXT } from "constants";

import "../Document.scss";

const MAX_FILE_UPLOAD_COUNT = 10;
const SUPPORTED_FORMATS = ["application/pdf", "image/png", "image/jpeg"];

function DocumentModal({ type, open, onClose, onSave, modalData, documentType, isLoading, fetchEmployeeDocumentList }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { bambooHrBulkUpload } = useFlags();

  const [fileName, setFileName] = useState("");
  const [shareWithEmployee, setShareWithEmployee] = useState(false);
  const [shareWithCustomer, setShareWithCustomer] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [files, setFiles] = useState([]);

  // TODO: legacy code should be removed when removing after LD flag bambooHrBulkUpload is removed
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const [updatedFileName, setUpdatedFileName] = useState(null);

  useEffect(() => {
    if (modalData) {
      setFileName(modalData?.documentName);
      setShareWithEmployee(modalData?.shareWithEmployee);
      setShareWithCustomer(modalData?.shareWithCustomer);
    } else {
      setFileName("");
      setShareWithEmployee(false);
      setShareWithCustomer(false);
    }
  }, [modalData]);

  useEffect(() => {
    if (open && type === "add") {
      setFileName("");
    }
  }, [open, type]);

  const handleInputField = (e) => {
    setFileName(e.target.value);
  };

  const handleFileUpload = (selectedFiles) => {
    selectedFiles.map(async (fileMetaData) => {
      const { file } = fileMetaData;
      const unixTimeStamp = Math.floor(Date.now() / 1000);
      const newFileName = `${unixTimeStamp}_${file.name}`;

      setUpdatedFileName(newFileName);
      let response = null;
      try {
        response = await getUploadDocumentSignedUrlForExistingEmployee(
          newFileName,
          file.type,
          file.size,
          documentType,
          params.id
        );
      } catch (error) {
        dispatch(
          showSnackbar({
            type: AlertType.error,
            message: error?.message,
            dispatch,
          })
        );
      }

      try {
        const options = {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setFiles((prev) =>
              prev.map((item) =>
                item.id === fileMetaData.id
                  ? { ...item, retry: false, status: "uploading", error: false, progress: percentage }
                  : item
              )
            );
          },
        };

        await axios.put(response?.url, file, options);

        setFiles((prev) =>
          prev.map((item) =>
            item.id === fileMetaData.id ? { ...item, uniqueFileName: newFileName, status: "complete" } : item
          )
        );
      } catch (error) {
        setFiles((prev) =>
          prev.map((item) =>
            item.id === fileMetaData.id ? { ...item, status: "failed", error: "Network error", retry: true } : item
          )
        );

        dispatch(
          showSnackbar({
            type: AlertType.error,
            message: error?.message,
            dispatch,
          })
        );
      }
    });
  };

  const handleCloseModal = () => {
    setFiles([]);
    onClose();
  };

  const isSaveButtonDisabled = () => {
    const hasCompleteFile = files.some((file) => file.status === "complete");
    const hasUploadingFile = files.some((file) => file.status === "uploading");

    if (hasCompleteFile && !hasUploadingFile) {
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    const apiPayload = files
      .map((fileData) => {
        const { uniqueFileName = "", file = {}, status } = fileData || {};
        const { type: fileType, size } = file || {};

        if (status === "complete")
          return {
            fileName: uniqueFileName,
            documentName: fileData.fileName,
            fileType,
            size,
            documentType,
          };
        return null;
      })
      .filter(Boolean);

    setIsSaving(true);

    try {
      await saveDocumentsOfEmployee(params.id, { files: apiPayload });

      dispatch(showSnackbar({ type: AlertType.success, message: "Document added successfully", dispatch }));
    } catch (error) {
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    } finally {
      fetchEmployeeDocumentList();
      setIsSaving(false);
      handleCloseModal();
    }
  };

  const legacyIsSaveAllowed = () => {
    if (type === "add") {
      return fileUploadStatus && fileName;
    }
    return !!fileName;
  };

  const legacyHandleFileUpload = async (selectedFiles) => {
    if (selectedFiles.length === 0) {
      setFileUploadStatus(false);
    } else {
      setUploadProgress(0);
      setFileUploadStatus(false);
      const { file } = selectedFiles[0];
      setSelectedFile(file);
      const unixTimeStamp = Math.floor(Date.now() / 1000);
      const newFileName = `${unixTimeStamp}_${file.name}`;

      setUpdatedFileName(newFileName);
      let response = null;
      try {
        response = await getUploadDocumentSignedUrlForExistingEmployee(
          newFileName,
          file.type,
          file.size,
          documentType,
          params.id
        );
      } catch (error) {
        dispatch(
          showSnackbar({
            type: AlertType.error,
            message: error?.message,
            dispatch,
          })
        );
      }

      try {
        const options = {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentage);
          },
        };
        await axios.put(response?.url, file, options);

        setFileUploadStatus(true);
      } catch (error) {
        setFileUploadStatus(true);
        setUploadProgress(0);
        dispatch(
          showSnackbar({
            type: AlertType.error,
            message: error?.message,
            dispatch,
          })
        );
      }
    }
  };

  const legacyHandleSave = async () => {
    await onSave({ fileName, updatedFileName, selectedFile, shareWithEmployee, shareWithCustomer });
    setFileName("");
    setShareWithCustomer(false);
    setShareWithEmployee(false);
    setFiles([]);
    onClose();
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function LegacyDocumentModal() {
    return (
      <Modal
        width="652px"
        title={type === "add" ? "Add Document" : "Edit Document"}
        open={open}
        onClose={() => onClose()}
      >
        <div className="mt-15 mb-1">
          {modalData ? (
            <SelectedFile
              files={[
                {
                  id: 1,
                  fileName: `${modalData?.documentName}${modalData?.documentExtension}`,
                  error: "",
                  size: modalData?.size,
                },
              ]}
              loading={false}
              showGreenTick={false}
              showButtons={false}
            />
          ) : (
            <DragAndDrop
              helperText={`PDF, PNG, JPEG ${MAX_FILE_SIZE_LIMIT_TEXT}`}
              loading={!fileUploadStatus}
              supportedFormats={["application/pdf", "image/png", "image/jpeg"]}
              maxFileSize={MAX_FILE_SIZE_LIMIT}
              onFileSelect={legacyHandleFileUpload}
              files={files}
              setFiles={setFiles}
              uploadProgress={uploadProgress}
              maximumFilesCount={MAX_FILE_UPLOAD_COUNT}
              isFileUploaded={fileUploadStatus}
            />
          )}
        </div>

        <Text
          className="mb-1"
          name="documentName"
          label="Document name"
          placeholder="e.g. Workflow Guide"
          fontSize="16px"
          fontWeight="400"
          onChange={handleInputField}
          value={fileName}
        />
        <div className="flex flex-row mt-1 pb-15">
          <TextCheckBox
            text="Share With Employee"
            checked={shareWithEmployee}
            onChange={(e) => setShareWithEmployee(e.target.checked)}
          />
          {documentType !== "Signed Documents" && (
            <div className="ml-5">
              <TextCheckBox
                text="Share With Customer"
                checked={shareWithCustomer}
                onChange={(e) => setShareWithCustomer(e.target.checked)}
              />
            </div>
          )}
        </div>
        <div className="flex flex-row justify-end mt-05 mb-05">
          <Button label="Cancel" variant="outlined" onClick={handleCloseModal} size="large" />
          <div className="ml-05">
            <Button
              label="Save"
              isDisabled={!legacyIsSaveAllowed()}
              onClick={legacyHandleSave}
              isLoading={isLoading}
              size="large"
            />
          </div>
        </div>
      </Modal>
    );
  }

  if (!bambooHrBulkUpload) return <>{LegacyDocumentModal()}</>;

  return (
    <Modal
      width="652px"
      title={type === "add" ? "Add Document" : "Edit Document"}
      open={open}
      onClose={handleCloseModal}
    >
      <div className="para-body-m-regular">{documentType}</div>
      <div className="my-1">
        {modalData ? (
          <SelectedFile
            files={[
              {
                id: 1,
                fileName: `${modalData?.documentName}${modalData?.documentExtension}`,
                error: "",
                size: modalData?.size,
              },
            ]}
            loading={false}
            showGreenTick={false}
            showButtons={false}
          />
        ) : (
          <DragAndDrop
            loading={!fileUploadStatus}
            helperText={`PDF, PNG, JPEG ${MAX_FILE_SIZE_LIMIT_TEXT} • Max ${MAX_FILE_UPLOAD_COUNT} documents allowed`}
            supportedFormats={SUPPORTED_FORMATS}
            maxFileSize={MAX_FILE_SIZE_LIMIT}
            onFileSelect={handleFileUpload}
            isMultiFileSupported
            files={files}
            setFiles={setFiles}
            maximumFilesCount={MAX_FILE_UPLOAD_COUNT}
          />
        )}
      </div>

      <div className="flex flex-row justify-end">
        <Button label="Cancel" variant="outlined" onClick={handleCloseModal} size="large" />
        <div className="ml-05">
          <Button
            label="Save"
            isDisabled={isSaveButtonDisabled()}
            onClick={handleSave}
            isLoading={isSaving}
            size="large"
          />
        </div>
      </div>
    </Modal>
  );
}

export default DocumentModal;

/* eslint-disable radix */
import { Alert, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

function SalaryAfterIncrease(props) {
  const { costToCompany, employeeId, getSalaryAfterIncrease, getUpdatedEmployeeSalary, employeeSalaryDetails } = props;

  const [salaryAfterIncrease, setSalaryAfterIncrease] = useState(0);
  const [percentageIncrease, setPercentageIncrease] = useState(0);
  const [, setIncrementAmount] = useState(0);
  const [salaryFlag, setSalaryFlag] = useState(false);
  const [fromApiValue, setFromApiValue] = useState(false);
  const [calculatePercentage, setCalculatePercentage] = useState(false);
  const [salaryReadOnlyFlag] = useState(false);

  const handlePercentageChange = (event) => {
    let input = event.target.value.replace(/[^0-9]/g, "");
    if (input != "") {
      input = `${parseInt(input, 10)}`;
    }

    setCalculatePercentage(false);
    if (input.length < 4) {
      setFromApiValue(false);
      if (!input || (input[input.length - 1].match("[0-9]") && input[0].match("[0-9]"))) setPercentageIncrease(input);

      let raiseAmount = 0;
      let raiseSalary = "";
      let distSalaryAfterIncrease = {};

      if (costToCompany != undefined) {
        if (input != "") {
          raiseSalary = parseInt(costToCompany) + (parseInt(costToCompany) * parseInt(input)) / 100;
          raiseSalary = parseInt(raiseSalary);

          raiseAmount = raiseSalary - parseInt(costToCompany);
        } else {
          raiseSalary = parseInt(costToCompany);
          raiseAmount = 0;
        }
        setIncrementAmount(raiseAmount);
        if (employeeId != undefined) {
          distSalaryAfterIncrease = {
            employeeId,
            salaryAfterIncrease: raiseSalary,
            percentageIncrease: input,
            raiseAmount,
            negativeRaiseAmountFlag: !(raiseAmount >= 0),
            currentSalary: parseInt(costToCompany),
          };
        }

        getUpdatedEmployeeSalary(distSalaryAfterIncrease, "raiseSalary");
      }
    }
  };

  const handleSalaryAfterIncrease = (event) => {
    const input = event.target.value.replace(/[^0-9]/g, "");
    let percentageInc = "";

    setCalculatePercentage(true);

    setFromApiValue(false);
    if (input.length <= 5) {
      setSalaryAfterIncrease(input);

      let raiseAmount = 0;
      let raiseSalary = "";
      let distSalaryAfterIncrease = {};

      if (costToCompany != undefined) {
        if (input != "") {
          raiseSalary = parseInt(input);
          raiseAmount = input - parseInt(costToCompany);
        } else {
          raiseSalary = 0;
          raiseAmount = 0;
        }

        setIncrementAmount(raiseAmount);
        if (input > parseInt(costToCompany) && costToCompany) {
          percentageInc = (raiseAmount / parseInt(costToCompany)) * 100;
        } else {
          percentageInc = 0;
        }
        percentageInc = parseFloat(percentageInc).toFixed(1);
        setPercentageIncrease(percentageInc);

        if (employeeId != undefined) {
          distSalaryAfterIncrease = {
            employeeId,
            salaryAfterIncrease: raiseSalary,
            raiseAmount,
            percentageIncrease: percentageInc,
            negativeRaiseAmountFlag: !(raiseAmount >= 0),
            currentSalary: parseInt(costToCompany),
          };

          getUpdatedEmployeeSalary(distSalaryAfterIncrease, "raiseSalary");
        }
      }
    }
  };

  useEffect(() => {
    if (salaryAfterIncrease < costToCompany) {
      setSalaryFlag(true);
    } else {
      setSalaryFlag(false);
    }
  }, [salaryAfterIncrease, percentageIncrease]);

  useEffect(() => {
    let raiseAmount = 0;
    let raiseSalary = "";
    let distSalaryAfterIncrease = {};

    if (costToCompany != undefined) {
      if (percentageIncrease != "") {
        if (calculatePercentage) {
          raiseSalary = salaryAfterIncrease != "" ? parseInt(salaryAfterIncrease) : "";
        } else {
          raiseSalary = parseInt(costToCompany) + (parseInt(costToCompany) * parseInt(percentageIncrease)) / 100;
        }

        raiseAmount = raiseSalary - parseInt(costToCompany);
      } else {
        if (calculatePercentage) {
          raiseSalary = salaryAfterIncrease != "" ? parseInt(salaryAfterIncrease) : "";
        } else {
          raiseSalary = parseInt(costToCompany);
        }

        raiseAmount = 0;
      }

      setIncrementAmount(raiseAmount);
      if (employeeId != undefined) {
        distSalaryAfterIncrease = {
          employeeId,
          salaryAfterIncrease: raiseSalary,
          // raiseSalary != "" ? parseInt(raiseSalary) : "",
          percentageIncrease,
          raiseAmount,
          negativeRaiseAmountFlag: !(raiseAmount >= 0),
          currentSalary: parseInt(costToCompany),
        };

        getSalaryAfterIncrease(distSalaryAfterIncrease, "raiseSalary");
      }

      if (!fromApiValue) {
        // setSalaryAfterIncrease(raiseSalary);
        setSalaryAfterIncrease(raiseSalary != "" ? parseInt(raiseSalary) : "");
      } else if (fromApiValue && employeeSalaryDetails?.incrementDetails?.incrementPercentage == 0) {
        setSalaryAfterIncrease(
          parseInt(
            employeeSalaryDetails?.salaryAfterIncrease
              ? employeeSalaryDetails.salaryAfterIncrease
              : costToCompany + employeeSalaryDetails.incrementDetails.incrementAmount
          )
        );
      } else {
        if (employeeSalaryDetails?.incrementDetails) {
          if (employeeSalaryDetails?.incrementDetails?.incrementAmount) {
            setSalaryAfterIncrease(parseInt(costToCompany + employeeSalaryDetails?.incrementDetails?.incrementAmount));
          } else {
            setSalaryAfterIncrease(parseInt(costToCompany));
          }
        }
        if (employeeSalaryDetails?.salaryAfterIncrease) {
          setSalaryAfterIncrease(parseInt(employeeSalaryDetails.salaryAfterIncrease));
        }
      }
    }
  }, [percentageIncrease]);

  useEffect(() => {
    let raiseAmount = 0;
    let raiseSalary = "";
    let distSalaryAfterIncrease = {};
    let percentageInc = "";

    if (costToCompany != undefined) {
      if (salaryAfterIncrease != "") {
        raiseSalary = parseInt(salaryAfterIncrease);
        raiseAmount = salaryAfterIncrease - parseInt(costToCompany);
        percentageInc = (parseInt(raiseAmount) / parseInt(costToCompany)) * 100;
      } else {
        raiseSalary = 0;
        raiseAmount = 0;
        percentageInc = 0;
      }

      setIncrementAmount(raiseAmount);
      if (employeeId != undefined) {
        distSalaryAfterIncrease = {
          employeeId,
          salaryAfterIncrease: raiseSalary,
          negativeRaiseAmountFlag: !(raiseAmount >= 0),
          raiseAmount,
          // percentageIncrease: parseInt(percentageIncrease),
          percentageIncrease: parseFloat(percentageInc).toFixed(1),

          currentSalary: parseInt(costToCompany),
        };

        getSalaryAfterIncrease(distSalaryAfterIncrease, "raiseSalary");
      }
      // setPercentageIncrease(percentageIncrease);
    }
  }, [salaryAfterIncrease]);

  useEffect(() => {
    if (employeeSalaryDetails?.incrementDetails && Object.keys(employeeSalaryDetails?.incrementDetails).length) {
      if (employeeSalaryDetails?.percentageIncrease || employeeSalaryDetails?.salaryAfterIncrease) {
        if (employeeSalaryDetails?.salaryAfterIncrease) {
          setFromApiValue(true);
        } else {
          setFromApiValue(false);
        }

        setPercentageIncrease(
          employeeSalaryDetails?.percentageIncrease ? employeeSalaryDetails?.percentageIncrease : 0
        );

        setSalaryAfterIncrease(employeeSalaryDetails.salaryAfterIncrease);
      } else {
        setFromApiValue(true);
        // if (
        //     costToCompany &&
        //     employeeSalaryDetails?.incrementDetails?.incrementAmount
        // ) {
        setSalaryAfterIncrease(parseInt(costToCompany + employeeSalaryDetails.incrementDetails.incrementAmount));
        // }

        // if (
        //     employeeSalaryDetails.incrementDetails
        //         .incrementPercentage != 0
        // )
        setPercentageIncrease(
          employeeSalaryDetails?.incrementDetails?.incrementPercentage
            ? employeeSalaryDetails.incrementDetails.incrementPercentage
            : 0
        );
      }
    } else {
      setSalaryAfterIncrease(0);
      setPercentageIncrease(0);
    }
  }, [employeeSalaryDetails]);

  return (
    // <Grid container direction="row">
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      rowSpacing={0.7}
      xs={12}
      sx={{ height: "62px", display: "grid" }}
    >
      <Grid item container direction="row" alignItems="center" justifyContent="center" columnSpacing={0.5} gap="8px">
        <TextField
          sx={{
            "& .MuiInputBase-root": {
              backgroundColor: "white",
              borderRadius: "7px",
              fontFamily: "Poppins-Medium",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000000",
              textAlign: "center",
              height: "46px",
              width: "76px",
            },
            "& .MuiOutlinedInput-input": {
              textAlign: "center",
              height: "46px",
              width: "76px",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#BFC2FF",
              },
              "&:hover fieldset": {
                borderColor: "var(--color-primitives-primary-600)",
              },
            },
            "&.MuiTextField-root": {
              marginTop: "0px !important",
              textAlign: "center",
              height: "46px",
              width: "76px !important",
            },
          }}
          inputProps={{ min: 0 }}
          id="userName"
          onChange={handlePercentageChange}
          value={percentageIncrease}
          variant="outlined"
          placeholder=" %"
          size="small"
          name="email"
        />

        <TextField
          sx={{
            "& .MuiInputBase-root": {
              backgroundColor: "white",
              borderRadius: "7px",
              borderColor: "#3FCAC2",
              fontFamily: "Poppins-Medium",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000000",
              textAlign: "center",
              width: "130px",
              height: "46px",
            },
            "& .MuiInputBase-input": {
              textAlign: "center",
              width: "130px",
              height: "46px",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#BFC2FF",
              },
              "&:hover fieldset": {
                borderColor: "var(--color-primitives-primary-600)",
              },
            },
            "&.MuiTextField-root": {
              marginTop: "0px !important",
              textAlign: "center",
              borderColor: "#3FCAC2",
              width: "130px !important",
              height: "46px",
            },
          }}
          id="userName"
          onChange={handleSalaryAfterIncrease}
          // inputProps={{ readOnly: salaryReadOnlyFlag }}
          disabled={salaryReadOnlyFlag}
          error={salaryFlag}
          value={salaryAfterIncrease}
          variant="outlined"
          placeholder="$"
          size="small"
          name="email"
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <Typography pl={1} fontFamily="Poppins-Medium !important" fontSize="16px" color="#000000">
                  $
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {salaryFlag ? (
        <Grid item textAlign="center" justifyItems="center" alignItems="center" pl={1}>
          <Alert
            icon={false}
            severity="error"
            sx={{
              background: "#F58285",
              borderRadius: "80px !important",
              paddingTop: "0.7px",
              paddingBottom: "1.2px",
              paddingLeft: "12px",
              paddingRight: "12px",
              // width: "245px",
              "& .MuiAlert-message": {
                padding: "0px ",
              },
            }}
          >
            <Typography p={0} textAlign="center" variant="paragraph" fontSize="10px" color="white">
              Salary cant be lower than current Salary
            </Typography>
          </Alert>
        </Grid>
      ) : null}
    </Grid>
    // {/* <IncrementAmount ncrementAmount={incrementAmount} /> */}
    // </Grid>
  );
}

export default SalaryAfterIncrease;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import GreetingHeader from "components/GreetingHeader/GreetingHeader";
import EarningsBanner from "components/Screens/DashBoard/EmployeeDashboardV2/EarningsBanner";
import PayrollBar from "components/Screens/DashBoard/EmployeeDashboardV2/PayrollHistory/PayrollBar";
import PayrollStubs from "components/Screens/DashBoard/EmployeeDashboardV2/PayrollHistory/PayrollStubs";
import EmployeePeersCom from "components/Screens/DashBoard/EmployeeDashboardV2/EmployeePeers/EmployeePeers";
import PageLoader from "components/common/PageLoader";
import Notifications from "components/Screens/DashBoard/CustomerDashBoardV2/Notifications";

import { GetEmployeeById } from "store/actions/EmployeePortal/GetEmployeeDetials";
import { employeeSalaryDetails } from "store/actions/Workforce/EmployeeDetils/SalaryDetails";
import { employeeSalaryInfo } from "store/actions/Workforce/EmployeeDetils/SalaryInfo";
import { usePermissions } from "components/Hook";

import "./index.css";

function EmployeeDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { inAppNotifications } = useFlags();

  const { loggedInUser } = usePermissions();

  const payrollDetails = useSelector((state) => state.EmployeeSalaryDetails);
  const salaryInfo = useSelector((state) => state.SalaryInfo);

  const loadingEmployeeSalaryDetils = useSelector((state) => state.EmployeeSalaryDetails.loadingEmployeeSalaryDetils);
  const isLoadingEmployeeDetails = useSelector((state) => state.GetEmployeeDetials.isLoadingEmployeeDetails);
  const loadingEmployeeSalaryInfo = useSelector((state) => state.SalaryInfo.loadingEmployeeSalaryInfo);

  React.useEffect(() => {
    dispatch(
      employeeSalaryDetails({
        request: `employee/${loggedInUser.userId}/payroll?${new URLSearchParams({
          orderBy: JSON.stringify({
            field: "paymentDate",
            direction: "asc",
          }),
        })}`,
      })
    );

    dispatch(
      GetEmployeeById({
        employeeId: loggedInUser?.userId,
      })
    );

    if (!Object.keys(salaryInfo.employeeSalaryInfo).length) {
      dispatch(
        employeeSalaryInfo({
          request: `employee/${loggedInUser.userId}/salary`,
        })
      );
    }
    if (localStorage.payoneerCode) {
      navigate("/main/edge-wallet");
    }
  }, []);

  return (
    <>
      {isLoadingEmployeeDetails == true || loadingEmployeeSalaryDetils == true || loadingEmployeeSalaryInfo == true ? (
        <PageLoader />
      ) : null}
      <div className="ep-dashboard">
        <Grid
          container
          rowSpacing={3}
          mt={0}
          sx={{
            background: "#FBFAFF",
            width: inAppNotifications ? "100%" : "calc(100% - 328px)",
            display: "flex",
            flexDirection: "column",
            gap: "0px",
          }}
        >
          <GreetingHeader isEmployee />
          <div>
            <EarningsBanner userDetails={loggedInUser} salaryInfo={salaryInfo.employeeSalaryInfo} />
          </div>
          <div className="ep-payroll-widget">
            <div className="ep-payroll-h1">
              <div className="ep-payroll-h1a">Payroll History</div>
              <div
                className="ep-payroll-h1b"
                id="view-details-payroll-history"
                onClick={() => navigate("/main/paystubs")}
              >
                View Details
              </div>
            </div>
            <div className="ep-payroll-data-div">
              <PayrollBar
                payrollData={payrollDetails.employeeSalaryDetails}
                salaryInfo={salaryInfo.employeeSalaryInfo}
              />
              <PayrollStubs />
            </div>
          </div>
          <>
            <div className="ep-payroll-h1">
              <div className="ep-payroll-h1a">Peers</div>
              <div className="ep-payroll-h1b" id="view-all-peers" onClick={() => navigate("/main/peers")}>
                View All
              </div>
            </div>
            <EmployeePeersCom />
          </>
        </Grid>

        {!inAppNotifications && (
          <Grid
            sx={{
              width: "328px",
              height: "calc(100vh - 46px)",
              position: "fixed",
              background: "#F5F5FF",
              right: 0,
            }}
          >
            <Notifications isEmployee />
          </Grid>
        )}
      </div>
    </>
  );
}

export default EmployeeDashboard;

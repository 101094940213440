import React from "react";
import "./Button.css";

function Button({
  type = "button",
  variant,
  size,
  style,
  leadIcon,
  leadIconColor,
  tailIcon,
  children,
  onClick,
  disabled,
  className = "",
}) {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`${variant} ${size} ${disabled ? "disabled" : ""} ${className}`}
      style={{
        ...style,
        ...(disabled
          ? {
              backgroundColor: "white",
              color: "#C7C5D0",
              border: "0.5px solid #C7C5D0",
            }
          : {}),
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {leadIcon && <div style={disabled ? { color: "#ACAAB4" } : { color: leadIconColor }}>{leadIcon}</div>}
      {children}
      {tailIcon && <div>{tailIcon}</div>}
    </button>
  );
}

export default Button;

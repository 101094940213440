import React from "react";
import { useSelector } from "react-redux";

import { Grid, Paper, Typography, ListItem, List } from "@mui/material";

import pendingTasksIcon from "components/Icons/PendingTasksIcon.png";
import Notices from "components/Widgets/Notices";

function TaskGrid() {
  const noticeBoard = useSelector((state) => state.GetNoticeBoard.noticeBoard);

  return (
    <Paper
      sx={{
        pt: 2,
        pb: 1,
        pl: 3,
        minHeight: "110px",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Grid item container direction="column">
        <Grid item container direction="row" alignContent="center" columnSpacing={2} mb={2}>
          <Grid item pt={0.5}>
            <img src={pendingTasksIcon} height={18} width="auto" alt="pending task icon" />
          </Grid>
          <Grid item>
            <Typography variant="h6Bold">Pending Tasks</Typography>
          </Grid>
        </Grid>

        {noticeBoard?.ok && noticeBoard?.payload.length ? (
          noticeBoard?.payload.map((notice) => {
            return notice?.notice != null ? <Notices notice={notice} /> : null;
          })
        ) : (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              <Typography variant="h6" fontSize="12px" color="var(--color-primitives-primary-600)">
                No Notices Found
              </Typography>
            </ListItem>
          </List>
        )}
      </Grid>
    </Paper>
  );
}

export default TaskGrid;

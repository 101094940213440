/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Button, Grid, Typography, Box, TextField, Autocomplete, InputAdornment } from "@mui/material";

import DataTableV2 from "components/Widgets/DataTableV2";
import PageLoader from "components/common/PageLoader";
import SalaryAfterIncrease from "./SalaryAfterIncrease";
import CurrentSalary from "./CurrentSalary";
import BonusDropdown from "./BonusDropdown";
import Employee from "../../Workforce/CustomerWorkforceV2/Employee";

import ProfleWithIconV2 from "components/Widgets/ProfileWithIconV2";
import AvatarIcon from "components/Icons/AvatarIcon";

import { EmployeeInfo } from "store/actions/EmployeeInfo";

import { baseUrl } from "util/APIBaseUrl";
import { convertDate, getCountryFlag, getValue, currencyFormat } from "../../CommonUtils";

const arr = [];
const employeeSalaryArray = [];
let updatedEmployeesId = [];

const tableheadCells = [
  {
    id: "Employees",
    numeric: false,
    disablePadding: true,
    label: <Typography variant="h5">All Employees</Typography>,
    align: "right",
    width: "20px",
  },
  {
    id: "Email",
    numeric: true,
    disablePadding: true,
    align: "center",
    label: <Typography variant="h5">Current Salary</Typography>,
  },
  {
    id: "Country",
    numeric: false,
    disablePadding: false,
    width: "390px",
    label: (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
        }}
      >
        <Typography variant="h5" textAlign="center" sx={{ width: "76px" }}>
          % Increase
        </Typography>
        <Typography variant="h5" textAlign="start" sx={{ width: "130px" }}>
          New Salary
        </Typography>
      </Box>
    ),
  },
  {
    id: "Cost_To_Company",
    numeric: true,
    disablePadding: false,
    width: "230px",
    label: (
      <Typography variant="h5" textAlign="center" sx={{ whiteSpace: "nowrap" }}>
        Bonuses (Optional)
      </Typography>
    ),
  },
];

function BonusTable(props) {
  const dispatch = useDispatch();
  const { platfromRebrandPhase1 } = useFlags();

  const { getTotalRaiseAmount } = props;

  const [tableRows, setTableRows] = useState([]);
  const [employeeUpdatedArray, setEmployeeUpdatedArray] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [apiFilters, setApiFilters] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("All");
  const [sortBy] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [employeeCountryList, setEmployeeCountryList] = useState([]);
  const [searchEmployeeDropdwon, setSearchEmployeeDropdwon] = useState([]);
  const [searchedEmployee, setSearchedEmployee] = useState({
    key: null,
    value: "",
  });

  const [apiFilterFlag, setapiFilterFlag] = useState({
    rowsPerPage: true,
    page: false,
    apiFilters: false,
    sortBy: false,
    updatedEmployeesIds: false,
  });

  const employeesInfo = useSelector((state) => state.EmployeeInfo.employeesInfo);

  const handleRowsPerPage = (pagination) => {
    setRowsPerPage(pagination.rowsPerPage);
    setPage(pagination.page);

    setapiFilterFlag({
      rowsPerPage: true,
      page: true,
      apiFilters: false,
      sortBy: false,
      updatedEmployeesIds: false,
    });
  };

  const handleSelectedRow = () => {};

  const handleSearchEmployee = (event, option) => {
    setSearchedEmployee(option);
    setSelectedCountry("All");
    if (option) {
      setApiFilters({ employeeId: [option?.key] });
    } else {
      setApiFilters({ employeeId: [] });
    }

    setapiFilterFlag({
      rowsPerPage: false,
      page: false,
      apiFilters: true,
      sortBy: false,
      updatedEmployeesIds: false,
    });
  };

  const handleCountryFilter = (value) => {
    setIsLoading(true);
    setApiFilters({ updated: false, countries: [value] });
    setSearchedEmployee({
      key: null,
      value: "",
    });

    setSelectedCountry(value);

    setapiFilterFlag({
      rowsPerPage: false,
      page: false,
      apiFilters: true,
      sortBy: false,
      updatedEmployeesIds: false,
    });
  };

  const handleRemoveFilter = () => {
    setIsLoading(true);
    setSearchedEmployee({
      key: null,
      value: "",
    });
    setSelectedCountry("All");
    setApiFilters({});
  };

  const handleSort = () => {
    setIsLoading(true);
    updatedEmployeesId = [];

    employeeUpdatedArray.map((employee) => {
      return updatedEmployeesId.push(employee.employeeId);
    });

    setSearchedEmployee({
      key: null,
      value: "",
    });

    setApiFilters({
      countries: [],
      updated: true,
    });
    setSelectedCountry("Updated");

    setapiFilterFlag({
      rowsPerPage: false,
      page: false,
      apiFilters: true,
      sortBy: false,
      updatedEmployeesIds: false,
    });
  };

  const getCountrySpecificBonusPreset = (country) => {
    let objIndex;
    let option;
    let dropdownData = {};
    const bonusPreset = [];

    dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));
    if (dropdownData != undefined && Object.keys(dropdownData).length && dropdownData.ok) {
      dropdownData?.payload?.bonusPreset?.[country]?.map((data) => {
        option = {
          key: data.bonusPresetId,
          value: data.description == "travel" ? "Travel Allowance" : data.description == "lunch" ? "Lunch Money" : "",
          amount: data.amount,
          country: data.country,
          imageName: data.imageName,
          description: data.description,
        };

        objIndex = bonusPreset.findIndex((obj) => obj.bonusPresetId == data.bonusPresetId);

        if (bonusPreset.length && objIndex != -1) {
          Object.assign(bonusPreset[objIndex], option);
        } else {
          bonusPreset.push(option);
        }
      });
    }
    return bonusPreset;
  };

  const getUpdatedEmployeeSalary = (employeeUpdatedSalary) => {
    const employeeUpdatedSalaryIndex = employeeSalaryArray.findIndex(
      (obj) => obj.employeeId == employeeUpdatedSalary?.employeeId
    );

    if (employeeSalaryArray.length && employeeUpdatedSalaryIndex != -1) {
      Object.assign(employeeSalaryArray[employeeUpdatedSalaryIndex], employeeUpdatedSalary);
    } else {
      employeeSalaryArray.push(employeeUpdatedSalary);
    }

    setEmployeeUpdatedArray([...employeeSalaryArray]);
  };

  const getSalaryAfterIncrease = (salaryAfterIncrease, type) => {
    let totalRaiseAmount = 0;
    let totalBonusAmount = 0;
    let raises = 0;
    let bonuses = 0;
    let totalSalaries = 0;

    const objIndex = arr.findIndex((obj) => obj.employeeId == salaryAfterIncrease?.employeeId);
    if (arr?.length && objIndex != -1) {
      if (type == "raiseSalary") {
        Object.assign(arr[objIndex], salaryAfterIncrease);
      } else if (type == "bonus") {
        Object.assign(arr[objIndex], salaryAfterIncrease);
      }
    } else {
      arr.push(salaryAfterIncrease);
    }

    // eslint-disable-next-line  array-callback-return
    // eslint-disable-next-line  radix
    arr.map((item) => {
      totalRaiseAmount = parseInt(totalRaiseAmount) + parseInt(item?.raiseAmount);
      if (item.raiseAmount > 0) {
        raises += 1;
      }
      if (item.amount > 0) {
        bonuses += 1;
      }
      totalSalaries += item?.currentSalary;
      if (item?.amount != undefined) {
        totalBonusAmount = parseInt(totalBonusAmount) + parseInt(item?.amount);
      }
    });

    getTotalRaiseAmount(totalRaiseAmount, raises, totalSalaries, totalBonusAmount, bonuses, arr, employeeUpdatedArray);
  };

  const getSearchDropdownOption = () => {
    const employees = [];
    let employeesValues = sessionStorage.getItem("employeesInfo");
    employeesValues = JSON.parse(employeesValues);
    if (employeesValues != undefined && selectedCountry == "All") {
      employeesValues?.rows?.map((employee) => {
        employees.push({
          key: employee.employeeId,
          employeeId: employee.employeeId,
          value: `${getValue(employee.firstName)} ${getValue(employee.lastName)}`,
          imageName: employee?.imageName,
          picturePath: employee?.picturePath,
          pictureVersion: employee?.pictureVersion,
          firstName: employee?.firstName,
          lastName: employee?.lastName,
          jobTitle: employee?.jobTitle,
        });
      });

      setSearchEmployeeDropdwon(employees);
    }
  };

  const getEmployeesCountryList = () => {
    if (employeeCountryList?.length != undefined && !employeeCountryList?.length) {
      setEmployeeCountryList(employeesInfo?.countryList);
    }
  };

  const getUpdatedEmployeesList = (employeesValues) => {
    const updatedEmployeeInfoLength = employeesInfo?.rows?.length;
    let employeeInfoLength;
    if (selectedCountry == "Updated" && employeesInfo?.rows?.length == 0) {
      employeeInfoLength = updatedEmployeeInfoLength + 1;
    } else {
      employeeInfoLength = updatedEmployeeInfoLength;
    }

    for (let i = 0; i < employeeInfoLength; i++) {
      for (let j = 0; j < employeeUpdatedArray.length; j++) {
        if (employeeUpdatedArray[j]?.employeeId == employeesInfo?.rows[i]?.employeeId) {
          Object.assign(employeesInfo.rows[i], employeeUpdatedArray[j]);
        } else {
          let searchedEmployeeExitInEmployeesInfo;
          const updatedEmployeeExitInEmployeesInfo = employeesInfo.rows.find(
            (employee) => employee.employeeId == employeeUpdatedArray[j].employeeId
          );
          if (searchedEmployee?.key != null) {
            searchedEmployeeExitInEmployeesInfo = employeesInfo.rows.find(
              (employee) => employee.employeeId == searchedEmployee.key
            );
          }

          if (updatedEmployeeExitInEmployeesInfo == undefined && selectedCountry == "Updated") {
            const updatedEmployeeExitInSessionEmployeesInfo = employeesValues.rows.find(
              (employee) => employee.employeeId == employeeUpdatedArray[j].employeeId
            );
            if (updatedEmployeeExitInSessionEmployeesInfo != undefined) {
              employeesInfo.rows.push({
                ...updatedEmployeeExitInSessionEmployeesInfo,
                ...employeeUpdatedArray[j],
              });
            }
          }
        }
      }
    }
  };

  const storeEmployeesInfoInSession = (employeesValues) => {
    if (selectedCountry == "All" && (employeesValues == undefined || searchedEmployee?.key == null)) {
      sessionStorage.setItem("employeesInfo", JSON.stringify(employeesInfo));
    }
  };

  useEffect(() => {
    const userAdditionalInfo = JSON.parse(sessionStorage.getItem("userAdditionalInfo"));

    if (userAdditionalInfo?.customerId != undefined) {
      if (
        apiFilterFlag.page ||
        apiFilterFlag.rowsPerPage ||
        apiFilterFlag.apiFilters ||
        apiFilterFlag.updatedEmployeesIds
      ) {
        dispatch(
          EmployeeInfo({
            customerID: userAdditionalInfo?.customerId,
            rowsPerPage: 500,
            page,
            sortBy,
            dist: apiFilters,
          })
        );
      }
    }
  }, [rowsPerPage, page, apiFilters, sortBy]);

  useEffect(() => {
    const payrollData = [];
    let totalRaiseAmount = 0;
    let totalSalaries = 0;
    let totalBonusAmount = 0;
    let bonusPreset = [];

    if (employeesInfo && Object.keys(employeesInfo).length) {
      let employeesValues = sessionStorage.getItem("employeesInfo");
      employeesValues = JSON.parse(employeesValues);

      storeEmployeesInfoInSession(employeesValues);

      getUpdatedEmployeesList(employeesValues);

      setapiFilterFlag({
        rowsPerPage: false,
        page: false,
        apiFilters: false,
        sortBy: false,
        updatedEmployeesIds: true,
      });

      getEmployeesCountryList();

      getSearchDropdownOption();

      employeesInfo?.rows?.map((employee) => {
        totalRaiseAmount += parseFloat(employee?.incrementDetails?.incrementAmount);
        totalSalaries += parseFloat(employee.costToCompany);
        totalBonusAmount += parseFloat(employee?.incrementDetails?.bonus);

        if (employee.country) {
          bonusPreset = getCountrySpecificBonusPreset(employee.country);
        }

        payrollData.push({
          id: employee.employeeId,
          Employees: (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
                position: "relative",
              }}
            >
              <AvatarIcon
                shape="rounded"
                size={{
                  width: "61px",
                  height: "61px",
                  borderRadius: "6px",
                }}
                source={`${baseUrl}/employee/${employee.employeeId}/logo?id=${employee?.pictureVersion}`}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: "4px",
                  left: "4px",
                  border: "2px solid white",
                  borderRadius: "99px",
                  width: "26px",
                  height: "26px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {getCountryFlag(employee?.country)}
              </Box>
              <ProfleWithIconV2
                textColor="#060E64"
                type="textOnly"
                data={{
                  name: `${getValue(employee?.firstName)} ${getValue(employee?.lastName)}`,
                  role: getValue(employee?.jobTitle),
                  joinDate: convertDate(employee?.startDate),
                  country: getValue(employee?.country),
                }}
              />
            </Box>
          ),
          Email: (
            <CurrentSalary
              employeeSalaryDetails={employee}
              costToCompany={
                employee?.costToCompany
                  ? currencyFormat(
                      getValue(employee?.costToCompany) -
                        (employee?.incrementDetails?.bonus ? employee.incrementDetails.bonus : 0) -
                        (employee?.incrementDetails?.incrementAmount ? employee.incrementDetails.incrementAmount : 0)
                    )
                  : "-"
              }
            />
          ),
          Country: (
            <SalaryAfterIncrease
              employeeSalaryDetails={employee}
              employeeId={employee?.employeeId}
              employeeUpdatedArray={employeeUpdatedArray}
              getSalaryAfterIncrease={getSalaryAfterIncrease}
              getUpdatedEmployeeSalary={getUpdatedEmployeeSalary}
              costToCompany={
                employee?.costToCompany
                  ? getValue(employee?.costToCompany) -
                    (employee?.incrementDetails?.bonus ? employee.incrementDetails.bonus : 0) -
                    (employee?.incrementDetails?.incrementAmount ? employee.incrementDetails.incrementAmount : 0)
                  : 0
              }
            />
          ),

          Cost_To_Company: (
            <BonusDropdown
              employeeSalaryDetails={employee}
              employeeUpdatedArray={employeeUpdatedArray}
              getSalaryAfterIncrease={getSalaryAfterIncrease}
              getUpdatedEmployeeSalary={getUpdatedEmployeeSalary}
              employeeId={employee?.employeeId}
              costToCompany={
                employee?.costToCompany
                  ? getValue(employee?.costToCompany) -
                    (employee?.incrementDetails?.bonus ? employee.incrementDetails.bonus : 0) -
                    (employee?.incrementDetails?.incrementAmount ? employee.incrementDetails.incrementAmount : 0)
                  : 0
              }
              bonusPreset={bonusPreset}
            />
          ),
        });
      });
    }

    setTableRows(payrollData);
    setIsLoading(false);
  }, [employeeUpdatedArray, employeesInfo]);

  return (
    <>
      {isLoading && <PageLoader />}
      <Grid sx={{ display: "flex", flexDirection: "column", gap: "18px" }}>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <Box alignItems="center">
              <Button
                variant="contained"
                disableElevation
                onClick={handleRemoveFilter}
                sx={{
                  backgroundColor: selectedCountry == "All" ? "var(--color-primitives-primary-100)" : "transparent",
                  border:
                    selectedCountry == "All" ? "1px solid var(--color-primitives-primary-100)" : "1px solid #777680",
                  borderRadius: "22px",
                  height: "24px",
                  minWidth: "2px",
                  mr: "7px",
                  ":hover": {
                    backgroundColor: selectedCountry == "All" ? "var(--color-primitives-primary-100)" : "transparent",
                  },
                }}
              >
                <Typography variant="h6" color="#212231">
                  All
                </Typography>
              </Button>
              {employeeCountryList?.length
                ? employeeCountryList?.map((item) => (
                    <Button
                      variant="contained"
                      disableElevation
                      size="small"
                      onClick={() => handleCountryFilter(item)}
                      mx={1}
                      sx={{
                        backgroundColor:
                          selectedCountry == item ? "var(--color-primitives-primary-100)" : "transparent",
                        border:
                          selectedCountry == item
                            ? "1px solid var(--color-primitives-primary-100)"
                            : "1px solid #777680",
                        height: "24px",
                        borderRadius: "22px",
                        minWidth: "2px",
                        mr: "7px",
                        ":hover": {
                          backgroundColor:
                            selectedCountry == item ? "var(--color-primitives-primary-100)" : "transparent",
                        },
                      }}
                    >
                      <Typography variant="h6" color="#212231">
                        {item}
                      </Typography>
                    </Button>
                  ))
                : null}

              <Button
                variant="contained"
                disableElevation
                size="small"
                onClick={handleSort}
                mx={1}
                sx={{
                  backgroundColor: selectedCountry == "Updated" ? "var(--color-primitives-primary-100)" : "transparent",
                  border:
                    selectedCountry == "Updated"
                      ? "1px solid var(--color-primitives-primary-100)"
                      : "1px solid #777680",
                  height: "24px",
                  borderRadius: "22px",
                  minWidth: "2px",
                  mr: "7px",
                  ":hover": {
                    backgroundColor:
                      selectedCountry == "Updated" ? "var(--color-primitives-primary-100)" : "transparent",
                  },
                }}
              >
                <Typography variant="h6" color="#212231">
                  Updated
                </Typography>
              </Button>
            </Box>
          </Grid>
          <Grid item textAlign="flex-end" xl={4} lg={4} md={4} sm={12} xs={12}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Autocomplete
                options={searchEmployeeDropdwon}
                value={searchedEmployee}
                fullWidth
                onChange={handleSearchEmployee}
                inputValue={searchedEmployee?.value || ""}
                className="bonus-table-autocomplete"
                onInputChange={(event, newInputValue) => {
                  setSearchedEmployee((prev) => ({ ...prev, value: newInputValue }));
                }}
                getOptionLabel={(option) => option.value}
                filterOptions={(options, state) =>
                  options.filter((option) => option.value.toLowerCase().includes(state.inputValue.toLowerCase()))
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    paddingRight: "4px !important",
                    padding: "4px",
                    borderRadius: "5px",
                    fontSize: "12px",
                  },

                  "& .MuiSvgIcon-root": {
                    fill: platfromRebrandPhase1 ? "red" : "#00201C",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    type="search"
                    id="search"
                    {...params}
                    variant="outlined"
                    fullWidth
                    placeholder="Search employees"
                    sx={{
                      ".MuiInputBase-root": {
                        backgroundColor: "white",
                        borderRadius: "99px",
                        borderColor: "#BFC2FF",
                        outlineColor: "#BFC2FF",
                        fontFamily: "Poppins-Regular",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        paddingRight: "6px",
                        paddingLeft: "14px",
                        border: "1px solid #BFC2FF",
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="start">
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14.9066 13.6148L19.8274 18.5355L18.5356 19.8272L13.6149 14.9065C12.3775 15.8684 10.8227 16.4412 9.13409 16.4412C5.09844 16.4412 1.8269 13.1696 1.8269 9.13397C1.8269 5.09832 5.09844 1.82678 9.13409 1.82678C13.1697 1.82678 16.4413 5.09832 16.4413 9.13397C16.4413 10.8226 15.8685 12.3774 14.9066 13.6148ZM9.13409 14.6144C12.1608 14.6144 14.6145 12.1607 14.6145 9.13397C14.6145 6.10724 12.1608 3.65358 9.13409 3.65358C6.10736 3.65358 3.6537 6.10724 3.6537 9.13397C3.6537 12.1607 6.10736 14.6144 9.13409 14.6144Z"
                              fill={platfromRebrandPhase1 ? "var(--color-primitives-neutral-400)" : "#00201C"}
                            />
                          </svg>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <span {...props}>
                    <Employee
                      setShowDropdown={() => {}}
                      showHoverStyles
                      employee={option}
                      clearSearchField={() => {}}
                    />
                  </span>
                )}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid item>
          <DataTableV2
            rowsData={tableRows}
            tableRowContent="top"
            tableHeader
            totalCount={
              employeesInfo?.count && selectedCountry != "Updated"
                ? employeesInfo.count
                : selectedCountry == "Updated"
                  ? employeesInfo?.rows?.length
                  : 0
            }
            tableheadCells={tableheadCells}
            title={null}
            rowTextAlign="start"
            subTitle={null}
            toolBar={false}
            minimumHeight
            slectedRowNavigation="/main/details"
            handleRowsPerPage={handleRowsPerPage}
            handleSelectedRow={handleSelectedRow}
            openDetailsPage="First_Name"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default BonusTable;

import React, { useState } from "react";
import { useSelector } from "react-redux";

import Button from "components/common/Button";
import CustomTooltip from "components/common/Tooltip";
import PageLoader from "components/common/PageLoader";
import PaymentBreakdownModal from "./PaymentBreakdownModal";

import { getUserAdditionalInfo, getLoadingAdditionalInfo } from "selectors/user";

import { InfoOutlined, ChevronRightOutlined } from "@mui/icons-material";

import "./PaymentBreakdown.scss";

function OnBoardingPaymentBreakdown() {
  const [showPaymentBreakdownModal, setShowPaymentBreakdownModal] = useState(false);

  const userAdditionalInfo = useSelector(getUserAdditionalInfo);
  const loadingAdditionalInfo = useSelector(getLoadingAdditionalInfo);

  const {
    totalAmount = 0,
    totalMonthlyFee = 0,
    nextMonthFee = 0,
    totalImplementationFee = 0,
    totalProratedAdjustment = 0,
    discount = 0,
    nextMonthFeeDiscount = 0,
    nextMonthTotalAmount = 0,
    associatesPlaced = 0,
  } = userAdditionalInfo?.onboardingChargeDetails ?? {};

  return (
    <div>
      <PaymentBreakdownModal
        open={showPaymentBreakdownModal}
        onClose={() => setShowPaymentBreakdownModal(false)}
        nextMonthFee={nextMonthFee}
        nextMonthTotalAmount={nextMonthTotalAmount}
        discount={nextMonthFeeDiscount}
        employeeCount={associatesPlaced}
      >
        {loadingAdditionalInfo ? <PageLoader /> : null}
      </PaymentBreakdownModal>

      <div className="payment-breakdown-wrapper">
        <div className="payment-breakdown">
          <div className="payment-breakdown-top" />
          <div className="payment-breakdown-header">
            <p className="payment-breakdown-title">First payment due</p>
          </div>
          <div className="amount-container">
            <div className="flex flex-col items-start">
              <p className="heading-h1-semibold">$ {totalAmount?.toLocaleString()}</p>
              <p className="para-body-m-regular color-text-body-primary">Total due this month</p>
            </div>
            <span className="para-body-m-regular color-text-body-primary">{associatesPlaced} employees</span>
          </div>
          <div className="amount-fee-container">
            <div className="amount-fee-item">
              <p className="flex items-center para-body-m-regular">
                Total monthly fee
                <CustomTooltip
                  content={
                    <div className="para-body-xs-medium">
                      Your regular monthly charge for your associate's services.
                    </div>
                  }
                  arrow={false}
                  placement="top"
                >
                  <InfoOutlined className="amount-fee-item-icon" />
                </CustomTooltip>
              </p>
              <span className="para-body-m-regular text-black">$ {totalMonthlyFee?.toLocaleString()}</span>
            </div>
            <div className="amount-fee-item">
              <p className="flex items-center para-body-m-regular">
                Total implementation fee
                <CustomTooltip
                  content={
                    <div className="para-body-xs-medium">
                      This is a one-time charge to set up your account and activate services.
                    </div>
                  }
                  arrow={false}
                  placement="top"
                >
                  <InfoOutlined className="amount-fee-item-icon" />
                </CustomTooltip>
              </p>
              <span className="para-body-m-regular text-black">$ {totalImplementationFee?.toLocaleString()}</span>
            </div>
            <div className="amount-fee-item">
              <p className="flex items-center para-body-m-regular">
                Total prorated adjustment
                <CustomTooltip
                  content={
                    <div className="para-body-xs-medium">
                      Based on your start date with your associate, an amount is adjusted to ensure you only pay for the
                      actual days of service received.
                    </div>
                  }
                  arrow={false}
                  placement="top"
                >
                  <InfoOutlined className="amount-fee-item-icon" />
                </CustomTooltip>
              </p>
              <span className="para-body-m-regular text-black">$ ({totalProratedAdjustment?.toLocaleString()})</span>
            </div>
            <div className="amount-fee-item">
              <p className="para-body-m-regular">Discount</p>
              <span className="para-body-m-regular text-black">$ ({discount?.toLocaleString()})</span>
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-05 pt-075">
          <Button
            type="button"
            name="next_month_breakdown"
            variant="text"
            size="small"
            label="See next month breakdown"
            isFullWidth={false}
            onClick={() => setShowPaymentBreakdownModal(true)}
            endIcon={<ChevronRightOutlined />}
          />
        </div>
      </div>
    </div>
  );
}

export default OnBoardingPaymentBreakdown;

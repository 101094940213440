import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector, useDispatch } from "react-redux";

import HolidayInfo from "./HolidayInfo";
import EmploymentInfo from "./EmploymentInfo";

import { usePermissions } from "components/Hook";
import PageLoader from "components/common/PageLoader";
import EdgeLogo from "components/Design/EdgeLogo/EdgeLogo";

import { GetAllHolidays } from "store/actions/Holidays";
import { getHolidaysDate, getWorkingDuration } from "components/Screens/CommonUtils";
import { baseUrl } from "util/APIBaseUrl";
import { getDateInFormat } from "helpers";

import EdgeOldLogo from "assets/svgs/EdgeLogo";
import CelebrationIcon from "assets/svgs/CelebrationIcon";

function ProfileTab() {
  const dispatch = useDispatch();
  const { loggedInUser, loggedInUserType } = usePermissions();
  const { platfromRebrandPhase1 } = useFlags();

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const allHolidays = useSelector((state) => state.Holidays.allHolidays);
  const loadingHolidaysList = useSelector((state) => state.Holidays.loadingHolidaysList);

  useEffect(() => {
    if (getEmployeeDetails?.ok) {
      const request = {
        customer: `${baseUrl}/customer/${loggedInUser?.customerId}/publicHolidays`,
        edge: `${baseUrl}/employee/${loggedInUser?.employeeId}/publicHolidays`,
      };

      dispatch(
        GetAllHolidays({
          request: request[loggedInUserType],
          region: getEmployeeDetails?.payload?.country,
          startDate: getHolidaysDate()?.startDate,
          endDate: getHolidaysDate()?.endDate,
        })
      );
    }
  }, [loggedInUserType, getEmployeeDetails, loggedInUser]);

  return (
    <>
      {(loadingGetEmployeeDetails || loadingHolidaysList) && <PageLoader />}
      <Grid>
        <Grid
          container
          sx={{
            border: "1px solid #B2B4E2",
            borderRadius: "24px",
          }}
        >
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "18px",
              padding: "4px 15px",
            }}
          >
            <Grid
              sx={{
                padding: "5px",
                boxShadow: "0px 4px 22px rgba(6, 14, 100, 0.03)",
                borderRadius: "15px",
              }}
            >
              {platfromRebrandPhase1 ? (
                <div className="flex items-center justify-center h-3 w-3">
                  <EdgeLogo icon size="lg" />
                </div>
              ) : (
                <EdgeOldLogo />
              )}
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins-Medium",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#000000",
                }}
              >
                {getWorkingDuration(getEmployeeDetails?.payload?.startDate)}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  color: "#52537A",
                }}
              >
                Work Duration
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "18px",
              padding: "4px 15px",
              borderLeft: "1px solid #B2B4E2",
            }}
          >
            <Grid
              sx={{
                padding: "5px",
                boxShadow: "0px 4px 22px rgba(6, 14, 100, 0.03)",
                borderRadius: "15px",
              }}
            >
              <CelebrationIcon />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins-Medium",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#000000",
                }}
              >
                {getDateInFormat(getEmployeeDetails?.payload?.dateOfBirth)}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  color: "#52537A",
                }}
              >
                {getEmployeeDetails?.payload?.firstName ? `${getEmployeeDetails?.payload?.firstName}’s Birthday` : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <HolidayInfo allHolidays={allHolidays?.data} />
        <EmploymentInfo />
      </Grid>
    </>
  );
}

export default ProfileTab;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";

import SidebarContent from "components/main/sidebar/components/SidebarContent";
import CollapsedSidebar from "components/main/sidebar/components/CollapsedSidebar";
import { StyledDrawer } from "components/main/sidebar/utils/SidebarDrawer";

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import "./Sidebar.scss";

function Sidebar({
  open = false,
  menuItems = [],
  logo,
  smallLogo,
  userInfo = {},
  onMenuItemClick,
  stickyItems = [],
  onToggle,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const [internalOpen, setInternalOpen] = useState(open);

  const handleMenuItemClick = (path, openInNewTab) => {
    if (onMenuItemClick) {
      onMenuItemClick(path, openInNewTab);
    } else if (path) {
      if (openInNewTab) {
        window.open(path, "_blank", "noopener,noreferrer");
      } else {
        navigate(path);
      }
    }
  };

  const handleToggle = () => {
    const newOpenState = !internalOpen;
    setInternalOpen(newOpenState);
    if (onToggle) {
      onToggle(newOpenState);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setInternalOpen(open);
  }, [open]);

  return (
    <StyledDrawer
      variant="permanent"
      anchor="left"
      open={internalOpen}
      sx={{ "& .MuiDrawer-paper": { justifyContent: "space-between" } }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!internalOpen ? (
        <CollapsedSidebar
          menuItems={menuItems}
          smallLogo={
            isHovered ? (
              <div className="sidebar-toggle-button collapsed" onClick={handleToggle}>
                <KeyboardDoubleArrowRightIcon fontSize="small" />
              </div>
            ) : (
              smallLogo
            )
          }
          location={location}
          onMenuItemClick={handleMenuItemClick}
          stickyItems={stickyItems}
          userInfo={userInfo}
        />
      ) : (
        <SidebarContent
          menuItems={menuItems}
          logo={
            <>
              {logo}
              {isHovered && (
                <div className="sidebar-toggle-button expanded" onClick={handleToggle}>
                  <KeyboardDoubleArrowLeftIcon fontSize="small" />
                </div>
              )}
            </>
          }
          userInfo={userInfo}
          location={location}
          onMenuItemClick={handleMenuItemClick}
          stickyItems={stickyItems}
        />
      )}
    </StyledDrawer>
  );
}

Sidebar.propTypes = {
  open: PropTypes.bool,

  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      icon: PropTypes.node.isRequired,
      category: PropTypes.string,
      openInNewTab: PropTypes.bool,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
          icon: PropTypes.node.isRequired,
          openInNewTab: PropTypes.bool,
        })
      ),
    })
  ),

  logo: PropTypes.node,
  smallLogo: PropTypes.node,

  userInfo: PropTypes.shape({
    name: PropTypes.string,
    companyName: PropTypes.string,
    startDate: PropTypes.string,
    avatar: PropTypes.node,
  }),

  onMenuItemClick: PropTypes.func,

  stickyItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      icon: PropTypes.node.isRequired,
    })
  ),

  onToggle: PropTypes.func,
};

Sidebar.defaultProps = {
  open: false,
  menuItems: [],
  logo: null,
  smallLogo: null,
  userInfo: {},
  onMenuItemClick: null,
  stickyItems: [],
  onToggle: null,
};

export default Sidebar;

import { useFlags } from "launchdarkly-react-client-sdk";

import { Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import vivoMainLogo from "assets/images/vivoMainLogo.svg";

import { s3ImagesBaseURL } from "util/APIBaseUrl";

function NewChatExperience({ examplePrompts, onPromptClick }) {
  const { platfromRebrandPhase1 } = useFlags();

  return (
    <div className="newChatExperience">
      <div className="centralMessage ">
        <div>
          <img
            src={platfromRebrandPhase1 ? `${s3ImagesBaseURL}/vivo.svg` : vivoMainLogo}
            className="logo"
            alt="vivo main logo"
          />
        </div>
        <div>
          <Typography
            sx={{
              fontFamily: "Poppins-Semibold",
              color: "#292A3D",
              textAlign: "center",
              fontSize: "20px",
              fontStyle: "normal",
              lineHeight: "31px",
              letterSpacing: "0.1px",
            }}
          >
            How can I help you today?
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              color: "#292A3D",
              textAlign: "center",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Choose a prompt from the list below or write your own to start a conversation:
          </Typography>
        </div>
      </div>
      <div className="examplePrompts">
        {examplePrompts.map((prompt, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="examplePrompt" onClick={() => onPromptClick(prompt.text)}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#14151F",
                textAlign: "left",
                flexGrow: 1,
              }}
            >
              {prompt.text}
            </Typography>
            <ArrowUpwardIcon
              sx={{ color: "var(--temp-color-primitives-primary-800)", visibility: "hidden" }}
              className="promptArrow"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default NewChatExperience;

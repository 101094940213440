import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Layout from "components/Screens/CustomerOnboarding/Layout";
import CalendlyWidget from "components/Screens/CustomerOnboarding/CalendlyMeeting/CalendlyWidget";
import Button from "components/common/Button";
import CalendlySuccessModal from "components/Screens/CustomerOnboarding/CalendlyMeeting/CalendlySuccessModal";

import { getLoggedInUserDetailsFromLocalStorage } from "helpers";
import { isEmptyObject } from "components/Screens/CustomerOnboarding/helpers";

import { getUserAdditionalInfo } from "selectors/user";

import { calendlyMeetingSuccessAPI } from "services/customer";

import "./CalendlyMeeting.scss";

function CalendlyMeeting() {
  const loggedInUser = getLoggedInUserDetailsFromLocalStorage();
  const userAdditionalInfo = useSelector(getUserAdditionalInfo);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const { accountManager = {} } = userAdditionalInfo;
  const { customerId = "", email = "", name = "" } = loggedInUser;
  const { calendlyLink = "" } = accountManager;
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const { onboardingProgress = {} } = userAdditionalInfo;

  const { steps = [] } = onboardingProgress;
  const MeetingStep = steps.find((step) => step.slug === "calendly-meeting");

  const onMeetingSuccess = async (calendlyEventData = {}) => {
    await calendlyMeetingSuccessAPI(
      customerId,
      { calendlyData: { customerEmail: email, ...calendlyEventData } },
      dispatch
    );
    setIsBtnDisabled(false);
  };

  const handleFinishClick = () => {
    setIsSuccessModalOpen(true);
  };

  useEffect(() => {
    if (MeetingStep?.completed_at !== null) {
      navigateTo("/main/dashboard");
    }
  }, []);

  return (
    <Layout
      title="Schedule Kick-off"
      fullName={name}
      isMeetingCreated={!isBtnDisabled}
      contentClass="calendly-onboarding-content-container w-100p"
    >
      {isEmptyObject(accountManager) ? (
        <div className="text-center para-body-m-regular p-4">
          It seems that your Account Manger is not assigned. Please reach out to your sales representative or{" "}
          <Link
            className="mail-to-link para-body-m-regular"
            to="#"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "mailto:support@onedge.co";
            }}
          >
            support@onedge.co
          </Link>{" "}
          to get help.
        </div>
      ) : (
        <div className="calendly-meeting-main-container">
          <CalendlyWidget prefill={{ email, name }} onMeetingSuccess={onMeetingSuccess} calendlyLink={calendlyLink} />
          <div className="calendly-onboarding-bottom-button">
            <div className="calendly-onboarding-bottom-button-container">
              <Button isDisabled={isBtnDisabled} label="Finish" size="large" onClick={handleFinishClick} />
            </div>
          </div>
        </div>
      )}
      <CalendlySuccessModal isOpen={isSuccessModalOpen} onClose={() => setIsSuccessModalOpen(false)} />
    </Layout>
  );
}

export default CalendlyMeeting;
